import React, { useState, useEffect } from 'react';
import { Grid, Tabs, Tab, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { categoriseTables } from '../../../helpers/dashboard.js';
import Table from './Table.js';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  tabs: {
    background: 'rgba(255,255,255,0.5)'
  },
  tab: {
    // so pulse can be seen
    overflow: 'visible',
    // override deselected tab opacity
    opacity: 1
  },
  content: {
    position: 'absolute',
    top: '4.9rem',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '2rem',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
      transform: 'scale(1)'
    },
    '100%': {
      opacity: 0,
      transform: 'scale(1.5)'
    }
  },
  pulsingBadge: {
    '&:before': {
      position: 'absolute',
      zIndex: -1,
      content: '""',
      backgroundColor: 'inherit',
      width: 20,
      height: 20,
      animation: '$pulse 1s linear infinite',
      borderRadius: 10
    }
  },
  sessionFinished: {
    top: '0.1rem'
  }
}));

const TableList = (props) => {
  const { tables, onGoToTableClick, refetch, session } = props;
  const [tableFilter, setTableFilter] = useState('all');
  const classes = useStyles();
  const { t } = useTranslation();

  const handleTableFilterChange = (event, value) => {
    setTableFilter(value);
  };

  useEffect(() => {
    // when the table is over, refresh since we want to show the
    // players' next table
    const aTableIsInactive = tables.some(table => table.status === 'InactiveFinished');

    if (aTableIsInactive) {
      refetch();
    }
  }, [refetch, tables]);

  const { visibleTables, counts } = categoriseTables(tables, tableFilter);

  const sessionFinished = session.status === 'Finished';

  // when session finishes, the tabs hide, so revert filter to 'all'
  useEffect(() => {
    if (session.status === 'Finished') {
      setTableFilter('all');
    }
  }, [session.status]);

  const atLeastOneDirectorCall = tables.some((table) => table.directorCallStatus === 'DirectorCalled');

  return (
    <>
      {
        !sessionFinished && (
          <div className={classes.tabs}>
            <Tabs
              onChange={handleTableFilterChange}
              value={tableFilter}
              indicatorColor='primary'
              centered
            >
              <Tab
                className={classes.tab}
                label={
                  <Badge color='secondary' badgeContent={counts.all}>{t('all-tables')}</Badge>
                }
                value='all'
              />
              <Tab
                className={classes.tab}
                label={
                  <Badge color='secondary' badgeContent={counts.inProgress}>{t('in-progress')}</Badge>
                }
                value='inProgress'
              />
              <Tab
                className={classes.tab}
                label={
                  <Badge color='secondary' badgeContent={counts.waitingForMovement}>{t('awaiting-movement')}</Badge>
                }
                value='waitingForMovement'
              />
              <Tab
                className={classes.tab}
                label={
                  <Badge
                    color={atLeastOneDirectorCall ? 'error' : 'secondary'}
                    classes={{
                      badge: atLeastOneDirectorCall && classes.pulsingBadge
                    }}
                    badgeContent={counts.requiringAttention}
                  >
                    {t('requiring-attention')}
                  </Badge>
                }
                value='requiringAttention'
              />
            </Tabs>
          </div>
        )
      }
      <div className={clsx(classes.content, sessionFinished && classes.sessionFinished)}>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='stretch'
          wrap='nowrap'
          spacing={2}
        >
          {
            visibleTables.map(table => {
              return (
                <Grid item key={table.id}>
                  <Table
                    key={table.id}
                    table={table}
                    handleGoToTableClick={() => { onGoToTableClick(table.id); }}
                  />
                </Grid>
              );
            })
          }
        </Grid>
      </div>
    </>
  );
};

export default TableList;
