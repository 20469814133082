import gql from 'graphql-tag';
import { userFragments } from '../fragments';

const userSubscriptions = {
  userUpdate: gql`
    subscription {
      userUpdate {
        ...FullUser
      }
    }
    ${userFragments.fullUser}
  `,
  userUpdateById: gql`
    subscription ($ids: [ID]!) {
      userUpdateById(ids: $ids) {
        ...FullUser
      }
    }
    ${userFragments.fullUser}
  `
};

export default userSubscriptions;
