import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PPSnackbar from './PPSnackbar.js';

const useStyles = makeStyles(theme => ({
  snackbarContainer: {
    position: 'absolute',
    top: 24,
    right: 24
  }
}));

const PPSnackbarContext = React.createContext();

const PPSnackbarProvider = (props) => {
  const { children } = props;
  const [snackbars, setSnackbars] = useState([]);
  const classes = useStyles();

  const openSnackbar = useCallback((key, message, type, optionalProps) => {
    const newSnackbar = {
      key,
      type,
      message,
      optionalProps: optionalProps || {}
    };

    setSnackbars(snackbars => {
      // if a snackbar already exists with this key, don't add it
      if (snackbars.find(snackbar => snackbar.key === key)) {
        return;
      }

      return [
        ...snackbars,
        newSnackbar
      ];
    });
  }, [setSnackbars]);

  const closeSnackbar = useCallback((key, reason) => {
    setSnackbars(snackbars => {
      const idx = snackbars.findIndex(snackbar => snackbar.key === key);
      const snackbar = snackbars[idx];

      if (!snackbar) {
        return snackbars;
      }

      if (snackbar.optionalProps.noClickAway && reason === 'clickaway') {
        return snackbars;
      }

      const newSnackbars = snackbars.concat();
      newSnackbars.splice(idx, 1);

      return newSnackbars;
    });
  }, [setSnackbars]);

  return (
    <PPSnackbarContext.Provider
      value={{
        openSnackbar: openSnackbar,
        closeSnackbar: closeSnackbar
      }}
    >
      <div className={classes.snackbarContainer}>
        {snackbars.map(snackbar => (
          <PPSnackbar
            key={snackbar.key}
            snackbarKey={snackbar.key}
            closeSnackbar={closeSnackbar}
            isOpen={true}
            message={snackbar.message}
            optionalProps={snackbar.optionalProps}
            type={snackbar.type}
          />
        ))}
      </div>
      {children}
    </PPSnackbarContext.Provider>
  );
};

const PPSnackbarConsumer = PPSnackbarContext.Consumer;

export { PPSnackbarProvider, PPSnackbarConsumer };
export default PPSnackbarContext;
