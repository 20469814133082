import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardWrapper from './CardWrapper.js';

const useStyles = makeStyles(() => ({
  group: {
    display: 'inline-block'
  },
  focusedSuit: {
    marginRight: 0,
    '&:last-child': {
      margin: 0
    }
  }
}));

const SpreadCardSuitGroup = (props) => {
  const {
    cards,
    seatIndex,
    focusedSuit,
    scale,
    ...other
  } = props;
  const classes = useStyles();

  if (cards.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(
        classes.group,
        focusedSuit && classes.focusedSuit
      )}
      style={{fontSize:`${scale}rem`}}
    >
      {
        cards.map((card, index) => {
          return (
            <CardWrapper
              key={index}
              card={card}
              isTop={seatIndex === 0}
              focusedSuit={focusedSuit}
              {...other}
            />
          );
        })
      }
    </div>
  );

};

SpreadCardSuitGroup.propTypes = {
  seatIndex: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  faceDown: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  onCardClick: PropTypes.func,
  cardRefCallback: PropTypes.func,
  scale: PropTypes.number
};

export default SpreadCardSuitGroup;
