import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LobbyPair from '../../../Components/Lobby/LobbyPair.js';
import DeletePairButton from '../../../Components/Lobby/DeletePairButton.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    color: '#333'
  },
  noPairChosenMsg: {
    background: '#fff',
    borderRadius: '0.5rem',
    textAlign: 'center',
    fontSize: '1.4rem',
    height: '6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const CurrentUserLobbyPair = (props) => {
  const { pairs, currentUser, session } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  let currentUserPair;

  if (currentUser.gameStatus === 'InPair') {
    currentUserPair = pairs.find(pair =>
      pair.user1.id === currentUser.id ||
      pair.user2.id === currentUser.id
    );
  }

  return (
    <div className={classes.root}>
      {
        currentUserPair
          ? <LobbyPair
            pair={currentUserPair}
            actionButton={
              <DeletePairButton
                pair={currentUserPair}
                disabled={session.status !== 'NotStarted'}
              />
            }
          />
          : <div className={classes.noPairChosenMsg}>
            {t('you-havent-chosen-your-partner')}
            </div>
      }
    </div>
  );
};

CurrentUserLobbyPair.propTypes = {
  pair: PropTypes.object
};

export default CurrentUserLobbyPair;
