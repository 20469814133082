import React from 'react';
import { boardQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';

const BoardNumbers = (props) => {
  const { children } = props;
  return (
    <PPQuery
      query={boardQueries.boardNumbers}
    >
      {data => {
        if (!data || !data.boardNumbers) { return null; }

        return children(data.boardNumbers);
      }}
    </PPQuery>
  );
};

export default BoardNumbers;
