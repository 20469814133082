import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import PPDialog from '../../../Components/PPDialog.js';

const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute !important',
    left: '0 !important',
    right: '0 !important',
    top: '0 !important',
    bottom: '0 !important'
  },
  backdrop: {
    position: 'absolute !important',
    left: '0 !important',
    right: '0 !important',
    top: '0 !important',
    bottom: '0 !important',
    backgroundColor: fade(theme.palette.background.main, 0.7)
  }
}));

const LobbyDialog = (props) => {
  const { children, containerRef, ...rest } = props;
  const classes = useStyles();

  return (
    <PPDialog
      open={true}
      classes={{ root: classes.dialog }}
      BackdropProps={{ classes: { root: classes.backdrop } }}
      container={containerRef.current}
      disableEnforceFocus
      {...rest}
    >
      {children}
    </PPDialog>
  );
};

LobbyDialog.propTypes = {
  children: PropTypes.node.isRequired,
  containerRef: PropTypes.object.isRequired
};

export default LobbyDialog;
