import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import hashColor from 'hash-color-material';
import OnlineStatusIndicator from './OnlineStatusIndicator.js';
import { getInitials } from '../../helpers/users.js';

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: '1em',
    width: '2.5em',
    maxWidth: '4.5rem',
    height: '2.5em',
    maxHeight: '4.5rem',
    backgroundColor: props => props.bgColor,
    color: props => theme.palette.getContrastText(props.bgColor)
  }
}));

const PlayerAvatar = (props) => {
  const { onlineStatus, avatarUrl, fullName, firstName, lastName } = props;
  const color = hashColor.getColorFromString(fullName, false, false);
  const classes = useStyles({ bgColor: color });

  // the <Avatar> component will fall back to these if avatarUrl is undefined
  // or if the image doesn't load
  // https://material-ui.com/components/avatars/#letter-avatars
  // https://material-ui.com/components/avatars/#fallbacks
  const initials = getInitials(firstName, lastName);

  return (
    <OnlineStatusIndicator
      onlineStatus={onlineStatus}
    >
      <Avatar
        className={classes.avatar}
        src={avatarUrl}
      >
        {initials}
      </Avatar>
    </OnlineStatusIndicator>
  );
};

PlayerAvatar.propTypes = {
  onlineStatus: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fullName: PropTypes.string,
  avatarUrl: PropTypes.string
};

export default PlayerAvatar;
