import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { sessionMutations } from '../../../graphql/mutations/';
import EndRoundDialog from './EndRoundDialog.js';
import { usePPMutation } from '../../../hooks/';
import { useTranslation } from 'react-i18next';

const EndRoundButton = (props) => {
  const { finalRound } = props;
  const { t } = useTranslation();

  const [showingEndRoundDialog, setShowingEndRoundDialog] = useState(false);
  const [endRound, { called, error, reset }] = usePPMutation(sessionMutations.endRound);

  const handleDialogContinue = useCallback(async () => {
    await endRound();
    setShowingEndRoundDialog(false);
    reset();
  }, [endRound, setShowingEndRoundDialog, reset]);

  const handleDialogCancel = () => {
    setShowingEndRoundDialog(false);
  };

  const handleEndRoundClick = () => {
    setShowingEndRoundDialog(true);
  };

  return (
    <>
      <Button
        onClick={handleEndRoundClick}
        variant='contained'
        color='secondary'
      >
        {
          finalRound
            ? t('end-session')
            : t('start-next-round')
        }
      </Button>
      {
        showingEndRoundDialog &&
          (
            <EndRoundDialog
              open
              handleContinue={handleDialogContinue}
              handleCancel={handleDialogCancel}
              finalRound={finalRound}
              loading={called && !error}
            />
          )
      }
    </>
  );
};

export default EndRoundButton;
