import gql from 'graphql-tag';

const userSettingsMutations = {
  updateUserSetting: gql`
    mutation ($name: String!, $value: String!) {
      updateUserSetting(name: $name, value: $value) {
        userId
      }
    }
  `,
  updateSoundSetting: gql`
    mutation ($name: String!, $value: Boolean!) {
      updateSoundSetting(name: $name, value: $value) {
        userId
      }
    }
  `
};

export default userSettingsMutations;
