import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { getSessionId } from '../../helpers/url.js';

const PPRouter = (props) => {
  const { children } = props;

  const sessionId = getSessionId(window.location.pathname);
  
  // this allows us to treat the first URL segment as the sessionId
  // and not have to worry about adding it in any routes throughout the app
  return (
    <BrowserRouter
      basename={sessionId ? '/session/' + sessionId : null}
    >
      {children}
    </BrowserRouter>
  );
};

export default PPRouter;
