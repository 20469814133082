import React from 'react';
import PropTypes from 'prop-types';
import { rankingQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';

class Rankings extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <PPQuery
        query={rankingQueries.rankings}
        fetchPolicy='no-cache'
      >
        {data => {
          return children(data.rankings.rankings);
        }}
      </PPQuery>
    );
  }
}

Rankings.propTypes = {
  children: PropTypes.func.isRequired
};

export default Rankings;
