import React from 'react';
import PropTypes from 'prop-types';
import SpreadCardList from './SpreadCardList.js';
import StackCardList from './StackCardList.js';
import { playHelpers, auctionHelpers } from 'shared-helpers';
const {
  isFlipped,
  isStack
} = playHelpers;

const {
  whoIsDummy
} = auctionHelpers;

const CardList = (props) => {
  const {
    direction,
    dummy,
    trick,
    trickNumber,
    viewpoint,
    focusedSuit,
    expose,
    index,
    forceCardsFaceUp,
    simplifiedLayout,
    ...rest
  } = props;

  let faceDown = isFlipped({
    direction: direction,
    dummy: dummy,
    trick: trick,
    trickNumber: trickNumber,
    viewpoint: viewpoint
  });

  let stack = isStack({
    direction: direction,
    dummy: dummy,
    trick: trick,
    trickNumber: trickNumber,
    viewpoint: viewpoint
  });

  let straightLine;

  // this is for people who aren't on the table who can see everything
  // - currently directors, in the future kibitzers
  if (forceCardsFaceUp) {
    faceDown = false;
  }

  // show cards to everyone playing on the table - e.g. if forced to by a claim
  if (expose) {
    faceDown = false;

    // the cards will be shown as a straight line (not fanned out)
    // to mimic cards being put down on a table
    if (!stack) {
      straightLine = true;
    }
  }

  // also show declarer's cards in a straight line for dummy
  // for legibility
  if (viewpoint === dummy && direction === whoIsDummy(dummy) && !faceDown) {
    straightLine = true;
  }

  if (focusedSuit) {
    stack = false;
  }

  // simplified layout - everybody gets straightline spreads for everything
  // apart from dummy when opponent is dummy
  // (because the stack still looks pretty natural on the side)
  if (simplifiedLayout) {
    if (stack && (index === 0 || index === 2)) {
      straightLine = true;
      stack = false;
    } else if (!stack) {
      straightLine = true;
    }
  }

  return stack ? (
      <StackCardList
        faceDown={faceDown}
        trickNumber={trickNumber}
        index={index}
        {...rest}
      />
    ) :
    (
      <SpreadCardList
        faceDown={faceDown}
        focusedSuit={focusedSuit}
        straightLine={straightLine}
        index={index}
        {...rest}
      />
    );
};

CardList.propTypes = {
  direction: PropTypes.string.isRequired,
  dummy: PropTypes.string.isRequired,
  trick: PropTypes.array.isRequired,
  trickNumber: PropTypes.number.isRequired,
  viewpoint: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  enabledSuit: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired,
  focusedSuit: PropTypes.string,
  onCardClick: PropTypes.func,
  cardRefCallback: PropTypes.func,
  straightLine: PropTypes.string,
  simplifiedLayout: PropTypes.bool,
  expose: PropTypes.bool
};

export default CardList;
