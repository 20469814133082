import React from 'react';
import ErrorPage from '../../Components/Errors/ErrorPage.js'
import { getLoginUrl, getSourceAppDisplayName } from '../../helpers/url.js';

const Error403 = () => {
  return (
    <ErrorPage
      heading='Sorry, you are not allowed to play this session'
      message={
        <>
        If you wish to play this session then please make sure you are logged into a Pianola account that is associated with the club running this session.&nbsp;
        <a href={getLoginUrl()}>Return to {getSourceAppDisplayName()}</a>
        </>
      }
    />
  );
};

export default Error403;
