import React from 'react';

const TabPanel = (props) => {
  const { children, value, name } = props;

  return (
    <div
      display={value === name ? 'block' : 'none'}
    >
      {
        value === name ?
          children :
          null
      }
    </div>
  );
};

export default TabPanel;
