import { getEnvVar } from './env.js';
const bridgemateUrl = getEnvVar('BRIDGEMATE_URL');
const pianolaUrl = getEnvVar('PIANOLA_URL');
const env = process.env.REACT_APP_ENV;

export const getSessionId = (pathname) => {
  // matches the 'foo' in /foo/bar
  const matches = pathname.match(/^\/session\/([^/]+)\/?/);

  if (!matches ||
    !matches.length) {
    return null;
  }

  const sessionId = matches[1];

  return sessionId;
};

export const getSourceAppName = (hostname) => {
  if (!hostname) { return null; }

  if (hostname.match(/pianola\.net$/) || hostname === 'localhost') {
    return 'pianola';
  } else if (hostname.match(/bridgemate\.club$/)) {
    return 'bridgemate';
  }
};

export const getSourceAppDisplayName = (hostname) => {
  if (getSourceAppName(window.location.hostname) === 'bridgemate') {
    return 'Bridgemate Play';
  } else {
    return 'Pianola';
  }
};

export const getAppDisplayName = (hostname) => {
  if (getSourceAppName(window.location.hostname) === 'bridgemate') {
    return 'Bridgemate Play';
  } else {
    return 'Pianola Play';
  }
};

export const getLogoutUrl = () => {
  if (env === 'local' || env === 'dev') {
    return '/logout';
  } else if (getSourceAppName(window.location.hostname) === 'bridgemate') {
    return bridgemateUrl;
  } else {
    return `${pianolaUrl}/Login/LogOut`;
  }
};

export const getLoginUrl = () => {
  if (env === 'local' || env === 'dev') {
    return '/login';
  } else if (getSourceAppName(window.location.hostname) === 'bridgemate') {
    return bridgemateUrl;
  } else {
    const sessionId = getSessionId(window.location.pathname);
    const url = `${window.location.protocol}//${window.location.hostname}/session/${sessionId}`
    return `${pianolaUrl}/Login?ReturnUrl=${encodeURIComponent(url)}`;
  }
};
