import gql from 'graphql-tag';

const pairFragments = {
  fullPair: gql`
    fragment FullPair on Pair {
      id
      isPhantomPair
      user1 {
        id
        firstName
        lastName
        fullName
        avatarUrl
      }
      user2 {
        id
        firstName
        lastName
        fullName
        avatarUrl
      }
    }
  `
};

export default pairFragments;
