import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  item: {
    marginRight: theme.spacing(1)
  },
  itemLabel: {
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: theme.game.status.itemLabel
  },
  itemLabelSmall: {
    fontSize: '1.2rem'
  },
  itemText: {
    fontSize: '2rem'
  },
  itemTextSmall: {
    fontSize: '1.8rem'
  },
}));

const StatusItem = (props) => {
  const { label, text } = props;
  const classes = useStyles();

  const isIpad = useMediaQuery('(max-width:1194px)');

  return (
    <Grid item>
      <div className={clsx(classes.itemLabel, isIpad && classes.itemLabelSmall)}>{label}</div>
      <div className={clsx(classes.itemText, isIpad && classes.itemTextSmall)}>{text}</div>
    </Grid>
  );
};

StatusItem.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired
};

export default StatusItem;
