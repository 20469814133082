import { useEffect, useContext } from 'react';
import PageReloadContext from '../Components/PageReloadContext.js';
import { build } from '../helpers/env.js';

// a hook that, when present, shows a warning
// when the user tries to reload or navigate away

const useCloseWarning = (enable) => {
  const { isReloading } = useContext(PageReloadContext);

  // in cases where showing the warning depneds on some kind of logic,
  // allow a boolean to be passed in
  // passing `false` will make this a no-op
  if (typeof enable === 'undefined') {
    enable = true;
  }

  // disable by default on local dev
  // because autoreload breaks
  if (build === 'LOCAL') {
    enable = false;
  }

  useEffect(() => {
    // isReloading means a user just pressed the "reload" button in the nav
    // we don't want to give a warning whenever that happens
    if (!enable || isReloading) { return; }

    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    // cleanup
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [enable, isReloading]);
};

export default useCloseWarning;
