import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Countdown from './Countdown.js';
import FinishedBoard from '../FinishedBoard.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  itemLabel: {
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    opacity: 0.7,
    textAlign: 'center'
  },
  itemText: {
    fontSize: '2rem',
    textAlign: 'center'
  }
}));

const LastRoundEnding = (props) => {
  const { session, table, onCountdownEnd } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        spacing={3}
      >
        <Grid item>
          <div className={classes.itemLabel}>{t('end-of-round')}</div>
          <div className={classes.itemText}>{table.roundNumber + 1}/{session.numRounds}</div>
        </Grid>
      </Grid>
      <FinishedBoard
        table={table}
      />
      <Countdown
        label={t('concluding-game-in')}
        seconds={5}
        onCountdownEnd={onCountdownEnd}
        size='small'
      />
    </div>
  );
};

export default LastRoundEnding;
