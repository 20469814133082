import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ActionButton from './ActionButton.js';
import { Assignment as AssignmentIcon, AssignmentTurnedIn as AssignmentTurnedInIcon } from '@material-ui/icons/';
import SystemDescriptionModal from '../../SystemDescription/SystemDescriptionModal.js';
import { SystemDescription as SystemDescriptionSQ } from '../../SubscriptionQueries/';
import { useTranslation } from 'react-i18next';

const SystemDescriptionButton = (props) => {
  const { currentUser } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <SystemDescriptionSQ>
      {(systemDescription) => (
        <>
          <ActionButton
            label={t('system')}
            icon={
              (systemDescription && systemDescription.text)
                  ? (<AssignmentTurnedInIcon style={{fontSize: 30}} />)
                  : (<AssignmentIcon style={{fontSize: 30}} />)
            }
            onClick={handleClick}
            tooltip={t('update-system-description')}
          />
          <SystemDescriptionModal
            open={isOpen}
            onClose={handleClose}
            currentUser={currentUser}
            systemDescription={systemDescription}
          />
        </>
      )}
    </SystemDescriptionSQ>
  );
};

SystemDescriptionButton.propTypes = {
  currentUser: PropTypes.object.isRequired
};

export default SystemDescriptionButton;
