import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as Sentry from '@sentry/browser';
import Paper from '@material-ui/core/Paper';
import ComponentError from './ComponentError.js';

const styles = {
  paper: {
    padding: 20,
    width: 400,
    margin: 50
  },
  button: {
    marginTop: 20
  }
};

class ErrorNotification extends React.Component {
  componentDidMount () {
    const { error, errorInfo } = this.props;
    // log error to sentry
    // https://docs.sentry.io/platforms/javascript/react/
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      scope.setTag('app.version', global.appVersion);
      Sentry.captureException(error);
    });
  }

  render () {
    const { classes, error } = this.props;
    return (
      <Paper className={classes.paper}>
        <ComponentError
          heading='Something went wrong'
          text={error && error.toString()}
        />
      </Paper>
    );
  };
}

ErrorNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  errorInfo: PropTypes.object
};

export default withStyles(styles)(ErrorNotification);
