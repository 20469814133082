import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import StateSwitch from '../../Components/Routing/StateSwitch.js';
import {
  Lobby as LobbySQ,
  Dashboard as DashboardSQ,
  Game as GameSQ
} from '../../Components/SubscriptionQueries/';
import ReconnectionMonitorContext from '../../Components/ReconnectionMonitorContext.js';
import Game from './Game/Game.js';
import Lobby from './Lobby/Lobby.js';
import Dashboard from './Dashboard/Dashboard.js';
import Results from './Results/Results.js';
import CallNotifier from './CallNotifier.js';

const Director = (props) => {
  const { currentUser, currentSession } = props;
  const reconnectionKey = useContext(ReconnectionMonitorContext);
  return (
    <>
      <Switch>
        <Route path='/director/table/:tableId' render={({ match, history }) => {
          const { tableId } = match.params;
          return (
            <GameSQ
              tableId={tableId}
              key={reconnectionKey}
            >
              {({ table, refetch, onCardPlayed, onBidMade, nextTableId }) => (
                <CallNotifier currentTable={table}>
                  <Game
                    table={table}
                    history={history}
                    refetch={refetch}
                    session={currentSession}
                    currentUser={currentUser}
                    onCardPlayed={onCardPlayed}
                    onBidMade={onBidMade}
                    nextTableId={nextTableId}
                  />
                </CallNotifier>
              )}
            </GameSQ>
          );
        }}/>
        <Route path='/director/results/:view?/:id?' render={({ match }) => (
          <CallNotifier>
            <Results
              match={match}
              session={currentSession}
            />
          </CallNotifier>
        )}/>
        <Route path='/director/' render={() => (
          <StateSwitch base='/director' value={currentSession.status} >
            <Route
              path={['/director/notstarted', '/director/starting']}
              render={() => (
                <LobbySQ
                  key={reconnectionKey}
                >
                  {({users, pairs}) => (
                    <Lobby
                      currentUser={currentUser}
                      users={users}
                      pairs={pairs}
                      session={currentSession}
                    />
                  )}
                </LobbySQ>
              )}
            />
            <Route
              path={['/director/inprogress', '/director/finished']}
              render={({ history }) => (
                <CallNotifier>
                  <DashboardSQ
                    key={reconnectionKey}
                    numPairs={currentSession.numPairs}
                  >
                    {({tables, users, refetch}) => (
                      <Dashboard
                        tables={tables}
                        history={history}
                        session={currentSession}
                        refetch={refetch}
                        users={users}
                        currentUser={currentUser}
                      />
                    )}
                  </DashboardSQ>
                </CallNotifier>
              )}
            />
          </StateSwitch>
        )}/>
      </Switch>
    </>
  );
};

Director.propTypes = {
  currentSession: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default Director;
