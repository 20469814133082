let staticRegion;

const setRegion = (region) => {
  staticRegion = region;
};

const getRegion = () => {
  if (staticRegion) {
    return staticRegion;
  }
  return 'eu-west-2';
};

export {
  setRegion,
  getRegion
};
