import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HybridSessionStartModal from './HybridSessionStartModal.js';
import SessionParametersModal from './SessionParametersModal.js';
import { sessionMutations } from '../../../graphql/mutations/';
import { usePPMutation } from '../../../hooks/';
import GameDetailsBase from '../../../Components/Lobby/GameDetailsBase.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Calendar from '../../../Components/Calendar.js';
import SessionStartingTime from '../../../Components/Lobby/SessionStartingTime.js';
import { useTranslation } from 'react-i18next';
import { useLocalSessionStarting } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  startGameButton: {
    backgroundColor: theme.lobby.directorCta.background,
    '&:disabled': {
      color: '#999'
    },
    '&:hover': {
      backgroundColor: theme.lobby.directorCta.hoverBackground
    }
  },
  emphasis: {
    fontSize: '3rem',
    margin: 0
  }
}));

const GameDetails = (props) => {
  const { session, pairs, containerRef } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [startSession, { loading, error }] = usePPMutation(sessionMutations.startSession, { memoize: true });
  const [setSessionStarting] = usePPMutation(sessionMutations.setSessionStarting);
  const { localSession, onStartingChange } = useLocalSessionStarting(session);

  const canStartSession = pairs.length >= 4;

  const handleStartClick = useCallback(() => {
    onStartingChange(true);
    setSessionStarting({
      variables: { starting: true }
    });
  }, [setSessionStarting, onStartingChange]);

  const handleSessionParamsClose = useCallback(() => {
    if (loading && !error) { return; }

    onStartingChange(false);
    setSessionStarting({
      variables: { starting: false }
    });
  }, [setSessionStarting, loading, error, onStartingChange]);

  const handleSessionParamsSubmit = useCallback(async (variables) => {
    if (loading && !error) { return; }

    await startSession({ variables });
  }, [startSession, loading, error]);

  const sessionStartingModal = localSession.sourceAppName === 'Bridgemate'
    ? (
      <HybridSessionStartModal
        session={localSession}
        pairs={pairs}
        onClose={handleSessionParamsClose}
        containerRef={containerRef}
      />
    )
    : (
      <SessionParametersModal
        pairs={pairs}
        session={localSession}
        loading={loading}
        onClose={handleSessionParamsClose}
        onSubmit={handleSessionParamsSubmit}
        containerRef={containerRef}
      />
    );

  return (
    <>
      <GameDetailsBase session={localSession}>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          spacing={4}
        >
          <Grid item>
            <Calendar datetime={localSession.dateTime} />
          </Grid>
          <Grid item xs>
            <SessionStartingTime session={localSession} />
            <p>
              <Button
                variant='contained'
                color='primary'
                onClick={handleStartClick}
                disabled={!canStartSession}
                className={classes.startGameButton}
              >
                {
                  localSession.sourceAppName === 'Bridgemate'
                    ? t('click-here-to-freeze-pairs')
                    : t('click-here-to-start-game')
                }
              </Button>
            </p>
          </Grid>
        </Grid>
      </GameDetailsBase>
      {
        localSession.status === 'Starting' && canStartSession && sessionStartingModal
      }
    </>
  );
};

export default GameDetails;
