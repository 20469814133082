import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MiniCard from '../../MiniCard.js';

const useStyles = makeStyles(theme => ({
  container: {
    fontSize: '2.4rem',
    fontWeight: 'bold',
    color: '#fff'
  },
  miniCard: {
    fontSize: '1.2rem',
    display: 'inline-block',
    verticalAlign: 'middle'
  }
}));

const Contract = (props) => {
  const { contract, declarer } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (!contract || !declarer) {
    return null;
  }

  return (
    <div className={classes.container}>
      <span className={classes.miniCard}>
        <MiniCard
          suit={contract.denomination}
          value={contract.level}
          risk={contract.risk}
          size='small'
          isBid
        />
      </span>
      &nbsp;{t('by-position', { position: t(declarer.toLowerCase()) })}
    </div>
  );
};

Contract.propTypes = {
  contract: PropTypes.object,
  declarer: PropTypes.string
};

export default Contract;
