import React from 'react';
import _ from 'lodash';
import {
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Setting = (props) => {
  const { name, setting, disabled, onChange, size } = props;
  const { t } = useTranslation();

  const settingTranslationId = _.kebabCase(setting.label);

  if (setting.type === 'boolean') {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={setting.value}
            disabled={disabled}
            onChange={(e) => { onChange(name, e.target.checked); }}
            color='primary'
            size={size || 'medium'}
          />
        }
        label={t(settingTranslationId)}
      />
    );
  } else {
    throw new Error('setting with this type not supported yet:' + setting.type);
  }
};

export default Setting;
