import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Deal from '../../Deal/Deal.js';
import MakeableContracts from '../MakeableContracts.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  makeableContractsMessage: {
    marginTop: '2rem'
  }
}));

const DealWithMakeableContracts = (props) => {
  const { board } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Deal
        dealer={board.dealer}
        viewpoint='S'
        vulnerable={board.vulnerable}
        cards={board.deal}
      />
      {
        board.makeableContractsStatus === 'Pending' && (
          <div className={classes.makeableContractsMessage}>
            {t('makeable-contracts-are-still-being-calculated-for-board')}
          </div>
        )
      }
      {
        board.makeableContracts && (
          <>
            <br/>
            <MakeableContracts
              makeableContracts={board.makeableContracts}
            />
          </>
        )
      }
    </>
  );
};

DealWithMakeableContracts.propTypes = {
  board: PropTypes.object.isRequired
};

export default DealWithMakeableContracts;
