import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { split, concat } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import _ from 'lodash';
import { wsEndpoint, queryEndpoint, mutationEndpoint } from '../helpers/env.js';
import { getJwt } from '../helpers/auth.js';
import { getLoginUrl, getSessionId, getSourceAppName } from '../helpers/url.js';
import { getCode, getMessage } from '../helpers/error.js';
import { getMainDefinition } from 'apollo-utilities';
import * as Sentry from '@sentry/browser';
import fetch from 'unfetch'; // fetch polyfill for older browsers

const wsClient = new SubscriptionClient(
  wsEndpoint,
  {
    // if we try to connect immediately, older browsers might error out
    // (before showing the 'browser not supported' message)
    lazy: true,
    // lambda may be a bit slow to warm up, so give it a moment just in case
    minTimeout: 3000,
    reconnect: true,
    connectionParams: () => ({
      token: getJwt(),
      sessionId: getSessionId(window.location.pathname),
      sourceAppName: getSourceAppName(window.location.hostname)
    })
  },
  null,
  []
);

// API gateway has a 120 minute connection limit
// reconnections initiated by us are quicker than reconnections due to server disconnect
// so we force the connection to reset every 90 mins
setInterval(() => {
  // isForced === false gets the client to reconnect
  // https://github.com/apollographql/subscriptions-transport-ws/blob/3590e076f549744af717dd3257fce78a659b7176/src/client.ts#L181
  wsClient.close(false);
}, 90 * 60 * 1000);

/*
wsClient.on('connected', (payload) => { console.log('connected!', payload); });
wsClient.on('connecting', (payload) => { console.log('connecting!', payload); });
wsClient.on('reconnecting', (payload) => { console.log('reconnecting!', payload); });
wsClient.on('reconnected', (payload) => { console.log('reconnected!', payload); });
wsClient.on('disconnected', (payload) => { console.log('disconnected!', payload); });
wsClient.on('error', (payload) => { console.log('error!', payload); });
*/

const webSocketLink = new WebSocketLink(wsClient);

const getHttpLink = (endpoint) => {
  return new HttpLink({
    uri: endpoint,
    // preferred way to set headers for the HTTP link according to:
    // https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-http#custom-auth
    // we can still pass the Auth header to http api as it is one of the accepted
    // headers
    fetch: (uri, options) => {
      const token = getJwt();
      if (token && token !== '') {
        options.headers.Authorization = `Bearer ${token}`;
      }
      const sessionId = getSessionId(window.location.pathname);
      if (sessionId) {
        options.headers['x-session-id'] = sessionId;
      }
      const sourceAppName = getSourceAppName(window.location.hostname);
      if (sourceAppName) {
        options.headers['x-source-app-name'] = sourceAppName;
      }
      return fetch(uri, options);
    }
  });
};

const errorLink = onError((err) => {
  const errCode = getCode(err);
  // so we don't show the error message (error caught by error boundary)
  // we can explicity set the network error message so we can check for
  // it in the boundary and not show a message
  // https://github.com/apollographql/apollo-link/issues/855#issuecomment-503721860
  if (errCode === 'UNAUTHENTICATED') {
    window.location = getLoginUrl();
    err.networkError.message = '401 UNAUTHENTICATED';
  } else if (errCode === 'FORBIDDEN') {
    window.location = '/error403';
    err.networkError.message = '403 FORBIDDEN';
  }

  const errMessage = getMessage(err);

  // log error to sentry
  Sentry.withScope(scope => {
    scope.setTag('app.version', global.appVersion);
    // JSON stringified otherwise Sentry stringifies nested data to e.g. [Object object]
    scope.setExtra('errData', JSON.stringify(err));
    Sentry.captureException(new Error(errMessage));
  });
});

// use websockets for subscriptions, and HTTP for queries / mutations
// (see https://www.howtographql.com/react-apollo/8-subscriptions/)
//
// both of these have their errors handled by errorLink
const queryLink = concat(errorLink, split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  webSocketLink,
  getHttpLink(queryEndpoint)
));

// now we have split the query and mutation clients we need to share the
// chache
const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link: queryLink
});

// create a seperate mutation client that connects to a single wsEndpoint
// memoize the client so we don't create more than the one needed
const getMutationClient = _.memoize((region) => {
  // take the endpoint from the .env file and substitute the region
  // to get a fixed mutation endpoint
  const endpoint = mutationEndpoint.replace('~REGION~', region);
  const mutationLink = concat(errorLink, split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    webSocketLink,
    getHttpLink(endpoint)
  ));
  return new ApolloClient({
    cache,
    link: mutationLink
  });
});

export { wsClient, getMutationClient };
export default client;
