import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    opacity: 0,
    background: theme.palette.secondary.main,
    color: '#000',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '2em',
    lineHeight: '1em',
    textAlign: 'center',
    transition: 'opacity 0.2s ease-in-out'
  },
  active: {
    opacity: 1
  }
}));

const YourTurn = (props) => {
  const { message, show } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, show && classes.active)}>
      {message}
    </div>
  );
};

export default YourTurn;
