import React from 'react';
import { useFullscreen } from '../../../hooks/';
import ActionButton from './ActionButton.js';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import MinimiseIcon from '@material-ui/icons/FullscreenExit';
import { useTranslation } from 'react-i18next';

const FullscreenButton = () => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const { t } = useTranslation();

  let icon = <FullscreenIcon style={{ fontSize: 30 }} />;
  let label = 'fullscreen';
  let tooltip = 'click-to-go-fullscreen';
  if (isFullscreen) {
    icon = <MinimiseIcon style={{ fontSize: 30 }} />;
    label = 'minimise';
    tooltip = 'click-to-exit-fullscreen';
  }

  return (
    <ActionButton
      icon={icon}
      label={t(label)}
      tooltip={t(tooltip)}
      onClick={toggleFullscreen}
    />
  );
};

export default FullscreenButton;
