import React, { useState, useCallback } from 'react';
import { usePPMutation, useSmallScreenSettings } from '../../hooks/';
import { userSettingsMutations } from '../../graphql/mutations';
import _ from 'lodash';

/**
 * To use these settings elsewhere in the app do something like:
 *
 * ```
 * import UserSettingsContext from '../../../Components/UserSettings/UserSettingsContext.js';
 *
 * const { enableSound, updateSetting } = useContext(UserSettingsContext);
 * // true at first invocation
 * // false afterwards
 * console.log(enableSound);
 *
 * useEffect(() => {
 *   updateSetting('enableSound', false);
 * }, []);
 * ```
 */

const UserSettingsContext = React.createContext();

const UserSettingsProvider = (props) => {
  const { children, currentUserSettings } = props;

  const initialSettings = currentUserSettings ? currentUserSettings.settings : {};

  const [settings, updateSettings] = useState(initialSettings);
  const [updateApiSetting] = usePPMutation(userSettingsMutations.updateUserSetting);
  const [updateApiSoundSetting] = usePPMutation(userSettingsMutations.updateSoundSetting);
  const smallScreenSettings = useSmallScreenSettings();

  const updateSetting = useCallback((name, value) => {
    const newSettings = _.cloneDeep(settings);
    newSettings[name].value = value;

    updateSettings(newSettings);

    updateApiSetting({
      variables: {
        name,
        // easy way to pass a scalar of any type
        // more validation happens in backend logic
        // https://stackoverflow.com/a/45601881/484538
        value: JSON.stringify(value)
      }
    });
  }, [settings, updateApiSetting]);

  const updateSoundSetting = useCallback((name, value) => {
    const newSettings = _.cloneDeep(settings);
    newSettings.sounds[name].value = value;

    updateSettings(newSettings);

    updateApiSoundSetting({ variables: { name, value } });
  }, [settings, updateApiSoundSetting]);

  // override these settings on smaller devices
  // to improve usability
  let settingsWithOverrides = settings;
  if (smallScreenSettings && settings) {
    settingsWithOverrides = _.cloneDeep(settings);

    if (smallScreenSettings.simplifiedLayout) {
      if (settingsWithOverrides.simplifiedLayout) {
        settingsWithOverrides.simplifiedLayout.value = true;
      }

      if (settingsWithOverrides.confirmClick) {
        settingsWithOverrides.confirmClick.value = true;
      }
    }

    if (smallScreenSettings.simplifiedCards && settingsWithOverrides.simplifiedCards) {
      settingsWithOverrides.simplifiedCards.value = true;
    }
  }

  return (
    <UserSettingsContext.Provider
      value={{
        ...settingsWithOverrides,
        updateSetting,
        updateSoundSetting
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

const UserSettingsConsumer = UserSettingsContext.Consumer;

export { UserSettingsProvider, UserSettingsConsumer };
export default UserSettingsContext;
