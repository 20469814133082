import React from 'react';
import { boardQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';

const Boards = (props) => {
  const { children } = props;
  return (
    <PPQuery
      query={boardQueries.boards}
    >
      {data => {
        if (!data || !data.boards) { return null; }

        return children(data.boards);
      }}
    </PPQuery>
  );
};

export default Boards;
