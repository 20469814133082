import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { DateTime } from 'luxon';

const styles = {
  container: {
    display: 'inline-block',
    fontFamily: 'Courer New, Courier, Mono',
    fontSize: '2rem',
    fontWeight: 'bold',
    lineHeight: '3.7rem',
    borderRadius: '0.5rem',
    padding: '0 0.8rem',
    cursor: 'default',
    color: '#fff',
    background: 'rgba(0,0,0,0.4)',
    whiteSpace: 'nowrap'
  }
};

class TimeNow extends React.Component {
  constructor() {
    super();
    this.state = {
      time: DateTime.now().toFormat('HH:mm')
    }
  }

  timenow () {
    if (this.secondsIndicator === ':'){
      this.secondsIndicator = ' ';
    } else {
      this.secondsIndicator = ':';
    }
    const dt = DateTime.now();
    return dt.toFormat('HH') + this.secondsIndicator + dt.toFormat('mm');
  };

  componentDidMount () {
    this.secondsIndicator = ':';
    this.interval = setInterval(() => this.setState({ time: this.timenow() }), 1000);
  };

  componentWillUnmount () {
    clearInterval(this.interval);
  };

  render () {
    const { classes } = this.props;
    return (
      <span className={classes.container}>
        {this.state.time}
      </span>
    );
  };
};

TimeNow.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TimeNow);
