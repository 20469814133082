import React from 'react';
import Grid from '@material-ui/core/Grid';
import ClaimButton from './ClaimButton.js';
import CallDirectorButton from './CallDirectorButton.js';
import ResultsButton from './ResultsButton.js';
import SystemDescriptionButton from './SystemDescriptionButton.js';

const PlayerActions = (props) => {
  const { table, session, currentUser, setMakingClaim } = props;

  return (
    <>
      <Grid
        container
        alignItems='center'
        wrap='nowrap'
      >
        <Grid item>
          <ClaimButton
            table={table}
            setMakingClaim={setMakingClaim}
          />
        </Grid>
        <Grid item>
          <CallDirectorButton
            table={table}
            currentUser={currentUser}
          />
        </Grid>
        <Grid item>
          <ResultsButton
            session={session}
          />
        </Grid>
        <Grid item>
          <SystemDescriptionButton
            currentUser={currentUser}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PlayerActions;
