import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Trick from './Trick.js';
import CardList from './CardList.js';
import CardPlayAnimation from './CardPlayAnimation.js';
import clsx from 'clsx';
import { cardHelpers, playHelpers } from 'shared-helpers';

const {
  getCardsForSeat,
  convertCodeToCardObject,
  arrangeSeatsBasedOnViewPoint
} = cardHelpers;
const {
  flagCardAsInvisible,
  getSuitToPlay
} = playHelpers;

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  flexGrow: {
    flex: 1
  },
  flexAuto: {
    flex: 'auto'
  },
  rowFirst: {
    paddingBottom: '2rem'
  },
  rowMiddle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rowLast: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: '2rem',
    minHeight: '25%'
  },
  columnFirst: {
    paddingRight: '2rem'
  },
  columnMiddle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0'
  },
  columnLast: {
    paddingLeft: '2rem'
  }
};

class CardPlay extends React.Component {
  constructor (props) {
    super(props);

    const localDeal = _.cloneDeep(this.props.deal);

    this.state = {
      localDeal: localDeal,
      visibleTrick: this.props.trick,
      enabledSuit: getSuitToPlay(this.props.activeSeat, this.props.trick, localDeal),
      focusedSuit: null,
      cardAnimation: {
        card: {
          suit: 'S',
          rank: 'A'
        },
        startPos: {
          left: 0,
          top: 0,
          width: 100,
          rotation: 0
        },
        endPos: {
          left: 0,
          top: 0,
          width: 100,
          rotation: 0
        },
        speed: 2000
      },
      animate: false
    };
  }

  cardRefs = [];
  trickRefs = [];

  handleCardRefCallback = (el, cardValue) => {
    if (!el) { return; }

    const newRef = {
      value: cardValue,
      ref: el
    };
    const oldRefIdx = this.cardRefs.findIndex(ref => ref.value === cardValue);

    if (oldRefIdx > -1) {
      this.cardRefs.splice(oldRefIdx, 1, newRef);
    } else {
      this.cardRefs.push(newRef);
    }
  };

  handleTrickRefCallback = (el) => {
    if (el) {
      this.trickRefs.push(
        {
          direction: el.id,
          ref: el
        }
      );
    }
  };

  handleContainerRefCallback = (el) => {
    this.containerRef = el;
  };

  animateCard = (card) => {
    const { activeSeat, animationSpeed } = this.props;
    // get value from suit and rank (car object)
    const value = `${card.rank}${card.suit}`;
    // get cardProps for this card from cardRefs array
    const { ref: cardRef } = _.find(this.cardRefs, { value: value});
    const cardProps = cardRef.getBoundingClientRect();
    const containerProps = this.containerRef.getBoundingClientRect();

    const rotation = cardRef.dataset.rotation;

    // get destination props from trickRefs based on direction
    const destinationRef = _.find(this.trickRefs, { direction: activeSeat }).ref;
    const destinationProps = destinationRef.getBoundingClientRect();

    // setup the card animation props
    const cardAnimation = {
      startPos: {
        // we need to subtract the container coordinates
        // in order to convert getBoundingClientRect's global coords
        // to local
        top: cardProps.top - containerProps.top,
        left: cardProps.left - containerProps.left,
        width: cardProps.width,
        rotation: rotation || 0
      },
      endPos: {
        top: destinationProps.top - containerProps.top,
        left: destinationProps.left - containerProps.left,
        width: destinationProps.width,
        rotation: 0
      },
      card: card,
      speed: animationSpeed
    };
    this.setState({
      cardAnimation: cardAnimation,
      animate: true
    });
  };

  handleAnimationComplete = (card) => {
    const { onAnimationComplete, trick } = this.props;

    this.setState({
      visibleTrick: trick,
      focusedSuit: null
    });

    if (trick.length === 4) {
      setTimeout(() => {
        this.setState({
          enabledSuit: 'all'
        });
        onAnimationComplete();
      }, this.props.trickEndDelay);
    } else {
      onAnimationComplete();
    }
  };

  playCard = (card) => {
    let { localDeal } = this.state;
    // remove the card from the deal
    setTimeout(() => {
      flagCardAsInvisible(localDeal, card);
      this.setState({
        localDeal: localDeal
      });
    }, 0); // without the set timeout the animation starts from the wrong place (top right)
    this.animateCard(card);
  };

  handleCardClick = ({ cardValue, event, autoplayed }) => {
    event && event.stopPropagation();

    const { onCardClick, activeSeat, confirmClick } = this.props;
    const { localDeal, focusedSuit } = this.state;

    const card = convertCodeToCardObject(cardValue, activeSeat);

    // click to confirm setting - player needs to click on a suit and
    // then again on a specific card
    if (confirmClick && !autoplayed) {
      const cardsLeft = localDeal.filter(card => card.visible && card.seat === activeSeat);

      // a suit hasn't been clicked yet, so focus on one
      if (focusedSuit !== card.suit &&
        // only do this if there's multiple cards -
        // no point in confirming if there's one card left
        cardsLeft.length > 1) {
        this.setState({ focusedSuit: card.suit });
        return;
      }
    }

    // if confirmClick is off or a suit has already been clicked,
    // pass click to the parent
    onCardClick(card, autoplayed);
  };

  handleContainerClick = (e) => {
    // if we've focused on a suit, clicking out unfocuses it
    if (this.state.focusedSuit) {
      this.setState({ focusedSuit: null });
    }
  };

  componentDidUpdate (prevProps) {
    // console.log('prevProps.trick', prevProps.trick);
    // console.log('this.props.trick', this.props.trick);

    // we store this in state rather than calculate it on-the-fly in render()
    // because we want enabledSuit to remain unchanged until the card animation has stopped playing
    if (prevProps.activeSeat !== this.props.activeSeat) {
      this.setState({
        enabledSuit: getSuitToPlay(
          this.props.activeSeat,
          this.state.visibleTrick,
          this.state.localDeal
        )
      });
    }

    if (prevProps.trick.length !== this.props.trick.length) {
      // clear visible trick with the first card that gets played
      if (this.props.trick.length === 1) {
        this.setState({ visibleTrick: [] });
      }

      const cardToPlay = this.props.trick[this.props.trick.length - 1];
      this.playCard(cardToPlay);
    }
  }

  getCardsForDirection = (direction, index) => {
    const {
      dummy,
      trickNumber,
      trick,
      activeSeat,
      canPlay,
      autoPlaySingletons,
      exposeCardsForSeat,
      forceCardsFaceUp,
      viewpoint,
      simplifiedCards,
      simplifiedLayout,
      contract
    } = this.props;
    const {
      localDeal,
      enabledSuit,
      focusedSuit
    } = this.state;
    const cardsForSeat = getCardsForSeat(localDeal, direction.value);
    let enabledSuitForSeat = '';
    let focusedSuitForSeat = '';
    if (direction.value === activeSeat && canPlay) {
      if (enabledSuit) {
        enabledSuitForSeat = enabledSuit;
      }
      if (focusedSuit) {
        focusedSuitForSeat = focusedSuit;
      }
    }
    return (
      <CardList
        key={index}
        index={index}
        cards={cardsForSeat}
        enabledSuit={enabledSuitForSeat}
        focusedSuit={focusedSuitForSeat}
        autoPlaySingletons={autoPlaySingletons}
        onCardClick={this.handleCardClick}
        cardRefCallback={this.handleCardRefCallback}
        contract={contract}
        direction={direction.value}
        dummy={dummy}
        trick={trick}
        simplifiedCards={simplifiedCards}
        simplifiedLayout={simplifiedLayout}
        trickNumber={trickNumber}
        viewpoint={viewpoint}
        expose={exposeCardsForSeat === direction.value || exposeCardsForSeat === 'all'}
        forceCardsFaceUp={forceCardsFaceUp}
      />
    );
  };

  render () {
    const {
      animationStartDelay,
      activeSeat,
      classes,
      viewpoint,
      simplifiedCards,
      trickNumber,
      trickOverlay
    } = this.props;

    const {
      cardAnimation,
      animate,
      visibleTrick
    } = this.state;

    const directions = arrangeSeatsBasedOnViewPoint(viewpoint);
    const trickElement = (
      <Trick
        trick={visibleTrick}
        trickNumber={trickNumber}
        trickRefCallback={this.handleTrickRefCallback}
        activeSeat={activeSeat}
        viewpoint={viewpoint}
        simplifiedCards={simplifiedCards}
        fadeOutDelay={500}
      />
    );

    return (
      <div
        ref={this.handleContainerRefCallback}
        className={classes.container}
        onClick={this.handleContainerClick}
      >
        <div className={clsx(classes.rowFirst, classes.flexAuto)}>
          {this.getCardsForDirection(directions[0], 0)}
        </div>
        <div className={clsx(classes.rowMiddle, classes.flexAuto)}>
          <div className={clsx(classes.columnFirst, classes.flexGrow)}>
            {this.getCardsForDirection(directions[3], 3)}
          </div>
          <div className={clsx(classes.columnMiddle, classes.flexGrow)}>
            { trickOverlay ? trickOverlay : trickElement }
          </div>
          <div className={clsx(classes.columnLast, classes.flexGrow)}>
            {this.getCardsForDirection(directions[1], 1)}
          </div>
        </div>
        <div className={clsx(classes.rowLast, classes.flexAuto)}>
          {this.getCardsForDirection(directions[2], 2)}
        </div>
        <CardPlayAnimation
          card={cardAnimation.card}
          simplifiedCards={simplifiedCards}
          startPos={cardAnimation.startPos}
          endPos={cardAnimation.endPos}
          speed={cardAnimation.speed}
          delay={animationStartDelay}
          onAnimationComplete={this.handleAnimationComplete}
          animate={animate}
        />
      </div>
    );
  }
}

CardPlay.propTypes = {
  onCardClick: PropTypes.func.isRequired,
  onAnimationComplete: PropTypes.func.isRequired,
  dummy: PropTypes.string.isRequired,
  activeSeat: PropTypes.string.isRequired,
  viewpoint: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired,
  deal: PropTypes.array.isRequired,
  trickNumber: PropTypes.number.isRequired,
  trick: PropTypes.array.isRequired,
  animationSpeed: PropTypes.number.isRequired,
  animationStartDelay: PropTypes.number.isRequired,
  trickEndDelay: PropTypes.number.isRequired,
  canPlay: PropTypes.bool.isRequired,
  trickOverlay: PropTypes.node,
  exposeCardsForSeat: PropTypes.string,
  forceCardsFaceUp: PropTypes.bool,
  simplifiedCards: PropTypes.bool,
  simplifiedLayout: PropTypes.bool
};

export default withStyles(styles)(CardPlay);
