import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  FormControlLabel,
  TextField,
  Switch,
  Grid
} from '@material-ui/core';
import PPDialog from '../../PPDialog.js';
import MiniCard from '../../MiniCard.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  backdrop: {
    left: '1rem',
    top: '8rem',
    right: '40rem',
    bottom: '1rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    minWidth: 'calc(72% - 1.4rem)'
  },
  container: {
    right: '40rem !important',
    minWidth: 'calc(72% - 1.4rem) !important'
  },
  content: {
    background: theme.palette.block.content.background,
    minWidth: '26rem',
    textAlign: 'center'
  },
  miniCard: {
    display: 'flex',
    justifyContent: 'center'
  },
  textField: {
    background: '#fff'
  },
  notice: {
    fontSize: '1.2rem',
    marginTop: '0.6rem'
  }
}));

const ConfirmBidModal = (props) => {
  const { bid, open, onClose, onBidConfirm } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [isAlerting, setIsAlerting] = useState(false);
  const [alertReason, setAlertReason] = useState(null);

  const handleReasonChange = useCallback((e) => {
    setAlertReason(e.target.value);
  }, [setAlertReason]);

  const handleAlertChange = useCallback((e) => {
    setIsAlerting(e.target.checked);
  }, [setIsAlerting]);

  const handleConfirmClick = useCallback(() => {
    if (!bid) { return; }

    onClose();
    setIsAlerting(false);
    setAlertReason(null);
    onBidConfirm({
      ...bid,
      alerted: isAlerting,
      alertReason: alertReason
    });
  }, [bid, onClose, onBidConfirm, isAlerting, alertReason]);

  const handleClose = useCallback(() => {
    onClose();
    setIsAlerting(false);
    setAlertReason(null);
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <PPDialog
      open={open}
      onClose={handleClose}
      hideDividers
      title={t('make-bid')}
      className={classes.container}
      contentClassName={classes.content}
      BackdropProps={{
        classes: {
          root: classes.backdrop
        }
      }}
      actions={
        <Grid
          justify='center'
          container
        >
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={handleConfirmClick}
              disabled={isAlerting && !alertReason}
            >
              {t('confirm-bid')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <br />
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='stretch'
        spacing={2}
      >
        <Grid item>
          <div className={classes.miniCard}>
            <MiniCard
              suit={bid.denomination ? bid.denomination : null}
              value={bid.level ? bid.level : bid.value}
              isBid
              alerted={isAlerting}
            />
          </div>
        </Grid>
        <Grid item>
          <FormControlLabel
            label={t('alert')}
            color='primary'
            control={
              <Switch
                name='confirm-bid-alert'
                onChange={handleAlertChange}
                color='primary'
              />
            }
          />
        </Grid>
        {
          isAlerting && (
            <Grid item>
              <TextField
                variant='outlined'
                id='confirm-bid-reason-label'
                label={t('reason-for-alerting')}
                multiline
                rows={4}
                onChange={handleReasonChange}
                value={alertReason}
                className={classes.textField}
              />
              <div className={classes.notice}>
                {t('this-will-be-shown-to-your-opponents-only')}
              </div>
            </Grid>
          )
        }
      </Grid>
      <br />
    </PPDialog>
  );
};

export default ConfirmBidModal;
