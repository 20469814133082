import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import MessageGroup from './MessageGroup.js';
import { TYPING_NOTIFICATION_EXPIRY, hasNotificationExpired, groupMessages } from '../../helpers/chat.js';
import MiddleMessage from '../MiddleMessage.js';
import { Translation } from 'react-i18next';

const styles = {
  root: {
    position: 'relative',
    padding: '0 2em',
    '@media all and (max-width:1194px)': {
      padding: '0 1em'
    }
  },
  middleMessageContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
};

// this component is a class in order to allow for the slightly hairly logic inside
// scrollToBottom() which depends on previous props

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleNotifications: props.typingNotifications
    };

    this.messagesEndRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.typingNotifications === prevState.visibleNotifications) {
      return null;
    }

    return { visibleNotifications: nextProps.typingNotifications };
  }

  clearNotifications = () => {
    // clear all visible notifications
    const notifications = this.state.visibleNotifications;
    if (!notifications.length) {
      return;
    }

    this.notificationTimeout = setTimeout(() => {
      const liveNotifications = notifications.filter(notification => {
        return !hasNotificationExpired(notification);
      });

      this.setState({
        visibleNotifications: liveNotifications
      });
    }, TYPING_NOTIFICATION_EXPIRY);
  };

  scrollToBottom = (prevProps, prevState) => {
    if (!this.messagesEndRef.current) { return; }

    let shouldScrollToBottom = prevProps.messages !== this.props.messages ||
      prevState.visibleNotifications !== this.state.visibleNotifications;
    let smoothScroll = true;

    // if this key changes, scroll to bottom
    // (this is currently necessary for claims, changes to which reduce the height
    // of the messages window, which forces the messages window to scroll upwards)
    if (prevProps.scrollKey !== this.props.scrollKey) {
      shouldScrollToBottom = true;
      // changes in height cause a sudden change in scroll position,
      // so we want our response to be sudden otherwise it looks odd
      smoothScroll = false;
    }

    if (shouldScrollToBottom) {
      // when individual messages come in, we want to do a smoothly animated scroll down
      // so they come into view
      //
      // however, when the messages first render we want to go straight to the bottom
      // (e.g. when refreshing the page, or especially if chat refreshes between two boards)
      if (prevProps.messages.length === 0 && this.props.messages !== 0) {
        smoothScroll = false;
      }

      if (!this.messagesEndRef.current) { return; }

      this.messagesEndRef.current.scrollIntoView({
        behavior: smoothScroll ? 'auto' : 'smooth',
        block: 'end'
      });
    }
  };

  playNewMessageSound = (prevProps) => {
    if (!this.props.messages.length) { return; }

    const lastMessage = this.props.messages[this.props.messages.length - 1];
    const timeSinceLastMessage = Date.now() - new Date(lastMessage.timestamp).getTime();

    // play sound for any new message
    if (this.props.messages.length > prevProps.messages.length &&
      // ... but not for system messages
      // (these are likely to have their own sounds)
      lastMessage.type !== 'System' &&
      // ... or for messages that are too old
      // (this is to catch e.g. old chat messages when the next board
      // loads and this component rerenders)
      timeSinceLastMessage < 3000) {
      this.props.playSound('newMessage');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    this.clearNotifications();
    this.scrollToBottom(prevProps, prevState);
    this.playNewMessageSound(prevProps);
  }

  componentWillUnmount() {
    clearTimeout(this.notificationTimeout);
  }

  render() {
    const { messages, classes, currentUser } = this.props;
    const messageGroups = groupMessages(messages, this.state.visibleNotifications, currentUser.id);

    return (
      <>
        {!messages.length && (
          <div className={classes.middleMessageContainer}>
            <Translation>
              {t => <MiddleMessage>{t('nothing-here-yet')}</MiddleMessage>}
            </Translation>    
          </div>
        )}
        <div className={classes.root}>
          {messageGroups.map((group, i) => (
            <MessageGroup
              key={group.timestamp + group.from.id + i}
              from={group.from}
              icon={group.icon}
              messages={group.messages}
            />
          ))}
          <div ref={this.messagesEndRef}></div>
        </div>
      </>
    )
  }
}

Messages.propTypes = {
  messages: PropTypes.array.isRequired,
  typingNotifications: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  playSound: PropTypes.func.isRequired,
  scrollKey: PropTypes.string
};

export default withStyles(styles)(Messages);
