import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';

import WarningIcon from '@material-ui/icons/WarningRounded';
import ErrorIcon from '@material-ui/icons/Report';
import TickIcon from '@material-ui/icons/Check';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: 'help',
    color: '#ccc'
  },
  orange: { color: '#FFAA25' },
  red: { color: '#E63D3D' },
  green: { color: '#73D801' }
}));

const icon = (type, color, classes) => {
  let icon = null;
  switch (type) {
    case 'warning':
      icon = <WarningIcon className={classes[color]} />;
      break;
    case 'error':
      icon = <ErrorIcon className={classes[color]} />;
      break;
    case 'lock':
      icon = <LockIcon className={classes[color]} />;
      break;
    default:
      icon = <TickIcon className={classes[color]} />;
      break;
  }
  return icon;
};

const Icon = (props) => {
  const { type, tooltip, color } = props;
  const classes = useStyles();

  return (
    <Tooltip arrow className={classes.icon} placement='top' title={<Typography>{tooltip}</Typography>}>
      {
        icon(type, color, classes)
      }
    </Tooltip>
  );
};

export default Icon;
