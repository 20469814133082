import React from 'react';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { tableQueries } from '../../graphql/queries/';
import { tableSubscriptions } from '../../graphql/subscriptions/';
import Users from './Users.js';
import { populatePairsWithUserData } from '../../helpers/users.js';
import { updateCardPlay } from '../../helpers/game.js';
import _ from 'lodash';

class Dashboard extends React.Component {
  state = {
    tables: []
  };

  updateTables = (tableId, props) => {
    const idx = this.state.tables.findIndex(table => table.id === tableId);

    if (idx === -1) {
      // got a notification for a table that we don't currently have in the list, ignore
      return;
    }

    const nextTable = {
      ...this.state.tables[idx],
      ...props
    };

    const newTables = this.state.tables.concat();
    newTables.splice(idx, 1, nextTable);
    this.setState({ tables: newTables });
  };

  handleTableQueryResult = ({ tablesInProgress }) => {
    this.setState({ tables: tablesInProgress });
  };

  handleTrickSubscriptionData = ({ data }) => {
    const { tableId, nsTricks, ewTricks, trick } = data.trickUpdate;
    const tableIdx = this.state.tables.findIndex(table => table.id === tableId);

    if (tableIdx === -1) {
      // got a notification for a table that we don't currently have in the list, ignore
      return;
    }

    const oldTable = this.state.tables[tableIdx];
    const newTable = {
      ...oldTable,
      nsTricks,
      ewTricks,
      cardPlay: updateCardPlay(trick, oldTable.cardPlay)
    };

    const newTables = this.state.tables.concat();
    newTables.splice(tableIdx, 1, newTable);

    this.setState({ tables: newTables });
  };

  startPolling = (refetchFn) => {
    if (this.pollingInterval) {
      this.stopPolling();
    }

    this.pollingInterval = setInterval(() => {
      this.refetch(refetchFn);
    }, 1000);
  };

  stopPolling = () => {
    clearInterval(this.pollingInterval);
    this.pollingInterval = null;
  };

  refetch = async (refetchFn) => {
    const result = await refetchFn();
    this.handleTableQueryResult(result.data);
  };

  handleTableSubscriptionData = ({ data }) => {
    const updatedTable = data.tableUpdate.table;
    this.updateTables(updatedTable.id, updatedTable);
  };

  handleDirectorCallSubscriptionData = ({ data }) => {
    const { tableId, directorCallStatus, directorCallerId } = data.directorCallUpdate;
    this.updateTables(tableId, { directorCallStatus, directorCallerId });
  };

  handleDirectorTurnSubscriptionData = ({ data }) => {
    const { tableId, activeSeat, declarer } = data.directorTurn;
    this.updateTables(tableId, { activeSeat, declarer });
  };

  render() {
    const numPairs = this.props.numPairs;
    const expectedNumTables = Math.ceil(numPairs / 2);

    return (
      <Users>
        {(users) => (
          <Query
            query={tableQueries.tablesInProgress}
            onCompleted={this.handleTableQueryResult}
            fetchPolicy='no-cache'
          >
            {({ refetch: refetchTables, data, startPolling, stopPolling }) => {
              // when a session starts, there should never be a situation where there's not enough tables for the amount of pairs we have
              // this can currently only happen in multiregion events where a user is from a different AWS region
              // and the session update event has been replicated to the user's region before the table objects have
              //
              // in those cases, we want to poll until the tables do come in
              //
              // we're using our own polling functions because of https://github.com/apollographql/react-apollo/issues/3709
              if (data &&
                data.tablesInProgress &&
                data.tablesInProgress.length < expectedNumTables) {
                this.startPolling(refetchTables);
                return null;
              } else {
                this.stopPolling();
              }

              return (
                <PPSubscription
                  subscription={tableSubscriptions.trickUpdate}
                  onSubscriptionData={this.handleTrickSubscriptionData}
                >
                  {() => (
                    <PPSubscription
                      subscription={tableSubscriptions.tableUpdate}
                      onSubscriptionData={this.handleTableSubscriptionData}
                    >
                      {() => (
                        <PPSubscription
                          subscription={tableSubscriptions.directorCallUpdate}
                          onSubscriptionData={this.handleDirectorCallSubscriptionData}
                        >
                          {() => (
                            <PPSubscription
                              subscription={tableSubscriptions.directorTurn}
                              onSubscriptionData={this.handleDirectorTurnSubscriptionData}
                            >
                              {() => {
                                const tables = _.cloneDeep(this.state.tables);
                                tables.forEach(table => {
                                  // populate each pair on the table with user details
                                  // (e.g. online status)
                                  const [nsPair, ewPair] = populatePairsWithUserData({
                                    pairs: [table.nsPair, table.ewPair],
                                    users
                                  });
                                  table.nsPair = nsPair;
                                  table.ewPair = ewPair;
                                });

                                return this.props.children({
                                  users,
                                  tables,
                                  refetch: () => { this.refetch(refetchTables); }
                                });
                              }}
                            </PPSubscription>
                          )}
                        </PPSubscription>
                      )}
                    </PPSubscription>
                  )}
                </PPSubscription>
              );
            }}
          </Query>
        )}
      </Users>
    );
  }
}

export default Dashboard;
