import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Player from '../Players/Player.js';
import Grid from '@material-ui/core/Grid';
import Icon from '../Icon.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    background: '#fff',
    borderRadius: '0.5rem',
    padding: '1rem',
    marginBottom: '1rem',
    overflow: 'hidden',
    textAlign: 'left',
    fontSize: '1rem',
    display: 'flex'
  },
  playerContainer: {
    flex: '1'
  },
  iconContainer: {
    justifyContent: 'end',
    alignContent: 'center',
    display: 'flex'
  },
  onlineIndicator: {
    marginTop: 9
  },
  actionButton: {
    marginRight: -8
  }
}));

const LobbyPair = (props) => {
  const { pair, showOnlineIndicator, actionButton } = props;
  const { user1, user2 } = pair;
  const classes = useStyles();
  const { t } = useTranslation();

  const isUserOneOffline = user1.onlineStatus === 'Offline';
  const isUserTwoOffline = user2.onlineStatus === 'Offline';
  const isEitherUserOffline = isUserOneOffline || isUserTwoOffline;
  let offlineIconTooltipText = '';

  if (isEitherUserOffline) {
    if (isUserOneOffline && isUserTwoOffline) {
      offlineIconTooltipText = t('both-partners-appear-to-be-offline');
    } else if (isUserOneOffline) {
      offlineIconTooltipText = t('name-appears-to-be-offline', { name: user1.firstName });
    } else if (isUserTwoOffline) {
      offlineIconTooltipText = t('name-appears-to-be-offline', { name: user2.firstName });
    }
  }

  // offlineIconTooltipText = offlineIconTooltipText + ' ' + t('to-be-offline');

  return (
    <div className={classes.container}>
      <div className={classes.playerContainer}>
        <Grid container>
          <Grid item xs={6}>
            <Player
              {...user1}
            />
          </Grid>
          <Grid item xs={6}>
            <Player
              {...user2}
            />
          </Grid>
        </Grid>
      </div>
      <div className={clsx(classes.iconContainer)}>
        {showOnlineIndicator && (
          <div className={classes.onlineIndicator}>
            {
              isEitherUserOffline
                ? <Icon type='warning' tooltip={offlineIconTooltipText} />
                : <Icon type='tick' color='green' tooltip={t('pair-ready-to-play')} />
            }
          </div>
        )}
        {actionButton && (
          <div className={classes.actionButton}>
            {actionButton}
          </div>
        )}
      </div>
    </div>
  );
};

LobbyPair.propTypes = {
  pair: PropTypes.object.isRequired,
  showOnlineIndicator: PropTypes.bool,
  actionButton: PropTypes.node
};

export default LobbyPair;
