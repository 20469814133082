import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    margin: 0,
    padding: theme.spacing(2),
    paddingRight: '6rem'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const PPDialog = (props) => {
  const {
    title,
    children,
    onClose,
    actions,
    hideCloseButton,
    hideDividers,
    contentClassName,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      {...rest}
    >
      <DialogTitle disableTypography className={classes.header}>
        <Typography variant='h6'>
          {title}
        </Typography>
        {
          hideCloseButton !== true && (
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )
        }
      </DialogTitle>
      <DialogContent
        className={contentClassName}
        dividers={!hideDividers}
      >
        {children}
      </DialogContent>
      {
        actions && (
          <DialogActions>
            {actions}
          </DialogActions>
        )
      }
    </Dialog>
  );
};

PPDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  hideCloseButton: PropTypes.bool,
  hideDividers: PropTypes.bool,
  actions: PropTypes.element,
  onClose: PropTypes.func,
  contentClassName: PropTypes.string
};

export default PPDialog;
