import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getAppDisplayName } from '../helpers/url.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.background.main,
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    zIndex: 10000,
    textAlign: 'center',
    fontWeight: 'bold',
    '@media (max-width:1000px), (orientation: portrait)': {
      display: 'flex'
    }
  },
  container: {
    maxWidth: '70rem',
    padding: '2rem 4rem'
  },
  img: {
    width: '100%'
  },
  paragraph: {
    margin: '0 auto',
    marginTop: '2rem',
    display: 'block',
    maxWidth: '40rem'
  }
}));

const ResolutionNotSupported = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Component is loaded before i18n in initialised resulting in an error in translations.
  // Message is translated based on current user language rather than through i18n, not the nicest fix but at least it works!

  const appName = getAppDisplayName();

  const text = t('appname-doesnt-currently-support-size', { appName });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img className={classes.img} src='/img/components/ResolutionNotSupported/devices.png' alt='devices' />
        <p className={classes.paragraph}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default ResolutionNotSupported;
