import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getMaxNumberOfRounds } from '../../../helpers/game.js';
import { useTranslation } from 'react-i18next';
import LobbyDialog from './LobbyDialog.js';
import SpinnerButton from '../../../Components/SpinnerButton.js';

const useStyles = makeStyles(theme => ({
  content: {
    background: theme.palette.block.content.background
  },
  formControl: {
    width: '100%',
    maxWidth: '30rem'
  },
  btnContainer: {
    position: 'relative'
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

const SessionParametersModal = (props) => {
  const { session, pairs, onClose, onSubmit, loading, containerRef } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [winnerType, setWinnerType] = useState(session.winnerType);

  const maxRounds = getMaxNumberOfRounds(pairs.length, winnerType);

  const [numRounds, setNumRounds] = useState(Math.min(session.numRounds, maxRounds));
  const [boardsPerRound, setBoardsPerRound] = useState(session.boardsPerRound);
  const [boardTimeLimit, setBoardTimeLimit] = useState(7.5);

  const roundTimeLimit = boardsPerRound * boardTimeLimit;
  // no other validation needed - type='number' on the inputs does a lot of it
  const canSubmit = numRounds && boardsPerRound && boardTimeLimit;

  const handleSubmitClick = useCallback(() => {
    if (!canSubmit) { return; }

    onSubmit({
      numRounds: parseInt(numRounds, 10),
      boardsPerRound: parseInt(boardsPerRound, 10),
      roundTimeLimit: parseFloat(roundTimeLimit, 10),
      winnerType
    });
  }, [numRounds, boardsPerRound, roundTimeLimit, winnerType, canSubmit, onSubmit]);

  const handleNumRoundsChange = (e) => {
    setNumRounds(e.target.value);
  };

  const handleBoardsPerRoundChange = (e) => {
    setBoardsPerRound(e.target.value);
  };

  const handleBoardTimeLimitChange = (e) => {
    setBoardTimeLimit(e.target.value);
  };

  const handleWinnerTypeChange = useCallback((e) => {
    const winnerType = e.target.value;
    // reduce number of rounds currently selected if it's now out of bounds
    const maxRounds = getMaxNumberOfRounds(pairs.length, winnerType);
    setNumRounds(Math.min(numRounds, maxRounds));
    setWinnerType(winnerType);
  }, [setWinnerType, setNumRounds, numRounds, pairs.length]);

  return (
    <LobbyDialog
      onClose={onClose}
      title={t('start-game') + ' ' + session.name}
      contentClassName={classes.content}
      containerRef={containerRef}
      actions={
        <>
          <Button
            onClick={onClose}
            disabled={loading}
          >
            {t('cancel')}
          </Button>
          <div className={classes.btnContainer}>
            <SpinnerButton
              variant='contained'
              color='primary'
              loading={loading}
              disabled={!canSubmit}
              onClick={handleSubmitClick}
            >
              {t('start-game')}
            </SpinnerButton>
          </div>
        </>
      }
    >
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='flex-start'
        spacing={2}
      >
        <Grid item xs={6}>
          {t('use-this-form-to-make-any-final-adjustments')}
          <br /><br />
          {t('please-note-that-any-players')}
        </Grid>
        <Grid item xs={6}>
          <form>
            <Grid
              container
              direction='column'
              justify='center'
              alignItems='stretch'
              spacing={2}
            >
              {/*
                The InputProps={{...}} thing is from
                https://stackoverflow.com/a/47798729/484538
              */}
              <Grid item>
                <TextField
                  variant='outlined'
                  label={t('number-of-rounds')}
                  type='number'
                  step={1}
                  disabled={loading}
                  InputProps={{ inputProps: { min: 1, max: maxRounds } }}
                  onChange={handleNumRoundsChange}
                  value={numRounds}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant='outlined'
                  label={t('boards-per-round')}
                  disabled={loading}
                  type='number'
                  InputProps={{ inputProps: { min: 1, step: 1 } }}
                  onChange={handleBoardsPerRoundChange}
                  value={boardsPerRound}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant='outlined'
                  disabled={loading}
                  label={t('time-per-board')}
                  type='number'
                  InputProps={{ inputProps: { min: 1, step: 0.5 } }}
                  onChange={handleBoardTimeLimitChange}
                  value={boardTimeLimit}
                  helperText={roundTimeLimit ? t('time-per-round-n-minutes', { n: roundTimeLimit }) : ''}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item>
                <FormControl variant='outlined' className={classes.formControl}>
                  <InputLabel>{t('winner-type')}</InputLabel>
                  <Select
                    label='Winner type'
                    onChange={handleWinnerTypeChange}
                    value={winnerType}
                    disabled={loading}
                  >
                    <MenuItem value='OneWinner'>{t('one-winner')}</MenuItem>
                    <MenuItem value='TwoWinner'>{t('two-winner')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </LobbyDialog>
  );
};

SessionParametersModal.propTypes = {
  containerRef: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default SessionParametersModal;
