import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import DealDirection from './DealDirection.js';
import { cardHelpers } from 'shared-helpers';
const { getCardsForSeat, arrangeSeatsBasedOnViewPoint } = cardHelpers;

const styles = {
  component: {
    position: 'relative',
    width: '31.9em',
    height: '34.6em',
    margin: '0 auto'
  }
};

class Deal extends React.Component {
  render () {
    const {
      classes,
      dealer,
      viewpoint,
      vulnerable,
      cards,
      scale,
      ...other
    } = this.props;

    const directions = arrangeSeatsBasedOnViewPoint(viewpoint);

    // component scale
    const componentScale = scale ? scale : 1;

    return (
      <div
        className={clsx(classes.component, 'viewpoint' + viewpoint)}
        style={{fontSize:`${componentScale}rem`}}
      >
      {
        directions.map((direction, index) => {
          const cardsForSeat = getCardsForSeat(cards, direction.value);
          return (
            <DealDirection
              key={index}
              index={index}
              direction={direction.key}
              dealer={dealer === direction.value ? true : false}
              vulnerable={
                (vulnerable === 'Both' || vulnerable.includes(direction.value))
                  && vulnerable !== 'None'
                  ? true : false
                }
              cards={cardsForSeat}
              {...other}
            />
          )
        })
      }
      </div>
    );
  };
};

Deal.propTypes = {
  classes: PropTypes.object.isRequired,
  dealer: PropTypes.string.isRequired,
  viewpoint: PropTypes.string.isRequired,
  vulnerable: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
  scale: PropTypes.number
};

export default withStyles(styles)(Deal);
