import React from 'react';
import { Grid } from '@material-ui/core';
import SettingsButton from './SettingsButton.js';
import RefreshButton from './RefreshButton.js';
import BugButton from './BugButton.js';
import FullscreenButton from './FullscreenButton.js';
import PlayerActions from './Player.js';
import DirectorActions from './Director.js';

const Actions = (props) => {
  const { currentUser, session, table, setMakingClaim } = props;

  const isDirector = currentUser && currentUser.type === 'Director';
  const isPlayer = currentUser && currentUser.type === 'Player';

  return (
    <Grid
      container
      spacing={1}
      wrap='nowrap'
    >
      {
        isPlayer && (
          <Grid item>
            <PlayerActions
              table={table}
              session={session}
              currentUser={currentUser}
              setMakingClaim={setMakingClaim}
            />
          </Grid>
        )
      }
      {
        isDirector && (
          <Grid item>
            <DirectorActions
              table={table}
              currentUser={currentUser}
              setMakingClaim={setMakingClaim}
            />
          </Grid>
        )
      }
      <Grid item>
        <Grid
          container
          wrap='nowrap'
        >
          <Grid item>
            <RefreshButton />
          </Grid>
          <Grid item>
            <FullscreenButton />
          </Grid>
          {
            window.gsc && (
              <Grid item>
                <BugButton />
              </Grid>
            )
          }
          <Grid item>
            <SettingsButton
              currentUser={currentUser}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Actions;
