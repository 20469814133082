import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import UserSettingsContext from './UserSettings/UserSettingsContext.js';

const useStyles = makeStyles(() => ({
  root: {
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    left: '-10rem',
    top: '-10rem'
  },
  img: {
    width: 1
  }
}));

const ImagePreloader = (props) => {
  const { simplifiedCards } = useContext(UserSettingsContext);
  const classes = useStyles();
  const suits = ['S', 'C', 'D', 'H'];
  const values = [2, 3, 4, 5, 6, 7, 8, 9, 'T', 'J', 'Q', 'K', 'A'];

  const biddingBoxAssets = [
    '/img/components/BiddingBox/bg_pass.svg',
    '/img/components/BiddingBox/bg_pass_bottom.svg',
    '/img/components/BiddingBox/bg_pass_hover.svg',
    '/img/components/BiddingBox/bg_pass_bottom_hover.svg',
    '/img/components/BiddingBox/bg_double.svg',
    '/img/components/BiddingBox/bg_double_hover.svg',
    '/img/components/BiddingBox/bg_double_disabled.svg',
    '/img/components/BiddingBox/bg_double_double.svg',
    '/img/components/BiddingBox/bg_double_double_hover.svg',
    '/img/components/BiddingBox/bg_double_double_disabled.svg',
    '/img/components/BiddingBox/bg_auction_tab_1.svg',
    '/img/components/BiddingBox/bg_auction_tab_1_hover.svg',
    '/img/components/BiddingBox/bg_icon_suit_club.svg',
    '/img/components/BiddingBox/bg_auction_tab_2.svg',
    '/img/components/BiddingBox/bg_auction_tab_2_hover.svg',
    '/img/components/BiddingBox/bg_icon_suit_diamond.svg',
    '/img/components/BiddingBox/bg_icon_suit_heart.svg',
    '/img/components/BiddingBox/bg_icon_suit_spade.svg'
  ];

  const contractSuits = [
    '/img/components/Contract/bg_icon_suit_club.svg',
    '/img/components/Contract/bg_icon_suit_diamond.svg',
    '/img/components/Contract/bg_icon_suit_heart.svg',
    '/img/components/Contract/bg_icon_suit_spade.svg'
  ];

  const style = (simplifiedCards && simplifiedCards.value) ? 'simplified' : 'normal';
  const cardRoot = `/img/cards/${style}`;

  return (
    <div className={classes.root}>
      {
        suits.map(suit => {
          return (
            values.map((value, index) => {
              const src = `${cardRoot}/${value}${suit}.png`;
              return (
                <img
                  src={src}
                  className={classes.img}
                  key={index}
                  alt={`${value}${suit}`}
                />
              );
            })
          );
        })
      }
      {
        biddingBoxAssets.map((src, index) => {
          return (
            <img
              src={src}
              className={classes.img}
              key={index}
              alt={`Bidding Box Asset ${index}`}
            />
          );
        })
      }
      {
        contractSuits.map((src, index) => {
          return (
            <img
              src={src}
              className={classes.img}
              key={index}
              alt={`Bidding Box Asset ${index}`}
            />
          );
        })
      }
    </div>
  );
};

export default ImagePreloader;
