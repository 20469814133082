// simplify user settings so we can send them via getsitecontrol
export const simplifyUserSettings = (settings) => {
  const settingsToSend = {};

  Object.keys(settings).forEach(key => {
    if (typeof settings[key].value === 'boolean') {
      settingsToSend[key] = settings[key].value;
    }
  });

  return settingsToSend;
};
