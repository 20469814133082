import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePPMutation } from '../../hooks/';
import { pairMutations } from '../../graphql/mutations/';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Tooltip,
  Typography
} from '@material-ui/core';
import SpinnerButton from '../SpinnerButton.js';
import { useTranslation } from 'react-i18next';

const DeletePairButton = (props) => {
  const { pair, disabled, tooltip } = props;
  const [deletePair, { called, reset, error }] = usePPMutation(pairMutations.deletePair, { memoize: true });
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (called) { return; }

    deletePair({
      variables: {
        pairId: pair.id
      }
    });
  }, [pair.id, deletePair, called]);

  // reset `called` every time the user pair changes
  const pairId = pair && pair.id;
  useEffect(() => {
    reset();
  }, [pairId, reset]);

  return (
    <Tooltip
      arrow placement='top' title={
        <Typography>
          {t(tooltip) || t('remove-partner')}
        </Typography>
      }
    >
      <span>
        <SpinnerButton
          isIconButton={true}
          onClick={handleClick}
          loading={called && !error}
          disabled={disabled}
        >
          <DeleteIcon fontSize='small' />
        </SpinnerButton>
      </span>
    </Tooltip>
  );
};

DeletePairButton.propTypes = {
  pair: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string
};

export default DeletePairButton;
