import React from 'react';
import { withApollo } from 'react-apollo';
import { PPSnackbarConsumer } from '../PPSnackbar/PPSnackbarContext.js';

// Component that wraps subscription logic up.
// We can't use the stock <Subscription> component or the useSubscription hook
// because they currently add a subscription twice:
// https://github.com/apollographql/apollo-client/issues/6037
//
// This wrapper is not written as a hook because hooks have a hairy relationship with
// starting / cleaning up - by default, any cleanup will happen with each update,
// rather than when the component unmounts, and we don't want to hammer our DB with sub/unsub requests

class PPSubscription extends React.Component {
  render() {
    const { client, subscription, variables, onSubscriptionData, children } = this.props;
    /**
     * using a second class so we can pass the snackbar consumer to every instance of PPSubscription
     * and still take advantage of componentDidMount()
     *
     * the 'key' is needed in cases where the same subscription can switch
     * to the same kind of object, but different ID
     * (e.g. the player's tableUpdate between 2 different games)
     *
     * without this, the second subscription might not be made
     */
    return (
      <PPSnackbarConsumer>
        {({ openSnackbar }) => (
          <InternalSubscription
            key={JSON.stringify(variables)}
            children={children}
            openSnackbar={openSnackbar}
            client={client}
            subscription={subscription}
            variables={variables}
            onSubscriptionData={onSubscriptionData}
          />
        )}
      </PPSnackbarConsumer>
    );
  }
}

class InternalSubscription extends React.Component {
  constructor(props) {
    super(props);

    this.subscription = null;
  }

  state = {
    subscriptionResult: null,
    subscription: null
  };

  componentDidMount() {
    const observer = this.props.client.subscribe({
      query: this.props.subscription,
      variables: this.props.variables
    });

    this.subscription = observer.subscribe({
      next: (subscriptionResult) => {
        if (this.subscription._state === 'closed') {
          return;
        }

        this.setState({ subscriptionResult });
        if (this.props.onSubscriptionData) {
          this.props.onSubscriptionData(subscriptionResult);
        }
      },
      error: (err) => {
        // we only need to worry about displaying the error here -
        // it will have been caught by onError in graphql/client.js
        // so Sentry instrumentation is unnecessary
        this.props.openSnackbar('error', err.message, 'error');
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    return this.props.children(this.state.subscriptionResult);
  }
}

export default withApollo(PPSubscription);
