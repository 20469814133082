import React, { useCallback, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import TableList from './TableList.js';
import Chat from '../../../Components/Chat/Chat.js';
import { tableMutations, messageMutations } from '../../../graphql/mutations/';
import { getTableThreadId, getPlayerSeatForTable } from '../../../helpers/game.js';
import GameFinishedBanner from './GameFinishedBanner.js';
import { usePPMutation, useCloseWarning } from '../../../hooks/';
import PPSnackbarContext from '../../../Components/PPSnackbar/PPSnackbarContext.js';
import { getEndGameLobbyRecipients } from '../../../helpers/chat.js';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  containerChat: {
    right: 0,
    top: '7rem',
    bottom: 0,
    width: '33.3333%',
    padding: '2rem 2rem 2rem 1rem',
    fontSize: '1rem'
  },
  containerSessionFinished: {
    left: 0,
    top: '7rem',
    width: '66.6667%',
    height: '13rem',
    padding: '2rem 1rem 1rem 2rem'
  },
  containerTables: {
    left: 0,
    top: '7rem',
    bottom: 0,
    width: '66.6667%',
    padding: '2rem 1rem 2rem 2rem'
  },
  containerTablesSessionFinished: {
    top: '20rem',
    paddingTop: '1rem'
  },
  blockContainer: {
    position: 'relative',
    background: theme.palette.block.content.background,
    borderRadius: '0.5rem',
    overflow: 'hidden',
    color: '#222',
    height: '100%'
  },
  blockHeader: {
    background: '#fff',
    height: '6rem',
    padding: '0 2rem',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '2rem'
  },
  blockContent: {
    position: 'absolute',
    top: '6.1rem',
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden'
  }
}));

const Dashboard = (props) => {
  const { tables, session, history, refetch, users, currentUser } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [setDirectorAtTable, { called, reset }] = usePPMutation(tableMutations.setDirectorAtTable);
  const [sendMessage] = usePPMutation(messageMutations.sendMessage);
  const { closeSnackbar } = useContext(PPSnackbarContext);

  const goToTable = useCallback(async (tableId) => {
    if (called) { return; }
    await setDirectorAtTable({
      variables: {
        tableId: tableId,
        directorAtTable: true
      }
    });

    // if there's a notification for that table,
    // close it
    closeSnackbar(tableId);

    reset();
    history.push(`/director/table/${tableId}`);
  }, [called, closeSnackbar, reset, history, setDirectorAtTable]);

  useCloseWarning(session && session.status === 'InProgress');

  // the dashboard chat thread is the announcements thread.
  // only the director can post to it, and all messages posted there
  // get cross-posted to every active table
  const handleMessageSent = useCallback((result) => {
    if (!result.data && !result.data.sendMessage) { return; }

    const { text, threadId } = result.data.sendMessage;

    if (threadId.indexOf('-announcements') === -1) {
      return;
    }

    tables.forEach(table => {
      sendMessage({
        variables: {
          text,
          threadId: getTableThreadId(session, table),
          type: 'Global'
        }
      });
    });
  }, [tables, sendMessage, session]);

  const sessionFinished = session.status === 'Finished';

  // if this is a playing director and the first round has just started,
  // take them to their own table instead
  useEffect(() => {
    const playingDirectorTable = tables.find(table => getPlayerSeatForTable(currentUser.id, table));

    if (!playingDirectorTable) { return; }

    const timeSinceStarted = new Date() - new Date(session.timeLastRoundStarted);

    if (session.roundsCompleted === 0 &&
      timeSinceStarted < 5000) {
      history.push(`/director/table/${playingDirectorTable.id}`);
    }
  }, [tables, currentUser.id, session, history]);

  const chatTabs = [
    {
      threadId: session.id + '-announcements',
      headerText: t('announcements'),
      placeholderText: t('send-a-message-to-all-tables')
    },
    {
      threadId: session.id,
      headerText: t('lobby'),
      placeholderText: t('send-a-message-to-everyone-in-lobby'),
      recipients: getEndGameLobbyRecipients(currentUser, users, session, t)
    }
  ];

  return (
    <>
      {sessionFinished && (
        <div className={clsx(classes.container, classes.containerSessionFinished)}>
          <GameFinishedBanner
            session={session}
            history={history}
          />
        </div>
      )}
      <div className={clsx(classes.container, classes.containerTables, sessionFinished && classes.containerTablesSessionFinished)}>
        <div className={classes.blockContainer}>
          <div className={classes.blockHeader}>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Grid item>
                {t('tables')}
              </Grid>
              <Grid item />
            </Grid>
          </div>
          <div className={classes.blockContent}>
            <TableList
              tables={tables}
              refetch={refetch}
              onGoToTableClick={goToTable}
              session={session}
            />
          </div>
        </div>
      </div>
      <div className={clsx(classes.container, classes.containerChat)}>
        <Chat
          tabs={chatTabs}
          threadId={session.id + '-announcements'}
          onMessageSent={handleMessageSent}
          currentUser={currentUser}
          headerText={t('messages-to-all-tables')}
          placeholderText={t('send-a-message-to-all-tables')}
        />
      </div>
    </>
  );
};

export default Dashboard;
