import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  select: {
    paddingLeft: 5
  },
  selectMenu: {
    maxHeight: 400
  }
}));

const RecipientSelector = (props) => {
  const { recipients, value, onChange } = props;
  const classes = useStyles();

  const handleChange = useCallback((e) => {
    let val = e.target.value;

    const recipient = recipients.find(recipient => recipient.id === val);

    if (recipient && recipient.disabled) {
      return;
    }

    onChange(val);
  }, [onChange, recipients]);

  return (
    <Select
      defaultValue=''
      value={value}
      displayEmpty
      onChange={handleChange}
      classes={{ select: classes.select }}
      MenuProps={{className: classes.selectMenu }}
    >
      {recipients.map(recipient => (
        <MenuItem
          key={recipient.id}
          value={recipient.id}
          disabled={recipient.disabled}
        >
          {recipient.name}
        </MenuItem>
      ))}
    </Select>
  );
};

RecipientSelector.propTypes = {
  recipients: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RecipientSelector;
