import { colours as pianolaColours } from './baseTheme.js';

export const colours = {
  darkBlue: '#063350',
  blue: '#145886'
};

const bridgemateTheme = {
  palette:{
    primary: {
      main: colours.blue,
      dark: colours.darkBlue,
      light: '#176499'
    },
    background: {
      main: colours.blue,
      dark: colours.darkBlue
    }
  },
  logo: {
    image: 'bridgemate_logo',
    background: 'transparent',
    marginLeft: '2rem',
    gameWidth: '20rem',
    gameHeight: '6rem'
  },
  header: {
    background: colours.darkBlue,
    activeTabBackground: colours.blue
  },
  buttonSpinner: {
    color: pianolaColours.gold
  },
  lobby: {
    directorCta: {
      background: pianolaColours.gold,
      hoverBackground: pianolaColours.darkGold
    }
  },
  game: {
    status: {
      itemLabel: pianolaColours.gold
    },
    backgroundGradient: {
      outer: colours.darkBlue,
      inner: colours.blue
    },
    cardImage: 'bridgemate_back',
    playerSeat: {
      background: '#fff',
      border: '#fff',
      text: '#111',
      activeBorder: pianolaColours.gold,
      activeBackground: '#fff',
      activeText: '#111',
      borderWidth: '0.5em'
    },
    viewpointPlayerSeat: {
      background: colours.darkBlue,
      border: 'transparent',
      text: '#fff',
      activeBorder: 'transparent',
      activeBackground: '#fff',
      activeText: '#111',
      borderWidth: '0'
    }
  },
  overrides: {
    MuiCircularProgress: {
      colorPrimary: {
        color: '#fff'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#0D2535'
      },
      arrow: {
        color: '#0D2535'
      }
    }
  }
};

export default bridgemateTheme;
