import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  eye: {
    cursor: 'pointer',
    color: theme.palette.error
  },
  hideShow: {
    textDecoration: 'underline',
    color: 'grey',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

class PPInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: '',
      passwordIsMasked: true,
      shrink: false
    };

    this.inputRef = React.createRef();
  }


  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  onChange = (e) => {
    const id = this.props.id;
    const value = e.target.value;
    const error = this.props.validate ? this.props.validate(value) : '';
    this.setState(
      {
        value,
        error
      });
    this.props.onChange(
      {
        id,
        value,
        error,
        event: e
      });
  };

  showEye = () => {
    const { classes, type } = this.props;
    if (type === 'password') {
      if (this.state.passwordIsMasked) {
        return (
          <InputAdornment position='end'>
            <span
              className={classes.hideShow}
              onClick={this.togglePasswordMask}
            >
              show
            </span>
            {
              /*
              <VisibilityOff
                className={classes.eye}
                color={'action'}
                onClick={this.togglePasswordMask}
              />
              */
            }
          </InputAdornment>
        )
      } else {
        return (
          <InputAdornment position='end'>
            <span
              className={classes.hideShow}
              onClick={this.togglePasswordMask}
            >
              hide
            </span>
            {
              /*
              <Visibility
                className={classes.eye}
                color={'action'}
                onClick={this.togglePasswordMask}
              />
              */
            }
          </InputAdornment>
        )
      }
    }
    return null;
  };

  getInputProps = () => {
    let { type, inputProps, className } = this.props;
    if (!inputProps) {
      inputProps = {};
    }
    if (type === 'password') {
      inputProps.endAdornment = this.showEye();
    }
    if (className) {
      inputProps.classes = {
        input: this.props.className
      };
    }
    return inputProps;
  };

  componentDidUpdate (prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState(
        {
          value: this.props.value
        }
      );
    }
  }

  render () {
    return (
      <TextField
        style={{backgroundColor: 'white'}}
        id={this.props.id}
        name={this.props.id}
        type={this.props.type === 'password' && this.state.passwordIsMasked ? 'password' : 'text'}
        label={this.props.label}
        ref={this.props.setRef ? this.props.setRef : this.inputRef}
        value={this.state.value}
        maxLength={this.props.maxLength}
        autoComplete={this.props.autoComplete}
        autoFocus={this.props.autoFocus}
        required={this.props.required}
        error={this.state.error !== ''}
        onChange={this.onChange}
        onBlur={this.onChange}
        onKeyDown={this.onChange}
        onKeyUp={this.props.onKeyUp}
        variant={this.props.variant ? this.props.variant : 'outlined'}
        InputProps={this.getInputProps()}
        InputLabelProps={{
          classes: {
            root: this.props.className ? this.props.className : null
          }
        }}
        fullWidth={true}
        multiline={this.props.rows && this.props.rows > 1 ? true : false}
        rows={this.props.rows ? this.props.rows : 1}
        disabled={this.props.readOnly}
        placeholder={this.props.placeholder}
      />
    );
  }
}

PPInput.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  setRef: PropTypes.func,
  value: PropTypes.any,
  maxlength: PropTypes.number,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  validate: PropTypes.func,
  onKeyUp: PropTypes.func,
  inputProps: PropTypes.object,
  rows: PropTypes.number,
  onAutoFillStart: PropTypes.func,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string
};

export default withStyles(styles)(PPInput);
