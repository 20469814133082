import React from 'react';
import PropTypes from 'prop-types';
import { userQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';
import { isAuthenticated } from '../../helpers/auth.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class CurrentUser extends React.Component {
  render() {
    const { children, ...rest } = this.props;
    if (isAuthenticated()) {
      return (
        <PPQuery
          query={userQueries.currentUser}
          {...rest}
        >
          {data => {
            return children(data.currentUser);
          }}
        </PPQuery>
      );
    } else {
      return children(null);
    }
  }
}

CurrentUser.propTypes = {
  children: PropTypes.func.isRequired
};

export default CurrentUser;
