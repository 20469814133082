import { useEffect } from 'react';
import PropTypes from 'prop-types';

const HotJarUser = (props) => {
  const { currentUser } = props;

  const userId = currentUser && currentUser.id;

  useEffect(() => {
    if (userId && window.hj) {
      window.hj('identify', userId, {
        memberId: userId
      });
    }
  }, [userId]);

  return null;
};

HotJarUser.propTypes = {
  currentUser: PropTypes.object.isRequired
};

export default HotJarUser;
