import React from 'react';
import PPSubscription from './PPSubscription.js';
import { tableSubscriptions } from '../../graphql/subscriptions/';

class Auction extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      auction: props.table.auction,
      activeSeat: props.table.activeSeat,
      contract: props.table.contract,
      declarer: props.table.declarer
    };
  }

  updateAuction = (data) => {
    const { activeSeat, bid: nextBid, contract, declarer } = data;

    const idx = this.state.auction.findIndex(bid => bid.step === nextBid.step);
    const nextAuction = this.state.auction.concat();

    if (idx === -1) {
      nextAuction.push(nextBid);
    } else {
      nextAuction.splice(idx, 1, nextBid);
    }

    this.setState({
      auction: nextAuction,
      activeSeat: activeSeat,
      contract: contract,
      declarer: declarer
    });
  };

  handleNewBid = ({ data }) => {
    this.updateAuction(data.bidFeed);
  };

  handleBidMade = ({ data }) => {
    this.updateAuction(data.makeBid);
  };

  render() {
    return (
      <PPSubscription
        subscription={tableSubscriptions.bidFeed}
        variables={{ tableId: this.props.table.id }}
        onSubscriptionData={this.handleNewBid}
      >
        {() => {
          const tableStatus = this.props.table.status;
          const table = {
            ...this.props.table,
            auction: this.state.auction,
            // if the auction SQ's active seat is out-of-date, we don't want it
            // to accidentally override that of the more general table subscription
            activeSeat: tableStatus === 'Auction' ? this.state.activeSeat : this.props.table.activeSeat,
            contract: this.state.contract,
            declarer: this.state.declarer
          };
          return this.props.children(table, this.handleBidMade);
        }}
      </PPSubscription>
    );
  }
}

export default Auction;
