import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime, Info } from 'luxon';
import { useTranslation } from 'react-i18next';

// are timezones supported in this browser?
// (really it's just IE11 that doesn't)
const timeZonesSupported = Info.features().zones;

const useStyles = makeStyles(theme => ({
  emphasis: {
    fontSize: '3rem',
    margin: 0
  }
}));

const SessionStartingTime = (props) => {
  const { session, emphasizeName } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const showTimeZone = timeZonesSupported && session.timeZone;

  const luxonStartingTime = DateTime.fromISO(session.dateTime, {
    zone: showTimeZone ? session.timeZone : undefined
  });

  let date = luxonStartingTime.toLocaleString(DateTime.DATE_HUGE);
  // is the game today?
  const gameIsToday = luxonStartingTime.toLocaleString(DateTime.DATE_SHORT) === DateTime.now().toLocaleString(DateTime.DATE_SHORT);
  if (gameIsToday) {
    date = t('today');
  }

  const time = luxonStartingTime.toLocaleString({
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  });

  return (
    <>
      {
        emphasizeName && (
          <p className={classes.emphasis}>{session.name}</p>
        )
      }
      <p>
        {!emphasizeName && (session.name + ' ')}
        {
          gameIsToday ? t('is-scheduled-to-start') : t('is-scheduled-to-start-on')
        }
      </p>
      <p className={classes.emphasis}>{t('date-at-time', { date: date, time: time })}</p>
    </>
  );
};

SessionStartingTime.propTypes = {
  session: PropTypes.object.isRequired,
  emphasizeName: PropTypes.bool
};

export default SessionStartingTime;
