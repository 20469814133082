// This hook maintains an up-to-date cardplay when a player has just played a card
// but the backend has yet to catch up - it makes playing a card feel instantaneous

import { useEffect, useState, useCallback } from 'react';
import { ppPlayHelpers } from 'shared-helpers';
const { addToCardsPlayed } = ppPlayHelpers;

const useLocalTable = (table) => {
  const [playedCard, setPlayedCard] = useState(null);
  const [visibleActiveSeat, setVisibleActiveSeat] = useState(table.activeSeat);

  let localTable;

  const playedCardIsInTable =
    playedCard &&
    table.cardPlay.length &&
    table.cardPlay[table.cardPlay.length - 1] &&
    table.cardPlay[table.cardPlay.length - 1].cards &&
    table.cardPlay[table.cardPlay.length - 1].cards.find(card => card.rank === playedCard.rank && card.suit === playedCard.suit);

  // if there's a card played but it's not present in cardplay from props
  // add it artificially
  if (playedCard && !playedCardIsInTable) {
    localTable = addToCardsPlayed(playedCard, localTable || table);
  } else {
    localTable = table;
  }

  // if the card the player played is present in the table
  // (ie. the backend has caught up to the local table)
  // undo any changes we've made
  useEffect(() => {
    if (playedCardIsInTable) {
      setPlayedCard(null);
    }
  }, [playedCardIsInTable, setPlayedCard]);

  const handleCardPlayed = useCallback((card) => {
    setPlayedCard(card);
  }, [setPlayedCard]);

  // the CardPlay component needs to have the activeSeat stay the same as
  // the player that just played the card, whereas the server sends the play
  // and the next active seat at the same time
  //
  // if we don't wait until after the animation to update the active seat,
  // CardPlay shows the card going into the wrong position in the trick
  const handleCardAnimationComplete = useCallback(() => {
    setVisibleActiveSeat(localTable.activeSeat);
  }, [localTable.activeSeat, setVisibleActiveSeat]);

  return {
    localTable: {
      ...localTable,
      activeSeat: visibleActiveSeat
    },
    onCardPlayed: handleCardPlayed,
    onCardAnimationComplete: handleCardAnimationComplete
  };
};

export default useLocalTable;
