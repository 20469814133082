import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import OpponentButtons from './OpponentButtons.js';
import Player from '../../../Components/Players/Player.js';
import { auctionHelpers } from 'shared-helpers';
import { getPlayerBySeat, getPlayerSeatForTable } from '../../../helpers/game.js';
import { useTranslation } from 'react-i18next';
import ClaimResponseLabel from './ClaimResponseLabel.js';

const { convertSeatCodeToFullWord, whoIsDummy } = auctionHelpers;

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: '#666',
    textTransform: 'uppercase',
    textShadow: '0 1px #fff'
  },
  respondents: {
    fontSize: '1em',
    marginTop: '1em'
  }
}));

const ClaimResponses = (props) => {
  const { table, currentUser } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const claim = table.claim;

  const responsesBySeat = {};

  claim.responses.forEach((response) => {
    responsesBySeat[response.seat] = response;
  });

  let respondentPositions;

  if (claim.seat === 'N' || claim.seat === 'S') {
    respondentPositions = ['E', 'W'];
  } else {
    respondentPositions = ['N', 'S'];
  }

  return (
    <>
      <div className={classes.heading}>{t('responses')}</div>
      <div className={classes.respondents}>
        <Grid
          container
          spacing={2}
          wrap='nowrap'
        >
          {
            respondentPositions.map(seat => {
              const respondent = getPlayerBySeat(seat, table);

              return (
                <Grid item xs key={seat}>
                  <Player
                    textStatus={t(convertSeatCodeToFullWord(seat).toLowerCase())}
                    {...respondent}
                    fullName={respondent.firstName}
                  />
                </Grid>
              );
            })
          }
        </Grid>
      </div>
      <Grid
        container
        spacing={2}
      >
        {
          respondentPositions.map(seat => {
            const playerSeat = getPlayerSeatForTable(currentUser.id, table);
            const response = responsesBySeat[seat];
            const isDummy = seat === whoIsDummy(table.declarer);
            const canRespondToClaim = playerSeat === seat && !response && !isDummy;

            return (
              <Grid item xs key={seat}>
                {
                  canRespondToClaim ?
                    (<OpponentButtons table={table}/>) :
                    (<ClaimResponseLabel response={responsesBySeat[seat]} isDummy={isDummy}/>)
                }
              </Grid>
            );
          })
        }
      </Grid>
    </>
  );
};

ClaimResponses.propTypes = {
  table: PropTypes.object,
  currentUser: PropTypes.object
};

export default ClaimResponses;
