import gql from 'graphql-tag';
import { sessionFragments } from '../fragments';

const sessionMutations = {
  startSession: gql`
    mutation (
      $numRounds: Int,
      $boardsPerRound: Int,
      $roundTimeLimit: Float,
      $winnerType: WinnerTypeEnum
    ) {
      startSession (
        numRounds: $numRounds,
        boardsPerRound: $boardsPerRound,
        roundTimeLimit: $roundTimeLimit,
        winnerType: $winnerType
      ) {
        ...FullSession
      }
    }
    ${sessionFragments.fullSession}
  `,
  endRound: gql`
    mutation {
      endRound {
        ...FullSession
      }
    }
    ${sessionFragments.fullSession}
  `,
  publishResults: gql`
    mutation {
      publishResults {
        ...FullSession
      }
    }
    ${sessionFragments.fullSession}
  `,
  setSessionStarting: gql`
    mutation ($starting: Boolean!) {
      setSessionStarting(starting: $starting) {
        ...FullSession
      }
    }
    ${sessionFragments.fullSession}
  `
}

export default sessionMutations;
