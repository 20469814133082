import React, { useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import PageReloadContext from '../PageReloadContext.js';

const useStyles = makeStyles(theme => ({
  refreshButton: {
    color: '#999',
    '&:hover': {
      color: '#fff'
    }
  }
}));

const RefreshButton = () => {
  const classes = useStyles();
  const { reload } = useContext(PageReloadContext);

  const handleClick = useCallback(() => {
    reload();

    return false;
  }, [reload]);

  return (
    <IconButton className={classes.refreshButton} onClick={handleClick}>
      <RefreshIcon fontSize='large' />
    </IconButton>
  );
};

export default RefreshButton;
