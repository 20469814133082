import React from 'react';
import PropTypes from 'prop-types';
import StatusTimer from '../../StatusTimer.js';
import StatusItem from './StatusItem.js';
import { useTranslation } from 'react-i18next';

const TimeLeft = (props) => {
  const { session } = props;
  const { t } = useTranslation();

  return (
    <StatusItem
      label={t('time-left')}
      text={(
        <StatusTimer
          timeLastRoundStarted={session.timeLastRoundStarted}
          roundTimeLimit={session.roundTimeLimit}
        />
      )}
    />
  );
};

TimeLeft.propTypes = {
  session: PropTypes.object.isRequired
};

export default TimeLeft;
