import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import { tableQueries } from '../../../graphql/queries/';
import { calculateTableProgressPercentage } from '../../../helpers/dashboard.js';
import PPDialog from '../../../Components/PPDialog.js';
import SpinnerButton from '../../../Components/SpinnerButton.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  content: {
    background: theme.palette.block.content.background
  }
}));

const EndRoundDialog = (props) => {
  const {
    open,
    handleContinue,
    handleCancel,
    finalRound,
    loading: loadingEndRound
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    loading: loadingTables,
    data
  } = useQuery(tableQueries.tablesInProgressShort, {
    fetchPolicy: 'no-cache'
  });

  if (loadingTables || !data.tablesInProgress) {
    return null;
  }

  const tablesNotDone = data.tablesInProgress.filter(table => {
    return table.status !== 'WaitingForMovement' &&
      table.status !== 'SessionFinished';
  });

  let cta = 'start-next-round';
  let checkAction = 'sure-you-want-to-start-next-round';

  if (finalRound) {
    cta = 'end-session';
    checkAction = 'sure-you-want-to-end-session';
  }

  return (
    <PPDialog
      open={open}
      onClose={handleCancel}
      title={t(cta)}
      contentClassName={classes.content}
      actions={(
        <>
          <Button onClick={handleCancel}>{t('cancel')}</Button>
          <SpinnerButton
            variant='contained'
            color='primary'
            onClick={handleContinue}
            loading={loadingEndRound}
          >
            {t(cta)}
          </SpinnerButton>
        </>
      )}
    >
      <>
        <p>
          {t(checkAction)}
        </p>
        {
          tablesNotDone.length > 0 &&
            (
              <>
                <p>
                  {t('there-are-currently-n-tables-still-playing', { n: tablesNotDone.length })}
                </p>
                <ul>
                  {
                    tablesNotDone.map(table => (
                      <li key={table.tableNumber}>
                        {t('table')} {table.tableNumber + 1} ({calculateTableProgressPercentage(table)}%)
                      </li>
                    ))
                  }
                </ul>
                <p>
                  {t('they-will-be-marked-as-not-played')}
                </p>
              </>
            )
        }
      </>
    </PPDialog>
  );
};

export default EndRoundDialog;
