import React from 'react';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { userQueries } from '../../graphql/queries/';
import { userSubscriptions } from '../../graphql/subscriptions/';
import { addUserFromSubscription } from '../../helpers/users.js';

class TableUsers extends React.Component {
  state = {
    users: []
  };

  handleUserSubscriptionData = ({ data }) => {
    const updatedUser = data.userUpdateById;
    const newUsers = addUserFromSubscription(this.state.users, updatedUser);

    this.setState({
      users: newUsers
    });
  };

  handleUserQueryResult = ({ usersById }) => {
    this.setState({ users: usersById });
  };

  render() {
    const table = this.props.table;
    const ids = [];

    if (!table.nsPair.isPhantomPair) {
      ids.push(table.nsPair.user1.id, table.nsPair.user2.id);
    }
    if (!table.ewPair.isPhantomPair) {
      ids.push(table.ewPair.user1.id, table.ewPair.user2.id);
    }

    return (
      <Query
        query={userQueries.usersById}
        variables={{ ids }}
        fetchPolicy='no-cache'
        onCompleted={this.handleUserQueryResult}
      >
        {() => (
          <PPSubscription
            subscription={userSubscriptions.userUpdateById}
            variables={{ ids }}
            onSubscriptionData={this.handleUserSubscriptionData}
          >
            {() => {
              return this.props.children(this.state.users);
            }}
          </PPSubscription>
        )}
      </Query>
    );
  }
}

export default TableUsers;
