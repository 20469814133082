import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, CardHeader } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  container: {
    width: '55rem'
  },
  content: {
    background: '#f1f1f1',
    padding: '2.4rem 2rem'
  }
}));

const ErrorPage = ({heading, message}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className={classes.root}
    >
      <Grid item>
        <Card className={classes.container}>
          <CardHeader title={heading} />
          <CardContent className={classes.content}>
            {message}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

ErrorPage.propTypes = {
  heading: PropTypes.string.isRequired,
  message: PropTypes.object
};

export default ErrorPage;
