import gql from 'graphql-tag';
import { pairFragments } from '../fragments/';

const pairMutations = {
  markAsPartner: gql`
    mutation ($userId: ID!) {
      markAsPartner(userId: $userId) {
        ...FullPair
      }
    }
    ${pairFragments.fullPair}
  `,
  deletePair: gql`
    mutation ($pairId: ID!) {
      deletePair(pairId: $pairId) {
        id
      }
    }`
}

export default pairMutations;
