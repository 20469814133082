import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PlayerAvatar from './PlayerAvatar.js';
import LastSeenTime from './LastSeenTime.js';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: '1.6em'
  },
  name: {
    fontSize: '1.6em',
    fontWeight: 'bold'
  },
  info: {
    fontSize: '1.3em',
    fontWeight: 'bold'
  },
  noTruncate: {
    textOverflow: 'initial'
  }
}));

const Player = (props) => {
  const {
    fullName,
    firstName,
    lastName,
    avatarUrl,
    onlineStatus,
    lastSeen,
    textStatus,
    badges,
    noTruncate
  } = props;
  const classes = useStyles();

  // set bools to false if it's not passed in as prop
  let { avatarOnly, showTooltip } = props;
  if (typeof avatarOnly === 'undefined') {
    avatarOnly = false;
    showTooltip = false;
  }

  let status = null;
  if (textStatus) {
    status = textStatus;
  } else if (onlineStatus === 'Offline') {
    status = (
      <LastSeenTime lastSeen={lastSeen} />
    );
  }

  return (
    <Tooltip
      arrow placement='top'
      title={
        <Typography>
          {fullName} ({onlineStatus})
        </Typography>
      }
      disableHoverListener={!showTooltip}
    >
      <Grid container>
        <Grid item container wrap='nowrap' spacing={1} alignItems='center'>
          <Grid item className={classes.avatar}>
            <PlayerAvatar
              avatarUrl={avatarUrl}
              onlineStatus={onlineStatus}
              fullName={fullName}
              firstName={firstName}
              lastName={lastName}
            />
          </Grid>
          {
            !avatarOnly && (
              <Grid item xs zeroMinWidth>
                <Typography noWrap className={clsx(classes.name, noTruncate && classes.noTruncate)}>{fullName}</Typography>
                <Typography noWrap className={clsx(classes.info, noTruncate && classes.noTruncate)}>
                  {status}
                  {badges}
                </Typography>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Tooltip>
  );
};

Player.propTypes = {
  fullName: PropTypes.string.isRequired,
  onlineStatus: PropTypes.string,
  avatarUrl: PropTypes.string,
  lastSeen: PropTypes.string,
  textStatus: PropTypes.string,
  avatarOnly: PropTypes.bool,
  showTooltip: PropTypes.bool,
  badges: PropTypes.node
};

export default Player;
