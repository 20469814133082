import React, { useState, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import { parseAdjustedScores } from '../../../../helpers/results.js';
import ScoreInput from './ScoreInput.js';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    background: theme.palette.error.dark,
    textAlign: 'center'
  },
  arrow: {
    color: theme.palette.error.dark
  },
  tooltipPlacementBottom: {
    margin: '2.5rem 0'
  }
}))(Tooltip);

const ScoreEditor = (props) => {
  const { nsScore, ewScore, setParsedScore, showErrors, setShowErrors } = props;

  const [nsAdjustedScore, setNSAdjustedScore] = useState(nsScore);
  const [ewAdjustedScore, setEWAdjustedScore] = useState(ewScore);
  const [error, setError] = useState('');

  const handleInputChange = useCallback((position, value) => {
    if (position === 'ns') {
      setNSAdjustedScore(value);
    } else {
      setEWAdjustedScore(value);
    }
  }, [setNSAdjustedScore, setEWAdjustedScore]);

  // every time the adjusted scores change, update error / parsed scores
  useEffect(() => {
    setError('');
    // hide errors for now, only display them when the user presses ✓ again
    setShowErrors(false);

    try {
      setParsedScore(parseAdjustedScores(nsAdjustedScore, ewAdjustedScore));
    } catch (e) {
      setParsedScore(null);
      setError(e.message);
    }
  }, [nsAdjustedScore, ewAdjustedScore, setParsedScore, setError, setShowErrors]);

  return (
    <StyledTooltip arrow open={showErrors} title={<Typography>{error}</Typography>}>
      <span>
        <ScoreInput
          onChange={handleInputChange}
          position='ns'
          value={nsAdjustedScore}
        />
        &nbsp;&nbsp;
        <ScoreInput
          onChange={handleInputChange}
          position='ew'
          value={ewAdjustedScore}
        />
      </span>
    </StyledTooltip>
  );
};

export default ScoreEditor;
