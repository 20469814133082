import gql from 'graphql-tag';
import { sessionFragments } from '../fragments';

const sessionQueries = {
  currentSession: gql`
    query {
      currentSession {
        ...FullSession
      }
    }
    ${sessionFragments.fullSession}
  `
};

export default sessionQueries;
