import React from 'react';
import Table from './Table.js';
import Auction from './Auction.js';
import Play from './Play.js';

class Game extends React.Component {
  render() {
    return (
      <Table
        tableId={this.props.tableId}
      >
        {({table, refetch, nextTableId}) => {
          if (!table) {
            return this.props.children({ refetch });
          }

          return (
            <Auction table={table}>
              {(table, onBidMade) => (
                <Play table={table}>
                  {(table, onCardPlayed) => {
                    return this.props.children({
                      table,
                      refetch,
                      nextTableId,
                      onBidMade,
                      onCardPlayed
                    });
                  }}
                </Play>
              )}
            </Auction>
          );
        }}
      </Table>
    );
  }
}

export default Game;
