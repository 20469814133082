import gql from 'graphql-tag';
import { systemDescriptionFragments } from '../fragments';

const systemDescriptionMutations = {
  setSystemDescription: gql`
    mutation (
      $text: String!
    ) {
      setSystemDescription(
        text: $text
      ) {
        ...FullSystemDescription
      }
    }
    ${systemDescriptionFragments.fullSystemDescription}
  `,
  setSystemDescriptionEdited: gql`
    mutation (
      $isEdited: Boolean!
    ) {
      setSystemDescriptionEdited(
        isEdited: $isEdited
      ) {
        ...FullSystemDescription
      }
    }
    ${systemDescriptionFragments.fullSystemDescription}
  `
};

export default systemDescriptionMutations;
