import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import MiniCard from '../../MiniCard.js';
import { auctionHelpers } from 'shared-helpers';
import { Translation } from 'react-i18next';

const { convertBidsToTableRows, getStepNumberFromTable } = auctionHelpers;

const styles = {
  component: {
    textAlign: 'center',
    fontSize: '1rem',
    border: 'none',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    userSelect: 'none',
    '& thead': {
      fontSize: '1.6em',
      '& tr': {
        '& th': {
          paddingBottom: '0.5em'
        }
      }
    },
    '& tbody': {
      '& tr': {
        background: 'rgba(0,0,0,0.1)',
        '& td': {
          padding: '0.4em 0.2em',
          borderTop: '0.1em solid rgba(0,0,0,0.2)',
          minWidth: '5.7em',
          '&:first-child': {
            borderLeft: '0.1em solid rgba(0,0,0,0.2)',
            paddingLeft: '0.4em'
          },
          '&:last-child': {
            borderRight: '0.1em solid rgba(0,0,0,0.2)',
            paddingRight: '0.4em'
          }
        }
      }
    }
  }
};

class AuctionTableBody extends React.Component {
  render () {
    const { classes, size, auction, dealer, onBidClick } = this.props;

    // turn bids into rows
    const rows = convertBidsToTableRows(auction, dealer);
    const directions = ['W', 'N', 'E', 'S'];

    // add fake bids to prevent the table from collapsing
    // if there's no entries at all
    if (!rows.length) {
      rows.push(['', '', '', '']);
    }

    return (
      <table className={classes.component} cellPadding='0' cellSpacing='0' border='0'>
        <thead>
          <tr>
            {
              directions.map((direction, index) => {
                return (
                  // Class component needs to get translator 't' function as render props
                  <Translation key={index}>
                    {t => <th>{t(direction && direction.toLowerCase())}</th>}
                  </Translation>
                );
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {
                    row.map((bid, cellIndex) => {
                      const step = getStepNumberFromTable(rows, rowIndex, cellIndex);
                      const bidObject = auction[step];

                      return (
                        <td key={cellIndex}>
                          {
                            bid === ''
                              ? <div style={{ height: '1rem' }} />
                              : <MiniCard
                                size={size}
                                tooltipText={bidObject.comments ? 'Add commentary' : null}
                                suit={bidObject.denomination ? bidObject.denomination : null}
                                value={bidObject.level ? bidObject.level : bidObject.value}
                                hasComment={!!(onBidClick && bidObject.comments)}
                                isBid
                                onClick={() => {
                                  if (onBidClick) {
                                    onBidClick(bidObject, step, rowIndex + 1);
                                  }
                                }}
                              />
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  }
}

AuctionTableBody.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  dealer: PropTypes.string.isRequired,
  auction: PropTypes.array.isRequired,
  onBidClick: PropTypes.func
};

export default withStyles(styles)(AuctionTableBody);
