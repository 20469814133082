import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, ButtonGroup } from '@material-ui/core';
import FinishedBoard from '../../FinishedBoard.js';
import { Travellers as TravellersSQ } from '../../SubscriptionQueries';
import Travellers from './Travellers.js';
import { useScreenDimensions } from '../../../hooks';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  block: {
    display: 'block'
  },
  container: {
    width: '70rem',
    margin: '0 auto',
    padding: '3rem 0'
  },
  travellersTable: {
    marginTop: '3rem'
  },
  buttonActive: {
    background: theme.palette.secondary.main,
    color: '#000',
    '&:hover': {
      background: theme.palette.secondary.main
    }
  }
}));

const WaitingForMovement = (props) => {
  const { tables, session } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const screenDimensions = useScreenDimensions();

  // set root styling based on screen height
  let rootDisplayClass = null;
  if (screenDimensions.height <= 850) {
    rootDisplayClass = classes.block;
  }

  const localTables = tables.concat().sort((a, b) => a.boardNumber - b.boardNumber);

  const [tableOfBoardToShow, setTableOfBoardToShow] = useState(localTables[localTables.length - 1]);

  const handleBoardClick = useCallback((tableNumber) => {
    setTableOfBoardToShow(localTables[tableNumber]);
  }, [setTableOfBoardToShow, localTables]);

  return (
    <div className={clsx(classes.root, rootDisplayClass)}>
      <div className={classes.container}>
        <Typography variant='h5'>
          {t('round-finished')}
        </Typography>
        <Typography variant='h6'>
          {t('waiting-for-director-to-call-movement')}
        </Typography>
        <br />
        {
          localTables.length > 1 && (
            <>
              <ButtonGroup
                size='large'
                color='secondary'
              >
                {
                  localTables.map((table, index) => (
                    <Button
                      key={table.id}
                      onClick={() => handleBoardClick(index)}
                      className={tableOfBoardToShow.boardNumber === table.boardNumber && classes.buttonActive}
                    >
                      {t('board')} {table.boardNumber + 1}
                    </Button>
                  ))
                }
              </ButtonGroup>
              <br /><br />
            </>
          )
        }
        <FinishedBoard
          table={tableOfBoardToShow}
        />
        <div className={classes.travellersTable}>
          <TravellersSQ
            boardNumber={tableOfBoardToShow.boardNumber}
          >
            {({ tables, refetchTables }) => (
              <Travellers
                tables={tables}
                board={tableOfBoardToShow.board}
                scoringMethod={session.scoringMethod}
                refetchTables={refetchTables}
              />
            )}
          </TravellersSQ>
        </div>
      </div>
    </div>
  );
};

WaitingForMovement.propTypes = {
  tables: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired
};

export default WaitingForMovement;
