import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import RankingTable from './RankingTable.js';
import MiddleMessage from '../../MiddleMessage.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0 auto',
    maxWidth: '140rem',
    '& a': {
      color: '#222'
    }
  }
}));

const Rankings = (props) => {
  const { rankings, winnerType, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const nsRankings = [];
  const ewRankings = [];

  if (!rankings || rankings.length === 0) {
    return (
      <MiddleMessage>{t('no-rankings-available-yet')}</MiddleMessage>
    );
  }

  if (winnerType === 'TwoWinner') {
    rankings.forEach(ranking => {
      if (ranking.position === 'NS') {
        nsRankings.push(ranking);
      } else {
        ewRankings.push(ranking);
      }
    });
  }

  return (
    <div className={classes.container}>
      {
        winnerType === 'TwoWinner'
          ? <Grid
            container
            direction='row'
            spacing={2}
            >
            <Grid item sm={6}>
              <RankingTable
                heading={t('north-south-pairs')}
                rankings={nsRankings}
                {...rest}
              />
            </Grid>
            <Grid item sm={6}>
              <RankingTable
                heading={t('east-west-pairs')}
                rankings={ewRankings}
                {...rest}
              />
            </Grid>
            </Grid>
          : <RankingTable
            heading={t('pairs')}
            rankings={rankings}
            {...rest}
            />
      }
    </div>
  );
};

Rankings.propTypes = {
  rankings: PropTypes.array.isRequired,
  winnerType: PropTypes.string.isRequired,
  scoringMethod: PropTypes.string.isRequired,
  linkPlayerNames: PropTypes.bool,
  linkBase: PropTypes.string
};

export default Rankings;
