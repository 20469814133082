import gql from 'graphql-tag';

const boardFragments = {
  fullBoard: gql`
    fragment FullBoard on Board {
      id
      vulnerable
      number
      dealer
      deal {
        rank
        suit
        seat
      }
      makeableContracts {
        declarer
        denomination
        result
      }
      makeableContractsStatus
    }
  `
};

export default boardFragments;
