import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { tableMutations } from '../../../graphql/mutations';
import { usePPMutation } from '../../../hooks/';
import { useTranslation } from 'react-i18next';
import SpinnerButton from '../../SpinnerButton.js';

const useStyles = makeStyles(theme => ({
  acceptButton: {
    background: '#7EB049',
    color: '#fff',
    '&:hover': {
      background: '#658E3A'
    }
  },
  rejectButton: {
    background: '#B73E3E',
    color: '#fff',
    '&:hover': {
      background: '#882D2D'
    }
  }
}));

const OpponentButtons = (props) => {
  const { table } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [pendingResponseValue, setPendingResponseValue] = useState(null);

  const [respondToClaim, { called, error }] = usePPMutation(tableMutations.respondToClaim, { memoize: true });

  const respond = useCallback((value) => {
    if (called) { return; }

    setPendingResponseValue(value);

    respondToClaim({
      variables: {
        tableId: table.id,
        value
      }
    });
  }, [respondToClaim, table.id, called, setPendingResponseValue]);

  const responseIsPending = called && !error;

  return (
    <Grid
      container
      justify='space-around'
      spacing={1}
    >
      <Grid item xs>
        <SpinnerButton
          className={classes.acceptButton}
          onClick={() => { respond(true); }}
          variant='contained'
          size='small'
          disabled={responseIsPending}
          loading={responseIsPending && pendingResponseValue === true}
          fullWidth
        >
          {t('accept')}
        </SpinnerButton>
      </Grid>
      <Grid item xs>
        <SpinnerButton
          className={classes.rejectButton}
          onClick={() => { respond(false); }}
          variant='contained'
          size='small'
          disabled={responseIsPending}
          loading={responseIsPending && pendingResponseValue === false}
          fullWidth
        >
          {t('reject')}
        </SpinnerButton>
      </Grid>
    </Grid>
  );
};

export default OpponentButtons;
