import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import { getTravellerTableData } from '../../../helpers/results.js';
import { getSourceAppName } from '../../../helpers/url.js';
import MiddleMessage from '../../MiddleMessage.js';
import TravellerScores from './TravellerScores/TravellerScores.js';
import { useTranslation } from 'react-i18next';

const sourceAppName = getSourceAppName(window.location.hostname);

const TravellersTable = (props) => {
  const {
    boardNumber,
    boardNotPlayedYet,
    tables,
    scoringMethod,
    editable,
    linkPlayerNames,
    linkBase,
  } = props;
  let {
    showMPs
  } = props;
  const { t } = useTranslation();

  // default to true
  if (typeof showMPs !== 'boolean') {
    showMPs = true;
  }

  const rows = getTravellerTableData(boardNumber, tables, sourceAppName, scoringMethod);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              {t('pairs')}
            </TableCell>
            <TableCell colSpan={4}>
              {t('contract')}
            </TableCell>
            <TableCell colSpan={2}>
              {t('scores')}
            </TableCell>
            {showMPs && (
              <TableCell colSpan={2}>
                {scoringMethod === 'MatchPoints' ? t('mps') : t('imps')}
              </TableCell>
            )}
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t('ns')}
            </TableCell>
            <TableCell>
              {t('ew')}
            </TableCell>
            <TableCell>
              {t('bid')}
            </TableCell>
            <TableCell>
              {t('by')}
            </TableCell>
            <TableCell>
              {t('result')}
            </TableCell>
            <TableCell>
              {t('lead')}
            </TableCell>
            <TableCell>
              {t('ns')}
            </TableCell>
            <TableCell>
              {t('ew')}
            </TableCell>
            {showMPs && (
              <>
                <TableCell>
                  {t('ns')}
                </TableCell>
                <TableCell>
                  {t('ew')}
                </TableCell>
              </>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={10}>
                <MiddleMessage>
                  {boardNotPlayedYet ? t('travellers-will-be-available-once-you-play-this-hand') : t('no-travellers-available-for-this-hand')}
                </MiddleMessage>
              </TableCell>
            </TableRow>
          )}
          {
            rows.map((row, i) => (
              <TableRow key={'row' + i}>
                <TableCell>
                  {
                    linkPlayerNames ?
                      (
                        <Link to={linkBase + row.nsPairId}>
                          {row.nsPair}
                        </Link>
                      ) :
                      row.nsPair
                  }
                </TableCell>
                <TableCell>
                  {
                    linkPlayerNames ?
                      (
                        <Link to={linkBase + row.ewPairId}>
                          {row.ewPair}
                        </Link>
                      ) :
                      row.ewPair
                  }
                </TableCell>
                <TableCell>
                  {row.bid}
                </TableCell>
                <TableCell>
                  {t(row.by && row.by.toLowerCase())}
                </TableCell>
                <TableCell>
                  {row.result}
                </TableCell>
                <TableCell>
                  {row.lead}
                </TableCell>
                <TravellerScores
                  key={row.tableNumber + row.boardNumber}
                  row={row}
                  editable={editable}
                  showMPs={showMPs}
                />
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TravellersTable.propTypes = {
  scoringMethod: PropTypes.string.isRequired,
  tables: PropTypes.array.isRequired,
  boardNumber: PropTypes.number.isRequired,
  linkPlayerNames: PropTypes.bool,
  linkBase: PropTypes.string,
  editable: PropTypes.bool,
  showMPs: PropTypes.bool
};

export default TravellersTable;
