import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '../../Players/Badge.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: '15rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.8em',
    paddingLeft: '0.4em'
  }
}));

const ViewpointSeat = (props) => {
  const { position, isDealer, isVulnerable } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  let dealerBadge = null;
  if (isDealer) {
    dealerBadge = (<Badge colour='yellow' text={t('dealer')} />);
  }

  const vulBadge = isVulnerable
    ? (<Badge colour='red' text={t('vul')} />)
    : (<Badge colour='green' text={t('not-vul')} />);

  return (
    <div className={classes.container}>
      {t(position.toLowerCase())}
      {dealerBadge}
      {vulBadge}
    </div>
  );
};

export default ViewpointSeat;
