import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getEnvVar } from './helpers/env.js';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

// init sentry
Sentry.init({
  dsn: getEnvVar('SENTRY_URL'),
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENV.toLowerCase(),
  whitelistUrls: [/(?:[a-z-]+\.)?pianola.net/]
});

// init i18n
i18n
  .use(initReactI18next)
  // this loads languages automatically when we switch to them
  .use(HttpApi)
  .init({
    debug: true,
    supportedLngs: ['en', 'nl'],
    fallbackLng: 'en',
    // turns e.g. `en-US` and `en-AU` to `en`
    nonExplicitSupportedLngs: true,
    react: {
      // changes the i18n and t instances on these events
      // which triggers a refresh for e.g. useEffect
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
      // by default i18n uses react suspense to defer showing stuff until translations are loaded
      // but we handle that in InitialLoad ourselves
      useSuspense: false
    },
    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false
    },
    backend: {
      loadPath: '/languages/{{lng}}.json'
    }
  });

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
