import React from 'react';
import SharedTravellers from '../../../Components/Results/Travellers/Travellers.js';

const Travellers = (props) => {
  const { boardNumber, onBoardNumberClick, session, ...rest } = props;

  return (
    <SharedTravellers
      {...rest}
      scoringMethod={session.scoringMethod}
      boardNumber={boardNumber}
      onBoardNumberClick={onBoardNumberClick}
    />
  );
};

export default Travellers;
