import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Bids from './Bids.js';
import { groupBidsBySeat } from '../../../helpers/auction.js';
import { cardHelpers } from 'shared-helpers';
import clsx from 'clsx';
const { arrangeSeatsBasedOnViewPoint } = cardHelpers;

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '54.5em',
    height: '56em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerShrunk: {
    height: '40em',
    '& $bidsIndex1': {
      width: '40em'
    },
    '& $bidsIndex3': {
      width: '40em'
    }
  },
  bids: {
    position: 'absolute',
    height: '7em',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'flex-end'
  },
  flexStart: {
    alignItems: 'flex-start'
  },
  bidsIndex0: {
    left: 0,
    top: 0,
    width: '100%'
  },
  bidsIndex1: {
    right: 0,
    top: '-7em',
    width: '56em',
    transformOrigin: '100% 100% 0',
    transform: 'rotate(-90deg)'
  },
  bidsIndex2: {
    left: 0,
    bottom: 0,
    width: '100%'
  },
  bidsIndex3: {
    left: 0,
    top: '-7em',
    width: '56em',
    transformOrigin: '0 100% 0',
    transform: 'rotate(90deg)'
  }
}));

const BidsBySeat = (props) => {
  const { auction, dealer, viewpoint, scale, shrink, children } = props;
  const bidsBySeat = groupBidsBySeat(auction, dealer);
  const seats = arrangeSeatsBasedOnViewPoint(viewpoint);
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, shrink && classes.containerShrunk)}
      style={{ fontSize: `${scale}rem` }}
    >
      {children}
      {
        seats.map((seat, index) => (
          <div
            className={clsx(classes.bids, classes['bidsIndex' + index], index === 0 && classes.flexStart)}
            key={index}
          >
            <Bids index={index} bids={bidsBySeat[seat.value]} />
          </div>
        ))
      }
    </div>
  );
};

BidsBySeat.propTypes = {
  auction: PropTypes.array.isRequired,
  dealer: PropTypes.string.isRequired,
  viewpoint: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  shrink: PropTypes.bool
};

export default BidsBySeat;
