import React, { useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '5.6rem',
    '& input': {
      textAlign: 'center',
      padding: '0.6rem 0'
    }
  }
}));

const ScoreInput = (props) => {
  const { onChange, position, value } = props;
  const classes = useStyles();

  const handleChange = useCallback((e) => {
    const val = e.currentTarget.value;
    onChange(position, val);
  }, [position, onChange]);

  return (
    <TextField
      className={classes.root}
      onChange={handleChange}
      size='small'
      value={value}
      variant='outlined'
    />
  );
};

export default ScoreInput;
