import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import MinimiseIcon from '@material-ui/icons/FullscreenExit';
import { useFullscreen } from '../../hooks/';

const useStyles = makeStyles(theme => ({
  button: {
    color: '#999',
    '&:hover': {
      color: '#fff'
    }
  }
}));

const FullscreenButton = () => {
  const classes = useStyles();
  const { isFullscreen, toggleFullscreen } = useFullscreen();

  let icon = <FullscreenIcon style={{ fontSize: 30 }} />;
  if (isFullscreen) {
    icon = <MinimiseIcon style={{ fontSize: 30 }} />;
  }

  return (
    <IconButton
      className={classes.button}
      onClick={toggleFullscreen}
    >
      {icon}
    </IconButton>
  );
};

export default FullscreenButton;
