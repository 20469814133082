import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { resultSubscriptions } from '../../graphql/subscriptions/';
import { tableQueries } from '../../graphql/queries/';

class Travellers extends React.Component {
  state = {
    tables: [],
    boardNotPlayedYet: false
  };

  handleResultData = (data) => {
    this.setState({
      tables: data.tablesForTravellers || [],
      boardNotPlayedYet: false
    });
  };

  handleResultError = (data) => {
    if (data &&
      data.graphQLErrors &&
      data.graphQLErrors[0] &&
      data.graphQLErrors[0].message === 'Board not played yet') {
      this.setState({
        tables: [],
        boardNotPlayedYet: true
      });
    } else {
      this.setState({
        tables: [],
        boardNotPlayedYet: false
      });
    }
  };

  handleResultSubscriptionData = async (refetch) => {
    const { data } = await refetch();

    this.handleResultData(data);
  };

  render() {
    const { boardNumber } = this.props;
    return (
      <Query
        query={
          this.props.withBoard
            ? tableQueries.tablesForTravellersWithBoard
            : tableQueries.tablesForTravellers
        }
        onCompleted={this.handleResultData}
        onError={this.handleResultError}
        variables={{ boardNumber: boardNumber }}
        fetchPolicy='no-cache'
      >
        {({ refetch: refetchTables, data: tableData, error }) => (
          <PPSubscription
            subscription={resultSubscriptions.resultsUpdate}
            onSubscriptionData={() => { this.handleResultSubscriptionData(refetchTables); }}
          >
            {() => {
              return this.props.children({
                tables: this.state.tables,
                boardNotPlayedYet: this.state.boardNotPlayedYet,
                refetchTables
              });
            }}
          </PPSubscription>
        )}
      </Query>
    );
  }
};

Travellers.propTypes = {
  boardNumber: PropTypes.number
};

export default Travellers;
