import gql from 'graphql-tag';
import { tableFragments } from '../fragments';

const tableSubscriptions = {
  bidFeed: gql`
    subscription ($tableId: ID) {
      bidFeed(tableId: $tableId) {
        ...AuctionEvent
      }
    }
    ${tableFragments.auctionEvent}
  `,
  directorCallUpdate: gql`
    subscription ($tableId: ID) {
      directorCallUpdate(tableId: $tableId) {
        tableId,
        tableNumber,
        directorCallStatus,
        directorCallerId
      }
    }
  `,
  trickUpdate: gql`
    subscription ($tableId: ID) {
      trickUpdate(tableId: $tableId) {
        ...PlayEvent
      }
    }
    ${tableFragments.playEvent}
  `,
  tableUpdate: gql`
    subscription ($id: ID) {
      tableUpdate(id: $id) {
        table {
          ...UpdateTable
        },
        nextTableId
      }
    }
    ${tableFragments.updateTable}
  `,
  claimUpdate: gql`
    subscription ($tableId: ID!) {
      claimUpdate(tableId: $tableId) {
        tableId,
        claim {
          ...Claim
        }
      }
    }
    ${tableFragments.claim}
  `,
  directorTurn: gql`
    subscription {
      directorTurn {
        tableId
        activeSeat
        declarer
        tableNumber
      }
    }
  `
};

export default tableSubscriptions;
