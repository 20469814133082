import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Tooltip,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const styles = {
  header: {
    overflow: 'hidden',
    padding: '0.8em',
    margin: '0.1em',
    borderRadius: '0.2em',
    background: '#9bc172',
    color: '#fff',
    textShadow: '0 0.1em 0 rgba(0,0,0,0.15)'
  },
  isVulnerable: {
    background: '#ae4646'
  },
  h3: {
    margin: 0,
    padding: 0,
    fontSize: '1.5em'
  },
  ul: {
    margin: 0,
    float: 'right',
    textTransform: 'uppercase',
    fontSize: '1.2em'
  },
  li: {
    display: 'inline',
    padding: '0 0 0 0.5em',
    cursor: 'help'
  }
};

const DealDirectionHeader = (props) => {
  const {
    classes,
    direction,
    dealer,
    vulnerable
  } = props;
  const { t } = useTranslation();

  return (
    <div
      className={clsx(classes.header, vulnerable ? classes.isVulnerable : null)}
    >
      <ul className={classes.ul}>
        {
          dealer
            ? <Tooltip arrow title={<Typography>{t('dealer')}</Typography>} placement='top'>
              <li className={classes.li}>{t('d-dealer')}</li>
              </Tooltip>
            : null
        }
        {
          vulnerable
            ? <Tooltip arrow title={<Typography>{t('vulnerable')}</Typography>} placement='top'>
              <li className={classes.li}>{t('v-vulnerable')}</li>
              </Tooltip>
            : <Tooltip arrow title={<Typography>{t('not-vulnerable')}</Typography>} placement='top'>
              <li className={classes.li}>{t('nv-not-vulnerable')}</li>
              </Tooltip>
        }
      </ul>
      <h3 className={classes.h3}>{t(direction.toLowerCase())}</h3>
    </div>
  );
};

DealDirectionHeader.propTypes = {
  direction: PropTypes.string.isRequired,
  dealer: PropTypes.bool.isRequired,
  vulnerable: PropTypes.bool.isRequired
};

export default withStyles(styles)(DealDirectionHeader);
