import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GameDetailsBase from '../../../Components/Lobby/GameDetailsBase.js';
import CurrentUserLobbyPair from './CurrentUserLobbyPair.js';
import Calendar from '../../../Components/Calendar.js';
import SessionStartingTime from '../../../Components/Lobby/SessionStartingTime.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  textCenter: {
    textAlign: 'center'
  },
  emphasis: {
    fontSize: '3rem',
    margin: 0
  }
}));

const GameDetails = (props) => {
  const { session, currentUser, pairs } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const playerInPair = currentUser.gameStatus === 'InPair';

  return (
    <GameDetailsBase session={session} playerInPair={playerInPair}>
      <div className={classes.textCenter}>
        <Calendar datetime={session.dateTime} />
        <br />
        {
          session.status === 'Starting'
            ? <>
              <p className={classes.emphasis}>
                {t('sessionName-is-about-to-start', { sessionName: session.name })}
              </p>
              {
                !playerInPair && (
                  <p>
                    {t('it-looks-like-you-didnt-choose-a-partner')}<br />
                    {t('unfortunately-you-will-not-be-able-to-play')}
                  </p>
                )
              }
              </>
            : <>
              <SessionStartingTime
                session={session}
                emphasizeName
              />
              <p>
                {
                  playerInPair
                    ? <span style={{ color: '#c7ff00' }}>
                      {t('you-are-ready-to-play')}
                      <br />
                      {t('please-ensure-you-remain-on-this-page')}
                      </span>
                    : <>
                      {t('please-ensure-you-have-this-page-loaded')}
                      <br />
                      {t('meanwhile-please-choose-your-partner')}
                      </>
                }
              </p>
              <div>
                <br />
                <CurrentUserLobbyPair
                  pairs={pairs}
                  currentUser={currentUser}
                  session={session}
                />
              </div>
              </>
        }
      </div>
    </GameDetailsBase>
  );
};

export default GameDetails;
