// one of more messages for a side of the conversion
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Message from './Message.js';
import MessageGroupHeader from './MessageGroupHeader.js';

const styles = {
  messageGroup: {
    position: 'relative',
    clear: 'both',
    listStyle: 'none',
    margin: 0,
    marginBottom: '1.5em',
    padding: '1em 0'
  }
};

const MessageGroup = (props) => {
  const { classes, messages, from, icon } = props;
  return (
    <>
      <MessageGroupHeader from={from} />
      <ul className={classes.messageGroup}>
        {
          messages.map((message, index) => {
            return (
              <Message
                from={from}
                key={index}
                icon={icon}
                {...message}
              />
            );
          })
        }
      </ul>
    </>
  );
};

MessageGroup.propTypes = {
  messages: PropTypes.array.isRequired,
  from: PropTypes.object.isRequired,
  icon: PropTypes.string
};

export default withStyles(styles)(MessageGroup);
