import { createMuiTheme } from '@material-ui/core/styles';
import baseTheme from './baseTheme.js';
import bridgemateTheme from './bridgemateTheme.js';

export const getTheme = (sourceAppName) => {
  // uncomment to test bridgemate theme
  // sourceAppName = 'bridgemate';

  if (sourceAppName === 'bridgemate') {
    return createMuiTheme(baseTheme, bridgemateTheme);
  } else {
    return createMuiTheme(baseTheme);
  }
};
