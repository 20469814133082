import gql from 'graphql-tag';
import userFragments from './user.js';

const messageFragments = {
  fullMessage: gql`
    fragment FullMessage on Message {
      id
      text
      timestamp
      threadId
      type
      icon
      user {
        ...DisplayUser
        type
      }
      recipient {
        ...DisplayUser
        type
      }
      attachments
      translate
    }
    ${userFragments.displayUser}
  `
};

export default messageFragments;
