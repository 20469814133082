import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { iOSversion } from '../helpers/mobile.js';
import { getAppDisplayName } from '../helpers/url.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.background.main,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'flex',
    zIndex: 100000,
    padding: '2rem 4rem'
  }
}));

const DeviceNotSupported = () => {
  const classes = useStyles();
  const iosVersion = iOSversion();
  const isUnsupported = iosVersion && parseFloat(iosVersion) <= 10;

  const { i18n, t, ready } = useTranslation();

  // if the device is unsupported, we can't guarantee the language will even load from CurrentUser
  // so try and infer the user's language from navigator.language instead
  //
  // this might mean the language flashes to English for a brief time until this language loads
  // but we're already providing a sub-par UX so this is probably fine
  useEffect(() => {
    // if i18n.language is 'en', that implies we fell back
    // and the user language was never set
    // so see if the user has a different preferred language and use that instead
    if (isUnsupported &&
      i18n.language === 'en' &&
      !navigator.language.match(/^en/)) {
      i18n.changeLanguage(navigator.language);
    }
  }, [i18n.language, i18n, isUnsupported]);

  if (isUnsupported && ready) {
    return (
      <div className={classes.container}>
        {t('this-devices-operating-system-is-too-old', { appName: getAppDisplayName() })}
        <br />
        {t('please-try-a-different-device')}
      </div>
    );
  } else {
    return null;
  }
};

export default DeviceNotSupported;
