import gql from 'graphql-tag';
import { sessionFragments } from '../fragments';

const sessionSubscriptions = {
  sessionUpdate: gql`
    subscription {
      sessionUpdate {
        ...FullSession
      }
    }
    ${sessionFragments.fullSession}
  `
};

export default sessionSubscriptions;
