import { useState, useEffect } from 'react';

// used for elements that glow whenever a player wins a trick
// (e.g. their name, the winning card)

const useTimedGlowingDirection = (props) => {
  const { trickCards, glowDuration, trickIndex } = props;
  const [glowingDirection, setGlowingDirection] = useState(null);
  const [doneGlowing, setDoneGlowing] = useState(false);

  const winningCard = trickCards.find(card => card.winner);
  const winningSeat = (winningCard && winningCard.seat);

  useEffect(() => {
    if (winningSeat) {
      setGlowingDirection(winningSeat);

      const timeout = setTimeout(() => {
        setGlowingDirection(null);
        setDoneGlowing(true);
      }, glowDuration);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      // reset
      setGlowingDirection(null);
      setDoneGlowing(false);
    }
  }, [winningSeat, trickIndex, setGlowingDirection, setDoneGlowing, glowDuration]);

  return { glowingDirection, doneGlowing };
};

export default useTimedGlowingDirection;
