import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Assignment as AssignmentIcon } from '@material-ui/icons/';

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: '1em',
    width: '2.5em',
    maxWidth: '4.5rem',
    height: '2.5em',
    maxHeight: '4.5rem',
    backgroundColor: '#fff',
    color: props => theme.palette.getContrastText('#fff')
  }
}));

const MessageIcon = (props) => {
  const { name } = props;
  const classes = useStyles();

  let icon;

  switch (name) {
    case 'systemDescription':
      icon = (<AssignmentIcon />);
      break;
    default:
      throw new Error('Message icons need to be added manually');
  }

  return (
    <Avatar className={classes.avatar} >
      {icon}
    </Avatar>
  );
};

MessageIcon.propTypes = {
  name: PropTypes.string.isRequired
};

export default MessageIcon;
