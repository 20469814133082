import React, { useEffect } from 'react';
import { logout } from '../../helpers/auth.js';
import { getLoginUrl } from '../../helpers/url.js';

const Logout = () => {
  useEffect(() => {
    logout();
    window.location = getLoginUrl();
  });

  return (
    <div>Logging you out...</div>
  );
};

export default Logout;
