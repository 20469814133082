import gql from 'graphql-tag';
import { boardFragments } from '../fragments';

const boardQueries = {
  boards: gql`
    query {
      boards {
        ...FullBoard
      }
    }
    ${boardFragments.fullBoard}
  `,
  boardNumbers: gql`
    query {
      boardNumbers
    }
  `
};

export default boardQueries;
