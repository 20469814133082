const seatRankings = [
  { value: 'W', rank: 0 },
  { value: 'N', rank: 1 },
  { value: 'E', rank: 2 },
  { value: 'S', rank: 3 }
];

export const groupBidsBySeat = (auction, dealer) => {
  const bids = {
    N: [],
    E: [],
    S: [],
    W: []
  };

  const dealerIdx = seatRankings.findIndex(seatRank => seatRank.value === dealer);

  // go along each of the seats in order and put the next bid in them
  for (let i = 0; i < auction.length; i++) {
    const rank = (i + dealerIdx) % 4;
    const seat = seatRankings[rank].value;

    bids[seat].push(auction[i]);
  }

  return bids;
};

export const isBoardPassedOut = (auction) => {
  return auction.length === 4 &&
    auction.every(bid => bid.value === 'Pass');
};
