export const colours = {
  lightGrey: '#3A3F49',
  darkGrey: '#2A2E35',
  altDarkGrey: '#2A2E35',
  gold: '#F7BC1A',
  lightGold: '#F8C333',
  darkGold: '#EAAB00',
  teal: '#6dae9b'
};

// overriding defaults: https://material-ui.com/customization/default-theme/
const baseTheme = {
  typography: {
    useNextVariants: true,
    htmlFontSize: 10,
    fontSize: 14
  },
  palette: {
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      main: colours.lightGrey,
      dark: 'rgba(0,0,0,0.25)'
    },
    block: {
      header: {
        background: '#fff',
        color: '#222'
      },
      content: {
        background: '#e0e1e2',
        color: '#fff'
      },
      footer: {
        background: '#fff',
        color: '#222'
      }
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colours.teal,
      // dark: will be calculated from palette.primary.main,
      contrastText: '#fff'
    },
    secondary: {
      light: colours.lightGold,
      main: colours.gold,
      dark: colours.darkGold
    }
  },
  logo: {
    image: 'pianola_logo',
    background: colours.teal,
    gameWidth: '15rem',
    gameHeight: '6rem'
  },
  buttonSpinner: {
    color: colours.teal
  },
  lobby: {
    directorCta: {
      background: colours.teal
    }
  },
  header: {
    background: '#282C33',
    activeTabBackground: `linear-gradient(0deg, ${colours.lightGrey} 0%, rgba(84,90,102,1) 100%)`
  },
  game: {
    cardImage: 'pianola_back',
    backgroundGradient: {
      outer: '#2B2F37',
      inner: 'rgba(255,255,255,0.15)'
    },
    status: {
      itemLabel: colours.gold
    },
    playerSeat: {
      background: colours.altDarkGrey,
      border: colours.altDarkGrey,
      text: '#fff',
      activeBorder: colours.gold,
      activeBackground: colours.gold,
      activeText: '#333',
      borderWidth: '0.3em'
    },
    // overriden below - same as playerSeat
    viewpointPlayerSeat: {
    }
  }
};

baseTheme.game.viewpointPlayerSeat = baseTheme.game.playerSeat;

export default baseTheme;
