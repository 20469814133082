import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BoardSelect from './BoardSelect.js';
import DealWithMakeableContracts from './DealWithMakeableContracts.js';
import { getCurrentlyActiveBoard } from '../../../helpers/results.js';
import TravellersTable from './TravellersTable.js';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0 auto',
    maxWidth: '140rem',
    '& a': {
      color: '#222'
    }
  }
}));

const Travellers = (props) => {
  const {
    tables,
    boards,
    boardNumber,
    scoringMethod,
    linkPlayerNames,
    linkBase,
    editable,
    onBoardNumberClick
  } = props;
  const classes = useStyles();

  const board = boardNumber ?
    boards.find(board => board.number === parseInt(boardNumber, 10) - 1) :
    getCurrentlyActiveBoard(tables, boards);

  return (
    <div className={classes.container}>
      <BoardSelect
        boardNumbers={boards.map(board => board.number)}
        currentActiveNumber={board.number}
        onBoardNumberClick={onBoardNumberClick}
      />
      <br />
      <Grid container wrap='nowrap' spacing={3}>
        <Grid item xs>
          <TravellersTable
            boardNumber={board.number}
            tables={tables}
            scoringMethod={scoringMethod}
            editable={editable}
            linkPlayerNames={linkPlayerNames}
            linkBase={linkBase}
          />
        </Grid>
        <Grid item>
          <DealWithMakeableContracts
            board={board}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Travellers.propTypes = {
  boardNumber: PropTypes.number,
  scoringMethod: PropTypes.string.isRequired,
  tables: PropTypes.array.isRequired,
  boards: PropTypes.array.isRequired,
  linkPlayerNames: PropTypes.bool.isRequired,
  linkBase: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  onBoardNumberClick: PropTypes.func.isRequired
};

export default Travellers;
