import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  component: {
    border: 'solid 1px black'
  },
  text: {
    marginTop: '1rem'
  }
}));

const ComponentError = (props) => {
  const { heading, text } = props;
  const classes = useStyles();
  return (
    <div className={classes.component}>
      <Typography variant='h4'>{heading}</Typography>
      {text}
    </div>
  )
};

ComponentError.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default ComponentError;
