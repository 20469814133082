import React from 'react';
import ErrorPage from '../../Components/Errors/ErrorPage.js';
import { getLoginUrl, getSourceAppDisplayName } from '../../helpers/url.js';

const Error404 = () => {
  return (
    <ErrorPage
      heading='Page not found'
      message={
        <>
          You have located a page that doesn't exist.
          Try clicking the link you were sent by your club or
          &nbsp;<a href={getLoginUrl()}>return to {getSourceAppDisplayName()}</a>
        </>
      }
    />
  );
};

export default Error404;
