import React from 'react';
import { Switch, useLocation } from 'react-router-dom';

/**
 * A component that behaves roughly like a <Switch> but forces the path
 * in any <Route>s inside it to match an arbitrary value
 *
 * For example:
 *
 * <StateSwitch base="/foo" value="bar">
 *  <Route path="/foo/bar"> ...  </Route>
 *  <Route path="/foo/baz"> ...  </Route>
 * </StateSwitch
 *
 * ... will render the first route regardless of the URL
 */

const StateSwitch = (props) => {
  const { base, value, children } = props;

  const location = useLocation();
  location.pathname = base + '/' + value;
  location.pathname = location.pathname.toLowerCase();

  return (
    <Switch location={location}>
      {children}
    </Switch>
  );
};

export default StateSwitch;
