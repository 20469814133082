import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClubMembers from './ClubMembers.js';
import Pairs from './Pairs.js';
import GameDetails from './GameDetails.js';
import Chat from '../../../Components/Chat/Chat.js';
import Block from '../../../Components/Block.js';
import { useCloseWarning } from '../../../hooks/';
import { getDirectorLobbyChatRecipients } from '../../../helpers/chat.js';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  containerLeft: {
    left: 0,
    bottom: 0,
    top: '7rem',
    width: '70%',
    padding: '2rem 1rem 2rem 2rem'
  },
  containerSession: {
    left: 0,
    right: 0,
    top: 0,
    height: '24rem',
    padding: '2rem 1rem 1rem 2rem'
  },
  containerMembers: {
    left: 0,
    bottom: 0,
    width: '43%',
    top: '24rem',
    padding: '1rem 1rem 2rem 2rem'
  },
  containerPairs: {
    bottom: 0,
    left: '43%',
    width: '57%',
    top: '24rem',
    padding: '1rem 1rem 2rem 1rem'
  },
  containerChat: {
    right: 0,
    top: '7rem',
    bottom: 0,
    width: '30%',
    padding: '2rem 2rem 2rem 1rem',
    fontSize: '1rem'
  }
}));

const Lobby = (props) => {
  const { session, users, currentUser, pairs } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const containerRef = useRef(null);

  useCloseWarning();

  return (
    <>
      <div ref={containerRef} className={clsx(classes.container, classes.containerLeft)}>
        <div className={clsx(classes.container, classes.containerSession)}>
          <GameDetails
            session={session}
            pairs={pairs}
            containerRef={containerRef}
          />
        </div>
        <div className={clsx(classes.container, classes.containerMembers)}>
          <Block
            heading={t('awaiting-partner')}
          >
            <ClubMembers
              users={users}
              currentUser={currentUser}
            />
          </Block>
        </div>
        <div className={clsx(classes.container, classes.containerPairs)}>
          <Block
            heading={t('n-confirmed-pairs', { n: pairs.length })}
            translateInComponent
          >
            <Pairs
              pairs={pairs}
              session={session}
              currentUser={currentUser}
            />
          </Block>
        </div>
      </div>
      <div className={clsx(classes.container, classes.containerChat)}>
        <Chat
          threadId={session.id}
          currentUser={currentUser}
          sendTypingNotifications
          headerText={t('messages-to-lobby')}
          placeholderText={t('send-a-message-to-everyone-in-lobby')}
          recipients={getDirectorLobbyChatRecipients(currentUser, users, t)}
        />
      </div>
    </>
  );
};

export default Lobby;
