import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SharedTravellers from '../../../Components/Results/Travellers/Travellers.js';

const Travellers = (props) => {
  const { ...rest } = props;

  const history = useHistory();

  const handleBoardNumberClick = useCallback((boardNumber) => {
    history.push('/director/results/travellers/' + boardNumber);
  }, [history]);

  return (
    <SharedTravellers
      {...rest}
      linkBase='/director/results/scorecards/'
      linkPlayerNames={true}
      editable={true}
      onBoardNumberClick={handleBoardNumberClick}
    />
  );
};

Travellers.propTypes = {
  boardNumber: PropTypes.number,
  tables: PropTypes.array.isRequired,
  boards: PropTypes.array.isRequired
};

export default Travellers;
