import React from 'react';
import PropTypes from 'prop-types';
import { tableQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';

const FinishedTablesForRound = (props) => {
  const { children, tableNumber, roundNumber } = props;
  return (
    <PPQuery
      query={tableQueries.finishedTablesForRound}
      variables={{ tableNumber, roundNumber }}
      fetchPolicy='no-cache'
    >
      {(data, refetch) => {
        return children(data.finishedTablesForRound, refetch);
      }}
    </PPQuery>
  );
};

FinishedTablesForRound.propTypes = {
  children: PropTypes.func.isRequired,
  tableNumber: PropTypes.number.isRequired,
  roundNumber: PropTypes.number.isRequired
};

export default FinishedTablesForRound;
