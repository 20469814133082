import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import TimeNow from './TimeNow.js';
import SessionStatus from '../../Views/Director/Dashboard/SessionStatus.js';
import EndRoundButton from '../../Views/Director/EndRoundButton/EndRoundButton.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 0.6rem 0 1rem'
  },
  nav: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    height: '7rem',
    fontWeight: 'bold',
    fontSize: '2rem'
  },
  navItem: {
    display: 'block',
    height: '6rem',
    lineHeight: '6rem',
    padding: '0 2rem',
    color: '#fff',
    textDecoration: 'none',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  navActiveItem: {
    background: theme.header.activeTabBackground
  }
}));

const DirectorHeader = (props) => {
  const { session } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const playStarted = session && session.status === 'InProgress';
  const finalRound = session && session.roundsCompleted === session.numRounds - 1;
  const sessionName = (session && session.name) || '';

  return (
    <Grid
      container
      justify='space-between'
      alignItems='center'
      wrap='nowrap'
      className={classes.container}
    >
      <Grid item xs>
        <Grid
          container
          alignItems='flex-end'
          className={classes.nav}
          wrap='nowrap'
        >
          <Grid item>
            <NavLink
              to='/director'
              className={classes.navItem}
              activeClassName={classes.navActiveItem}
              isActive={(match, location) => {
                return location.pathname === '/director' ||
                  location.pathname.match(/^\/director(\/inprogress|\/notstarted|\/starting)/);
              }}
            >
              {t('dashboard')}
            </NavLink>
          </Grid>
          <Grid item>
            <NavLink
              to='/director/results'
              className={classes.navItem}
              activeClassName={classes.navActiveItem}
            >
              {
                session.status === 'Finished'
                  ? <>{t('results')}</>
                  : <>{t('live-results')}</>
              }
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          justify='flex-end'
          alignItems='center'
          spacing={2}
          wrap='nowrap'
        >
          <Grid item>
            {
              playStarted
                ? <Grid
                  container
                  direction='row'
                  alignItems='center'
                  spacing={2}
                  wrap='nowrap'
                  >
                  <Grid item>
                    <SessionStatus
                      session={session}
                    />
                  </Grid>
                  <Grid item>
                    <EndRoundButton
                      finalRound={finalRound}
                    />
                  </Grid>
                  </Grid>
                : <Typography variant='h6'>{sessionName}</Typography>
            }
          </Grid>
          {
            session &&
              <Grid item>
                <TimeNow />
              </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DirectorHeader;
