import gql from 'graphql-tag';
import { systemDescriptionFragments } from '../fragments';

const systemDescriptionSubscriptions = {
  systemDescriptionUpdate: gql`
    subscription {
      systemDescriptionUpdate {
        ...FullSystemDescription
      }
    }
    ${systemDescriptionFragments.fullSystemDescription}
  `
};

export default systemDescriptionSubscriptions;
