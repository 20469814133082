import React, { useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Setting from './Setting.js';
import UserSettingsContext from './UserSettingsContext.js';
import PPDialog from '../PPDialog.js';
import { Grid, Typography, FormControlLabel, Switch } from '@material-ui/core';
import PlayerAvatar from '../Players/PlayerAvatar.js';
import { getLogoutUrl } from '../../helpers/url.js';
import { useSmallScreenSettings } from '../../hooks';
import { useTranslation } from 'react-i18next';
// import SoundSettings from './SoundSettings.js';

const useStyles = makeStyles(theme => ({
  content: {
    background: theme.palette.block.content.background
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logoutLink: {
    color: '#666',
    width: '100%',
    textAlign: 'left'
  },
  divider: {
    borderTop: '1px dashed #999',
    marginTop: '1rem',
    paddingTop: '1rem'
  }
}));

const SettingsModal = (props) => {
  const { currentUser, open, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { updateSetting, updateLocalSetting, ...settings } = useContext(UserSettingsContext);

  const smallScreenSettings = useSmallScreenSettings();
  const forceSimplifiedLayout = smallScreenSettings.simplifiedLayout;
  const forceSimplifiedCards = smallScreenSettings.simplifiedCards;

  const handleSettingChange = useCallback((name, value) => {
    updateSetting(name, value);
  }, [updateSetting]);

  if (!open) {
    return null;
  }

  return (
    <PPDialog
      open
      onClose={onClose}
      title={t('settings')}
      contentClassName={classes.content}
      maxWidth='md'
    >
      <Grid
        container
        spacing={3}
      >
        <Grid item className={classes.leftColumn}>
          <div>
            {/* setting onlineStatus to null hides it */}
            <PlayerAvatar
              {...currentUser}
              onlineStatus={null}
            />
            <Typography variant='h6'>{currentUser.fullName}</Typography>
            <div>
              {currentUser.region}
            </div>
          </div>
          <a className={classes.logoutLink} href={getLogoutUrl()}>{t('logout')}</a>
        </Grid>
        <Grid item xs>
          {/*
          uncomment this when we've settled on sounds
          div under this needs a divider when we do
          <div>
            <SoundSettings/>
          </div>
          <div className={classes.divider}>
          */}
          <div>
            <Setting
              name='confirmClick'
              onChange={handleSettingChange}
              disabled={forceSimplifiedLayout}
              setting={settings.confirmClick}
            />
            {
              forceSimplifiedLayout && (
                <div><small>{t('click-to-confirm-is-always-used')}</small></div>
              )
            }
          </div>
          <div className={classes.divider}>
            <Setting
              name='autoPlaySingletons'
              onChange={handleSettingChange}
              setting={settings.autoPlaySingletons}
            />
          </div>
          <div className={classes.divider}>
            <FormControlLabel
              control={
                <Switch
                  checked={false}
                  color='primary'
                  size='medium'
                  disabled
                />
              }
              label={t('enable-sound-effects')}
            />
          </div>
          <div className={classes.divider}>
            <Setting
              name='simplifiedCards'
              onChange={handleSettingChange}
              setting={settings.simplifiedCards}
              disabled={forceSimplifiedCards}
            />
            {
              forceSimplifiedCards && (
                <div><small>{t('simplified-card-design-is-always-used')}</small></div>
              )
            }
          </div>
          <div className={classes.divider}>
            <Setting
              name='simplifiedLayout'
              onChange={handleSettingChange}
              setting={settings.simplifiedLayout}
              disabled={forceSimplifiedLayout}
            />
            {
              forceSimplifiedLayout && (
                <div><small>{t('simplified-layout-is-always-used')}</small></div>
              )
            }
          </div>
        </Grid>
      </Grid>
    </PPDialog>
  );
};

export default SettingsModal;
