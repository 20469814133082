import gql from 'graphql-tag';
import { pairFragments } from '../fragments/';

const pairQueries = {
  pairs: gql`
    query {
      pairs {
        ...FullPair
      }
    }
    ${pairFragments.fullPair}
  `
};

export default pairQueries;
