import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MiniCard from '../MiniCard.js';

const useStyles = makeStyles(() => ({
  minicard: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '1rem'
  }
}));

const MessageMiniCard = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.minicard}>
      <MiniCard
        {...props}
        size='small'
      />
    </span>
  );
};

export default MessageMiniCard;
