import { getPlayerSeatForTable, isOurTurn } from './game.js';

export const getTableWarningStatus = (table) => {
  // TODO ANDREY: I couldn't figure out why the table obj here doesn't include claim
  // I tried to have a message so the director knows the specific reason for the call
  // if (table.directorCallStatus === 'DirectorCalled' && table.claim && ((table.claim.responses[0] && !table.claim.responses[0].response) || (table.claim.responses[1] && !table.claim.responses[1].response))) {
  //   return {
  //     message: 'director called to resolve claim',
  //     severity: 'red'
  //   };
  // }

  // did anyone call the director?
  if (table.directorCallStatus === 'DirectorCalled') {
    return {
      message: 'director-called',
      severity: 'red'
    };
  }

  // are any users offline?
  let offlineUser = false;
  let directorsTurn = false;

  [
    table.nsPair.user1,
    table.nsPair.user2,
    table.ewPair.user1,
    table.ewPair.user2
  ].forEach(user => {
    if (user.onlineStatus === 'Offline') {
      offlineUser = true;
    }

    if (user.type === 'Director') {
      const directorSeat = getPlayerSeatForTable(user.id, table);
      directorsTurn = isOurTurn({ viewpoint: directorSeat, activeSeat: table.activeSeat, declarer: table.declarer });
    }
  });

  if (directorsTurn) {
    return {
      message: 'your-turn',
      severity: 'yellow'
    };
  }

  if (offlineUser) {
    return {
      message: 'missing-players',
      severity: 'yellow'
    };
  }

  return null;
};

export const categoriseTables = (tables, filter) => {
  const visibleTables = [];
  const counts = {
    all: 0,
    inProgress: 0,
    waitingForMovement: 0,
    requiringAttention: 0
  };

  const countAndCheckFilter = (table, category) => {
    counts[category] += 1;
    if (filter === category) {
      visibleTables.push(table);
    }
  };

  tables.forEach(table => {
    let newTable;
    // assign warnings to any tables that need warnings
    const warning = getTableWarningStatus(table);

    if (warning) {
      // append the warning on a new table so we don't edit it
      // by reference accidentally
      newTable = {
        ...table,
        warning
      };
    } else {
      newTable = table;
    }

    if (table.status === 'WaitingForMovement') {
      countAndCheckFilter(newTable, 'waitingForMovement');
    } else if (newTable.status !== 'WaitingForMovement' &&
      newTable.status !== 'SessionFinished') {
      countAndCheckFilter(newTable, 'inProgress');
    }

    if (newTable.warning) {
      countAndCheckFilter(newTable, 'requiringAttention');
    }

    countAndCheckFilter(newTable, 'all');
  });

  return { visibleTables, counts };
};

export const calculateTableProgressPercentage = (table) => {
  const cardPlay = table.cardPlay || [];
  let cardsPlayed = (table.nsTricks + table.ewTricks) * 4;

  if (cardPlay.length) {
    const lastTrick = cardPlay[cardPlay.length - 1];
    if (lastTrick.cards.length !== 4) {
      cardsPlayed += lastTrick.cards.length;
    }
  }

  const percentage = Math.round((100 * cardsPlayed) / 52);
  return percentage;
};
