import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import { auctionHelpers } from 'shared-helpers';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  suit: {
    fontSize: '2.4rem'
  }
}));

const { convertSeatCodeToFullWord } = auctionHelpers;

const MakeableContracts = (props) => {
  const { makeableContracts } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const contractObj = {};

  // convert the contracts to a data structure
  // so we can arrange the rows/columns in a predictable order
  makeableContracts.forEach(contract => {
    const { declarer, denomination, result } = contract;
    if (!contractObj[declarer]) {
      contractObj[declarer] = {};
    }

    // `0`s in the PBN should be shown as `-` here
    contractObj[declarer][denomination] = result || '-';
  });

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align='center' className={classes.suit}>
              &clubs;
            </TableCell>
            <TableCell align='center' className={classes.suit}>
              &diams;
            </TableCell>
            <TableCell align='center' className={classes.suit}>
              &hearts;
            </TableCell>
            <TableCell align='center' className={classes.suit}>
              &spades;
            </TableCell>
            <TableCell align='center'>
              NT
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            ['N', 'S', 'E', 'W'].map(seat => (
              <TableRow key={seat}>
                <TableCell>
                  {t(convertSeatCodeToFullWord(seat).toLowerCase())}
                </TableCell>
                <TableCell align='center'>
                  {contractObj[seat].C}
                </TableCell>
                <TableCell align='center'>
                  {contractObj[seat].D}
                </TableCell>
                <TableCell align='center'>
                  {contractObj[seat].H}
                </TableCell>
                <TableCell align='center'>
                  {contractObj[seat].S}
                </TableCell>
                <TableCell align='center'>
                  {contractObj[seat].NT}
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MakeableContracts;
