import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { Settings } from 'luxon';
import GenericSpinner from './Components/GenericSpinner.js';

const LanguageLoader = (props) => {
  const { currentUser, children } = props;
  const [loading, setLoading] = useState(true);

  const langCode = currentUser && currentUser.language;

  // change language based on whatever is set in currentUser
  useEffect(() => {
    if (!langCode) { return; }

    const run = async () => {
      await i18n.changeLanguage(langCode);
      Settings.defaultLocale = langCode;

      setLoading(false);
    };

    run();
  }, [langCode]);

  if (langCode && loading) {
    return (<GenericSpinner/>);
  }

  return children;
};

export default LanguageLoader;
