import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import TabPanel from '../../../Components/TabPanel.js';
import Rankings from '../../../Components/Results/Rankings/Rankings.js';
import Travellers from './Travellers.js';
import RefreshWidget from './RefreshWidget.js';
import clsx from 'clsx';
import { getSourceAppName, getAppDisplayName } from '../../../helpers/url.js';
import { getSessionFinishedHeading, getSessionFinishedMessage } from '../../../helpers/results.js';
import { useTranslation } from 'react-i18next';

const sourceAppName = getSourceAppName(window.location.hostname);
const appDisplayName = getAppDisplayName(window.location.hostname);

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  containerMessage: {
    height: '10rem',
    left: 0,
    right: 0,
    background: 'rgba(0,0,0,0.5)',
    borderRadius: '0.5rem',
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  containerMessageWaiting: {
    height: '15rem'
  },
  containerResults: {
    top: '11rem',
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.block.content.background,
    borderRadius: '0.5rem',
    color: '#333'
  },
  containerResultsWaiting: {
    top: '16rem'
  },
  header: {
    background: '#fff',
    height: '6rem',
    padding: '0 2rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '2rem'
  },
  tabs: {
    background: 'rgba(255,255,255,0.5)',
    marginTop: '0.1rem'
  },
  content: {
    position: 'absolute',
    top: '10.5rem',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '2rem',
    overflow: 'hidden',
    overflowY: 'auto'
  }
}));

const ResultsPane = (props) => {
  const {
    session,
    rankings,
    sessionFinished,
    refetchRankings,
    tables,
    boards,
    refetchTables,
    boardNumber,
    onBoardNumberClick
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [view, setView] = useState('ranking');

  const handleChange = useCallback((e, val) => {
    setView(val);
  }, [setView]);

  const waitingForBMResults = sourceAppName === 'bridgemate' && !sessionFinished;

  return (
    <>
      <div className={clsx(classes.container, classes.containerMessage, waitingForBMResults && classes.containerMessageWaiting)}>
        <Typography variant='h6'>{t(getSessionFinishedHeading(session, sessionFinished))}</Typography>
        <div>
          {t(getSessionFinishedMessage(session, sessionFinished), { appName: appDisplayName })}
          {waitingForBMResults && (
            <RefreshWidget
              refetchRankings={refetchRankings}
              refetchTables={refetchTables}
            />
          )}
        </div>
      </div>
      <div className={clsx(classes.container, classes.containerResults, waitingForBMResults && classes.containerResultsWaiting)}>
        <div className={classes.header}>
          {t('results')}
        </div>
        <div className={classes.tabs}>
          <Tabs
            onChange={handleChange}
            value={view}
            indicatorColor='primary'
            centered
          >
            <Tab label={t('ranking')} value='ranking' />
            <Tab label={t('travellers')} value='travellers' />
          </Tabs>
        </div>
        <div className={classes.content}>
          <TabPanel value={view} name='ranking'>
            <Rankings
              rankings={rankings}
              winnerType={session.winnerType}
              scoringMethod={session.scoringMethod}
            />
          </TabPanel>
          <TabPanel value={view} name='travellers'>
            <Travellers
              tables={tables}
              boards={boards}
              session={session}
              boardNumber={boardNumber}
              onBoardNumberClick={onBoardNumberClick}
            />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

ResultsPane.propTypes = {
  session: PropTypes.object.isRequired,
  rankings: PropTypes.array.isRequired,
  refetchRankings: PropTypes.func.isRequired,
  refetchTables: PropTypes.func.isRequired,
  boards: PropTypes.array.isRequired,
  tables: PropTypes.array.isRequired,
  onBoardNumberClick: PropTypes.func.isRequired,
  boardNnumber: PropTypes.number,
  sessionFinished: PropTypes.bool
};

export default ResultsPane;
