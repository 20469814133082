import gql from 'graphql-tag';
import { userFragments } from '../fragments';

const userQueries = {
  // uses jwt to get current user
  currentUser: gql`
    query {
      currentUser {
        ...FullUser
        region
      }
    }
    ${userFragments.fullUser}
  `,
  users: gql`
    query {
      users {
        ...FullUser
      }
    }
    ${userFragments.fullUser}
  `,
  usersById: gql`
    query ($ids: [ID]!) {
      usersById(ids: $ids) {
        ...FullUser
      }
    }
    ${userFragments.fullUser}
  `
};

export default userQueries;
