/**
 * A banner with an optional button to the right
 * Mostly used in the Director dashboard
 */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    background: 'rgba(0,0,0,0.5)',
    padding: '2rem',
    borderRadius: '0.5rem',
    height: '10rem'
  }
}));

const ActionBanner = props => {
  const { children, headingText, buttonText, onButtonClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container alignItems='center'>
        <Grid item xs>
          <Typography variant='h6'>{headingText}</Typography>
          {children}
        </Grid>
        {buttonText && (
          <Grid item>
            <Button onClick={onButtonClick} variant='contained' color='primary'>
              {buttonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

ActionBanner.propTypes = {
  children: PropTypes.node.isRequired,
  headingText: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func
};

export default ActionBanner;
