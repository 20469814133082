import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import MiniCard from '../../../Components/MiniCard.js';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  bid: {
    fontSize: '1.5em',
    padding: '0 0.5em',
    opacity: 0.5
  },
  latestBid: {
    opacity: 1
  }
}));

const Bids = (props) => {
  const { bids, index } = props;
  const classes = useStyles();

  const lastBidIndex = bids.length;

  return (
    <Grid
      container
      justify='center'
      alignItems={index === 0 ? 'flex-start' : 'flex-end'}
    >
    {
      bids.map((bid, index) => {
        return (
          <Grid item className={clsx(classes.bid, (index + 1) === lastBidIndex && classes.latestBid)} key={index}>
            <MiniCard
              suit={bid.denomination ? bid.denomination : null}
              value={bid.level ? bid.level : bid.value}
              isBid={true}
              alerted={bid.alerted}
            />
          </Grid>
        );
      })
    }
    </Grid>
  );
};

export default Bids;
