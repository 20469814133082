import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SharedGame from '../../../Components/Game/Game.js';
import SessionFinished from '../SessionFinished/SessionFinished.js';
import SoundEffectsContext from '../../../Components/SoundEffects/SoundEffectsContext.js';
import { usePreviousValue } from '../../../hooks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  logoContainer: {
    position: 'fixed',
    left: '1rem',
    top: '1rem',
    zIndex: 1,
    color: '#fff'
  },
  logo: {
    display: 'block',
    width: theme.logo.gameWidth,
    height: theme.logo.gameHeight,
    background: `url("/img/${theme.logo.image}.svg") no-repeat center center`,
    backgroundColor: theme.logo.background,
    backgroundSize: 'contain',
    overflow: 'hidden',
    textIndent: '-100rem',
    borderRadius: '0.5rem'
  }
}));

const Logo = () => {
  const classes = useStyles();
  return (
    <div className={classes.logoContainer}>
      <div className={classes.logo}>
        Pianola Play
      </div>
    </div>
  );
};

const Game = (props) => {
  const { table, session, refetch, currentUser, onCardPlayed, onBidMade } = props;
  const playSound = useContext(SoundEffectsContext);
  const directorCallStatus = table && table.directorCallStatus;
  const tableStatus = table && table.status;
  const prevDirectorCallStatus = usePreviousValue(directorCallStatus);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    let timeout;

    if (tableStatus === 'InactiveFinished') {
      // when the table is over, refresh since the user will need to be taken
      // to their next active table
      timeout = setTimeout(() => { refetch(); }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [tableStatus, refetch]);

  // play sounds for when director is called / joins / leaves
  useEffect(() => {
    if (directorCallStatus === 'DirectorCalled' && prevDirectorCallStatus !== 'DirectorCalled') {
      playSound('directorCalled');
    } else if (directorCallStatus === 'DirectorAtTable' && prevDirectorCallStatus !== 'DirectorAtTable') {
      playSound('directorJoined');
    } else if (directorCallStatus === 'None' && prevDirectorCallStatus === 'DirectorAtTable') {
      playSound('directorLeft');
    }
  }, [directorCallStatus, prevDirectorCallStatus, playSound]);

  const tableIsNull = !table;

  // when a session starts, a user in a pair should never be in a situation where the table is undefined
  // this can currently only happen in multiregion events where a user is from a different AWS region
  // and the session update event has been replicated to the user's region before the table objects have
  //
  // in those cases, we want to poll until the tables do come in
  useEffect(() => {
    let interval;

    if (tableIsNull && currentUser.gameStatus !== 'NotInPair') {
      interval = setInterval(() => { refetch(); }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [tableIsNull, refetch, currentUser.gameStatus]);

  if (tableIsNull) {
    // otherwise, a user who's not in a pair can just go away
    if (currentUser.gameStatus === 'NotInPair') {
      return (
        <p className={classes.errorMessage}>
          {t('unfortunately-the-session-has-already-started')}
        </p>
      );
    }

    return null;
  }

  // if the table is done but there's still tables in progress,
  // show the session finished screen just for this lot
  if (tableStatus === 'SessionFinished') {
    return (
      <SessionFinished
        session={session}
        lastTablePlayed={table}
        currentUser={currentUser}
      />
    );
  }

  return (
    <>
      {
        currentUser.gameStatus !== 'SessionFinished' && (<Logo />)
      }
      <SharedGame
        table={table}
        session={session}
        refetch={refetch}
        currentUser={currentUser}
        onCardPlayed={onCardPlayed}
        onBidMade={onBidMade}
      />
    </>
  );
};

export default Game;
