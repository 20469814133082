import React from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { calculateTableProgressPercentage } from '../../../helpers/dashboard.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  waitingForMovementMessage: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  },
  itemLabel: {
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    fontWeight: 'bold'
  },
  itemText: {
    fontSize: '2rem'
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '2.8rem'
  }
}));

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    width: '100%',
    height: '1rem',
    borderRadius: '0.5rem'
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200]
  },
  bar: {
    borderRadius: '0 0.5rem 0.5rem 0',
    backgroundColor: theme.palette.primary.main
  }
}))(LinearProgress);

const getTextStatus = (tableStatus) => {
  // return ID's for useTranslation hook
  switch (tableStatus) {
    case 'WaitingForPlayers':
      return 'waiting-for-players';
    case 'Auction':
      return 'bidding';
    case 'InactiveFinished':
      return '...';
    case 'SessionFinished':
      return 'finished-last-round';
    default:
      return 'not-applicable';
  }
};

const TableStatus = (props) => {
  const { table } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (table.status === 'WaitingForMovement') {
    return (
      <div className={classes.waitingForMovementMessage}>
        {
          table.isSitOut
            ? t('sitting-this-round-out')
            : t('round-completed-awaiting-movement')
        }
      </div>
    );
  }

  const totalTricksPlayed = (table.nsTricks || 0) + (table.ewTricks || 0);
  // for a brief moment, when the last card of the last trick is played,
  // we have totalTricksPlayed === 13 but the table status hasn't yet updated
  // this Math.min() prevents us from showing the table as playing trick 14
  const currentTrickNumber = Math.min(totalTricksPlayed + 1, 13);
  const progressPct = calculateTableProgressPercentage(table);

  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      spacing={2}
      wrap='nowrap'
    >
      {
        !table.isSitOut && (
          <Grid item>
            <div className={classes.itemLabel}>{t('board')}</div>
            <div className={classes.itemText}>{table.orderInRound + 1}/{table.boardsPerRound}</div>
          </Grid>
        )
      }
      {
        table.status === 'Play'
          ? (
            <>
              <Grid item>
                <div className={classes.itemLabel}>{t('trick')}</div>
                <div className={classes.itemText}>{currentTrickNumber}</div>
              </Grid>
              <Grid item xs>
                <div className={classes.itemLabel}>{t('progress')}</div>
                <div className={classes.progressContainer}>
                  <StyledLinearProgress
                    variant='determinate'
                    value={progressPct}
                  />
                </div>
              </Grid>
            </>
          )
          : (
            <Grid item xs>
              <div className={classes.itemLabel}>{t('progress')}</div>
              <div className={classes.itemText}>{t(getTextStatus(table.status))}</div>
            </Grid>
          )
      }
    </Grid>
  );
};

export default TableStatus;
