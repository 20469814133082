import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTimedGlowingDirection } from '../../../hooks';
import PlayerForSeat from './PlayerForSeat.js';
import ViewpointSeat from './ViewpointSeat.js';
import YourTurn from './YourTurn.js';
import { cardHelpers, auctionHelpers } from 'shared-helpers';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const { arrangeSeatsBasedOnViewPoint } = cardHelpers;
const { whoIsDummy } = auctionHelpers;

const useStyles = makeStyles(theme => ({
  player: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: 1,
    pointerEvents: 'none',
    fontSize: '1rem',
    '@media all and (max-width:1194px)': {
      fontSize: '0.8rem'
    },
    '@media all and (max-height:700px)': {
      fontSize: '0.7rem'
    }
  },
  index0: {
    top: 0,
    left: 0,
    width: '100%',
    alignItems: 'flex-start'
  },
  index1: {
    right: 0,
    top: '-6em',
    transformOrigin: '100% 100% 0',
    transform: 'rotate(-90deg)',
    width: 'calc(100vh - 9em)'
  },
  index2: {
    bottom: 0,
    left: 0,
    width: '100%',
    transition: 'bottom 0.3s ease-in-out, scale 0.3s ease-in-out'
  },
  index3: {
    left: 0,
    top: '-6em',
    transformOrigin: '0 100% 0',
    transform: 'rotate(90deg)',
    width: 'calc(100vh - 9em)'
  },
  active: {
    '& $item': {
      background: theme.game.playerSeat.activeBackground,
      color: theme.game.playerSeat.activeText,
      borderColor: theme.game.playerSeat.activeBorder
    }
  },
  item: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignContent: 'center',
    height: '6em',
    padding: '0 1.2em 0 0.6em',
    borderRadius: '0.8em 0.8em 0 0',
    border: 'solid',
    borderWidth: theme.game.playerSeat.borderWidth,
    background: theme.game.playerSeat.background,
    color: theme.game.playerSeat.text,
    borderColor: theme.game.playerSeat.border,
    borderBottomWidth: 0,
    transition: 'background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out',
    boxShadow: '0 0 2px 1px rgba(0,0,0,0.3)'
  },
  itemIndex0: {
    borderTop: 0,
    borderBottomWidth: theme.game.playerSeat.borderWidth,
    borderRadius: '0 0 0.8em 0.8em'
  },
  itemIndex2: {
    height: '5em',
    borderWidth: theme.game.viewpointPlayerSeat.borderWidth,
    background: theme.game.viewpointPlayerSeat.background,
    color: theme.game.viewpointPlayerSeat.text,
    borderColor: theme.game.viewpointPlayerSeat.border
  },
  glowing: {
    boxShadow: '0 0 3em #EAAB00'
  }
}));

const PlayerSeats = (props) => {
  const {
    playersBySeat,
    dealer,
    declarer,
    vulnerable,
    trick,
    activeSeat,
    viewpoint,
    glowDuration
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  // positions based on viewpoint
  const seats = arrangeSeatsBasedOnViewPoint(viewpoint || 'S');

  // glowing player name based on who won the trick
  const { glowingDirection } = useTimedGlowingDirection({
    trickCards: trick ? trick.cards : [],
    trickIndex: trick ? trick.index : -1,
    glowDuration
  });

  let yourTurnMessageIndex;
  let yourTurnMessage;

  // show the correct 'your turn' message in the correct place
  // depending on viewpoint and active seat
  if (activeSeat === viewpoint) {
    if (declarer && viewpoint === whoIsDummy(declarer)) {
      yourTurnMessageIndex = 2;
      yourTurnMessage = 'playing-from-dummy';
    } else {
      yourTurnMessageIndex = 2;
      yourTurnMessage = 'your-turn';
    }
  } else if (declarer && viewpoint === declarer && activeSeat === whoIsDummy(declarer)) {
    yourTurnMessageIndex = 0;
    yourTurnMessage = 'play-from-dummy';
  }

  return (
    <>
      {
        seats.map((seat, index) => {
          const player = playersBySeat[seat.value];
          const isDealer = (seat.value === dealer);

          let isVulnerable = vulnerable === 'Both';
          if ((seat.value === 'N' || seat.value === 'S') && vulnerable === 'NS') {
            isVulnerable = true;
          } else if ((seat.value === 'E' || seat.value === 'W') && vulnerable === 'EW') {
            isVulnerable = true;
          }

          return (
            <div
              className={clsx(classes.player, classes[`index${index}`], seat.value === activeSeat && classes.active)}
              key={index}
            >
              <div className={clsx(classes.item, classes[`itemIndex${index}`], seat.value === glowingDirection && classes.glowing)}>
                {
                  index === 2
                    ? <ViewpointSeat
                      position={seat.key}
                      isDealer={isDealer}
                      isVulnerable={isVulnerable}
                    />
                    : <PlayerForSeat
                      player={player}
                      textStatus={seat.key}
                      isDealer={isDealer}
                      isVulnerable={isVulnerable}
                    />
                }
                <YourTurn
                  message={t(yourTurnMessage)}
                  show={yourTurnMessageIndex === index}
                />
              </div>
            </div>
          );
        })
      }
    </>
  );
};

export default PlayerSeats;
