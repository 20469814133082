import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MessagesSQ from '../SubscriptionQueries/Messages.js';
import InnerChat from './InnerChat.js';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    background: theme.palette.background.dark,
    color: theme.palette.block.content.color,
    borderRadius: '0.5em',
    overflow: 'hidden'
  }
}));

const Chat = (props) => {
  const {
    threadId,
    onMessageSent: propsOnMessageSent,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MessagesSQ threadId={threadId}>
        {({ messages, typingNotifications, onMessageSent }) => (
          <InnerChat
            threadId={threadId}
            messages={messages}
            typingNotifications={typingNotifications}
            sqOnMessageSent={onMessageSent}
            propsOnMessageSent={propsOnMessageSent}
            {...rest}
          />
        )}
      </MessagesSQ>
    </div>
  );
};

Chat.propTypes = {
  tabs: PropTypes.array,
  threadId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  sendTypingNotifications: PropTypes.bool,
  onMessageSent: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  placeholderText: PropTypes.string,
  headerText: PropTypes.string,
  theme: PropTypes.string,
  scrollKey: PropTypes.string
};

export default Chat;
