import { DateTime } from 'luxon';
import { areOpponents, getPlayerSeatForTable } from './game.js';

// hide typing notifications after 10s
export const TYPING_NOTIFICATION_EXPIRY = 10000;

export const hasNotificationExpired = (notification) => {
  const now = Date.now();
  const expiryTime = new Date(notification.timestamp).getTime() + TYPING_NOTIFICATION_EXPIRY;

  return expiryTime < now;
};

export const groupMessages = (messages, typingNotifications, currentUserId) => {
  const messageGroups = [];

  messages.forEach((message, i) => {
    let startNewGroup = false;

    const time = DateTime.fromISO(message.timestamp);

    if (i > 0) {
      const prevMessage = messages[i - 1];
      const prevTime = DateTime.fromISO(prevMessage.timestamp);

      // start a new message group if...
      // the messages is from a different user (obvs)
      if (message.user.id !== prevMessage.user.id) {
        startNewGroup = true;
        // more than a minute has passed since the last message
      } else if (time > prevTime.plus({ minutes: 1 })) {
        startNewGroup = true;
      } else if (message.icon) {
        // if an icon was passed, this is a special message that shouldn't be grouped
        // (e.g. a system description being sent on behalf of 2 people)
        startNewGroup = true;
      }
    }

    if (i === 0 || startNewGroup) {
      const from = {
        id: message.user.id,
        firstName: message.user.firstName,
        lastName: message.user.lastName,
        fullName: message.user.fullName,
        avatarUrl: message.user.avatarUrl
      };

      const type = message.user.type && message.user.type.toLowerCase();

      // messages from the current user will be shown to the right
      if (message.user.id === currentUserId) {
        from.isCurrentUser = true;
      } else if (type === 'director') {
        // distinguish director messages
        from.type = type;
      }

      messageGroups.push({
        from,
        icon: message.icon,
        timestamp: time.toLocaleString(DateTime.TIME_24_SIMPLE),
        messages: [{
          text: message.text,
          type: message.type,
          attachments: message.attachments,
          recipient: message.recipient,
          translate: message.translate
        }]
      });
    } else {
      messageGroups[messageGroups.length - 1].messages.push({
        text: message.text,
        timestamp: message.timestamp,
        type: message.type,
        attachments: message.attachments,
        recipient: message.recipient,
        translate: message.translate
      })
    }
  });

  const lastMessageGroup = messageGroups.length && messageGroups[messageGroups.length - 1];

  typingNotifications.forEach(notification => {
    // don't show expired notifications
    if (hasNotificationExpired(notification)) {
      return;
    }

    // if the typing notification is from the user who last sent a message
    // add it to the previous group
    if (lastMessageGroup &&
      notification.user.id === lastMessageGroup.from.id) {
      // if the last typing notification from that user came before the last message
      // that means the notification is stale, so hide it
      if (messages.length &&
        (notification.timestamp < messages[messages.length - 1].timestamp)) {
        return;
      }

      lastMessageGroup.messages.push({ isTypingNotification: true });
    } else {
      messageGroups.push({
        from: {
          id: notification.user.id,
          fullName: notification.user.fullName,
          avatarUrl: notification.user.avatarUrl
        },
        messages: [{ isTypingNotification: true }]
      });
    }
  });

  return messageGroups;
};

export const getChatRecipient = (user) => {
  return { id: user.id, name: user.fullName };
};

export const getTableChatRecipients = (currentUser, table, session, t) => {
  const otherPlayersOnTable = [
    table.nsPair.user1, table.nsPair.user2,
    table.ewPair.user1, table.ewPair.user2
  ].filter(user => user.id !== currentUser.id);

  let recipients;

  // director can chat with everyone on the table
  if (currentUser.type === 'Director') {
    recipients = otherPlayersOnTable
      .map(getChatRecipient);
  } else {
    // a player can chat with their opponents at any time
    const currentUserSeat = getPlayerSeatForTable(currentUser.id, table);
    // ... but only at certain points with their partner
    const partnersCannotChat = table.status !== 'WaitingForMovement' && table.status !== 'SessionFinished';

    recipients = otherPlayersOnTable
      // only look at players for this - rules for chatting to director are different
      // (even if director is playing here)
      .filter(otherPlayer => otherPlayer.type === 'Player')
      .map(otherPlayer => {
        const otherPlayerSeat = getPlayerSeatForTable(otherPlayer.id, table);
        const recipient = getChatRecipient(otherPlayer);

        if (partnersCannotChat && !areOpponents(currentUserSeat, otherPlayerSeat)) {
          recipient.disabled = true;
        }

        return recipient;
      });

    // add director - players can only chat to director when director's about
    const directorRecipient = { id: session.directorId, name: t('director') };

    if (table.directorCallStatus !== 'DirectorAtTable') {
      directorRecipient.disabled = true;
    }

    recipients.push(directorRecipient);
  }

  recipients.unshift({ id: '', name: t('table') });

  return recipients;
};

export const getEndGameLobbyRecipients = (currentUser, allUsers, session, t) => {
  const recipients = allUsers.filter(user => {
    return user.gameStatus === 'SessionFinished' &&
      currentUser.id !== user.id;
  }).map(getChatRecipient);

  if (currentUser.type !== 'Director') {
    recipients.push({ id: session.directorId, name: t('director') });
  }

  recipients.unshift({ id: '', name: t('everyone') });

  return recipients;
};

export const getDirectorLobbyChatRecipients = (currentUser, allUsers, t) => {
  const recipients = allUsers
    .filter(user => currentUser.id !== user.id)
    .map(getChatRecipient);

  recipients.unshift({ id: '', name: t('everyone') });

  return recipients;
};

export const getPlayerLobbyChatRecipients = (currentUser, pairs, session, t) => {
  const recipients = [
    { id: '', name: t('everyone') },
    { id: session.directorId, name: t('director') }
  ];

  if (currentUser.gameStatus !== 'InPair') {
    return recipients;
  }

  let partner;

  for (let i = 0; i < pairs.length; i++) {
    if (pairs[i].user1.id === currentUser.id) {
      partner = pairs[i].user2;
      break;
    } else if (pairs[i].user2.id === currentUser.id) {
      partner = pairs[i].user1;
      break;
    }
  }

  if (partner) {
    recipients.push(getChatRecipient(partner));
  }

  return recipients;
};
