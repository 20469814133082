import { getSystemDescriptionUserNameById } from '../../helpers/systemDescription.js';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const LastEditedDescription = (props) => {
  const { systemDescription, currentUser } = props;
  const { t } = useTranslation();

  const editingUserId = systemDescription && systemDescription.currentlyEditingUserId;
  const disabled = editingUserId && editingUserId !== currentUser.id;

  let description;

  if (disabled) {
    description = t('currently-edited-by-name', {
      name: getSystemDescriptionUserNameById(systemDescription, editingUserId)
    });
  } else if (systemDescription && systemDescription.lastEdited) {
    const luxonEditedTime = DateTime.fromISO(systemDescription.lastEdited);
    const date = luxonEditedTime.toLocaleString(DateTime.DATETIME_SHORT);

    if (systemDescription.lastEditedUserId === currentUser.id) {
      description = t('last-edited-by-you-on-date', { date });
    } else {
      description = t('last-edited-by-name-on-date', {
        name: getSystemDescriptionUserNameById(systemDescription, systemDescription.lastEditedUserId),
        date
      });
    }
  }

  if (!description) {
    return null;
  }

  return description;
};

LastEditedDescription.propTypes = {
  currentUser: PropTypes.object.isRequired,
  systemDescription: PropTypes.object
};

export default LastEditedDescription;
