import React, { useCallback, useEffect, useState } from 'react';
import { tableMutations } from '../../../graphql/mutations';
import { usePPMutation, usePreviousValue } from '../../../hooks';
import DirectorCalledOverlay from '../../../Views/Player/Game/DirectorCalledOverlay.js';
import ActionButton from './ActionButton.js';
import DirectorIcon from '@material-ui/icons/FlagRounded';
import { useTranslation } from 'react-i18next';

const CallDirectorButton = (props) => {
  const { table, currentUser } = props;
  const { t } = useTranslation();
  const [setDirectorCalled] = usePPMutation(tableMutations.setDirectorCalled);
  const directorCallStatus = table.directorCallStatus;
  const canCallDirector = directorCallStatus === 'None';

  // we need to show a spinner while the director call is being processed
  // but we can't just use `loading` from usePPMutation because we need to wait
  // for the updated notification via websockets, which could be very slow ...
  //
  // so we use loading as state...
  const [loading, setLoading] = useState(false);
  const prevDirectorCallStatus = usePreviousValue(directorCallStatus);

  // ... then flip `loading` to true when the mutation fires
  // and wait until `directorCallStatus` actually changes before flipping it to false
  useEffect(() => {
    if (loading && directorCallStatus !== prevDirectorCallStatus) {
      setLoading(false);
    }
  }, [directorCallStatus, prevDirectorCallStatus, loading, setLoading]);

  const handleCallDirectorClick = useCallback(() => {
    setLoading(true);

    setDirectorCalled({
      variables: {
        tableId: table.id,
        directorCalled: directorCallStatus === 'None'
      }
    });
  }, [setLoading, setDirectorCalled, directorCallStatus, table.id]);

  let directorTooltip = '';
  switch (table.directorCallStatus) {
    case 'DirectorCalled':
      directorTooltip = 'director-called';
      break;
    case 'DirectorAtTable':
      directorTooltip = 'director-already-at-the-table';
      break;
    default:
      directorTooltip = 'call-the-director';
      break;
  }

  return (
    <>
      <ActionButton
        label={t('director')}
        icon={<DirectorIcon style={{ fontSize: 30 }} />}
        onClick={handleCallDirectorClick}
        disabled={!canCallDirector}
        tooltip={t(directorTooltip)}
        loading={canCallDirector && loading}
      />
      <DirectorCalledOverlay
        directorCallStatus={table.directorCallStatus}
        directorCallerId={table.directorCallerId}
        claim={table.claim}
        currentUser={currentUser}
        onCancelClick={handleCallDirectorClick}
        loading={loading}
      />
    </>
  );
};

export default CallDirectorButton;
