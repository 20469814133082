import React from 'react';
import ErrorNotification from './ErrorNotification.js';

// https://reactjs.org/docs/error-boundaries.html
// https://codepen.io/gaearon/pen/wqvxGa?editors=0010

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      eventId: null
    };
  }

  // Catch errors in any components below and re-render with error message
  componentDidCatch(error, errorInfo) {
    console.log('componentDidCatch error', error);
    console.log('componentDidCatch errorInfo', errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    // if 401 the user is going to be redirectd to Pianola login so dont show any message
    const isUnauthenticated = error && error.message.indexOf('401') > -1 ? true : false;
    // if 403 the user is going to be redirectd to 403 page so dont show any message
    const isForbidden = error && error.message.indexOf('403') > -1 ? true : false;
    if (isUnauthenticated || isForbidden) {
      return null;
    } else if (errorInfo) {
      return (
        <ErrorNotification
          error={error}
          errorInfo={errorInfo}
        />
      );
    } else {
      // Normally, just render children
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
