import React from 'react';
import Grid from '@material-ui/core/Grid';
import Player from '../../../Components/Players/Player.js';

const Pair = (props) => {
  const { pair } = props;
  const { user1, user2 } = pair;

  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      spacing={1}
      wrap='nowrap'
    >
      <Grid item>
        <Player
          {...user1}
          avatarOnly
          showTooltip
        />
      </Grid>
      <Grid item>
        <Player
          {...user2}
          avatarOnly
          showTooltip
        />
      </Grid>
    </Grid>
  );
};

export default Pair;
