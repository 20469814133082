import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'rgba(0,0,0,0.25)',
    borderRadius: '0.5rem',
    width: '100%',
    height: '100%',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ready: {
    border: '0.2rem solid #c7ff00'
  },
  sessionStarting: {
    background: 'rgba(255, 255, 255, 0.20)'
  },
  container: {
    width: '100%',
    maxWidth: '90rem',
    padding: '0 2rem'
  }
}));

const GameDetails = (props) => {
  const { session, playerInPair, children } = props;
  const classes = useStyles();

  const sessionStarting = session.status === 'Starting';

  return (
    <div className={clsx(classes.root, playerInPair && classes.ready, sessionStarting && classes.sessionStarting)}>
      <div className={classes.container}>
        {children}
      </div>
    </div>
  );
};

export default GameDetails;
