console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);
const env = process.env.REACT_APP_ENV || 'local';
const build = env.toUpperCase();

const getEnvVar = (name) => {
  const ENV = build;
  const fullVarName = `REACT_APP_${name}_${ENV}`;
  const retVar = process.env[fullVarName];
  return retVar;
};

const queryEndpoint = getEnvVar('QUERY_URI');
const mutationEndpoint = getEnvVar('MUTATION_URI');
const wsEndpoint = getEnvVar('WEBSOCKET_URI');

export { build, queryEndpoint, mutationEndpoint, wsEndpoint, getEnvVar };
