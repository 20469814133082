import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Badge from '@material-ui/core/Badge';
import AlertIcon from '@material-ui/icons/PriorityHigh';
import { Translation } from 'react-i18next';

const styles = {
  component: {
    position: 'relative',
    minWidth: '5.2em',
    height: '3.6em',
    fontWeight: 'bold',
    borderRadius: '0.5em',
    overflow: 'hidden',
    textTransform: 'uppercase',
    textAlign: 'center',
    background: '#f5f5f5',
    padding: '0 0.5em',
    color: '#4a4a4a',
    border: '0.1em solid #ddd',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span:after': {
      display: 'none'
    }
  },
  sizeSmall: {
    height: '3em',
    borderRadius: '0.3em',
    '& span': {
      marginTop: '-0.1em'
    }
  },
  sizeLarge: {
    width: '7.8em',
    height: '5.4em',
    borderRadius: '0.3em',
    '& span': {
      zoom: 1.5
    }
  },
  hasSuit: {
    display: 'inline-block',
    fontSize: '2em',
    '&:after': {
      content: '""',
      display: 'inline',
      padding: '0 0.45em',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center',
      backgroundSize: 'contain',
    }
  },
  textOnly: {
    display: 'inline-block',
    fontSize: '1.5em',
    '&:after': {
      display: 'none'
    }
  },
  risk: {
    fontSize: '2em',
    marginLeft: '0.2em'
  },
  isSpade: {
    background: '#ebf1f8',
    color: '#3b76bb',
    '& span:after': {
      backgroundImage: 'url("/img/components/MiniCard/bg_icon_suit_spade.svg")'
    }
  },
  isDiamond: {
    background: '#fef6e9',
    color: '#f5a623',
    '& span:after': {
      backgroundImage: 'url("/img/components/MiniCard/bg_icon_suit_diamond.svg")'
    }
  },
  isClub: {
    background: '#ecf1e6',
    color: '#417505',
    '& span:after': {
      backgroundImage: 'url("/img/components/MiniCard/bg_icon_suit_club.svg")'
    }
  },
  isHeart: {
    background: '#fcebed',
    color: '#d0021b',
    '& span:after': {
      backgroundImage: 'url("/img/components/MiniCard/bg_icon_suit_heart.svg")'
    }
  },
  isNoTrump: {
    '& span': {
      fontSize: '2em',
      '&:after': {
        display: 'none'
      }
    },
    '& i': {
      fontStyle: 'normal',
      marginLeft: '0.2em'
    }
  },
  isPass: {
    background: '#7ed321',
    borderColor: '#7ed321',
    color: '#fff'
  },
  isDouble: {
    background: '#bc1025',
    borderColor: '#bc1025',
    color: '#fff'
  },
  isDoubleDouble: {
    background: '#3367a5',
    borderColor: '#3367a5',
    color: '#fff'
  },
  isBid: {
    '& span:before': {
      display: 'none'
    },
    '& span:after': {
      display: 'inline'
    }
  },
  showCrown: {
    borderColor: '#f5a623 !important',
    boxShadow: '0 0 5px #f5a623',
    '&:before,&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '2.2em',
      height: '2.2em',
      background: 'transparent url("/img/components/MiniCard/bg_overlay_winner.svg") no-repeat right bottom',
      backgroundSize: 'contain'
    }
  },
  icon: {
    fontSize: '1em'
  }
};

const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    height: '1.2em',
    width: '1.2em',
    minWidth: '1.2em',
    padding: 0,
    borderRadius: '100%'
  },
}))(Badge);

class MiniCardBody extends React.Component {
  classes = () => {
    const { classes, suit, value, size, isBid, showCrown } = this.props;
    let allClasses = [classes.component];

    switch (size) {
      case 'small':
        allClasses.push(classes.sizeSmall);
        break;
      case 'large':
        allClasses.push(classes.sizeLarge);
        break;
      default:
        allClasses.push(classes.sizeMedium);
        break;
    }

    switch (suit) {
      case 'S':
        allClasses.push(classes.isSpade);
        break;
      case 'H':
        allClasses.push(classes.isHeart);
        break;
      case 'D':
        allClasses.push(classes.isDiamond);
        break;
      case 'C':
        allClasses.push(classes.isClub);
        break;
      case 'NT':
        allClasses.push(classes.isNoTrump);
        break;
      default:
        switch (value) {
          case 'Pass':
            allClasses.push(classes.isPass);
            break;
          case 'X':
            allClasses.push(classes.isDouble);
            break;
          case 'XX':
            allClasses.push(classes.isDoubleDouble);
            break;
          default:
            break;
        }
    }

    if (isBid) {
      allClasses.push(classes.isBid);
    }

    if (showCrown) {
      allClasses.push(classes.showCrown);
    }

    return allClasses.join(' ');
  };

  render () {
    const { classes, suit, value, risk, alerted } = this.props;
    return (
      <StyledBadge badgeContent={<AlertIcon className={classes.icon} />} color='secondary' invisible={!alerted}>
        <Translation>
          {t => {
              return (
                <span
                  className={value !== '' ? this.classes() : null}
                >
                  {
                    suit ?
                      suit === 'NT'
                      ? <span className={ classes.textOnly }>{value}<i>{suit}</i></span>
                      : <span className={ classes.hasSuit }>{value}</span>
                    : <span className={ classes.textOnly }>
                        { 
                          value === 'Pass' ? t(value.toLowerCase()) : value
                        }
                      </span>
                  }
                  {risk && <span className={ classes.risk }>{risk}</span>}
                </span>
              )
            }}
        </Translation>
      </StyledBadge>
    );
  }
}

MiniCardBody.propTypes = {
  classes: PropTypes.object.isRequired,
  suit: PropTypes.string,
  value: PropTypes.string,
  alerted: PropTypes.bool,
  risk: PropTypes.string,
  showCrown: PropTypes.bool
};

export default withStyles(styles)(MiniCardBody);
