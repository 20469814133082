import gql from 'graphql-tag';

const sessionFragments = {
  fullSession: gql`
    fragment FullSession on Session {
      id
      status
      name
      dateTime
      timeZone
      boardsPerRound
      roundsCompleted
      roundTimeLimit
      timeLastRoundStarted
      numRounds
      numPairs
      organisation
      winnerType
      scoringMethod
      resultsPublished
      directorId
      sourceAppName
      errorCode
      region
    }
  `
};

export default sessionFragments;
