import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SpreadCardSuitGroup from './SpreadCardSuitGroup.js';
import { cardHelpers } from 'shared-helpers';
import { useComponentScale } from '../../../hooks';
import { getCardsForSpread, checkForSingleton } from '../../../helpers/card.js';

const {
  getCardsForSuit,
  getOrderOfSuitsForCardList
} = cardHelpers;

const useStyles = makeStyles(() => ({
  list: {
    padding: 0,
    margin: '0 auto',
    textAlign: 'center',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    fontSize: 0,
    width: '100%',
    pointerEvents: 'none'
  },
  index0: {
    transform: 'rotate(180deg)',
    transformOrigin: '50% 50% 0',
  },
  index0StraightLine: {
    transform: 'none',
    paddingTop: '1rem'
  },
  index0StraightLineFaceUp: {
    paddingTop: '7rem',
    '@media all and (max-width:1194px)': {
      paddingTop: '5.5rem'
    }
  },
  index0FocusedSuit: {
    transform: 'none',
    paddingTop: '7rem',
    '@media all and (max-width:1194px)': {
      paddingTop: '6rem'
    }
  },
  index1: {
    position: 'absolute',
    top: 0,
    right: 'calc(100vh/9)',
    width: 'calc(100vh - 9rem)',
    transform: 'rotate(-90deg)',
    transformOrigin: '100% 0 0'
  },
  index1FaceUp: {
    right: 'calc(100vh/7)'
  },
  index1StraightLine: {
    right: 'calc(-50vh + 5rem)',
    transformOrigin: '50% 100% 0',
    bottom: '50%',
    top: 'auto'
  },
  index2: {
  },
  index3: {
    position: 'absolute',
    top: 0,
    left: 'calc(100vh/9)',
    width: 'calc(100vh - 9rem)',
    transform: 'rotate(90deg)',
    transformOrigin: '0 0 0'
  },
  index3FaceUp: {
    left: 'calc(100vh/7)'
  },
  index3StraightLine: {
    left: 'calc(-50vh + 5rem)',
    transformOrigin: '50% 100% 0',
    bottom: '50%',
    top: 'auto',
    '& div:first-child div:first-child': {
      marginLeft: '0 !important'
    }
  }
}));

const SpreadCardList = (props) => {
  const {
    index,
    cards,
    faceDown,
    enabledSuit,
    contract,
    autoPlaySingletons,
    focusedSuit,
    straightLine,
    ...other
  } = props;

  const componentScale = useComponentScale();

  const suits = getOrderOfSuitsForCardList(contract, index);
  let cardsInSuitOrder = [];

  suits.forEach(suit => {
    // TODO don't do this twice
    const suitCards = getCardsForSuit(cards, suit);
    cardsInSuitOrder = cardsInSuitOrder.concat(suitCards);
  });

  const spreadCards = getCardsForSpread(cardsInSuitOrder, enabledSuit, straightLine, index);

  const autoPlay = autoPlaySingletons && checkForSingleton(cards, enabledSuit);

  const classes = useStyles();
  const seatIndex = index;

  let scale;
  if (index === 2) {
    scale = componentScale;
  }  else {
    if (faceDown) {
      scale = componentScale / 1.75;
    } else {
      scale = componentScale / 1.35;
    }
  }
  if (straightLine) {
    if (faceDown) {
      scale = componentScale / 1.6;
    } else {
      scale = componentScale / 1.1;
    }
  }

  return (
    <div className={
      clsx(
        classes.list,
        classes[`index${index}`],
        !faceDown && classes[`index${index}FaceUp`],
        straightLine && classes[`index${index}StraightLine`],
        (!faceDown && straightLine) && classes[`index${index}StraightLineFaceUp`],
        focusedSuit && classes[`index${index}FocusedSuit`]
      )}
    >
    {
      suits.map((suit, index) => {
        let suitCards = getCardsForSuit(spreadCards, suit);
        // enable cards that the player can click
        const enabled = suit === enabledSuit || enabledSuit === 'all';
        // only fade out the other cards if there is an enabled suit
        const faded = enabledSuit && !enabled;
        // if there's a focused suit (e.g. with confirm to click) only show cards for that suit
        const hidden = focusedSuit && focusedSuit !== suit;

        // setting cards visibility this way
        // (as opposed to hiding or not rendering the suit group)
        // gives us a nice animation when the cards appear / reappear
        if (hidden) {
          suitCards = suitCards.map(card => {
            return { ...card, visible: false };
          });
        }

        return (
          <SpreadCardSuitGroup
            key={index}
            seatIndex={seatIndex}
            cards={suitCards}
            faceDown={faceDown}
            focusedSuit={focusedSuit}
            enabled={enabled}
            faded={faded}
            scale={scale}
            autoPlay={autoPlay && enabled}
            {...other}
          />
        );
      })
    }
    </div>
  );
};

SpreadCardList.propTypes = {
  index: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  faceDown: PropTypes.bool.isRequired,
  enabledSuit: PropTypes.string.isRequired,
  focusedSuit: PropTypes.string,
  contract: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  cardRefCallback: PropTypes.func,
  straightLine: PropTypes.bool
};

export default SpreadCardList;
