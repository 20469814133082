import React from 'react';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';

import { resultSubscriptions } from '../../graphql/subscriptions/';
import { rankingQueries } from '../../graphql/queries/';

class Rankings extends React.Component {
  handleResultSubscriptionData = (refetch) => {
    refetch();
  }

  render() {
    return (
      <Query
        query={rankingQueries.rankings}
        fetchPolicy='no-cache'
      >
        {({ refetch, data }) => (
          <PPSubscription
            subscription={resultSubscriptions.resultsUpdate}
            onSubscriptionData={() => { this.handleResultSubscriptionData(refetch); }}
          >
            {() => {
              if (!data || !data.rankings || !data.rankings.rankings) { return null; }
              return this.props.children({
                rankings: data.rankings.rankings,
                sessionFinished: data.rankings.sessionFinished,
                refetchRankings: refetch
              });
            }}
          </PPSubscription>
        )}
      </Query>
    );
  }
}

export default Rankings;
