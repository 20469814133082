import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import DealDirectionHeader from './DealDirectionHeader.js';
import DealDirectionBody from './DealDirectionBody.js';

const styles = {
  component: {
    position: 'absolute',
    lineHeight: '1em',
    width: '16em',
    height: '11.6em',
    background: '#fff',
    border: '0.1em solid #ccc',
    borderRadius: '0.3em',
    color: '#333',
    overflow: 'hidden',
    fontWeight: 'bold',
    userSelect: 'none',
    textAlign: 'left'
  },
  index0: {
    left: '8em'
  },
  index1: {
    right: 0,
    top: '11.5em'
  },
  index2: {
    left: '8em',
    bottom: 0
  },
  index3: {
    left: 0,
    top: '11.5em'
  }
};

const DealDirection = (props) => {
  const {
    classes,
    index,
    ...other
   } = props;
  return (
    <div className={clsx(classes.component, classes['index' + index])}>
      <DealDirectionHeader {...other} />
      <DealDirectionBody
        {...other}
      />
    </div>
  );
}

DealDirection.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
  dealer: PropTypes.bool.isRequired,
  vulnerable: PropTypes.bool.isRequired,
  cards: PropTypes.array.isRequired
}

export default withStyles(styles)(DealDirection);
