import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  '@keyframes dotOne': {
    '0%, 50%': {
      opacity: 0.4
    },
    '25%': {
      opacity: 1
    }
  },
  '@keyframes dotTwo': {
    '25%, 75%': {
      opacity: 0.4
    },
    '50%': {
      opacity: 1
    }
  },
  '@keyframes dotThree': {
    '50%, 100%': {
      opacity: 0.4
    },
    '75%': {
      opacity: 1
    }
  },
  bubble: {
    overflow: 'hidden',
    padding: '0.6rem 0'
  },
  dot: {
    height: '1rem',
    width: '1rem',
    borderRadius: '100%',
    float: 'left',
    margin: '0 0.25rem',
    background: '#999',
    opacity: '0.4',
    animation: '$dotTwo 1.1s linear infinite',
    '&:first-child': {
      animationName: '$dotOne'
    },
    '&:last-child': {
      animationName: '$dotThree'
    }
  }
}));

const TypingDots = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.bubble}>
      <div className={classes.dot}></div>
      <div className={classes.dot}></div>
      <div className={classes.dot}></div>
    </div>
  );
};

export default TypingDots;
