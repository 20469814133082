import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Badge, Tooltip, Typography } from '@material-ui/core';
import SpinnerButton from '../../SpinnerButton.js';
import clsx from 'clsx';

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 14,
    right: 15,
    pointerEvents: 'none'
  }
}))(Badge);

const useStyles = makeStyles(theme => ({
  button: {
    display: 'block',
    background: theme.palette.background.dark,
    color: '#fff',
    width: '11rem',
    height: '6rem',
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    fontWeight: 'bold',
    padding: 0,
    overflow: 'hidden',
    margin: '0 0.1rem',
    '&:hover': {
      background: 'rgba(0,0,0,0.35)'
    },
    '&:disabled': {
      color: 'rgba(255,255,255,.40)'
    }
  },
  smallButton: {
    fontSize: '1rem',
    width: '7rem'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0.3rem'
  },
  disabled: {
    cursor: 'not-allowed'
  }
}));

const ActionButton = (props) => {
  const {
    label,
    tooltip,
    badge,
    icon,
    onClick,
    disabled,
    loading
  } = props;
  const classes = useStyles();

  const isIpad = useMediaQuery('(max-width:1194px)');

  return (
    <Tooltip arrow title={<Typography>{tooltip}</Typography>} disableHoverListener={!tooltip}>
      <StyledBadge color='secondary' badgeContent={badge} invisible={!badge}>
        <div className={clsx(disabled && classes.disabled)}>
          <SpinnerButton
            className={clsx(classes.button, isIpad && classes.smallButton)}
            onClick={onClick}
            disabled={disabled}
            loading={loading}
          >
            <span className={classes.icon}>{icon}</span>
            {label}
          </SpinnerButton>
        </div>
      </StyledBadge>
    </Tooltip>
  );
};

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  badge: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default ActionButton;
