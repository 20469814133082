import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  responsePlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: '0.1rem dashed #999',
    fontSize: '1.3em',
    lineHeight: '1.3em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '0.4rem',
    height: '100%',
    padding: '0.5em 0',
    '@media all and (max-width:1194px)': {
      padding: '0.5em 1em'
    }
  },
  accepted: {
    color: '#203E00',
    background: '#E1EFD2',
    borderStyle: 'solid',
    borderColor: '#4B9200'
  },
  rejected: {
    color: '#670000',
    background: '#FFC7C7',
    borderStyle: 'solid',
    borderColor: '#770000'
  }
}));

const getResponseDescription = (type) => {
  if (type === 'PartnerRejected') {
    return 'partner-rejected';
  } else if (type === 'PartnerAccepted') {
    return 'partner-accepted';
  } else {
    return type.toLowerCase();
  }
};

const ClaimResponseLabel = (props) => {
  const { response, isDummy } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (response) {
    return (
      <div className={clsx(classes.responsePlaceholder, response.value ? classes.accepted : classes.rejected)}>
        {t(getResponseDescription(response.type))}
      </div>
    );
  } else {
    return (
      <div className={classes.responsePlaceholder}>
        {isDummy ? t('waiting-for-partner') : t('awaiting-response')}
      </div>
    );
  }
};

ClaimResponseLabel.propTypes = {
  response: PropTypes.object,
  isDummy: PropTypes.bool
};

export default ClaimResponseLabel;
