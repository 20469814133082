import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, useMediaQuery } from '@material-ui/core';
import Chat from '../Chat/Chat.js';
import TabPanel from '../TabPanel.js';
import Actions from '../Game/Actions/Actions.js';
import Status from '../Game/Status/Status.js';
import { FinishedTablesForRound } from '../Queries/';
import Auction from './Auction/Auction.js';
import Play from './Play/Play.js';
import WaitingForMovement from './WaitingForMovement/WaitingForMovement.js';
import GameStartTransition from '../TransitionScreens/GameStartTransition.js';
import SitOut from './SitOut/SitOut.js';
import ClaimPanel from './Claim/ClaimPanel.js';
import { getTableThreadId, getClaimScrollKey, getPlayerSeatForTable } from '../../helpers/game.js';
import { getTableChatRecipients } from '../../helpers/chat.js';
import { useCloseWarning } from '../../hooks/';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    overflow: 'hidden',
    zIndex: 0
  },
  game: {
    top: '8rem',
    left: '1rem',
    bottom: '1rem',
    right: '1rem'
  },
  leftContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '0.5rem',
    background: `${theme.game.backgroundGradient.outer} radial-gradient(${theme.game.backgroundGradient.inner} 20%, ${theme.game.backgroundGradient.outer} 90%)`
  },
  rightCol: {
    maxWidth: '39rem',
    width: '28%'
  },
  rightContainer: {
    height: '100%',
    marginLeft: '1rem'
  },
  toolbar: {
    top: '1rem',
    right: '1rem'
  },
  inactiveContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  readOnly: {
    pointerEvents: 'none',
    userSelect: 'none',
    '& *': {
      pointerEvents: 'none',
      userSelect: 'none'
    }
  }
}));

const Game = (props) => {
  const {
    table,
    session,
    currentUser,
    onCardPlayed,
    onBidMade,
    readOnly
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useCloseWarning();

  let status = table.status;

  if (status === 'WaitingForMovement' && table.isSitOut) {
    status = 'SitOut';
  }

  const [makingClaim, setMakingClaim] = useState(false);

  const handleMakingClaimClose = useCallback(() => {
    setMakingClaim(false);
  }, [setMakingClaim]);

  // if the 'make claim' panel is open and a card is played, close it
  const handleCardPlayed = useCallback((card) => {
    if (makingClaim) {
      setMakingClaim(false);
    }

    onCardPlayed(card);
  }, [onCardPlayed, makingClaim, setMakingClaim]);

  const isReadOnly = readOnly && (status === 'Play' || status === 'Auction');

  // show all cards face up for director who isn't playing at this table
  const forceCardsFaceUp = currentUser.type === 'Director' && !getPlayerSeatForTable(currentUser.id, table);
  const isIpad = useMediaQuery('(max-width:1194px)');

  return (
    <>
      <div className={clsx(classes.container, classes.game)}>
        <Grid
          container
          justify='space-between'
          alignItems='stretch'
          style={{ height: '100%' }}
        >
          <Grid item xs>
            <div className={clsx(classes.leftContainer, isReadOnly && classes.readOnly)}>
              <GameStartTransition table={table} session={session}>
                <TabPanel
                  value={status}
                  name='Auction'
                >
                  <Auction
                    table={table}
                    onBidMade={onBidMade}
                    forceCardsFaceUp={forceCardsFaceUp}
                  />
                </TabPanel>
                <TabPanel
                  value={status}
                  name='Play'
                >
                  <Play
                    table={table}
                    forceCardsFaceUp={forceCardsFaceUp}
                    onCardPlayed={handleCardPlayed}
                  />
                </TabPanel>
                <TabPanel
                  value={status}
                  name='WaitingForMovement'
                >
                  <FinishedTablesForRound
                    roundNumber={table.roundNumber}
                    tableNumber={table.tableNumber}
                  >
                    {(tables) => (
                      <WaitingForMovement
                        tables={tables}
                        session={session}
                      />
                    )}
                  </FinishedTablesForRound>
                </TabPanel>
                <TabPanel
                  value={status}
                  name='SitOut'
                >
                  <SitOut
                    table={table}
                    session={session}
                  />
                </TabPanel>
                <TabPanel
                  value={status}
                  name='InactiveFinished'
                >
                  <div className={classes.inactiveContainer}>
                    <CircularProgress />
                  </div>
                </TabPanel>
              </GameStartTransition>
            </div>
          </Grid>
          <Grid item className={classes.rightCol}>
            <div
              className={classes.rightContainer}
              style={{ fontSize: isIpad ? '0.8rem' : '1rem' }}
            >
              <Grid
                container
                direction='column'
                justify='space-between'
                alignItems='stretch'
                style={{ height: '100%' }}
              >
                <Grid item>
                  <ClaimPanel
                    table={table}
                    makingClaim={makingClaim}
                    onMakingClaimClose={handleMakingClaimClose}
                    currentUser={currentUser}
                  />
                </Grid>
                <Grid item xs>
                  <Chat
                    threadId={getTableThreadId(session, table)}
                    recipients={getTableChatRecipients(currentUser, table, session, t)}
                    currentUser={currentUser}
                    sendTypingNotifications
                    theme='dark'
                    scrollKey={getClaimScrollKey(table)}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={clsx(classes.container, classes.toolbar)}>
        <Grid
          container
        >
          <Grid item>
            <Status
              session={session}
              table={table}
            />
          </Grid>
          <Grid item>
            <div style={{ marginLeft: '1rem' }}>
              <Actions
                currentUser={currentUser}
                session={session}
                table={table}
                setMakingClaim={setMakingClaim}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Game;
