import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StatusTimer from '../../../Components/StatusTimer.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  item: {
    marginRight: theme.spacing(1)
  },
  itemLabel: {
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    opacity: 0.7
  },
  itemText: {
    fontSize: '2rem'
  }
}));

const SessionStatus = (props) => {
  const { session } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction='row'
      justify='flex-start'
      alignItems='center'
      spacing={2}
      wrap='nowrap'
    >
      <Grid item>
        <div className={classes.itemLabel}>{t('round')}</div>
        <div className={classes.itemText}>{session.roundsCompleted + 1}/{session.numRounds}</div>
      </Grid>
      <Grid item>
        <div className={classes.itemLabel}>{t('time-limit')}</div>
        <div className={classes.itemText}>{session.roundTimeLimit}min</div>
      </Grid>
      <Grid item>
        <div className={classes.itemLabel}>{t('time-left')}</div>
        <div className={classes.itemText}>
          <StatusTimer
            timeLastRoundStarted={session.timeLastRoundStarted}
            roundTimeLimit={session.roundTimeLimit}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default SessionStatus;
