import React, { useState, useEffect, useCallback } from 'react';
import { useFullscreen } from '../hooks/';

const PageReloadContext = React.createContext();

const PageReloadProvider = (props) => {
  const { children } = props;
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const [isReloading, setIsReloading] = useState(false);

  const reload = useCallback(() => {
    // reloading while in full screen leads to buggy behaviour in iOS
    if (isFullscreen) {
      toggleFullscreen();
    }

    setIsReloading(true);
  }, [setIsReloading, isFullscreen, toggleFullscreen]);

  useEffect(() => {
    if (isReloading) {
      window.location.reload();
    }
  }, [isReloading]);

  return (
    <PageReloadContext.Provider
      value={{
        reload,
        isReloading
      }}
    >
      {children}
    </PageReloadContext.Provider>
  );
};

const PageReloadConsumer = PageReloadContext.Consumer;

export { PageReloadProvider, PageReloadConsumer };
export default PageReloadContext;
