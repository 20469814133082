import gql from 'graphql-tag';
import { tableFragments } from '../fragments';

const tableMutations = {
  makeBid: gql`
    mutation (
      $tableId: ID!,
      $value: BidValueScalar!,
      $level: ContractLevelScalar,
      $denomination: ContractDenominationEnum,
      $alerted: Boolean,
      $alertReason: String
    ) {
      makeBid(
        tableId: $tableId,
        value: $value,
        level: $level,
        denomination: $denomination,
        alerted: $alerted,
        alertReason: $alertReason
      ) {
        ...AuctionEvent
      }
    }
    ${tableFragments.auctionEvent}
  `,
  playCard: gql`
    mutation (
      $tableId: ID!,
      $rank: CardRankScalar!,
      $suit: CardSuitScalar!,
      $autoplayed: Boolean
    ) {
      playCard(
        tableId: $tableId,
        rank: $rank,
        suit: $suit,
        autoplayed: $autoplayed
      ) {
        ...PlayEvent
      }
    }
    ${tableFragments.playEvent}
  `,
  setDirectorCalled: gql`
    mutation (
      $tableId: ID!,
      $directorCalled: Boolean!
    ) {
      setDirectorCalled(
        tableId: $tableId,
        directorCalled: $directorCalled
      )
    }`,
  setDirectorAtTable: gql`
    mutation (
      $tableId: ID!,
      $directorAtTable: Boolean!
    ) {
      setDirectorAtTable(
        tableId: $tableId,
        directorAtTable: $directorAtTable
      )
    }`,
  makeClaim: gql`
    mutation (
      $tableId: ID!,
      $tricksClaimed: Int!,
      $reason: String!
    ) {
      makeClaim(
        tableId: $tableId,
        tricksClaimed: $tricksClaimed,
        reason: $reason
      ) {
        ...Claim
      }
    }
    ${tableFragments.claim}
  `,
  respondToClaim: gql`
    mutation (
      $tableId: ID!,
      $value: Boolean!
    ) {
      respondToClaim(
        tableId: $tableId,
        value: $value
      ) {
        ...Claim
      }
    }
    ${tableFragments.claim}
  `,
  resolveClaim: gql`
    mutation (
      $tableId: ID!,
      $tricksAwarded: Int!
    ) {
      resolveClaim(
        tableId: $tableId,
        tricksAwarded: $tricksAwarded
      ) {
        ...Claim
      }
    }
    ${tableFragments.claim}
  `
};

export default tableMutations;
