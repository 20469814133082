import React, { useCallback, useState } from 'react';
import ActionButton from './ActionButton.js';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import SettingsModal from '../../UserSettings/SettingsModal.js';
import { useTranslation } from 'react-i18next';

const SettingsButton = (props) => {
  const { currentUser } = props;
  const { t } = useTranslation();
  const [showingSettings, setShowingSettings] = useState(false);

  const handleShowSettingsModalClick = useCallback(() => {
    setShowingSettings(true);
  }, [setShowingSettings]);

  const handleSettingsModalClose = useCallback(() => {
    setShowingSettings(false);
  }, [setShowingSettings]);

  return (
    <>
      <ActionButton
        label={t('settings')}
        icon={<SettingsIcon style={{ fontSize: 30 }} />}
        onClick={handleShowSettingsModalClick}
      />
      <SettingsModal
        open={showingSettings}
        onClose={handleSettingsModalClose}
        currentUser={currentUser}
      />
    </>
  );
};

export default SettingsButton;
