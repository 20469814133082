import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { wasClaimRejected } from '../../../helpers/game.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  resolutionContainer: {
    paddingTop: '2em'
  },
  heading: {
    textAlign: 'center',
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: '#666',
    textTransform: 'uppercase',
    textShadow: '0 1px #fff'
  },
  resolution: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '3em',
    fontSize: '2em',
    fontWeight: 'bold'
  }
}));

const ClaimResolutionStatus = (props) => {
  const { table } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  let message = null;

  if (table.claim && table.claim.responses.length > 0) {
    if (wasClaimRejected(table.claim)) {
      if (table.directorCallStatus === 'DirectorAtTable') {
        message = 'director-is-here-to-resolve-this-claim';
      } else {
        message = 'claim-rejected-waiting-for-director';
      }
    } else if (table.claim.responses.length === 2) {
      message = 'claim-accepted';
    }
  }

  if (!message) {
    return null;
  }

  return (
    <div className={classes.resolutionContainer}>
      <div className={classes.heading}>{t('resolution')}</div>
      <div className={classes.resolution}>
        {t(message)}
      </div>
    </div>
  );
};

export default ClaimResolutionStatus;
