import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Backdrop, Typography } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/PauseCircleOutline';
import { wasClaimRejected } from '../../../helpers/game.js';
import { useTranslation } from 'react-i18next';
import SpinnerButton from '../../../Components/SpinnerButton.js';

const useStyles = makeStyles(theme => ({
  container: {
    left: '1rem',
    top: '8rem',
    right: '40rem',
    bottom: '1rem',
    background: 'rgba(0,0,0,0.7)',
    textShadow: '0 0.3rem 1rem rgba(0,0,0,0.75)',
    borderRadius: '0.5rem',
    minWidth: 'calc(72% - 1.4rem)'
  },
  icon: {
    fontSize: '20rem',
    color: '#fff'
  }
}));

const DirectorCalledOverlay = (props) => {
  const {
    directorCallStatus,
    directorCallerId,
    currentUser,
    claim,
    onCancelClick,
    loading
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (directorCallStatus !== 'DirectorCalled') {
    return null;
  }

  // only allow players to cancel the overlay if it didn't appear in response
  // to a rejected claim
  let isCallCancellable = true;

  if (claim && wasClaimRejected(claim)) {
    isCallCancellable = false;
  }

  return (
    <Backdrop className={classes.container} open>
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
      >
        <Grid item>
          <PauseIcon className={classes.icon} />
        </Grid>
        <Grid item>
          <Typography variant='h6'>{t('play-is-paused-waiting-for-director')}</Typography>
        </Grid>
        {
          isCallCancellable && (
            <Grid item>
              <br />
              {
                currentUser.id === directorCallerId
                  ? <SpinnerButton
                    variant='contained'
                    onClick={onCancelClick}
                    loading={loading}
                  >
                    {t('cancel-director-call')}
                    </SpinnerButton>
                  : <>
                    {t('only-the-player-who-called-can-cancel')}
                    </>
              }
            </Grid>
          )
        }
      </Grid>
    </Backdrop>
  );
};

export default DirectorCalledOverlay;
