import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import {
  getRankingTableData
} from '../../../helpers/results.js';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const ROW_HEIGHT = 50;

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0 !important',
    borderTop: '1px solid #ccc'
  },
  tableBody: {
    position: 'relative'
  },
  // these restylings of default table styles are
  // necessary because of the row animation - without
  // these, position: absolute wrecks any other styles
  row: {
    height: ROW_HEIGHT,
    display: 'flex',
    width: '100%'
  },
  cell: {
    display: 'block',
    minWidth: 75
  },
  nameCell: {
    flexGrow: 1
  }
}));

const RankingTable = (props) => {
  const { heading, rankings, scoringMethod, linkPlayerNames, linkBase } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const rows = getRankingTableData(rankings, scoringMethod);
  const transitions = useTransition(rows,
    {
      from: (item, i) => ({ position: 'absolute', opacity: 0, top: ROW_HEIGHT * i }),
      leave: { height: 0, opacity: 0 },
      enter: (item, i) => ({ top: ROW_HEIGHT * i, opacity: 1, height: ROW_HEIGHT }),
      update: (item, i) => ({ top: ROW_HEIGHT * i }),
      keys: row => row.pairId
    }
  );

  return (
    <Card>
      <CardHeader
        title={heading}
      />
      <CardContent className={classes.cardContent}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                className={classes.row}
              >
                <TableCell className={classes.cell}>
                  {t('place')}
                </TableCell>
                <TableCell className={clsx(classes.cell, classes.nameCell)}>
                  {t('players')}
                </TableCell>
                <TableCell className={classes.cell}>
                  {t('score')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              className={classes.tableBody}
              style={{
                height: ROW_HEIGHT * rows.length
              }}
            >
              {
                transitions((styles, row) => (
                  row &&
                    <animated.tr
                      key={row.pairId}
                      style={styles}
                      className={classes.row}
                    >
                      <TableCell className={classes.cell}>
                        {row.place}
                      </TableCell>
                      <TableCell className={clsx(classes.cell, classes.nameCell)}>
                        {
                          linkPlayerNames
                            ? (
                              <Link to={linkBase + row.pairId}>
                                {row.players}
                              </Link>
                            )
                            : row.players
                        }
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.score}
                      </TableCell>
                    </animated.tr>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

RankingTable.propTypes = {
  heading: PropTypes.string.isRequired,
  rankings: PropTypes.array.isRequired,
  scoringMethod: PropTypes.string.isRequired,
  linkPlayerNames: PropTypes.bool,
  linkBase: PropTypes.string
};

export default RankingTable;
