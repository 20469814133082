import _ from 'lodash';
import { getSourceAppName } from './url.js';

export const getPairNames = (pair) => {
  if (!pair) { return '(names pending)'; }

  const user1FullName = pair.user1.fullName.trim() || '(name pending)';
  const user2FullName = pair.user2.fullName.trim() || '(name pending)';

  return `${user1FullName} & ${user2FullName}`;
};

export const getRankingTableData = (rankings, scoringMethod) => {
  return rankings.map(ranking => {
    let score;

    if (typeof ranking.score === 'number') {
      score = _.round(ranking.score, 2) +
        // when scoring with matchpoints, the ranking is a %
        // with IMPs, we just show the imps
        (scoringMethod === 'MatchPoints' ? '%' : '');
    } else {
      score = '-';
    }

    return {
      place: ranking.place,
      pairId: ranking.pair.id,
      players: getPairNames(ranking.pair),
      score
    };
  });
};

const getShortName = (user) => {
  if (user.firstName && user.lastName) {
    return user.firstName.substring(0, 1) + ' ' + user.lastName;
  } else if (user.fullName) {
    // BM doesn't give us separate first names and last names,
    // just use the fullName as passed in
    return user.fullName;
  } else {
    // offline pairs where we don't have a name yet
    return '(name pending)';
  }
};

export const getShortPairNames = (pair) => {
  return getShortName(pair.user1) +
    ' & ' +
    getShortName(pair.user2);
};

export const getContractResult = ({ contract, declarer, nsTricks, ewTricks }) => {
  if (!contract) {
    return '';
  }

  let declarerTricks;

  if (declarer === 'N' || declarer === 'S') {
    declarerTricks = nsTricks;
  } else {
    declarerTricks = ewTricks;
  }

  const oddTricks = declarerTricks - 6;
  const diff = oddTricks - parseInt(contract.level, 10);

  let result;

  if (diff > 0) {
    result = '+' + diff;
  } else if (diff < 0) {
    result = String(diff);
  } else {
    result = '=';
  }

  return result;
};

const getPairScore = (table, position) => {
  if (position !== 'ns' && position !== 'ew') {
    throw new Error('unexpected position passed in');
  }

  if (table[position + 'ArtificialScore']) {
    return table[position + 'ArtificialScore'] + '%';
  } else if (table[position + 'AssignedScore']) {
    return table[position + 'AssignedScore'];
  }

  return table[position + 'Score'];
};

const getNormalOrAssignedScore = (table, position) => {
  if (position !== 'ns' && position !== 'ew') {
    throw new Error('unexpected position passed in');
  }

  if (table[position + 'ArtificialScore']) {
    return null;
  }

  return table[position + 'AssignedScore'] || table[position + 'Score'];
};

export const sortTablesByScore = (tables) => {
  tables.sort((a, b) => {
    const aNsScore = getNormalOrAssignedScore(a, 'ns');
    const bNsScore = getNormalOrAssignedScore(b, 'ns');

    // if both tables have numeric scores of the same type, order by that
    if (typeof aNsScore === 'number' && typeof bNsScore === 'number') {
      return bNsScore - aNsScore;
    }
    if (typeof a.nsArtificialScore === 'number' && typeof b.nsArtificialScore === 'number') {
      return b.nsArtificialScore - a.nsArtificialScore;
    }

    // order normal / assigned scores over anything else
    if (typeof aNsScore === 'number') {
      return -1;
    } else if (typeof bNsScore === 'number') {
      return 1;
    }

    // order artificial scores over no scores
    if (typeof a.nsArtificialScore === 'number') {
      return -1;
    } else if (typeof b.nsArtificialScore === 'number') {
      return 1;
    }

    // no scores can do whatever they damn please
    return 0;
  });
};

const formatMPs = (mps, sourceAppName, scoringMethod) => {
  if (typeof mps !== 'number') {
    return '-';
  }

  // BM sends us MPs as percentages
  if (sourceAppName === 'bridgemate' && scoringMethod === 'MatchPoints') {
    return mps + '%';
  } else {
    return mps;
  }
};

export const getTravellerTableData = (boardNumber, tables, sourceAppName, scoringMethod) => {
  const tablesForThisBoard = tables.filter(table => table.boardNumber === boardNumber);

  sortTablesByScore(tablesForThisBoard);

  return tablesForThisBoard.map(table => ({
    tableNumber: table.tableNumber,
    boardNumber: table.boardNumber,
    nsPairId: table.nsPair.id,
    nsPair: getShortPairNames(table.nsPair),
    ewPairId: table.ewPair.id,
    ewPair: getShortPairNames(table.ewPair),
    bid: table.contract ? table.contract.value : '',
    by: table.declarer,
    result: getContractResult(table),
    lead: table.leadPlay ? (table.leadPlay.rank + table.leadPlay.suit) : '',
    nsScore: getPairScore(table, 'ns'),
    ewScore: getPairScore(table, 'ew'),
    nsMPs: formatMPs(table.nsMPs, sourceAppName, scoringMethod),
    ewMPs: formatMPs(table.ewMPs, sourceAppName, scoringMethod),
    notPlayed: table.notPlayed
  }));
};

export const getCurrentlyActiveBoard = (tables, boards) => {
  let latestActiveBoardNumber = 0;
  tables.forEach(table => {
    if (table.status !== 'InactiveFinished') {
      latestActiveBoardNumber = Math.max(latestActiveBoardNumber, table.boardNumber);
    }
  });

  return boards.find(board => board.number === latestActiveBoardNumber);
};

export const getScorecardsTableData = (pairId, tables, boards, sourceAppName, scoringMethod) => {
  const rows = [];

  tables.forEach(table => {
    let pairPosition;

    if (table.nsPair.id === pairId) {
      pairPosition = 'ns';
    } else if (table.ewPair.id === pairId) {
      pairPosition = 'ew';
    } else {
      return;
    }

    let versus;
    let mps;
    let versusId;

    const score = getPairScore(table, pairPosition);

    if (pairPosition === 'ns') {
      versusId = table.ewPair.id;
      versus = getPairNames(table.ewPair);
      mps = table.nsMPs;
    } else {
      versusId = table.nsPair.id;
      versus = getPairNames(table.nsPair);
      mps = table.ewMPs;
    }

    rows.push({
      board: table.boardNumber + 1,
      bid: table.contract ? table.contract.value : '',
      by: table.declarer,
      result: getContractResult(table),
      versus,
      versusId,
      score,
      mps: formatMPs(mps, sourceAppName, scoringMethod)
    });
  });

  rows.sort((a, b) => a.board - b.board);

  return rows;
};

const VALID_PCT_VALUES = [40, 50, 60];

export const parseAdjustedScores = (ns, ew) => {
  const intRegex = /^-?\d+$/;
  const percentRegex = /^\d+%$/;

  const nsInt = parseInt(ns, 10);
  let ewInt = parseInt(ew, 10);

  // artificial score === percentages
  let isArtificialScore;

  if (intRegex.test(ns)) {
    if (ew && !intRegex.test(ew)) {
      throw new Error('Please enter one or two numerical values');
    }

    if (ns && ew && nsInt !== -ewInt) {
      throw new Error('The adjusted score for EW should be the inverse of NS (you can also leave it blank)');
    }

    isArtificialScore = false;
    ewInt = -nsInt;
  } else if (percentRegex.test(ns) && percentRegex.test(ew)) {
    if (VALID_PCT_VALUES.indexOf(nsInt) === -1 || VALID_PCT_VALUES.indexOf(ewInt) === -1) {
      throw new Error('Only percentage values of 40, 50 and 60 are supported');
    }

    isArtificialScore = true;
  } else {
    throw new Error('Please enter either one numerical value or two percentage values');
  }

  return { isArtificialScore, ns: nsInt, ew: ewInt };
};

export const getSessionFinishedHeading = (session, rankingsSessionFinished) => {
  const sourceAppName = getSourceAppName(window.location.hostname);

  if (session.status === 'InProgress' ||
    (sourceAppName === 'bridgemate' && !rankingsSessionFinished)) {
    return 'tables-still-in-play';
  } else {
    return 'game-finished';
  }
};

export const getSessionFinishedMessage = (session, rankingsSessionFinished) => {
  const sourceAppName = getSourceAppName(window.location.hostname);
  // const appDisplayName = getAppDisplayName(window.location.hostname);

  if (session.status === 'InProgress') {
    return 'these-results-will-update-as-other-tables-finish';
  }

  const concludedMessage = 'the-game-has-now-concluded-thank-you-for-playing';

  if (sourceAppName === 'bridgemate') {
    if (rankingsSessionFinished) {
      return concludedMessage;
    } else {
      return 'the-online-section-has-concluded';
    }
  } else {
    return concludedMessage;
  }
};
