import { useEffect, useState, useCallback, useContext } from 'react';
import PPSnackbarContext from '../Components/PPSnackbar/PPSnackbarContext.js';

const getPrefixedProp = (obj, name) => {
  const capped = name.charAt(0).toUpperCase() + name.slice(1);

  return obj[name] ||
    obj['webkit' + capped] || // safari
    obj['ms' + capped]; // IE11
};

// hook to help with managing full screen-ness

const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);
  const { openSnackbar } = useContext(PPSnackbarContext);

  // make sure isFullscreen is consistent with the actual fullscreen-ness as reported by the browser
  // e.g. if the user leaves full screen using escape
  useEffect(() => {
    const interval = setInterval(() => {
      const fullscreenEl = getPrefixedProp(document, 'fullscreenElement');

      if (fullscreenEl && !isFullscreen) {
        setIsFullscreen(true);
      } else if (!fullscreenEl && isFullscreen) {
        setIsFullscreen(false);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [isFullscreen, setIsFullscreen]);

  const toggleFullscreen = useCallback(() => {
    if (!getPrefixedProp(document, 'fullscreenEnabled')) {
      openSnackbar('error', 'This browser does not support fullscreen', 'error');
      return false;
    }

    const elem = document.documentElement;

    if (getPrefixedProp(document, 'fullscreenElement')) {
      const exitFullscreen = getPrefixedProp(document, 'exitFullscreen');
      exitFullscreen && exitFullscreen.call(document);
    } else {
      const requestFullscreen = getPrefixedProp(elem, 'requestFullscreen');
      requestFullscreen && requestFullscreen.call(elem);
    }
  }, [openSnackbar]);

  return { isFullscreen, toggleFullscreen };
};

export default useFullscreen;
