import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Player from '../../Players/Player.js';
import { auctionHelpers } from 'shared-helpers';
import { getPlayerBySeat } from '../../../helpers/game.js';
import { useTranslation } from 'react-i18next';
const { convertSeatCodeToFullWord } = auctionHelpers;

const useStyles = makeStyles(theme => ({
  player: {
    width: '20em',
    margin: '0 auto',
    fontSize: '1.1em'
  },
  paper: {
    position: 'relative',
    fontSize: '1.6em',
    padding: '0.8em 1.2em',
    margin: '1.5em 0 1em 0',
    '@media all and (max-width:1194px)': {
      padding: '0.5em 1em',
      margin: '1em 0'
    },
    '&:before': {
      content: '""',
      display: 'block',
      width: '1.6em',
      height: '1.6em',
      background: 'transparent url("/img/components/Claim/triangle.svg") no-repeat',
      backgroundSize: '100%',
      position: 'absolute',
      left: '4.4em',
      top: '-0.8em',
      '@media all and (max-width:1194px)': {
        display: 'none'
      }
    }
  }
}));

const ClaimSpeechBubble = (props) => {
  const { table, children } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  // if there's no ongoing claim, show current player -
  // they're looking at the form about to make a claim
  const claimantSeat = table.claim
    ? table.claim.seat
    : table.viewpoint;

  const claimant = getPlayerBySeat(claimantSeat, table);

  return (
    <>
      <div className={classes.player}>
        <Player
          textStatus={t(convertSeatCodeToFullWord(claimantSeat).toLowerCase())}
          {...claimant}
        />
      </div>
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </>
  );
};

export default ClaimSpeechBubble;
