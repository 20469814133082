import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Deal from '../../../Components/Deal/Deal.js';
import MakeableContracts from '../../../Components/Results/MakeableContracts.js';
import BlockSubeading from '../../../Components/BlockSubheading.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  deal: {
    borderTop: '1px solid #ccc'
  },
  makeableContracts: {
    background: '#f1f1f1',
    padding: '1.5rem !important'
  },
  makeableContractsMessage: {
    marginTop: '2rem'
  },
  heading: {
    marginTop: 0
  }
}));

const Hands = (props) => {
  const { boards } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleTravellerClick = (boardNumber) => {
    history.push('/director/results/travellers/' + boardNumber);
  };

  return (
    <Grid
      container
      justify='center'
      spacing={2}
    >
      {boards.map(board => (
        <Grid item key={board.id}>
          <Card>
            <CardHeader
              title={t('board') + ' ' + (board.number + 1)}
              action={
                <Button variant='outlined' size='small' onClick={() => handleTravellerClick(board.number + 1)}>
                  {t('traveller')}
                </Button>
              }
            />
            <CardContent className={classes.deal}>
              <Deal
                dealer={board.dealer}
                viewpoint='S'
                vulnerable={board.vulnerable}
                cards={board.deal}
              />
              {
                board.makeableContractsStatus === 'Pending' && (
                  <div className={classes.makeableContractsMessage}>
                    {t('makeable-contracts-are-still-being-calculated-for-board')}
                  </div>
                )
              }
            </CardContent>
            {
              board.makeableContracts && (
                <CardContent className={classes.makeableContracts}>
                  <BlockSubeading cssClasses={[classes.heading]}>{t('makeable-contracts')}:</BlockSubeading>
                  <MakeableContracts
                    makeableContracts={board.makeableContracts}
                  />
                </CardContent>
              )
            }
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Hands;
