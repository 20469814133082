import { useState, useCallback } from 'react';

const useLocalSessionStarting = (session) => {
  const [localStatus, setLocalStatus] = useState(session.status);

  let localSession;

  if (localStatus !== session.status) {
    localSession = {
      ...session,
      status: localStatus
    };
  } else {
    localSession = session;
  }

  const handleStartingChange = useCallback((starting) => {
    setLocalStatus(starting ? 'Starting' : 'NotStarted');
  }, [setLocalStatus]);

  return {
    localSession,
    onStartingChange: handleStartingChange
  };
};

export default useLocalSessionStarting;
