import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Tabs, Tab, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TabPanel from '../../../Components/TabPanel.js';
import {
  Travellers as TravellersSQ,
  Scorecards as ScorecardsSQ,
  Rankings as RankingsSQ
} from '../../../Components/SubscriptionQueries';
import {
  Boards as BoardsQ,
  Pairs as PairsQ
} from '../../../Components/Queries';
import { useCloseWarning } from '../../../hooks/';
import Rankings from '../../../Components/Results/Rankings/Rankings.js';
import Travellers from './Travellers.js';
import Hands from './Hands.js';
import Scorecards from './Scorecards.js';
// import PublishResultsButton from './PublishResultsButton.js';
import DownloadUsebioButton from './DownloadUsebioButton.js';
import MiddleMessage from '../../../Components/MiddleMessage.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: '2rem',
    top: '9rem',
    right: '2rem',
    bottom: '2rem',
    background: theme.palette.block.content.background,
    color: '#333',
    borderRadius: '0.5rem',
    overflow: 'hidden'
  },
  header: {
    background: '#fff',
    height: '6rem',
    padding: '0 2rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '2rem'
  },
  tabs: {
    background: 'rgba(255,255,255,0.5)',
    marginTop: '0.1rem'
  },
  content: {
    position: 'absolute',
    top: '11rem',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '2rem',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  alertContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  alert: {
    background: 'rgb(232, 244, 253)',
    color: 'rgb(13, 60, 97)',
    padding: '2rem',
    border: '0.2rem solid #fff'
  }
}));

const Results = (props) => {
  const { session, match } = props;
  const { view = 'ranking', id } = match.params;
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const playStarted = session && session.status !== 'NotStarted';

  // boards.sort((a, b) => a.number - b.number);

  const handleChange = (_, val) => {
    history.push('/director/results/' + val);
  };

  const sessionFinished = session.status === 'Finished';

  useCloseWarning(!sessionFinished);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid
          container
          alignItems='center'
        >
          <Grid item xs>
            {
              sessionFinished
                ? <>{session.name} {t('results')}</>
                : <>{t('live-results')}</>
            }
          </Grid>
          <Grid item>
            {
              /*
              <PublishResultsButton
                session={session}
              />
              */
            }
            {
              sessionFinished && (
                <DownloadUsebioButton />
              )
            }
          </Grid>
        </Grid>
      </div>
      <div className={classes.tabs}>
        <Tabs
          onChange={handleChange}
          value={view}
          indicatorColor='primary'
          centered
        >
          <Tab label={t('ranking')} value='ranking' />
          <Tab label={t('travellers')} value='travellers' />
          <Tab label={t('scorecards')} value='scorecards' />
          <Tab label={t('hands')} value='hands' />
        </Tabs>
      </div>
      <div className={classes.content}>
        {
          playStarted
            ?
            <BoardsQ>
              {(boards) => (
                <>
                  <TabPanel value={view} name='ranking'>
                    <RankingsSQ>
                      {({ rankings }) => (
                        <Rankings
                          rankings={rankings}
                          winnerType={session.winnerType}
                          linkPlayerNames={true}
                          linkBase='/director/results/scorecards/'
                          scoringMethod={session.scoringMethod}
                        />
                      )}
                    </RankingsSQ>
                  </TabPanel>
                  <TabPanel value={view} name='travellers'>
                    <TravellersSQ boardNumber={parseInt(id) - 1}>
                      {({ tables }) => (
                        <Travellers
                          tables={tables}
                          boards={boards}
                          boardNumber={parseInt(id)}
                          scoringMethod={session.scoringMethod}
                        />
                      )}
                    </TravellersSQ>
                  </TabPanel>
                  <TabPanel value={view} name='scorecards'>
                    <PairsQ>
                      {pairs => (
                        <ScorecardsSQ pairId={id}>
                          {({ tables }) => (
                            <Scorecards
                              pairs={pairs}
                              tables={tables}
                              boards={boards}
                              pairId={id}
                              scoringMethod={session.scoringMethod}
                            />
                          )}
                        </ScorecardsSQ>
                      )}
                    </PairsQ>
                  </TabPanel>
                  <TabPanel value={view} name='hands'>
                    <Hands
                      boards={boards}
                    />
                  </TabPanel>
                </>
              )}
            </BoardsQ>
            : <MiddleMessage>{t('results-will-be-available-here')}</MiddleMessage>
        }
      </div>
    </div>
  );
};

Results.propTypes = {
  match: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired
};

export default Results;
