import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  btnContainer: {
    position: 'relative'
  },
  spinner: {
    color: theme.buttonSpinner.color,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: props => -props.spinnerSize / 2,
    marginLeft: props => -props.spinnerSize / 2
  }
}));

const SpinnerButton = (props) => {
  const {
    disabled,
    loading,
    children,
    spinnerSize = 24,
    isIconButton,
    ...rest
  } = props;
  const classes = useStyles({ spinnerSize });

  const ButtonComponent = isIconButton ? IconButton : Button;

  return (
    <div className={classes.btnContainer}>
      <ButtonComponent
        disabled={disabled || loading}
        {...rest}
      >
        {children}
      </ButtonComponent>
      {loading && (
        <CircularProgress
          size={spinnerSize}
          className={classes.spinner}
        />
      )}
    </div>
  );
};

SpinnerButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isIconButton: PropTypes.bool,
  spinnerSize: PropTypes.number
};

export default SpinnerButton;
