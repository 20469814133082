import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import ClubMembers from './ClubMembers.js';
import Pairs from './Pairs.js';
import GameDetails from './GameDetails.js';
import Chat from '../../../Components/Chat/Chat.js';
import Block from '../../../Components/Block.js';
import clsx from 'clsx';
import { Tabs, Tab } from '@material-ui/core';
import { getPlayerLobbyChatRecipients } from '../../../helpers/chat.js';
import TabPanel from '../../../Components/TabPanel.js';
import { useCloseWarning } from '../../../hooks/';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  containerContent: {
    left: 0,
    top: '7rem',
    right: '42rem',
    bottom: 0,
    '@media all and (max-width:1194px)': {
      right: '30rem'
    }
  },
  containerSession: {
    left: 0,
    top: 0,
    bottom: 0,
    width: '50%',
    padding: '2rem 1rem 2rem 2rem'
  },
  containerPairs: {
    left: '50%',
    top: 0,
    bottom: 0,
    right: 0,
    padding: '2rem 2rem 2rem 1rem'
  },
  tabs: {
    background: 'rgba(255,255,255,0.5)'
  },
  containerPairsWrapper: {
    padding: '0 2rem 1rem 2rem'
  },
  containerChat: {
    right: '2rem',
    top: '9rem',
    bottom: '2rem',
    width: '40rem',
    fontSize: '1rem',
    '@media all and (max-width:1194px)': {
      width: '28rem'
    }
  }
}));

const Lobby = (props) => {
  const { users, currentUser, pairs, session } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useCloseWarning();

  const [playersFilter, setPlayersFilter] = useState('awaitingPartner');
  const handleFilterChange = (event, newValue) => {
    setPlayersFilter(newValue);
  };

  // when the user gets added to a pair, switch to showing "confirmed pairs"
  // when the user gets removed from a pair, return to "awaiting partner"
  useEffect(() => {
    if (currentUser.gameStatus === 'InPair') {
      setPlayersFilter('confirmedPairs');
    } else {
      setPlayersFilter('awaitingPartner');
    }
  }, [currentUser.gameStatus]);

  let blockIcon = 'warning';
  let blockColour = 'orange';
  let blockTooltip = 'you-need-to-choose-your-partner';

  if (currentUser.gameStatus === 'InPair') {
    blockIcon = 'tick';
    blockColour = 'green';
    blockTooltip = 'you-have-a-partner-for-this-game';
  }

  if (session.status === 'Starting') {
    blockIcon = 'lock';
    blockColour = 'grey';
    blockTooltip = 'this-game-is-about-to-start-so-changes-to-partners-not-allowed';
  }

  return (
    <>
      <div className={clsx(classes.container, classes.containerContent)}>
        <div className={clsx(classes.container, classes.containerSession)}>
          <GameDetails
            session={session}
            currentUser={currentUser}
            pairs={pairs}
          />
        </div>
        <div className={clsx(classes.container, classes.containerPairs)}>
          <Block
            heading={t('players-in-this-game')}
            icon={blockIcon}
            iconColor={blockColour}
            iconTooltip={t(blockTooltip)}
            noPadding
          >
            <div className={classes.tabs}>
              <Tabs
                value={playersFilter}
                onChange={handleFilterChange}
                indicatorColor='primary'
                centered
              >
                <Tab label={t('awaiting-partner')} value='awaitingPartner' />
                <Tab label={t('confirmed-pairs')} value='confirmedPairs' />
              </Tabs>
            </div>

            <br />

            <div className={classes.containerPairsWrapper}>

              <TabPanel value={playersFilter} name='awaitingPartner'>
                <ClubMembers
                  users={users}
                  currentUser={currentUser}
                  canMarkAsPartner={session.status === 'NotStarted'}
                />
              </TabPanel>

              <TabPanel value={playersFilter} name='confirmedPairs'>
                <Pairs
                  pairs={pairs}
                />
              </TabPanel>

            </div>

          </Block>
        </div>
      </div>
      <div className={clsx(classes.container, classes.containerChat)}>
        <Chat
          threadId={session.id}
          currentUser={currentUser}
          sendTypingNotifications
          headerText={t('messages-to-lobby')}
          placeholderText={t('send-a-message-to-everyone-in-lobby')}
          recipients={getPlayerLobbyChatRecipients(currentUser, pairs, session, t)}
        />
      </div>
    </>
  );
};

export default Lobby;
