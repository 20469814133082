import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  li: {
    fontSize: '1.2em',
    lineHeight: '1.4em',
    float: 'left',
    marginLeft: '0.5em'
  }
};

const DealDirectionCard = (props) => {
  const { classes, card } = props;
  return (
    <li className={classes.li}>
      {card.rank}
    </li>
  );
}

DealDirectionCard.propTypes = {
  classes: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired
};

export default withStyles(styles)(DealDirectionCard);
