import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, Link } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import DirectorHeader from './DirectorHeader.js';
import PlayerHeader from './PlayerHeader.js';
import SettingsButton from '../UserSettings/SettingsButton.js';
import RefreshButton from './RefreshButton.js';
import FullscreenButton from './FullscreenButton.js';
import SystemDescriptionButton from './SystemDescriptionButton.js';
import { getSourceAppDisplayName } from '../../helpers/url.js';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'fixed',
    background: theme.header.background,
    left: 0,
    top: 0,
    right: 0,
    height: '7rem',
    display: 'flex',
    zIndex: 1
  },
  logo: {
    display: 'block',
    width: '18rem',
    height: '7rem',
    background: `url("/img/${theme.logo.image}.svg") no-repeat center center`,
    backgroundColor: theme.logo.background,
    marginLeft: theme.logo.marginLeft,
    backgroundSize: 'contain',
    overflow: 'hidden',
    textIndent: '-100rem',
    marginRight: '2rem'
  },
  bmLogo: {
    color: 'white'
  }
}));

const Nav = (props) => {
  const {
    session,
    currentUser,
    children
  } = props;
  const classes = useStyles();
  const location = useLocation();

  const isDirector = currentUser && currentUser.type === 'Director';
  const isPlayer = currentUser && currentUser.type === 'Player';

  let showNav = true;

  // hide nav bar when viewing a game:
  // for players any session that's in progress doesn't need the nav
  if ((isPlayer && session.status === 'InProgress' && currentUser.gameStatus !== 'SessionFinished') ||
    // for directors, only hide the nav when they've joined a table
    (isDirector && location.pathname.match(/^\/director\/table/))) {
    showNav = false;
  }

  return (
    <>
      {showNav && (
        <div className={classes.headerContainer}>
          <Grid
            container
            justify='center'
            alignItems='center'
          >
            <Grid item>
              <Link to='/' className={classes.logo}>{getSourceAppDisplayName()}</Link>
            </Grid>
            <Grid item xs>
              {
                isDirector && (
                  <DirectorHeader
                    session={session}
                  />
                )
              }
              {
                isPlayer && (
                  <PlayerHeader
                    session={session}
                  />
                )
              }
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems='center'
              wrap='nowrap'
              style={{ height: '100%' }}
            >
              {
                currentUser && (
                  <Grid item>
                    <SystemDescriptionButton
                      currentUser={currentUser}
                    />
                  </Grid>
                )
              }
              <Grid item>
                <RefreshButton />
              </Grid>
              <Grid item>
                <FullscreenButton />
              </Grid>
              <Grid item>
                <SettingsButton currentUser={currentUser} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {children}
    </>
  );
};

export default Nav;
