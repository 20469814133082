import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import DealDirectionCard from './DealDirectionCard.js';

const styles = {
  icon_c: {
    backgroundImage: 'url("/img/components/Deal/club.svg")'
  },
  icon_d: {
    backgroundImage: 'url("/img/components/Deal/diamond.svg")'
  },
  icon_h: {
    backgroundImage: 'url("/img/components/Deal/heart.svg")'
  },
  icon_s: {
    backgroundImage: 'url("/img/components/Deal/spade.svg")'
  },
  row: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
    backgroundSize: 'contain',
    paddingLeft: '1.8em',
    marginBottom: '0.1em',
    overflow: 'hidden',
    height: '1.8em'
  },
  ul: {
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    margin: 0
  }
};

const DealDirectionRow = (props) => {
  const { classes, suit, cards, ...other } = props;
  return (
    <li className={clsx(classes.row, classes.selectable, classes[`icon_${suit.toLowerCase()}`])}>
      <ul className={classes.ul}>
        {
          cards.map((card, index) => {
            return (
              <DealDirectionCard
                key={index}
                card={card}
                {...other}
              />
            );
          })
        }
      </ul>
    </li>
  );
};

DealDirectionRow.propTypes = {
  classes: PropTypes.object.isRequired,
  suit: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired
};

export default withStyles(styles)(DealDirectionRow);
