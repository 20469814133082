import React, { useContext } from 'react';
import ActionButton from './ActionButton.js';
import BugIcon from '@material-ui/icons/BugReportRounded';
import UserSettingsContext from '../../UserSettings/UserSettingsContext.js';
import { simplifyUserSettings } from '../../../helpers/feedback.js';

const BugButton = () => {
  const settings = useContext(UserSettingsContext);

  const handleClick = () => {
    // pass in extra info to getsitecontrol
    window.gsc('params', {
      useragent: navigator.userAgent,
      vendor: navigator.vendor,
      platform: navigator.platform,
      userSettings: simplifyUserSettings(settings)
    });
    // show the widget
    window.gsc('show', 53203);
  };
  return (
    <ActionButton
      label='Bug?'
      icon={<BugIcon style={{ fontSize: 30 }} />}
      onClick={handleClick}
    />
  );
};

export default BugButton;
