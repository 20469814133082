import gql from 'graphql-tag';
import userFragments from './user.js';

const systemDescriptionFragments = {
  fullSystemDescription: gql`
    fragment FullSystemDescription on SystemDescription {
      id
      user1 {
        ...DisplayUser
      }
      user2 {
        ...DisplayUser
      }
      text
      lastEdited
      lastEditedUserId
      currentlyEditingUserId
    }
    ${userFragments.displayUser}
  `
};

export default systemDescriptionFragments;
