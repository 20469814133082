import React from 'react';
import PropTypes from 'prop-types';
import Player from '../../Players/Player.js';
import Badge from '../../Players/Badge.js';
import { useTranslation } from 'react-i18next';

const PlayerForSeat = (props) => {
  const { player, textStatus, isVulnerable, isDealer } = props;
  const { t } = useTranslation();

  let dealerBadge = null;
  if (isDealer) {
    dealerBadge = (<Badge colour='yellow' text={t('d-dealer')} />);
  }

  const vulBadge = isVulnerable
    ? (<Badge colour='red' text={t('v-vulnerable')} />)
    : (<Badge colour='green' text={t('nv-not-vulnerable')} />);

  let directorBadge = null;

  if (player.type === 'Director') {
    directorBadge = (<Badge colour='blue' text={t('td-tournament-director')} />);
  }

  return (
    <Player
      {...player}
      noTruncate
      textStatus={t(textStatus.toLowerCase())}
      badges={
        <>
          {directorBadge}
          {dealerBadge}
          {vulBadge}
        </>
      }
    />
  );
};

PlayerForSeat.propTypes = {
  player: PropTypes.object.isRequired,
  textStatus: PropTypes.string.isRequired,
  isDealer: PropTypes.bool,
  isVulnerable: PropTypes.bool
};

export default PlayerForSeat;
