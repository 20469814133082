export const populatePairsWithUserData = ({ users, pairs }) => {
  const usersById = {};

  // build a cache of users by id so we don't iterate through them repeatedly
  users.forEach(user => usersById[user.id] = user);

  const newPairs = pairs.map(pair => {
    return {
      ...pair,
      // if we have a user with that id in our cache
      // update the pair with its properties
      user1: usersById[pair.user1.id] || pair.user1,
      user2: usersById[pair.user2.id] || pair.user2
    };
  });
  return newPairs;
};

export const addUserFromSubscription = (users, updatedUser) => {
  const newUsers = [];
  let found = false;

  users.forEach(user => {
    if (user.id === updatedUser.id) {
      newUsers.push(updatedUser);
      found = true;
    } else {
      newUsers.push(user);
    }
  });

  if (!found) {
    newUsers.push(updatedUser);
  }

  return newUsers;
};

export const hasUserTypeMismatch = (currentUser, currentSession) => {
  if ((currentUser.type === 'Director' && currentSession.directorId !== currentUser.id) ||
    (currentUser.type !== 'Director' && currentSession.directorId === currentUser.id)) {
    return true;
  }

  return false;
};

export const getInitials = (firstName, lastName) => {
  return (firstName ? firstName[0].toUpperCase() : '') +
    (lastName ? lastName[0].toUpperCase() : '');
};
