import gql from 'graphql-tag';

const userFragments = {
  displayUser: gql`
    fragment DisplayUser on User {
      id
      firstName
      lastName
      fullName
      avatarUrl
      type
    }
  `,
  fullUser: gql`
    fragment FullUser on User {
      id,
      firstName,
      lastName,
      avatarUrl,
      onlineStatus,
      gameStatus,
      lastSeen,
      type,
      fullName,
      language,
      fullName
    }
  `
};

export default userFragments;
