import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'absolute',
    zIndex: 1,
    width: '22.5rem',
    overflow: 'hidden',
    pointerEvents: 'none',
    transform: 'translate3d(0, 0, 0)',
    transitionTimingFunction: 'ease',
    transitionDuration: '250ms',
    transitionDelay: '0ms',
    transitionProperty: 'transform, width',
    border: '0.1rem solid rgba(0,0,0,0.25)',
    borderRadius: '0.5rem',
    fontSize: 0
  },
  img: {
    width: '100%'
  }
}));

const AnimatedCard = (props) => {

  // console.log("<AnimatedCard/> fired");

  const {
    card,
    animateSpeed,
    left,
    top,
    width,
    rotation,
    simplifiedCards,
    visible
  } = props;

  const classes = useStyles();

  const positionStyles = {
    transform: `translate3d(${left}px, ${top}px, 0) rotate(${rotation}deg)`,
    width: `${width}px`,
    transitionDuration: `${animateSpeed}ms, ${animateSpeed/2}ms`,
    display: visible ? 'block' : 'none'
  };

  let src = `/img/cards/${simplifiedCards ? 'simplified' : 'normal'}/${card.rank}${card.suit}.png`;

  return (
    <div
      className={classes.overlay}
      style={positionStyles}
    >
      <img
        className={classes.img}
        src={src}
        alt={`Card - ${card.rank}${card.suit}`}
      />
    </div>
  );
};

AnimatedCard.propTypes = {
  card: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  animateSpeed: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default AnimatedCard;
