/**
 * Custom hook does a few things:
 *
 * 1. Adds generalised error handling for mutations. We've got error
 * boundaries too, but they don't work for functional components.
 * (https://github.com/facebook/react/issues/14981)
 *
 * This focuses purely on error feedback - error logging will have already been
 * tackled by the onError handler in graphql/client.js
 *
 * 2. Add the ability to reset the mutation.
 * Specifically useful when calling a mutation multiple times
 *
 * This bit is adapted from https://github.com/apollographql/apollo-feature-requests/issues/170#issuecomment-687738532
 *
 * 3. Add memoization (see below)
 */
import { useContext, useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PPSnackbarContext from '../Components/PPSnackbar/PPSnackbarContext.js';
import { getMessage } from '../helpers/error.js';
import { getRegion } from '../helpers/session.js';
import { getMutationClient } from '../graphql/client.js';
import _ from 'lodash';

// takes arguments passed to a mutation and creates a memoization key
const resolver = (arg) => {
  return (arg && arg.variables) ?
    JSON.stringify(arg.variables) :
    arg;
};

const usePPMutation = (mutation, options = {}) => {
  const region = getRegion();

  const { openSnackbar } = useContext(PPSnackbarContext);
  // we store all the properties on useMutation's vanilla result inside `resultState`
  // and update them depending on what the mutation does
  const [resultState, setResultState] = useState({ called: false, loading: false });

  const reset = useCallback(() => {
    setResultState({});
  }, [setResultState]);

  const [fn, origResult] = useMutation(mutation, {
    ...options,
    // we now send mutations to a single region to prevent issues with global
    // tables causing data issues (duplicates etc), so set the client here
    client: getMutationClient(region),
    // overwrite the vanilla onCompleted and onError
    // so we can hook into them and update `result` vars
    onCompleted: (data) => {
      setResultState({
        data,
        loading: false,
        called: true
      });
      options.onCompleted && options.onCompleted(data);
    },
    onError: (error) => {
      setResultState({
        loading: false,
        called: true,
        error
      });
      // TODO don't take `t` from options, use it here
      // this is a temp fix until the i18next init PR is merged
      openSnackbar('error', getMessage(error, options.t), 'error');
      options.onError && options.onError(error);
    }
  });

  const wrappedFn = async (args) => {
    setResultState({ loading: true, called: true });
    return fn(args);
  };

  // options.memoize is used to prevent potentially destructive
  // mutations from being made more than once (e.g. playing a card)
  //
  // when the mutation is called with the same variables, it won't make a second request
  // and will instead return the same thing (e.g. a fulfilled promise)
  const memoizedFn = useCallback(
    options.memoize ?
      _.memoize(wrappedFn, resolver) :
      wrappedFn
  , [fn]);

  return [memoizedFn, {
    data: resultState.data,
    loading: resultState.loading,
    called: resultState.called,
    error: resultState.error,
    client: origResult.client,
    reset
  }];
};

export default usePPMutation;
