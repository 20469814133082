import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MakeClaim from './MakeClaim.js';
import ResolveClaim from './ResolveClaim.js';
import ClaimDescription from './ClaimDescription.js';
import ClaimResponses from './ClaimResponses.js';
import { wasClaimRejected } from '../../../helpers/game.js';
import ClaimResolutionStatus from './ClaimResolutionStatus.js';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.block.content.background,
    fontSize: '1em',
    color: '#222',
    marginBottom: '1em',
    borderRadius: '0.5rem',
    padding: '2em',
    overflow: 'hidden',
    '@media all and (max-width:1194px)': {
      padding: '1em'
    }
  },
  directorResolvingClaim: {
    padding: '1.6em',
    border: '0.4em solid transparent',
    borderColor: theme.palette.secondary.main
  }
}));

const ClaimPanel = (props) => {
  const { table, makingClaim, onMakingClaimClose, currentUser } = props;
  const classes = useStyles();

  if (makingClaim && !table.claim) {
    return (
      <div className={classes.container}>
        <MakeClaim
          table={table}
          onMakingClaimClose={onMakingClaimClose}
        />
      </div>
    );
  }

  if (table.status !== 'Play' || !table.claim) {
    return null;
  }

  const directorResolvingClaim = currentUser.type === 'Director' && wasClaimRejected(table.claim);

  return (
    <div className={clsx(classes.container, directorResolvingClaim && classes.directorResolvingClaim)}>
      <ClaimDescription table={table} />
      <ClaimResponses table={table} currentUser={currentUser} />
      {
        directorResolvingClaim && (
          <ResolveClaim
            table={table}
          />
        )
      }
      {
        currentUser.type === 'Player' && (
          <ClaimResolutionStatus
            table={table}
            currentUser={currentUser}
          />
        )
      }
    </div>
  );
};

export default ClaimPanel;
