import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  alertContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  alert: {
    color: '#666',
    padding: '1rem 2rem',
    margin: '2rem',
    border: '0.2rem dashed rgb(0,0,0,0.1)',
    borderRadius: '0.5rem',
    fontSize: '1.6rem'
  }
}));

const Results = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.alertContainer}>
      <div className={classes.alert}>
        {children}
      </div>
    </div>
  );
};

export default Results;
