// Used for text translation when a JSX element/snippet needs to be included in the interpolation.
//
// example JSON:
//
// "translation-key": "Alert {{bid}} with explanation: {{explanation}}
//
// in code:
//
// <TranslationWithJSX
//   id='translation-key'
//   translationProps={{
//     bid: (<MiniCard />)
//     explanation: 'something'
//   }}
// />
//
// results in:
//
// <span>Alert </span><MiniCard /><span> with explanation: something</span>

import React from 'react';
import { useTranslation } from 'react-i18next';

const TranslationWithJSX = (props) => {
  const { id, translationProps } = props;
  const { t } = useTranslation();

  const jsxProps = {};
  const normalProps = {};

  // go through translation props and see which ones are JSX
  if (translationProps) {
    for (const key in translationProps) {
      // store JSX ones separately,
      // and plain old strings/numbers/etc normally
      if (typeof translationProps[key] === 'object' && React.isValidElement(translationProps[key])) {
        jsxProps[key] = translationProps[key];
        // this will make t() replace {{jsxElement}} with {jsxElement}
        // - by default, i18next will strip {{jsxElement}} if there's no matching prop
        // so this tricks it to leave something we can recognize as a placeholder further down
        normalProps[key] = `{${key}}`;
      } else {
        normalProps[key] = translationProps[key];
      }
    }
  }

  let message = t(id, normalProps);

  // interpolate all JSX placeholders
  for (const key in jsxProps) {
    const messageParts = message.split(`{${key}}`);
    message = (
      <>
        <span>{messageParts[0]}</span>
        {jsxProps[key]}
        <span>{messageParts[1]}</span>
      </>
    );
  }

  return message;
};

export default TranslationWithJSX;
