import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '../Card.js';
import { cardHelpers } from 'shared-helpers';
import { useComponentScale } from '../../../hooks';

const {
  getCardsForSeat,
  arrangeSeatsBasedOnViewPoint
} = cardHelpers;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '28em',
    height: '32em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.2s ease-in-out'
  },
  faded: {
    opacity: 0.4,
    '& $card': {
      background: '#fff',
      borderRadius: '0.6rem',
    },
    '& img': {
      opacity: 0.5
    }
  },
  card: {
    position: 'absolute',
    width: '12em',
    height: '16.8em',
    transition: 'box-shadow 0.2s ease-in-out',
    '& img': {
      position: 'relative',
      zIndex: 1
    }
  },
  index0: {
    left: '52%',
    top: 0,
    marginLeft: '-5.5em'
  },
  index1: {
    right: 0,
    top: '52%',
    marginTop: '-7.7em'
  },
  index2: {
    left: '48%',
    bottom: 0,
    marginLeft: '-5.5em'
  },
  index3: {
    left: 0,
    top: '48%',
    marginTop: '-7.7em'
  },
  direction: {
    color: '#fff',
    textAlign: 'center',
    display: 'block',
    fontSize: '1.3em',
    lineHeight: '2em',
    fontWeight: 'bold',
    transition: 'inherit',
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
}));

const Trick = (props) => {
  const { trick, trickNumber, fadeOutDelay, trickRefCallback, viewpoint, simplifiedCards } = props;

  const directions = arrangeSeatsBasedOnViewPoint(viewpoint);
  const classes = useStyles();

  const [fadeOut, setFadeOut] = useState(false);

  // store cards with indexes - so we can always show the last one at the top
  const localTrick = trick.map((card, index) => {
    return {
      ...card,
      zIndex: index
    };
  });

  // component scale
  const componentScale = useComponentScale();
  const scale = componentScale / 1.1;

  const winningCard = trick.find(card => card.winner);

  useEffect(() => {
    if (winningCard) {
      const timeout = setTimeout(() => {
        setFadeOut(true);
      }, fadeOutDelay);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      setFadeOut(false);
    }
  }, [trickNumber, setFadeOut, fadeOutDelay, winningCard]);

  return (
    <div
      className={clsx(
        classes.container,
        fadeOut && classes.faded
      )}
      style={{fontSize:`${scale}rem`}}
    >
      {
        directions.map((direction, index) => {
          const card = getCardsForSeat(localTrick, direction.value)[0];
          return (
            <div
              className={clsx(
                classes.card,
                classes[`index${index}`]
              )}
              ref={trickRefCallback}
              id={direction.value}
              key={index}
              style={{zIndex: card && card.zIndex}}
            >
            {
              card && (
                <Card
                  value={`${card.rank}${card.suit}`}
                  simplifiedCards={simplifiedCards}
                  faceDown={false}
                />
              )
            }
            </div>
          );
        })
      }
    </div>
  );
};

Trick.propTypes = {
  trick: PropTypes.array.isRequired,
  viewpoint: PropTypes.string.isRequired,
  trickRefCallback: PropTypes.func
};

export default Trick;
