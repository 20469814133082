import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Rankings as RankingsQuery } from '../../../Components/Queries/';
import TabPanel from '../../../Components/TabPanel.js';
import Rankings from '../../../Components/Results/Rankings/Rankings.js';
import PPDialog from '../../../Components/PPDialog.js';
import Travellers from './Travellers.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '90%',
    maxWidth: 'none'
  },
  content: {
    background: theme.palette.block.content.background,
    padding: 0,
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column'
  },
  tabsContainer: {
    background: 'rgba(255,255,255,0.5)'
  },
  tabPanelContainer: {
    position: 'relative',
    flexGrow: 1
  },
  tabPanelContent: {
    padding: '2rem',
    overflow: 'hidden',
    overflowY: 'auto'
  }
}));

const ResultsModal = (props) => {
  const { open, onClose, winnerType, scoringMethod } = props;
  const [view, setView] = useState('ranking');
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = useCallback((e, val) => {
    setView(val);
  }, [setView]);

  return (
    <PPDialog
      open={open}
      onClose={onClose}
      title={t('current-rankings')}
      contentClassName={classes.content}
      PaperProps={{ className: classes.dialog }}
    >
      <div className={classes.tabsContainer}>
        <Tabs
          onChange={handleChange}
          value={view}
          indicatorColor='primary'
          centered
        >
          <Tab label={t('ranking')} value='ranking' />
          <Tab label={t('travellers')} value='travellers' />
        </Tabs>
      </div>
      <div className={classes.tabPanelContainer}>
        <TabPanel value={view} name='ranking'>
          <div className={classes.tabPanelContent}>
            <RankingsQuery>
              {(rankings) => (
                <Rankings
                  rankings={rankings}
                  winnerType={winnerType}
                  scoringMethod={scoringMethod}
                />
              )}
            </RankingsQuery>
          </div>
        </TabPanel>
        <TabPanel value={view} name='travellers'>
          <div className={classes.tabPanelContent}>
            <Travellers
              scoringMethod={scoringMethod}
            />
          </div>
        </TabPanel>
      </div>
    </PPDialog>
  );
};

ResultsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  winnerType: PropTypes.string.isRequired,
  scoringMethod: PropTypes.string.isRequired
};

export default ResultsModal;
