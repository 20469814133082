import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button
} from '@material-ui/core';
import { usePPMutation } from '../../hooks';
import { userMutations } from '../../graphql/mutations';
import PPInput from '../../Components/Forms/PPInput/PPInput.js';
import { getAppDisplayName } from '../../helpers/url.js';
import { persistJwt } from '../../helpers/auth.js';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  container: {
    width: '40rem'
  },
  content: {
    background: '#f1f1f1',
    padding: '2.4rem 2rem'
  },
  actions: {
    justifyContent: 'center',
    padding: '2rem 0'
  }
}));

const Login = () => {
  const classes = useStyles();

  const [fields, setFields] = useState({email: '', password: ''});
  const [login, result] = usePPMutation(userMutations.loginUser);
  const handleChange = (input) => {
    fields[input.id] = input.value;
    setFields(fields);
  }
  const handleClick = useCallback(async () => {
    await login({ variables: fields });
  }, [login, fields]);

  const token = result && result.data && result.data.loginUser && result.data.loginUser.token;

  useEffect(() => {
    if (token) {
      persistJwt(token);

      // yeah the sessionId is hardcoded. this component is only meant to be used in development
      // because in prod, logging in would go through pianola
      window.location = '/session/5';
    }
  }, [token]);

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className={classes.root}
    >
      <Grid item>
        <Card className={classes.container}>
          <CardHeader title={'Log in to ' + getAppDisplayName()} />
          <CardContent className={classes.content}>
            <PPInput
              id='email'
              label='Email address'
              type='text'
              onChange={handleChange}
              value={fields.email}
            />
            <br/><br/>
            <PPInput
              id='password'
              label='Password'
              type='password'
              onChange={handleChange}
              value={fields.password}
            />
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleClick}
              disabled={result.loading}
            >
              Log in
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
