import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import Deal from './Deal/Deal.js';
import AuctionTable from './Game/Auction/AuctionTable.js';
import MakeableContracts from './Results/MakeableContracts.js';
import { auctionHelpers } from 'shared-helpers';
import { useTranslation } from 'react-i18next';
const { convertSeatCodeToFullWord } = auctionHelpers;

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid rgba(255,255,255,0.25)',
    borderRadius: '1em',
    padding: '1.5em 2em 2.5em 2em',
    textAlign: 'center',
    fontSize: '1rem'
  },
  info: {
    background: 'rgba(0,0,0,0.2)',
    borderRadius: '0.5em',
    padding: '1em 2em',
    margin: '2em 0'
  },
  itemLabel: {
    textTransform: 'uppercase',
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: '#999'
  },
  itemText: {
    fontSize: '2em'
  },
  makeableContractsMessage: {
    fontSize: '1.5em',
    marginTop: '1em'
  },
  makeableContracts: {
    marginTop: '2em'
  }
}));

const FinishedBoard = (props) => {
  const { table } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isIpad = useMediaQuery('(max-width:1194px)');

  if (!table || !table.board) { return null; }

  return (
    <div className={classes.container}>
      <Typography variant='h6'>
        {t('board-n-summary', { n: table.boardNumber + 1 })}
      </Typography>
      <div className={classes.info}>
        <Grid
          container
          justify='space-around'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <div className={classes.itemLabel}>{t('score')}</div>
            <div className={classes.itemText}>
              {t('ns')}: {table.nsScore} / {t('ew')}: {table.ewScore}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.itemLabel}>{t('tricks')}</div>
            <div className={classes.itemText}>
              {t('ns')}: {table.nsTricks} / {t('ew')}: {table.ewTricks}
            </div>
          </Grid>
          {
            table.contract && (
              <Grid item>
                <div className={classes.itemLabel}>{t('contract')}</div>
                <div className={classes.itemText}>
                  {table.contract.level}&nbsp;
                  {table.contract.denomination}&nbsp;
                  {
                    table.contract.risk !== '' && (
                      <>
                        {table.contract.risk}&nbsp;
                      </>
                    )
                  }
                  {t('by-position', {
                    position: t(convertSeatCodeToFullWord(table.declarer).toLowerCase())
                  })}
                </div>
              </Grid>
            )
          }
        </Grid>
      </div>
      <Grid
        container
        alignItems='center'
        spacing={2}
        justify='space-between'
      >
        <Grid item>
          <Deal
            dealer={table.board.dealer}
            viewpoint='S'
            vulnerable={table.board.vulnerable}
            cards={table.board.deal}
            scale={isIpad ? 0.8 : 1}
          />
          {table.board.makeableContractsStatus === 'Pending' && (
            <div className={classes.makeableContractsMessage}>
              {t('makeable-contracts-are-still-being-calculated-for-board')}
            </div>
          )}
          {table.board.makeableContracts && (
            <div className={classes.makeableContracts}>
              <MakeableContracts
                makeableContracts={table.board.makeableContracts}
              />
            </div>
          )}
        </Grid>
        <Grid item>
          <AuctionTable
            table={table}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FinishedBoard;
