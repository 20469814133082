import React from 'react';
import PropTypes from 'prop-types';
import StatusItem from './StatusItem.js';
import { useTranslation } from 'react-i18next';

const TableNumber = (props) => {
  const { table } = props;
  const { t } = useTranslation();

  return (
    <StatusItem
      label={t('table')}
      text={table.tableNumber + 1}
    />
  );
};

TableNumber.propTypes = {
  table: PropTypes.object.isRequired
};

export default TableNumber;
