import { useEffect } from 'react';
import { usePPMutation } from '../hooks/';
import { userMutations } from '../graphql/mutations';

// ping the server every 30s
const ONLINE_PING_INTERVAL = 30000;

const OnlinePing = (props) => {
  const { currentUser } = props;
  const [sendOnlinePing] = usePPMutation(userMutations.sendOnlinePing);

  useEffect(() => {
    if (!currentUser) { return; }

    const interval = setInterval(() => {
      sendOnlinePing();
    }, ONLINE_PING_INTERVAL);

    // send one ASAP, for good measure
    sendOnlinePing();

    return () => {
      clearInterval(interval);
    };
    // the empty [] makes this execute just the once for every instance of OnlinePing
    // eslint-disable-next-line
  }, []);

  return null;
};

export default OnlinePing;
