import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton, useMediaQuery, InputBase } from '@material-ui/core';
import SendMessageIcon from '@material-ui/icons/Send';
import { messageMutations } from '../../graphql/mutations';
import { usePPMutation } from '../../hooks/';
import { throttle } from 'lodash';
import { TYPING_NOTIFICATION_EXPIRY } from '../../helpers/chat.js';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import RecipientSelector from './RecipientSelector.js';

const useStyles = makeStyles(theme => ({
  container: {
    background: '#fff',
    borderRadius: '0.5rem',
    padding: '0.6rem 0.6rem 0.6rem 1.4rem'
  },
  input: {
    color: '#333',
    width: '100%',
    minHeight: '5rem'
  },
  button: {
    background: theme.palette.secondary.main,
    marginLeft: '0.6rem',
    color: '#fff',
    '&:hover': {
      background: theme.palette.secondary.dark
    }
  }
}));

const NewMessage = (props) => {
  const {
    threadId,
    onMessageSent,
    onBeforeMessageSend,
    sendTypingNotifications,
    readOnly,
    recipientId,
    recipients,
    onRecipientChange,
    placeholderText
  } = props;
  const [message, setMessage] = useState('');
  const [sendMessage] = usePPMutation(messageMutations.sendMessage);
  const [startTyping] = usePPMutation(messageMutations.startTyping);
  const [endTyping] = usePPMutation(messageMutations.endTyping);

  const classes = useStyles();

  // send a typing notification at most every 5 seconds
  const throttledStartTyping = useCallback(
    throttle(
      startTyping,
      TYPING_NOTIFICATION_EXPIRY / 2,
      { trailing: false }
    ),
    []);

  const handleMessageChange = useCallback((event) => {
    const value = event.target.value;

    if (value === message) {
      return;
    }

    setMessage(value);

    if (sendTypingNotifications && !recipientId) {
      if (value) {
        throttledStartTyping({ variables: { threadId } });
      } else {
        // only explicitly end typing if the user clears out their message
        // otherwise the other users can infer when typing stopped e.g. based on timings
        endTyping({ variables: { threadId } });
      }
    }
  }, [message, setMessage, sendTypingNotifications, throttledStartTyping, endTyping, threadId, recipientId]);

  const handleBlur = useCallback((event) => {
    if (sendTypingNotifications) {
      endTyping({ variables: { threadId } });
    }
  }, [sendTypingNotifications, endTyping, threadId]);

  const submit = useCallback(async () => {
    if (!message) { return; }
    setMessage('');

    const payload = {
      text: message,
      threadId: threadId,
      recipientId: recipientId,
      // if there's a specific recipient for this message,
      // send it as private
      type: recipientId ? 'Private' : 'Standard'
    };

    if (onBeforeMessageSend) {
      onBeforeMessageSend(payload);
    }

    const result = await sendMessage({
      variables: payload
    });
    if (onMessageSent) {
      onMessageSent(result);
    }
  }, [message, threadId, recipientId, setMessage, sendMessage, onMessageSent, onBeforeMessageSend]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submit();
    }
  }, [submit]);

  const handleClick = () => {
    submit();
  };

  const isIpad = useMediaQuery('(max-width:1194px)');

  return (
    <div className={classes.container}>
      <Grid
        container
        direction='column'
      >
        <Grid
          item
          container
          alignItems='flex-end'
        >
          <Grid item xs>
            <InputBase
              multiline
              type='text'
              id='message'
              value={message}
              onChange={handleMessageChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              rowsMax={isIpad ? 3 : 5}
              readOnly={readOnly}
              placeholder={placeholderText}
              className={classes.input}
            />
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleClick}
              disabled={readOnly}
              className={classes.button}
            >
              <SendMessageIcon/>
            </IconButton>
          </Grid>
        </Grid>
        {(recipients && recipients.length) ?
            (
              <Grid item>
                <RecipientSelector
                  value={recipientId}
                  onChange={onRecipientChange}
                  recipients={recipients}
                />
              </Grid>
            ) : null
        }
      </Grid>
    </div>
  );
};

NewMessage.propTypes = {
  threadId: PropTypes.string.isRequired,
  sendTypingNotifications: PropTypes.bool,
  onMessageSent: PropTypes.func,
  onBeforeMessageSend: PropTypes.func,
  recipients: PropTypes.array,
  recipientId: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholderText: PropTypes.string.isRequired
};

export default NewMessage;
