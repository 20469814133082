import React from 'react';
import { usePPMutation } from '../../../hooks/';
import { usebioMutations } from '../../../graphql/mutations/';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const DownloadUsebioButton = () => {
  const { t } = useTranslation();
  const [downloadXml] = usePPMutation(usebioMutations.download);
  const handleClick = async () => {
    const result = await downloadXml();
    var link = document.createElement('a');
    link.setAttribute('download', '');
    link.href = result.data.download.downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const publishButtonTooltipText = t('click-here-to-download-these-results-in-usebio');
  return (
    <Tooltip arrow placement='top' title={<Typography>{publishButtonTooltipText}</Typography>}>
      <span>
        <Button onClick={handleClick} variant='contained' color='primary'>
          {t('download-results-xml')}
        </Button>
      </span>
    </Tooltip>
  );
};

export default DownloadUsebioButton;
