import { useEffect, useState } from 'react';
import { DateTime, Duration } from 'luxon';

/**
 * A bare-bones component that updates once a second and shows the time
 * left until the end of the round
 *
 * It's on its own so it can be rerendered without touching
 * any components that would otherwise take more resources to rerender
 */
const StatusTimer = (props) => {
  const { timeLastRoundStarted, roundTimeLimit } = props;
  // pick an unimportant state so we can force React to rerender
  // eslint-disable-next-line
  const [_, setTime] = useState(Date.now());

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  });

  const timeUntilRoundEnd = DateTime.fromISO(timeLastRoundStarted, { zone: 'utc' })
    .plus({ minutes: roundTimeLimit });

  const difference = Math.max(timeUntilRoundEnd.diff(DateTime.now({ zone: 'utc' })).milliseconds, 0);
  const timeLeft = Duration.fromMillis(difference).toFormat('hh:mm:ss');

  return timeLeft;
};

export default StatusTimer;
