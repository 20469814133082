import React, { useCallback, useState, useEffect } from 'react';
import { isOurTurn } from '../../../helpers/game.js';
import ActionButton from '../Actions/ActionButton.js';
import ClaimIcon from '@material-ui/icons/NotificationImportantRounded';
import { useTranslation } from 'react-i18next';

const ClaimButton = (props) => {
  const { table, setMakingClaim } = props;
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (isOurTurn(table) && !table.claim && table.status === 'Play') {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [setEnabled, table]);

  const handleClick = useCallback(() => {
    setMakingClaim(prevState => !prevState);
  }, [setMakingClaim]);

  return (
    <ActionButton
      icon={<ClaimIcon style={{ fontSize: 30 }} />}
      label={t('claim')}
      onClick={handleClick}
      disabled={!enabled}
      tooltip={t('make-a-claim')}
    />
  );
};

export default ClaimButton;
