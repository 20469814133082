import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Assignment as AssignmentIcon, AssignmentTurnedIn as AssignmentTurnedInIcon } from '@material-ui/icons/';
import SystemDescriptionModal from '../SystemDescription/SystemDescriptionModal.js';
import { SystemDescription as SystemDescriptionSQ } from '../SubscriptionQueries/';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  button: {
    color: '#999',
    '&:hover': {
      color: '#fff'
    }
  }
}));

const SystemDescriptionButton = (props) => {
  const { currentUser } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <SystemDescriptionSQ>
      {(systemDescription) => {
        const systemDescriptionSet = systemDescription && systemDescription.text;

        return (
          <>
            <Tooltip
              arrow
              title={
                <Typography>
                  {
                    systemDescriptionSet ?
                      t('system-description-already-set') :
                      t('system-description-not-set')
                  }
                </Typography>
              }
              disableHoverListener={currentUser.gameStatus === 'NotInPair'}
            >
              {/*
                wrap this in a span because otherwise the tooltip won't show
                if the button is disabled
              */}
              <span>
                <IconButton
                  className={classes.button}
                  onClick={handleClick}
                  disabled={currentUser.gameStatus === 'NotInPair'}
                >
                  {
                    systemDescriptionSet
                      ? (<AssignmentTurnedInIcon fontSize='large' />)
                      : (<AssignmentIcon fontSize='large' />)
                  }
                </IconButton>
              </span>
            </Tooltip>
            <SystemDescriptionModal
              open={isOpen}
              onClose={handleClose}
              currentUser={currentUser}
              systemDescription={systemDescription}
            />
          </>

        );
      }}
    </SystemDescriptionSQ>
  );
};

SystemDescriptionButton.propTypes = {
  currentUser: PropTypes.object.isRequired
};

export default SystemDescriptionButton;
