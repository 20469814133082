import React from 'react';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { systemDescriptionQueries } from '../../graphql/queries/';
import { systemDescriptionSubscriptions } from '../../graphql/subscriptions/';

class SystemDescription extends React.Component {
  state = {
    systemDescription: null
  };

  handleSystemDescriptionData = (data) => {
    this.setState({
      systemDescription: data.systemDescription
    });
  }

  handleSystemDescriptionSubscriptionData = ({ data }) => {
    this.setState({
      systemDescription: data.systemDescriptionUpdate
    });
  };

  render() {
    return (
      <Query
        query={systemDescriptionQueries.systemDescription}
        onCompleted={this.handleSystemDescriptionData}
        fetchPolicy='no-cache'
      >
        {({ data }) => (
          <PPSubscription
            subscription={systemDescriptionSubscriptions.systemDescriptionUpdate}
            onSubscriptionData={this.handleSystemDescriptionSubscriptionData}
          >
            {() => {
              return this.props.children(this.state.systemDescription);
            }}
          </PPSubscription>
        )}
      </Query>
    );
  }
}

export default SystemDescription;
