import gql from 'graphql-tag';
import pairFragments from './pair.js';
import boardFragments from './board.js';

const tableFragments = {
  fullTable: gql`
    fragment FullTable on Table {
      id
      nsPair {
        ...FullPair
      }
      ewPair {
        ...FullPair
      }
      northPlayerId
      eastPlayerId
      viewpoint
      activeSeat
      tableNumber
      boardsPerRound
      boardNumber
      roundNumber
      orderInRound
      auction {
        value
        level
        denomination
        step
        alerted
      }
      contract {
        value
        level
        denomination
        risk
      }
      declarer
      cardPlay {
        index
        cards {
          rank
          suit
          seat
          step
          winner
        }
      }
      nsTricks
      ewTricks
      board {
        ...FullBoard
      }
      claim {
        seat
        reason
        tricksClaimed
        responses {
          seat
          value
          type
        }
      }
      status
      isSitOut
      directorCallStatus
      directorCallerId
      dateTime
      dateTimeEnd
    }
    ${pairFragments.fullPair}
    ${boardFragments.fullBoard}
  `,
  updateTable: gql`
    fragment UpdateTable on Table {
      id
      tableNumber
      status
      nsTricks
      ewTricks
      nsScore
      ewScore
      dateTimeEnd
      activeSeat
    }
  `,
  resultTable: gql`
    fragment ResultTable on Table {
      id
      nsPair {
        ...FullPair
      }
      ewPair {
        ...FullPair
      }
      tableNumber
      boardsPerRound
      boardNumber
      roundNumber
      orderInRound
      contract {
        value
        level
        denomination
        risk
      }
      declarer
      nsTricks
      ewTricks
      nsScore
      ewScore
      nsArtificialScore
      ewArtificialScore
      nsAssignedScore
      ewAssignedScore
      nsMPs
      ewMPs
      notPlayed
      status
      leadPlay {
        rank
        suit
      }
    }
    ${pairFragments.fullPair}
  `,
  tableForInterstitial: gql`
    fragment TableForInterstitial on Table {
      id
      nsPair {
        ...FullPair
      }
      ewPair {
        ...FullPair
      }
      northPlayerId
      eastPlayerId
      tableNumber
      boardsPerRound
      boardNumber
      roundNumber
      orderInRound
      ewTricks
      nsTricks
      nsScore
      ewScore
      contract {
        value
        level
        denomination
        risk
      }
      declarer
      auction {
        value
        level
        denomination
      }
      board {
        ...FullBoard
      }
    }
    ${pairFragments.fullPair}
    ${boardFragments.fullBoard}
  `,
  playEvent: gql`
    fragment PlayEvent on PlayEvent {
      activeSeat,
      tableId,
      trick {
        index,
        cards {
          suit,
          rank,
          seat,
          step,
          winner
        }
      },
      nsTricks,
      ewTricks,
      delay
    }
  `,
  auctionEvent: gql`
    fragment AuctionEvent on AuctionEvent {
      activeSeat,
      tableId,
      bid {
        value,
        level,
        denomination,
        step,
        alerted
      },
      contract {
        value
        level
        denomination
        risk
      },
      declarer
    }
  `,
  claim: gql`
    fragment Claim on Claim {
      seat
      reason
      tricksClaimed
      responses {
        seat
        value
        type
      }
    }
  `
};

export default tableFragments;
