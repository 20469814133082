import React from 'react';
import UIfx from 'uifx';
import { UserSettingsConsumer } from '../UserSettings/UserSettingsContext.js';

const SOUNDS = {
  directorCalled: 'mixkit-select-click-1109.mp3',
  directorJoined: 'mixkit-select-click-1109.mp3',
  directorLeft: 'mixkit-select-click-1109.mp3',
  newBoard: 'mixkit-select-click-1109.mp3',
  newRound: 'mixkit-select-click-1109.mp3',
  newMessage: 'mixkit-select-click-1109.mp3',
  yourTurn: 'mixkit-select-click-1109.mp3'
};

const SoundEffectsContext = React.createContext();

// this needs to be a class component because playSound needs to be 100% stable
// otherwise any sounds that play using useEffect may play several times

class SoundPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.soundEffects = {};
  }

  componentDidMount() {
    if (this.props.enableSound &&
      this.props.enableSound.value) {
      this.loadSounds();
    }
  }

  componentDidUpdate() {
    // if `enabledSound` get set later on,
    // load sounds if they haven't been already
    const loadedSounds = Object.keys(this.soundEffects);
    if (this.props.enableSound &&
      this.props.enableSound.value &&
      !loadedSounds.length) {
      this.loadSounds();
    }
  }

  loadSounds = () => {
    Object.keys(SOUNDS).forEach(soundName => {
      this.soundEffects[soundName] = new UIfx('/sounds/' + SOUNDS[soundName]);
    });
  };

  playSound = (name) => {
    if (!this.props.enableSound ||
      !this.props.enableSound.value ||
      !this.props.sounds) {
      return;
    }

    if (!this.soundEffects[name]) {
      console.warn(`couldn't find sound effect named: ${name}`);
      return;
    }

    if (this.props.sounds[name].value) {
      this.soundEffects[name].play();
    }
  };

  render() {
    return (
      <SoundEffectsContext.Provider
        value={this.playSound}
      >
        {this.props.children}
      </SoundEffectsContext.Provider>
    );
  }
}

class SoundEffectsProvider extends React.Component {
  render(props) {
    const { children } = this.props;

    return (
      <UserSettingsConsumer>
        {(userSettings = {}) => {
          const { enableSound, sounds } = userSettings;

          return (
            <SoundPlayer
              children={children}
              enableSound={enableSound}
              sounds={sounds}
            />
          );
        }}
      </UserSettingsConsumer>
    );
  }
}

const SoundEffectsConsumer = SoundEffectsContext.Consumer;

export { SoundEffectsProvider, SoundEffectsConsumer };
export default SoundEffectsContext;
