import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#fff',
    borderRadius: '0.5rem',
    padding: '3rem',
    fontWeight: 'bold'
  },
  link: {
    display: 'block',
    border: '0.1rem solid #ccc',
    borderRadius: '0.5rem',
    width: '5rem',
    height: '5rem',
    fontSize: '3rem',
    lineHeight: '5rem',
    textAlign: 'center',
    textDecoration: 'none',
    margin: '0 0.2rem',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#aaa',
      boxShadow: '0 0.2rem 0.5rem rgba(0,0,0,0.1)'
    },
    '&:active': {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: '#fff !important',
      padding: 0
    }
  },
  activeLink: {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: '#fff !important'
  }
}));

const BoardSelect = (props) => {
  const { boardNumbers, currentActiveNumber, onBoardNumberClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid
        container
        justify='center'
        spacing={2}
      >
        {
          boardNumbers.map(boardNumber => (
            <Grid item key={boardNumber}>
              <button
                className={clsx(
                  classes.link,
                  boardNumber === currentActiveNumber && classes.activeLink
                )}
                onClick={() => { onBoardNumberClick(boardNumber + 1); }}
              >
                {boardNumber + 1}
              </button>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
};

BoardSelect.propTypes = {
  boardNumbers: PropTypes.array.isRequired,
  currentActiveNumber: PropTypes.number.isRequired,
  onBoardNumberClick: PropTypes.func.isRequired
};

export default BoardSelect;
