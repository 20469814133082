import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import StateSwitch from '../../Components/Routing/StateSwitch.js';
import {
  Lobby as LobbySQ,
  Game as GameSQ
} from '../../Components/SubscriptionQueries/';
import ReconnectionMonitorContext from '../../Components/ReconnectionMonitorContext.js';
import Lobby from './Lobby/Lobby.js';
import Game from './Game/Game.js';

const Player = (props) => {
  const { currentUser, currentSession } = props;
  const reconnectionKey = useContext(ReconnectionMonitorContext);

  return (
    <>
      <StateSwitch base='/player' value={currentSession.status} >
        <Route
          path={['/player/notstarted', '/player/starting']}
          render={() => (
            <LobbySQ
              key={reconnectionKey}
            >
              {({users, pairs}) => (
                <Lobby
                  currentUser={currentUser}
                  users={users}
                  pairs={pairs}
                  session={currentSession}
                />
              )}
            </LobbySQ>
          )}
        />
        <Route
          path={['/player/inprogress', '/player/finished']}
          render={() => (
            <GameSQ
              key={reconnectionKey}
            >
              {({table, refetch, onCardPlayed, onBidMade}) => (
                <Game
                  table={table}
                  session={currentSession}
                  refetch={refetch}
                  currentUser={currentUser}
                  onCardPlayed={onCardPlayed}
                  onBidMade={onBidMade}
                />
              )}
            </GameSQ>
          )}
        />
      </StateSwitch>
    </>
  );
};


Player.propTypes = {
  currentSession: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default Player;
