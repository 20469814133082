// This hook maintains an up-to-date auction when a player has just made a bid
// but the backend has yet to catch up - it makes the bidding feel instantaneous

import { useEffect, useState, useCallback } from 'react';
import { auctionHelpers, cardHelpers } from 'shared-helpers';
const { hasEnded, isPassedOut } = auctionHelpers;
const { getNextSeatForPlay } = cardHelpers;

const useLocalAuction = (table) => {
  const [madeBid, setMadeBid] = useState(null);

  let localTable;

  const bidIsInAuction =
    madeBid &&
    table.auction.length &&
    table.auction.find(bid => bid.value === madeBid.value && bid.step === madeBid.step);

  // if there's a bid made but it's not present in the auction from props
  // add it artificially
  if (madeBid && !bidIsInAuction) {
    localTable = { ...table };
    localTable.auction = table.auction.concat();
    localTable.auction.push(madeBid);

    // if the auction has ended or has been passed out, we don't care about
    // showing this immediately - wait until the backend confirms this
    if (!hasEnded(table.auction) && !isPassedOut(table.auction)) {
      // otherwise, move on to the next player
      localTable.activeSeat = getNextSeatForPlay(localTable.activeSeat);
    }
  } else {
    localTable = table;
  }

  // if the bid the player made is present in the auction
  // (ie. the backend has caught up to the local auction)
  // undo any changes we've made
  useEffect(() => {
    if (bidIsInAuction) {
      setMadeBid(null);
    }
  }, [bidIsInAuction, setMadeBid]);

  const handleBidMade = useCallback((bid) => {
    const newBid = {
      ...bid,
      step: table.auction.length
    };

    setMadeBid(newBid);
  }, [table.auction.length, setMadeBid]);

  return { localTable, onBidMade: handleBidMade };
};

export default useLocalAuction;
