import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: '2.5rem'
  },
  cardContainer: {
    width: '2em'
  },
  card: {
    background: '#ddd',
    border: '0.1em solid #fff',
    borderRadius: '0.1em',
    margin: '0 auto'
  },
  vertical: {
    width: '1em',
    height: '1.3em'
  },
  horizontal: {
    width: '1.3em',
    height: '1em'
  }
}));

const TricksCount = (props) => {
  const { nsTricks, ewTricks, viewpoint } = props;
  const classes = useStyles();

  return (
    <Grid item className={classes.container}>
      <Grid
        container
        alignItems='center'
        spacing={1}
        wrap='nowrap'
      >
        <Grid item>
          <div className={classes.cardContainer}>
            <div className={clsx(classes.card, viewpoint === 'N' || viewpoint === 'S' ? classes.vertical : classes.horizontal)}></div>
          </div>
        </Grid>
        <Grid item xs>
          {nsTricks}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems='center'
        spacing={1}
        wrap='nowrap'
      >
        <Grid item>
          <div className={classes.cardContainer}>
            <div className={clsx(classes.card, viewpoint === 'E' || viewpoint === 'W' ? classes.vertical : classes.horizontal)}></div>
          </div>
        </Grid>
        <Grid item xs>
          {ewTricks}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TricksCount;
