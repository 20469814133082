import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LastRoundEnding from './LastRoundEnding.js';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    left: 0, top: 0, right: 0, bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const GameEndTransition = (props) => {
  const { children, table, session } = props;
  const classes = useStyles();

  const status = table.status;
  const timeSinceTableEnded = new Date() - new Date(table.dateTimeEnd);

  let initialShowingScreen = status === 'SessionFinished' &&
    timeSinceTableEnded < 5000;

  const [showingScreen, setShowingScreen] = useState(initialShowingScreen);

  const handleCountdownEnd = useCallback(() => {
    setShowingScreen(null);
  }, [setShowingScreen]);

  if (showingScreen) {
    return (
      <div className={classes.container}>
        <LastRoundEnding
          table={table}
          session={session}
          onCountdownEnd={handleCountdownEnd}
        />
      </div>
    );
  }

  return children;
};

export default GameEndTransition;
