import gql from 'graphql-tag';

const usebioMutations = {
  download: gql`
    mutation {
      download {
        xml
        downloadUrl
      }
    }
  `,
  upload: gql`
    mutation {
      upload {
        success
      }
    }
  `
}

export default usebioMutations;
