import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography } from '@material-ui/core';
import { systemDescriptionMutations } from '../../graphql/mutations';
import { usePPMutation } from '../../hooks/';
import PPDialog from '../PPDialog.js';
import LastEditedDescription from './LastEditedDescription.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 400
  },
  helpText: {
    marginBottom: 20
  }
}));

const SystemDescriptionModal = (props) => {
  const { open, systemDescription, currentUser, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const systemDescriptionText = (systemDescription && systemDescription.text) || '';
  const [localText, setLocalText] = useState(systemDescriptionText);

  const [setSystemDescription] = usePPMutation(systemDescriptionMutations.setSystemDescription, { memoize: true });
  const [setSystemDescriptionEdited, { called }] = usePPMutation(systemDescriptionMutations.setSystemDescriptionEdited);

  // if this message isn't already being marked as edited, mark it as being edited by current user
  // (if it is, editing will be disabled)
  const editingUserId = systemDescription && systemDescription.currentlyEditingUserId;
  useEffect(() => {
    if (!editingUserId && open) {
      setSystemDescriptionEdited({ variables: { isEdited: true } });
    }
  }, [open, editingUserId, setSystemDescriptionEdited]);

  // rare, but if this user refreshes the page while the system description is open
  // it might never flip back to closed
  // in which case the partner will never be able to edit it!
  useEffect(() => {
    if (!open && currentUser.id === editingUserId && !called) {
      setSystemDescriptionEdited({ variables: { isEdited: false } });
    }
  }, [open, editingUserId, currentUser, called, setSystemDescriptionEdited]);

  // if the text changes (e.g. the partner has just edited it)
  // update local state
  useEffect(() => {
    setLocalText(systemDescriptionText);
  }, [systemDescriptionText, setLocalText]);

  const handleClose = useCallback(async () => {
    onClose();
    await setSystemDescriptionEdited({ variables: { isEdited: false } });
  }, [onClose, setSystemDescriptionEdited]);

  const handleChange = useCallback((e) => {
    setLocalText(e.target.value);
  }, [setLocalText]);

  const handleSave = useCallback(async () => {
    handleClose();
    await setSystemDescription({ variables: { text: localText } });
  }, [handleClose, setSystemDescription, localText]);

  const disabled = editingUserId && editingUserId !== currentUser.id;

  return (
    <PPDialog
      title={t('system-description')}
      onClose={handleClose}
      open={open}
      contentClassName={classes.container}
      actions={
        <Button
          onClick={handleSave}
          disabled={disabled}
        >
          {t('save-changes')}
        </Button>
      }
    >
      <Typography className={classes.helpText}>
        {t('describe-your-partnership-system-here')}
      </Typography>
      <TextField
        onChange={handleChange}
        value={localText || ''}
        label={t('description')}
        rows={4}
        variant='outlined'
        fullWidth={true}
        disabled={disabled}
        helperText={
          <LastEditedDescription
            systemDescription={systemDescription}
            currentUser={currentUser}
          />
        }
        multiline
      />
    </PPDialog>
  );
};

SystemDescriptionModal.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  systemDescription: PropTypes.object
};

export default SystemDescriptionModal;
