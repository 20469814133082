import gql from 'graphql-tag';
import { messageFragments } from '../fragments';

const messageQueries = {
  messages: gql`
    query ($threadId: ID!) {
      messages (threadId: $threadId) {
        ...FullMessage
      }
    }
    ${messageFragments.fullMessage}
  `
};

export default messageQueries;
