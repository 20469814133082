import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chat from '../../../Components/Chat/Chat.js';
import { Boards as BoardsQ } from '../../../Components/Queries/';
import {
  Rankings as RankingsSQ,
  Travellers as TravellersSQ,
  Users as UsersSQ
} from '../../../Components/SubscriptionQueries/';
import GameEndTransition from '../../../Components/TransitionScreens/GameEndTransition.js';
import clsx from 'clsx';
import ResultsPane from './ResultsPane.js';
import { useTranslation } from 'react-i18next';
import { getEndGameLobbyRecipients } from '../../../helpers/chat.js';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  leftContainer: {
    left: '1rem',
    right: '44rem',
    top: '8rem',
    bottom: '1rem'
  },
  containerChat: {
    right: '1rem',
    top: '8rem',
    bottom: '1rem',
    width: '42rem',
    fontSize: '1rem'
  }
}));

const SessionFinished = (props) => {
  const { session, currentUser, lastTablePlayed } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [boardNumber, setBoardNumber] = useState(1);

  const handleBoardNumberClick = useCallback((boardNumber) => {
    setBoardNumber(boardNumber);
  }, [setBoardNumber]);

  return (
    <>
      <div className={clsx(classes.container, classes.leftContainer)}>
        <GameEndTransition
          table={lastTablePlayed}
          session={session}
        >
          <BoardsQ>
            {(boards) => (
              <RankingsSQ>
                {({ rankings, sessionFinished, refetchRankings }) => (
                  <TravellersSQ
                    boardNumber={boardNumber - 1}
                  >
                    {({ tables, refetchTables }) => (
                      <ResultsPane
                        session={session}
                        rankings={rankings}
                        sessionFinished={sessionFinished}
                        refetchRankings={refetchRankings}
                        tables={tables}
                        boards={boards}
                        refetchTables={refetchTables}
                        boardNumber={boardNumber}
                        onBoardNumberClick={handleBoardNumberClick}
                      />
                    )}
                  </TravellersSQ>
                )}
              </RankingsSQ>
            )}
          </BoardsQ>
        </GameEndTransition>
      </div>
      <div className={clsx(classes.container, classes.containerChat)}>
        <UsersSQ>
          {(users) => (
            <Chat
              threadId={session.id}
              currentUser={currentUser}
              sendTypingNotifications={true}
              headerText={t('end-of-game-chat')}
              recipients={getEndGameLobbyRecipients(currentUser, users, session, t)}
            />
          )}
        </UsersSQ>
      </div>
    </>
  );
};

SessionFinished.propTypes = {
  session: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  lastTablePlayed: PropTypes.object
};

export default SessionFinished;
