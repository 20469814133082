import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import BidsBySeat from '../Auction/BidsBySeat.js';
import Contract from './Contract.js';
import { useComponentScale } from '../../../hooks';
import { getPlayerBySeat } from '../../../helpers/game.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  message: {
    textAlign: 'center',
    fontSize: '2.4rem',
    fontWeight: 'bold',
    color: '#fff'
  }
}));

const BidsOverlay = (props) => {
  const { table, shrink } = props;
  const classes = useStyles();
  const scale = useComponentScale() / 1.1;
  const { t } = useTranslation();

  const nextPlayer = getPlayerBySeat(table.activeSeat, table);

  return (
    <div className={classes.container}>
      <BidsBySeat
        auction={table.auction}
        dealer={table.board.dealer}
        viewpoint={table.viewpoint || 'S'}
        scale={scale}
        shrink={shrink}
      >
        <div className={classes.message}>
          <Contract
            contract={table.contract}
            declarer={table.declarer}
          />
          {t('name-to-lead', { name: nextPlayer.firstName })}
        </div>
      </BidsBySeat>
    </div>
  );
};

BidsOverlay.propTypes = {
  table: PropTypes.object.isRequired,
  shrink: PropTypes.bool
};

export default BidsOverlay;
