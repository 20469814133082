import React, { useState, useEffect } from 'react';

// Detects websocket reconnections and updates a value
// which child components can use to determine whether they need to fully refresh or not
//
// usage:
//
// const reconnectionKey = useContext(ReconnectionMonitorContext);
//
// return (
//   <SomeSubscriptionQuery key={reconnectionKey}>
//     {thing}
//   </SomeSubscriptionQuery>
// );

const ReconnectionMonitorContext = React.createContext();

const ReconnectionMonitorProvider = (props) => {
  const { wsClient, children } = props;
  const [reconnectionKey, setReconnectionKey] = useState(Date.now());

  useEffect(() => {
    const fn = () => {
      // updating this will wipe the state of a few SubscriptionQueries further down
      setReconnectionKey(Date.now());
    };

    const removeListener = wsClient.on('reconnected', fn);
    return () => {
      removeListener();
    };
  }, [wsClient]);

  return (
    <ReconnectionMonitorContext.Provider
      value={reconnectionKey}
    >
      {children}
    </ReconnectionMonitorContext.Provider>
  );
};

const ReconnectionMonitorConsumer = ReconnectionMonitorContext.Consumer;

export { ReconnectionMonitorProvider, ReconnectionMonitorConsumer };
export default ReconnectionMonitorContext;
