import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TravellersTable from '../../Results/Travellers/TravellersTable.js';
import { getSourceAppName } from '../../../helpers/url.js';

const sourceAppName = getSourceAppName(window.location.hostname);

const Travellers = (props) => {
  const { tables, board, scoringMethod, refetchTables } = props;

  // on bridgemate, refetch travellers every 30s
  // so they can include updated offline results
  useEffect(() => {
    if (sourceAppName !== 'bridgemate') { return; }

    const interval = setInterval(() => {
      refetchTables();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchTables]);

  return (
    <TravellersTable
      tables={tables}
      boardNumber={board.number}
      scoringMethod={scoringMethod}
      showMPs={false}
    />
  );
};

Travellers.propTypes = {
  tables: PropTypes.array.isRequired,
  board: PropTypes.object.isRequired,
  scoringMethod: PropTypes.string.isRequired,
  refetchTables: PropTypes.func.isRequired
};

export default Travellers;
