import React from 'react';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { messageQueries } from '../../graphql/queries';
import { messageSubscriptions } from '../../graphql/subscriptions';

class Messages extends React.Component {
  state = {
    messages: [],
    typingNotifications: []
  };

  addMessage = (newMessage) => {
    const hasMessage = this.state.messages.find(message => message.id === newMessage.id);

    if (!hasMessage) {
      const messages = [
        ...this.state.messages,
        newMessage
      ];
      this.setState({ messages });
    }
  };

  handleQueryResult = ({ messages }) => {
    if (messages) {
      this.setState({
        messages
      });
    }
  };

  handleMessageSent = ({ data }) => {
    this.addMessage(data.sendMessage);
  };

  handleSubscriptionData = ({ data }) => {
    const event = data && data.messageFeed;

    if (event && event.type === 'Message') {
      this.addMessage(event.message);
    } else if (event && (event.type === 'Typing' || event.type === 'EndTyping')) {
      const newNotification = event.typingNotification;

      if (!newNotification.user) { return; }

      const oldNotificationIdx = this.state.typingNotifications.findIndex(notification => newNotification.user.id === notification.user.id);

      const typingNotifications = this.state.typingNotifications.concat();

      // if the notification is for a user typing
      if (event.type === 'Typing') {
        // replace the one we already had, if we do have one
        if (oldNotificationIdx > -1) {
          typingNotifications.splice(oldNotificationIdx, 1, newNotification);
        } else {
          // otherwise add it to our list
          typingNotifications.push(newNotification);
        }
      } else if (event.type === 'EndTyping') {
        // with notifications for a user ending their typing,
        // simply remove any existing notifications for them
        if (oldNotificationIdx > -1) {
          typingNotifications.splice(oldNotificationIdx, 1);
        }
      }

      this.setState({ typingNotifications });
    }
  };

  render() {
    return (
      <Query
        query={messageQueries.messages}
        variables={{ threadId: this.props.threadId }}
        onCompleted={this.handleQueryResult}
        fetchPolicy='no-cache'
      >
        {() => (
          <PPSubscription
            subscription={messageSubscriptions.messageFeed}
            variables={{ threadId: this.props.threadId }}
            onSubscriptionData={this.handleSubscriptionData}
          >
            {() => {
              return this.props.children({
                messages: this.state.messages,
                typingNotifications: this.state.typingNotifications,
                onMessageSent: this.handleMessageSent
              });
            }}
          </PPSubscription>
        )}
      </Query>
    );
  }
}

export default Messages;
