import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { resultSubscriptions } from '../../graphql/subscriptions/';
import { tableQueries } from '../../graphql/queries/';

class Scorecards extends React.Component {
  handleResultSubscriptionData = (refetch) => {
    refetch();
  }

  render() {
    const { pairId } = this.props;
    return (
      <Query
        query={tableQueries.tablesForScorecards}
        variables={{ pairId: pairId }}
        fetchPolicy='no-cache'
      >
        {({ refetch: refetchTables, data: tableData }) => (
          <PPSubscription
            subscription={resultSubscriptions.resultsUpdate}
            onSubscriptionData={() => { this.handleResultSubscriptionData(refetchTables); }}
          >
            {() => {
              if (!tableData) { return null; }

              return this.props.children({
                tables: tableData.tablesForScorecards
              });
            }}
          </PPSubscription>
        )}
      </Query>
    );
  }
};

Scorecards.propTypes = {
  pairId: PropTypes.string
};

export default Scorecards;
