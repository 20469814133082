import React, { useEffect, useContext } from 'react';
import Countdown from './Countdown.js';
import { FinishedTablesForRound } from '../Queries';
import FinishedBoard from '../FinishedBoard.js';
import SoundEffectsContext from '../SoundEffects/SoundEffectsContext.js';
import { useTranslation } from 'react-i18next';

const NewBoardStarting = (props) => {
  const { table, onCountdownEnd } = props;
  const playSound = useContext(SoundEffectsContext);
  const { t } = useTranslation();

  useEffect(() => {
    playSound('newBoard');
  }, [playSound]);

  return (
    <>
      <FinishedTablesForRound
        tableNumber={table.tableNumber}
        roundNumber={table.roundNumber}
      >
        {(tables) => {
          if (!tables || !tables.length) { return null; }

          return (
            <FinishedBoard
              table={tables[tables.length - 1]}
            />
          );
        }}
      </FinishedTablesForRound>
      <Countdown
        label={t('next-board-starting-in')}
        seconds={5}
        onCountdownEnd={onCountdownEnd}
        size='small'
      />
    </>
  );
};

export default NewBoardStarting;
