import React from 'react';
import PropTypes from 'prop-types';
import StatusItem from './StatusItem.js';
import { useTranslation } from 'react-i18next';

const RoundsCount = (props) => {
  const { session } = props;
  const { t } = useTranslation();

  return (
    <StatusItem
      label={t('round')}
      text={(session.roundsCompleted + 1) + '/' + session.numRounds}
    />
  );
};

RoundsCount.propTypes = {
  session: PropTypes.object.isRequired
};

export default RoundsCount;
