import React from 'react';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { userQueries } from '../../graphql/queries/';
import { userSubscriptions } from '../../graphql/subscriptions/';
import { addUserFromSubscription } from '../../helpers/users.js';

class Users extends React.Component {
  state = {
    users: []
  };

  handleUserSubscriptionData = ({ data }) => {
    const updatedUser = data.userUpdate;
    const newUsers = addUserFromSubscription(this.state.users, updatedUser);

    this.setState({
      users: newUsers
    });
  };

  handleUserQueryResult = ({ users }) => {
    this.setState({ users });
  };

  render() {
    return (
      <Query
        query={userQueries.users}
        onCompleted={this.handleUserQueryResult}
        fetchPolicy='no-cache'
      >
        {() => (
          <PPSubscription
            subscription={userSubscriptions.userUpdate}
            onSubscriptionData={this.handleUserSubscriptionData}
          >
            {() => {
              return this.props.children(this.state.users);
            }}
          </PPSubscription>
        )}
      </Query>
    );
  }
}

export default Users;
