import React from 'react';
import PropTypes from 'prop-types';
import { userSettingsQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';

class CurrentUserSettings extends React.Component {
  render() {
    const { children, ...rest } = this.props;

    return (
      <PPQuery
        query={userSettingsQueries.currentUserSettings}
        {...rest}
      >
        {data => {
          return children(data.currentUserSettings);
        }}
      </PPQuery>
    );
  }
}

CurrentUserSettings.propTypes = {
  children: PropTypes.func.isRequired
};

export default CurrentUserSettings;
