import React, { useCallback, useState, useEffect, useContext } from 'react';
import CardPlay from './CardPlay.js';
import { auctionHelpers } from 'shared-helpers';
import { isOurTurn, getPlayersBySeat, wasClaimRejected } from '../../../helpers/game.js';
import { tableMutations } from '../../../graphql/mutations';
import { usePPMutation, useLocalPlay } from '../../../hooks/';
import UserSettingsContext from '../../UserSettings/UserSettingsContext.js';
import Contract from './Contract.js';
import TricksCount from './TricksCount.js';
import SoundEffectsContext from '../../SoundEffects/SoundEffectsContext.js';
import PlayerSeats from '../PlayerSeats/PlayerSeats.js';
import BidsOverlay from './BidsOverlay.js';

const { whoIsDummy } = auctionHelpers;

const GLOW_DURATION = 4000;

const Play = (props) => {
  const {table, forceCardsFaceUp, onCardPlayed} = props;
  const [playCard] = usePPMutation(tableMutations.playCard, { memoize: true });
  const { confirmClick, autoPlaySingletons, simplifiedCards, simplifiedLayout } = useContext(UserSettingsContext);
  const playSound = useContext(SoundEffectsContext);
  const [called, setCalled] = useState(false);

  const {
    localTable,
    onCardPlayed: onLocalTableCardPlayed,
    onCardAnimationComplete: onLocalTableCardAnimationComplete
  } = useLocalPlay(table);

  let lastTrick;

  if (localTable.cardPlay.length) {
    lastTrick = localTable.cardPlay[localTable.cardPlay.length - 1];
  }

  const lastTrickCards = lastTrick ? lastTrick.cards : [];
  const trickNumber = lastTrick ? lastTrick.index : 0;

  const handleCardClick = useCallback(async (card, autoplayed) => {
    if (called) { return; }

    // this will update the local version of `table`,
    // so the card appears to have been played instantly
    //
    // don't do it for autoplayed cards because this somehow interferes
    // with how notifications are received
    if (!autoplayed) {
      onLocalTableCardPlayed(card);
    }

    // this will disable any subsequent clicks of cards until the animation is complete
    // e.g. to prevent users from accidentally playing cards when double clicking
    setCalled(true);

    const result = await playCard({
      variables: {
        tableId: table.id,
        ...card,
        autoplayed
      }
    });

    onCardPlayed(result);
  }, [table.id, playCard, called, onCardPlayed, setCalled, onLocalTableCardPlayed]);

  const handleAnimationComplete = useCallback(() => {
    onLocalTableCardAnimationComplete();

    setCalled(false);
  }, [onLocalTableCardAnimationComplete, setCalled]);

  const allCardsPlayed = localTable.cardPlay.reduce((allCards, trick) => allCards.concat(trick.cards), []);

  // get deal with visibility for each card - whether it's been played yet or not
  const dealWithVisibility = localTable.board.deal.map(dealCard => {
    const cardFound = allCardsPlayed.find(card => card.suit === dealCard.suit && card.rank === dealCard.rank);

    return {
      ...dealCard,
      visible: !cardFound
    };
  });

  let canPlay;
  if (localTable.directorCallStatus === 'DirectorCalled' || localTable.claim) {
    canPlay = false;
  } else {
    canPlay = isOurTurn({
      declarer: localTable.declarer,
      viewpoint: localTable.viewpoint,
      activeSeat: localTable.activeSeat
    });
  }

  useEffect(() => {
    if (canPlay) {
      playSound('yourTurn');
    }
  }, [canPlay, playSound]);

  let exposeCardsForSeat;

  if (localTable.claim) {
    const atLeastOneRejection = wasClaimRejected(localTable.claim);

    // expose the hand of the player that's claiming
    // if the claim is rejected, show everybody's hand
    exposeCardsForSeat = atLeastOneRejection ? 'all' : localTable.claim.seat;
  }

  if (!localTable.contract || !localTable.declarer) {
    return null;
  }

  return (
    <>
      <CardPlay
        onCardClick={handleCardClick}
        onAnimationComplete={handleAnimationComplete}
        trick={lastTrickCards}
        animationSpeed={125}
        animationStartDelay={0}
        deal={dealWithVisibility}
        trickNumber={trickNumber}
        activeSeat={localTable.activeSeat}
        trickEndDelay={100}
        canPlay={canPlay}
        autoPlaySingletons={autoPlaySingletons.value}
        confirmClick={confirmClick.value}
        dummy={whoIsDummy(localTable.declarer)}
        viewpoint={localTable.viewpoint || 'S'}
        contract={localTable.contract}
        exposeCardsForSeat={exposeCardsForSeat}
        forceCardsFaceUp={forceCardsFaceUp}
        simplifiedCards={simplifiedCards.value}
        simplifiedLayout={simplifiedLayout.value}
        trickOverlay={
          allCardsPlayed.length === 0 && (
            <BidsOverlay
              table={localTable}
              shrink={forceCardsFaceUp}
            />
          )
        }
      />
      <PlayerSeats
        playersBySeat={getPlayersBySeat(table)}
        viewpoint={table.viewpoint}
        activeSeat={localTable.activeSeat}
        dealer={table.board.dealer}
        declarer={table.declarer}
        vulnerable={table.board.vulnerable}
        trick={lastTrick}
        glowDuration={GLOW_DURATION + 350}
      />
      <div style={{
        position: 'absolute',
        right: '1.5rem',
        bottom: '1rem',
      }}>
        <TricksCount
          nsTricks={table.nsTricks}
          ewTricks={table.ewTricks}
          viewpoint={table.viewpoint || 'S'}
        />
      </div>
      {allCardsPlayed.length > 0 && (
        <div style={{
          position: 'absolute',
          left: '1.5rem',
          bottom: '1rem'
        }}>
          <Contract
            contract={table.contract}
            declarer={table.declarer}
          />
        </div>
      )}
    </>
  );
};

export default Play;
