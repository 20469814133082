import React from 'react';
import { Route } from 'react-router-dom';
import { getLoginUrl } from '../../helpers/url.js';
import { isAuthenticated, isAuthorised } from '../../helpers/auth.js';

const PrivateRoute = ({
  component,
  authorisedUserTypes,
  currentUser,
  currentSession,
  ...rest
}) => {
  if (!isAuthenticated() || !isAuthorised(authorisedUserTypes, currentUser)) {
    window.location.href = getLoginUrl();
    return null;
  }
  return (
    <Route {...rest} render={() => {
      return (
        React.createElement(component, {
          ...rest,
          currentUser,
          currentSession
        })
      )
    }} />
  )
};

export default PrivateRoute;
