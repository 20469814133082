import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Pair from './Pair.js';
import Countdown from './Countdown.js';
import SoundEffectsContext from '../SoundEffects/SoundEffectsContext.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    maxWidth: '38rem',
    margin: '0 auto'
  },
  section: {
    paddingBottom: '2rem'
  },
  itemLabel: {
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    opacity: 0.7,
    textAlign: 'center'
  },
  itemText: {
    fontSize: '2rem',
    textAlign: 'center'
  },
  pair: {
    textAlign: 'left'
  },
  vs: {
    margin: 0,
    padding: '0.5rem 0',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 'bold',
    opacity: 0.7,
    textAlign: 'center'
  }
}));

const NextRoundStarting = (props) => {
  const { table, session, onCountdownEnd } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const playSound = useContext(SoundEffectsContext);

  useEffect(() => {
    playSound('newRound');
  }, [playSound]);

  return (
    <Grid
      className={classes.container}
      container
      direction='column'
      justify='center'
    >
      <Grid
        item
        container
        className={classes.section}
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item>
          <div className={classes.itemLabel}>{t('round')}</div>
          <div className={classes.itemText}>{table.roundNumber + 1}/{session.numRounds}</div>
        </Grid>
        <Grid item>
          <div className={classes.itemLabel}>{t('time-limit')}</div>
          <div className={classes.itemText}>{session.roundTimeLimit}min</div>
        </Grid>
      </Grid>
      <br />
      <Grid
        item
        container
        className={classes.section}
        direction='column'
        alignItems='stretch'
      >
        <Grid item className={classes.pair}>
          <Pair
            pair={table.nsPair}
          />
        </Grid>
        <Grid item className={classes.vs}>
          {t('vs-versus')}
        </Grid>
        <Grid item className={classes.pair}>
          <Pair
            pair={table.ewPair}
          />
        </Grid>
      </Grid>
      <br />
      <Countdown
        label={t('round-starting-in')}
        seconds={3}
        onCountdownEnd={onCountdownEnd}
      />
    </Grid>
  );
};

export default NextRoundStarting;
