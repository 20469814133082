import useScreenDimensions from './useScreenDimensions.js';

// at these sizes, force certain settings
// e.g. simplified cards
const useSmallScreenSettings = () => {
  const { width, height } = useScreenDimensions();
  const simplifiedLayout = width <= 1194 || height <= 650;
  const simplifiedCards = width <= 1194 || height <= 800;

  return { simplifiedLayout, simplifiedCards };
};

export default useSmallScreenSettings;
