import gql from 'graphql-tag';

const resultSubscriptions = {
  resultsUpdate: gql`
    subscription {
      resultsUpdate {
        sessionId
      }
    }
  `
};

export default resultSubscriptions;
