import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NewRoundStarting from './NewRoundStarting.js';
import NewBoardStarting from './NewBoardStarting.js';
import { useScreenDimensions } from '../../hooks';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0, top: 0, right: 0, bottom: 0,
    overflow: 'hidden',
    overflowY: 'auto',
    textAlign: 'center'
  },
  block: {
    display: 'flex'
  },
  container: {
    width: '70rem',
    height: '100%',
    margin: '0 auto',
    padding: '3rem 0'
  }
}));

// the first time the auction starts, we want to show an interstitial screen for a few seconds
// we can't do this server-side because AWS Lambda can't simply return tables with a Starting status,
// wait then update the status to Auction
const GameStartTransition = (props) => {
  const { children, table, session } = props;
  const classes = useStyles();
  const screenDimensions = useScreenDimensions();

  // set root styling based on screen height
  let rootDisplayClass = null;
  if (screenDimensions.height <= 750) {
    rootDisplayClass = classes.block;
  }

  const status = table.status;
  const timeSinceTableStarted = new Date() - new Date(table.dateTime);

  let ScreenComponent = null;

  if (table.orderInRound === 0) {
    ScreenComponent = NewRoundStarting;
  } else {
    ScreenComponent = NewBoardStarting;
  }

  let initialShowingScreen = status === 'Auction' && timeSinceTableStarted < 5000;
  const [showingScreen, setShowingScreen] = useState(initialShowingScreen);

  const handleCountdownEnd = useCallback(() => {
    setShowingScreen(null);
  }, [setShowingScreen]);

  if (showingScreen) {
    return (
      <div className={clsx(classes.root, rootDisplayClass)}>
        <div className={classes.container}>
          <ScreenComponent
            table={table}
            session={session}
            onCountdownEnd={handleCountdownEnd}
          />
        </div>
      </div>
    );
  }

  return children;
};

export default GameStartTransition;
