import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import PairSelect from './PairSelect.js';
import { getSourceAppName } from '../../../helpers/url.js';
import { getScorecardsTableData, getPairNames } from '../../../helpers/results.js';
import { useTranslation } from 'react-i18next';

const sourceAppName = getSourceAppName(window.location.hostname);

const useStyles = makeStyles(() => ({
  container: {
    margin: '0 auto',
    maxWidth: '140rem',
    '& a': {
      color: '#222'
    }
  },
  cardContent: {
    padding: '0 !important',
    borderTop: '1px solid #ddd'
  }
}));

const Scorecards = (props) => {
  const { pairs, tables, boards, scoringMethod } = props;
  let { pairId } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  let currentPairNames;

  if (!pairs.length) {
    return null;
  }

  // no pair available, pick an arbitrary one from the list
  if (!pairId) {
    pairId = pairs[0].id;
  }
  const currentPair = _.find(pairs, { id: pairId });
  currentPairNames = getPairNames(currentPair);

  const rows = getScorecardsTableData(pairId, tables, boards, sourceAppName, scoringMethod);

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={2}
        wrap='nowrap'
      >
        <Grid item>
          <PairSelect
            pairs={pairs}
          />
        </Grid>
        <Grid item xs>
          <Card>
            <CardHeader
              title={currentPairNames}
            />
            <CardContent className={classes.cardContent}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t('board')}
                      </TableCell>
                      <TableCell>
                        {t('versus')}
                      </TableCell>
                      <TableCell>
                        {t('bid')}
                      </TableCell>
                      <TableCell>
                        {t('by')}
                      </TableCell>
                      <TableCell>
                        {t('result')}
                      </TableCell>
                      <TableCell>
                        {t('score')}
                      </TableCell>
                      <TableCell>
                        {scoringMethod === 'MatchPoints' ? t('mps') : t('imps')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      rows.map((row, i) => (
                        <TableRow key={'row' + i}>
                          <TableCell>
                            <Link to={'/director/results/travellers/' + row.board}>
                              {row.board}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={'/director/results/scorecards/' + row.versusId}>
                              {row.versus}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {row.bid}
                          </TableCell>
                          <TableCell>
                            {t(row.by && row.by.toLowerCase())}
                          </TableCell>
                          <TableCell>
                            {row.result}
                          </TableCell>
                          <TableCell>
                            {row.score}
                          </TableCell>
                          <TableCell>
                            {row.mps}
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

Scorecards.propTypes = {
  pairId: PropTypes.string,
  pairs: PropTypes.array.isRequired,
  tables: PropTypes.array.isRequired,
  boards: PropTypes.array.isRequired,
  scoringMethod: PropTypes.string.isRequired
};

export default Scorecards;
