import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = {
  container: {
    background: 'rgba(0,0,0,0.25)',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '1rem',
    padding: '1rem 1rem',
    margin: '0 auto',
    width: '100%',
    maxWidth: '20rem',
    '& span': {
      display: 'block',
      fontSize: '10rem',
      lineHeight: '10rem',
      marginTop: '0.5rem'
    }
  },
  small: {
    maxWidth: '100%',
    marginTop: '1rem',
    '& span': {
      fontSize: '4rem',
      lineHeight: '4rem'
    }
  }
};

// This is intentionally done as a class component - trying to balance between state
// and `onCountdownEnd` from props led to some odd-to-diagnose bugs

class Countdown extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      secondsLeft: props.seconds
    };
    this.interval = null;
  }

  componentDidMount () {
    this.interval = setInterval(() => {
      this.setState(({ secondsLeft }) => {
        return { secondsLeft: secondsLeft - 1 };
      });
    }, 1000);
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  componentDidUpdate () {
    if (this.state.secondsLeft < 1) {
      this.props.onCountdownEnd();
    }
  }

  render () {
    return (
      <div className={clsx(this.props.classes.container, (this.props.size && this.props.size === 'small') && this.props.classes.small)}>
        {this.props.label}
        <span>
          {this.state.secondsLeft}
        </span>
      </div>
    );
  }
}

export default withStyles(styles)(Countdown);
