import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LobbyDialog from './LobbyDialog.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    marginTop: 10,
    color: 'red'
  }
}));

const getMessageFromCode = (code, t) => {
  switch (code) {
    case 'SESSION_PAIR_MISMATCH':
      return t('the-number-of-pairs-entered-doesnt-match');
    case 'SESSION_PARAMETERS_ERROR':
      return t('there-was-a-problem-starting-the-session') + ' ' + t('check-number-of-pairs');
    default:
      return t('there-was-a-problem-starting-the-session') + ' ' + t('try-again-or-contact-support');
  }
};

const HybridSessionStartModal = (props) => {
  const { session, pairs, onClose, containerRef } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <LobbyDialog
      onClose={onClose}
      title={t('starting-game', { game: session.name })}
      containerRef={containerRef}
      actions={
        <Button onClick={onClose}>{t('cancel')}</Button>
      }
    >
      {t('the-online-section-is-ready-to-start', { n: pairs.length })}
      <br /><br />
      {t('please-start-the-session-using-your-scoring-software')}
      <br /><br />
      {t('please-note-that-any-players')}
      {
        session.errorCode && (
          <div className={classes.errorMessage}>
            {getMessageFromCode(session.errorCode, t)}
          </div>
        )
      }

    </LobbyDialog>
  );
};

HybridSessionStartModal.propTypes = {
  children: PropTypes.node.isRequired,
  containerRef: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  pairs: PropTypes.array.isRequired,
  onClose: PropTypes.func
};

export default HybridSessionStartModal;
