import React from 'react';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import TableUsers from './TableUsers.js';
import { tableQueries } from '../../graphql/queries/';
import { tableSubscriptions } from '../../graphql/subscriptions/';
import { populatePairsWithUserData } from '../../helpers/users.js';
import _ from 'lodash';

const END_STATUSES = ['InactiveFinished', 'WaitingForMovement', 'SessionFinished'];

class Table extends React.Component {
  state = {
    table: null,
    nextTableId: null,
    users: []
  };

  handleTableQueryResult = (result) => {
    const table = result.currentTableForPlayer || result.tableById;
    this.setState({ table });
  };

  handleTableSubscriptionData = ({ data }) => {
    const { table: updatedTable, nextTableId } = data.tableUpdate;

    /**
     * It's a limitation of Lambda that the API can't return a response + notification for the last
     * card played, then wait 5s, then emit another notification for the game to finish.
     *
     * Instead we send the two notifications simultaneously, and here on the client-side we
     * artificially delay the game finish one by a few seconds
     */
    const isEndOfPlay = this.state.table.status === 'Play' && END_STATUSES.indexOf(updatedTable.status) > -1;
    const isEndOfAuction = this.state.table.status === 'Auction' && END_STATUSES.indexOf(updatedTable.status) > -1;

    if (isEndOfPlay || isEndOfAuction) {
      setTimeout(() => {
        this.setState({
          table: {
            ...this.state.table,
            ...updatedTable
          },
          nextTableId
        });
      }, 3000);

      return;
    }

    this.setState({
      table: {
        ...this.state.table,
        ...updatedTable
      },
      nextTableId
    });
  };

  handleDirectorCallSubscriptionData = ({ data }) => {
    const { directorCallStatus, directorCallerId } = data.directorCallUpdate;

    const newTable = {
      ...this.state.table,
      directorCallStatus,
      directorCallerId
    };

    this.setState({ table: newTable });
  };

  refetch = async (refetchFn) => {
    const result = await refetchFn();
    this.handleTableQueryResult(result.data);
  };

  render() {
    let tableQuery;
    let variables;

    if (this.props.tableId) {
      tableQuery = tableQueries.tableById;
      variables = { id: this.props.tableId };
    } else {
      tableQuery = tableQueries.currentTableForPlayer;
    }

    return (
      <Query
        query={tableQuery}
        variables={variables}
        fetchPolicy='no-cache'
        onCompleted={this.handleTableQueryResult}
      >
        {
          ({ refetch: refetchTable, loading, error }) => {
            if (loading) { return null; }

            if (error || !this.state.table) {
              return this.props.children({
                table: null,
                refetch: () => { this.refetch(refetchTable); }
              });
            }

            return (
              <PPSubscription
                subscription={tableSubscriptions.tableUpdate}
                variables={{ id: this.state.table.id }}
                onSubscriptionData={this.handleTableSubscriptionData}
              >
                {() => (
                  <PPSubscription
                    subscription={tableSubscriptions.directorCallUpdate}
                    variables={{ tableId: this.state.table.id }}
                    onSubscriptionData={this.handleDirectorCallSubscriptionData}
                  >
                    {() => (
                      <TableUsers table={this.state.table}>
                        {(users) => {
                          const table = _.cloneDeep(this.state.table);

                          // populate each pair on the table with user details
                          // (e.g. online status)
                          const [nsPair, ewPair] = populatePairsWithUserData({
                            pairs: [table.nsPair, table.ewPair],
                            users
                          });
                          table.nsPair = nsPair;
                          table.ewPair = ewPair;

                          return this.props.children({
                            table,
                            nextTableId: this.state.nextTableId,
                            refetch: () => { this.refetch(refetchTable); }
                          });
                        }}
                      </TableUsers>
                    )}
                  </PPSubscription>
                )}
              </PPSubscription>
            )
          }
        }
      </Query>
    );
  }
}

export default Table;
