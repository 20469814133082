import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

const GenericSpinner = () => {
  return (
    <Backdrop invisible open>
      <CircularProgress />
    </Backdrop>
  );
};

export default GenericSpinner;
