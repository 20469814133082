import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import DealDirectionRow from './DealDirectionRow.js';
import { cardHelpers } from 'shared-helpers';
const { getCardsForSuit } = cardHelpers;

const styles = {
  ul: {
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    margin: '0.5em 0 0 0.6em !important'
  }
};

const DealDirectionBody = (props) => {
  const { classes, cards, ...other } = props;
  const suits = ['S', 'H', 'D', 'C'];
  return (
    <ul className={classes.ul}>
      {
        suits.map((suit, index) => {
          return (
            <DealDirectionRow
              key={index}
              suit={suit}
              cards={getCardsForSuit(cards, suit)}
              {...other}
            />
          )
        })
      }
    </ul>
  );
}

DealDirectionBody.propTypes = {
  classes: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired
}

export default withStyles(styles)(DealDirectionBody);
