import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import BiddingItem from './BiddingItem.js';
import MiddleMessage from './MiddleMessage.js';
import clsx from 'clsx';
import { auctionHelpers } from 'shared-helpers';
import { isBoardPassedOut } from '../../helpers/auction.js';
import { Translation } from 'react-i18next';

const {
  isHigherThanCurrentBid,
  hasEnded,
  doubleOption,
  wereLastThreeBidsAllPass,
  getContract
} = auctionHelpers;

const styles = {
  biddingBox: {
    position: 'relative',
    background: 'rgba(0,0,0,0.4)',
    width: '40em',
    minWidth: '40em',
    height: '41.4em',
    borderRadius: '0.8em',
    overflow: 'hidden',
    fontWeight: 'bold',
    userSelect: 'none'
  },
  ul: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: 0,
    margin: 0
  },
  li: {
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '-1.4em',
    width: '105%',
    '&:last-child': {
      margin: 0
    }
  },
  pass: {
    position: 'static',
    backgroundImage: 'url("/img/components/BiddingBox/bg_pass.svg")',
    backgroundSize: 'contain',
    width: '16.1em',
    margin: '0 -0.1em 0 0',
    color: '#417505',
    '&:after': {
      content: '""',
      display: 'block',
      height: '2.5em',
      background: 'transparent url("/img/components/BiddingBox/bg_pass_bottom.svg") no-repeat left bottom',
      backgroundSize: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0,
      pointerEvents: 'none',
      zIndex: 101,
      width: '95%'
    },
    '&:hover': {
      backgroundImage: 'url("/img/components/BiddingBox/bg_pass_hover.svg")',
      '&:after': {
        backgroundImage: 'url("/img/components/BiddingBox/bg_pass_bottom_hover.svg")'
      }
    }
  },
  x: {
    backgroundImage: 'url("/img/components/BiddingBox/bg_double.svg")',
    backgroundSize: 'contain',
    width: '12.8em',
    margin: '0 -0.9em 0 0',
    color: '#d0021b',
    '&:hover': {
      backgroundImage: 'url("/img/components/BiddingBox/bg_double_hover.svg")'
    },
    '&$disabled': {
      backgroundImage: 'url("/img/components/BiddingBox/bg_double_disabled.svg") !important'
    }
  },
  xx: {
    backgroundImage: 'url("/img/components/BiddingBox/bg_double_double.svg") !important',
    backgroundSize: 'contain',
    width: '12.1em !important',
    margin: '0 !important',
    color: '#3b76bb !important',
    '&:hover': {
      backgroundImage: 'url("/img/components/BiddingBox/bg_double_double_hover.svg") !important'
    },
    '&$disabled': {
      backgroundImage: 'url("/img/components/BiddingBox/bg_double_double_disabled.svg") !important'
    }
  },
  disabled: {
    color: '#999 !important',
    cursor: 'not-allowed'
  },
  dimmed: {
    visibility: 'hidden'
    // opacity: 0.5,
    // pointerEvents: 'none',
    // filter: 'grayscale(100%)'
  }
};

class BiddingBox extends React.Component {
  isDisabled = (value, isFinalRow) => {
    const { auction, dealer } = this.props;

    // if auction ended then disable
    if (hasEnded(auction) || isBoardPassedOut(auction)) {
      return true;
    }

    // can we show double or redouble
    if (isFinalRow) {
      const double = doubleOption(auction, dealer);
      switch(value) {
        case 'AP':
          // you can only AP when there is a valid contract
          if (getContract(auction, dealer) !== undefined) {
            return false;
          }
          break;
        case 'Pass':
          // if value is 'Pass' and last 3 bids were also pass
          // we need to disable it
          if (wereLastThreeBidsAllPass(auction)) {
            // if 3 players have passed so far, the fourth player can too
            // this would pass out and therefore scrap the game
            if (auction.length === 3) {
              return false;
            }
            return true;
          }
          return false;
        case 'X':
          if (double === 'X') {
            return false;
          }
          break;
        case 'XX':
          if (double === 'XX') {
            return false;
          }
          break;
        default:
          break;
      }
      return true;
    }

    // if auction empty then enable
    if (this.props.auction.length === 0) {
      return false;
    }

    if (isHigherThanCurrentBid(auction, 'N', value)) {
      return false;
    }

    return true;
  }

  render () {
    const { classes, auction, clickable, onClick } = this.props;

    const rowValues = ['1', '2', '3', '4', '5', '6', '7'];
    const rowOptions = ['NT', 'S', 'H', 'D', 'C'];
    const lastRow = ['Pass', 'X', 'XX'];

    let zIndex = 10;

    const ended = hasEnded(auction) || isBoardPassedOut(auction);

    return (
      <Translation>
        {(t => {
            return (
              <div className={clsx(classes.biddingBox, !clickable && classes.dimmed)}>
                <ul className={classes.ul}>
                  {
                    rowValues.map(value => {
                      return (
                        <li className={classes.li} key={value} style={{zIndex:10-zIndex}}>
                        {
                          rowOptions.map((option, index) => {
                            const disabled = this.isDisabled(value + option);
                            if (!disabled) {
                              zIndex--;
                              return (
                                <BiddingItem
                                  key={index}
                                  suit={option}
                                  value={value}
                                  onClick={onClick}
                                  zIndex={zIndex}
                                  clickable={clickable}
                                  disabled={false}
                                />
                              );
                            }
                            return null;
                          })
                        }
                        </li>
                      )
                    })
                  }
                  <li className={classes.li} style={{zIndex:10-zIndex}}>
                  {
                    lastRow.map((value, index) => {
                      const disabled = this.isDisabled(value, true);
                      // if (!disabled) {
                        zIndex--;
                        return (
                          <BiddingItem
                            key={index}
                            value={value}
                            onClick={onClick}
                            className={clsx(classes[value.toLowerCase()], disabled ? classes.disabled : null)}
                            zIndex={zIndex}
                            clickable={clickable}
                            disabled={disabled}
                          />
                        );
                      // }
                      // return null;
                    })
                  }
                  </li>
                </ul>
                {
                  ended && (
                    <MiddleMessage text={t('auction-complete')} />
                  )
                }
              </div>
            )
          })}
      </Translation>
    )
  }
}

BiddingBox.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  dealer: PropTypes.string.isRequired,
  auction: PropTypes.array.isRequired,
  clickable: PropTypes.bool
}

export default withStyles(styles)(BiddingBox);
