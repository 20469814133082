import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem, Paper } from '@material-ui/core';
import { tableMutations } from '../../../graphql/mutations';
import { usePPMutation } from '../../../hooks/';
import { useTranslation } from 'react-i18next';
import TranslationWithJSX from '../../TranslationWithJSX.js';
import SpinnerButton from '../../SpinnerButton.js';

const useStyles = makeStyles(theme => ({
  form: {
    padding: '1em 2em 2em 2em',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '2em'
  },
  tricksToAward: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
    fontSize: '1.6em'
  }
}));

const ResolveClaim = (props) => {
  const { table } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const claim = table.claim;

  const [tricksAwarded, setTricksAwarded] = useState(claim.tricksClaimed);
  const [resolveClaim, { called, error }] = usePPMutation(tableMutations.resolveClaim, { memoize: true });

  const maxTricksPossible = 13 - (table.nsTricks + table.ewTricks);
  const trickOptions = [];
  for (let i = maxTricksPossible; i >= 0; i--) {
    trickOptions.push(i);
  }

  const claimingPair = (claim.seat === 'N' || claim.seat === 'S')
    ? 'ns'
    : 'ew';

  const handleDropdownChange = useCallback((e) => {
    setTricksAwarded(e.target.value);
  }, [setTricksAwarded]);

  const handleSubmit = useCallback(() => {
    if (called) { return; }

    resolveClaim({
      variables: {
        tricksAwarded,
        tableId: table.id
      }
    });
  }, [resolveClaim, tricksAwarded, table.id, called]);

  const tricksNumSelect = (
    <FormControl>
      <Select
        labelId='resolve-claim-tricks-label'
        id='resolve-claim-tricks'
        value={tricksAwarded}
        onChange={handleDropdownChange}
      >
        {trickOptions.map(i => (
          <MenuItem key={i} value={i}>{i}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <div>
      <Paper className={classes.form}>
        <div className={classes.tricksToAward}>
          <TranslationWithJSX
            id={`award-n-tricks-to-${claimingPair}`}
            translationProps={{ n: tricksNumSelect }}
          />
        </div>
        <SpinnerButton
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          loading={called && !error}
        >
          {t('resolve-claim')}
        </SpinnerButton>
      </Paper>
    </div>
  );
};

export default ResolveClaim;
