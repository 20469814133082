// This hook maintains an up-to-date chat when a player has just sent a message
// but the backend has yet to catch up - it makes sending messages feel instantaneous

import { useEffect, useState, useCallback } from 'react';

const useLocalChat = (messages, currentUser, recipients) => {
  // as messages are sent by the user, they're added to this pending array in state
  const [pendingMessages, setPendingMessages] = useState([]);

  const stillPendingMessages = [];

  // first see which ones of the pending messages are actually still pending from the server...
  if (pendingMessages.length && messages.length) {
    pendingMessages.forEach((pendingMessage) => {
      const isInServerMessages = !!messages.find(message => message.text === pendingMessage.text && message.user.id === pendingMessage.user.id);

      if (!isInServerMessages) {
        stillPendingMessages.push(pendingMessage);
      }
    });
  }

  // any messages that are actually pending get added back to the array of messages
  // that gets displayed to the user
  let localMessages = messages.concat();

  if (stillPendingMessages.length) {
    localMessages = localMessages.concat(stillPendingMessages);
  }

  // then, make sure our list of pending messages is up-to-date
  // whenever the server adds new messages
  useEffect(() => {
    if (stillPendingMessages.length === pendingMessages.length) {
      return;
    }

    setPendingMessages(stillPendingMessages.concat());
  }, [stillPendingMessages, pendingMessages.length, setPendingMessages]);

  // when the user sends a message, construct something that looks like what the server would send back
  // and store it as pending
  const handleMessageSent = useCallback((message) => {
    let recipient;

    if (message.recipientId) {
      recipient = recipients.find(recipient => recipient.id === message.recipientId);
    }

    const newMessage = {
      ...message,
      timestamp: new Date().toISOString(),
      recipient: recipient ? { fullName: recipient.name } : undefined,
      user: currentUser
    };

    setPendingMessages((currentPendingMessages) => {
      return [
        ...currentPendingMessages,
        newMessage
      ];
    });
  }, [setPendingMessages, currentUser, recipients]);

  return {
    localMessages,
    onMessageSent: handleMessageSent
  };
};

export default useLocalChat;
