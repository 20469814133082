import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Messages from './Messages.js';
import NewMessage from './NewMessage.js';
import ChatHeader from './ChatHeader.js';
import Grid from '@material-ui/core/Grid';
import { SoundEffectsConsumer } from '../SoundEffects/SoundEffectsContext.js';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocalChat } from '../../hooks';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%'
  },
  content: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  footer: {
    background: theme.palette.block.footer.background,
    padding: '1.2em',
    '@media all and (max-width:1194px)': {
      padding: '0.6em'
    }
  },
  footerDark: {
    background: theme.palette.background.dark
  }
}));

const InnerChat = (props) => {
  const {
    threadId,
    tabs,
    readOnly,
    sendTypingNotifications,
    messages,
    typingNotifications,
    propsOnMessageSent,
    sqOnMessageSent,
    currentUser,
    theme,
    scrollKey
  } = props;
  let {
    placeholderText,
    recipients,
    headerText
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentThreadId, setCurrentThreadId] = useState(threadId);
  const [recipientId, setRecipientId] = useState('');

  let currentTab;

  if (tabs && tabs.length) {
    currentTab = tabs.find(tab => tab.threadId === currentThreadId);
  }

  if (currentTab) {
    placeholderText = currentTab.placeholderText;
    recipients = currentTab.recipients;
  }

  if (recipientId && recipients) {
    const recipient = recipients.find(recipient => recipient.id === recipientId);

    if (recipient) {
      placeholderText = t('send-a-message-to-name', { name: recipient.name });
    }
  }

  const handleTabChange = useCallback((val) => {
    setCurrentThreadId(val);
  }, [setCurrentThreadId]);

  const handleRecipientChange = useCallback((id) => {
    setRecipientId(id);
  }, [setRecipientId]);
  const { localMessages, onMessageSent: onBeforeMessageSend } = useLocalChat(messages, currentUser, recipients);

  const handleMessageSent = (result) => {
    // the subscriptionQuery is interested in the result of the mutation
    // so it can instantly add its message to the existing list
    sqOnMessageSent(result);

    // parent components may also be interested in any messages that get sent
    // (e.g. directors broadcasting to all tables)
    if (propsOnMessageSent) {
      propsOnMessageSent(result);
    }
  };

  return (
    <Grid
      container
      direction='column'
      justify='space-between'
      alignItems='stretch'
      className={classes.container}
    >
      <ChatHeader
        headerText={headerText}
        tabs={tabs}
        currentThreadId={currentThreadId}
        onTabChange={handleTabChange}
        theme={theme}
      />
      <Grid item xs className={classes.content}>
        <SoundEffectsConsumer>
          {(playSound) => (
            <Messages
              messages={localMessages}
              currentUser={currentUser}
              typingNotifications={typingNotifications}
              playSound={playSound}
              scrollKey={scrollKey}
            />
          )}
        </SoundEffectsConsumer>
      </Grid>
      {
        !readOnly && (
          <Grid item>
            <div className={clsx(classes.footer, theme === 'dark' && classes.footerDark)}>
              <NewMessage
                threadId={currentThreadId}
                sendTypingNotifications={sendTypingNotifications}
                onMessageSent={handleMessageSent}
                onBeforeMessageSend={onBeforeMessageSend}
                readOnly={readOnly}
                placeholderText={placeholderText || t('send-a-message')}
                recipientId={recipientId}
                onRecipientChange={handleRecipientChange}
                recipients={recipients}
              />
            </div>
          </Grid>
        )
      }
    </Grid>
  );
};

InnerChat.propTypes = {
  tabs: PropTypes.array,
  threadId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  sendTypingNotifications: PropTypes.bool,
  onMessageSent: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  placeholderText: PropTypes.string,
  headerText: PropTypes.string,
  theme: PropTypes.string,
  scrollKey: PropTypes.string
};

export default InnerChat;
