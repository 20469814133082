import gql from 'graphql-tag';
import { messageFragments } from '../fragments';

const messageMutations = {
  sendMessage: gql`
    mutation (
      $text: String!,
      $threadId: ID!,
      $type: MessageTypeEnum,
      $recipientId: ID
    ) {
      sendMessage(
        text: $text,
        threadId: $threadId,
        type: $type,
        recipientId: $recipientId
      ) {
        ...FullMessage
      }
    }
    ${messageFragments.fullMessage}
  `,
  startTyping: gql`
    mutation (
      $threadId: ID!
    ) {
      startTyping(
        threadId: $threadId
      ) {
        threadId,
        user {
          id
        },
        timestamp
      }
    }
  `,
  endTyping: gql`
    mutation (
      $threadId: ID!
    ) {
      endTyping(
        threadId: $threadId
      ) {
        threadId,
        user {
          id
        },
        timestamp
      }
    }
  `

}

export default messageMutations;
