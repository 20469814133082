import React from 'react';
import {
  Grid,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pair from './Pair.js';
import TableStatus from './TableStatus.js';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#ccc',
    padding: '0.5rem',
    borderRadius: '0.8rem'
  },
  container: {
    background: '#fff',
    padding: '0.5rem 1rem 0.5rem 0',
    borderRadius: '0.5rem'
  },
  tableNumber: {
    fontSize: '4rem',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '15%',
    maxWidth: '10rem'
  },
  players: {
    fontSize: '1.1rem',
    '@media all and (max-width:1194px)': {
      fontSize: '0.8rem'
    }
  },
  vsLabel: {
    fontSize: '1.4rem',
    fontWeight: 'bold'
  },
  success: {
    background: theme.palette.success.main
  },
  warningOrange: {
    background: theme.palette.warning.main
  },
  warningRed: {
    background: theme.palette.error.main
  },
  warningMessage: {
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    paddingTop: '0.4rem'
  }
}));

const Table = (props) => {
  const { table, handleGoToTableClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const hasWarning = table.warning;
  let warningCssClass = null;

  if (hasWarning) {
    switch (table.warning.severity) {
      case 'red':
        warningCssClass = classes.warningRed;
        break;
      default:
        warningCssClass = classes.warningOrange;
        break;
    }
  }

  const waitingForMovement = table.status === 'WaitingForMovement';
  const sessionFinished = table.status === 'SessionFinished';

  return (
    <div className={clsx(classes.root, (waitingForMovement || sessionFinished) && classes.success, hasWarning && !sessionFinished && warningCssClass)}>
      <div className={classes.container}>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          spacing={2}
          wrap='nowrap'
        >
          <Grid item xs>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='center'
              spacing={2}
              wrap='nowrap'
            >
              <Grid item className={classes.tableNumber}>
                {table.tableNumber + 1}
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='center'
                  spacing={1}
                  wrap='nowrap'
                  className={classes.players}
                >
                  {
                    table.isSitOut
                      ? (
                        <Grid item>
                          <Pair pair={table.ewPair} />
                        </Grid>
                      )
                      : (
                        <>
                          <Grid item>
                            <Pair pair={table.nsPair} />
                          </Grid>
                          <Grid item className={classes.vsLabel}>
                            {t('vs-versus')}
                          </Grid>
                          <Grid item>
                            <Pair pair={table.ewPair} />
                          </Grid>
                        </>
                      )
                  }
                </Grid>
              </Grid>
              <Grid item xs>
                <TableStatus table={table} />
              </Grid>
            </Grid>
          </Grid>
          {
            !sessionFinished && (
              <Grid item>
                <Button variant='contained' color='primary' onClick={handleGoToTableClick}>{t('go-to-table')}</Button>
              </Grid>
            )
          }
        </Grid>
      </div>
      {
        hasWarning && !sessionFinished && (
          <div className={classes.warningMessage}>
            {t(table.warning.message).toUpperCase()}
          </div>
        )
      }
    </div>
  );
};

export default Table;
