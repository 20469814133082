import { auctionHelpers } from 'shared-helpers';
const { whoIsDummy } = auctionHelpers;

export const getPlayersBySeat = (table) => {
  const players = {};

  if (table.northPlayerId === table.nsPair.user1.id) {
    players.N = table.nsPair.user1;
    players.S = table.nsPair.user2;
  } else {
    players.N = table.nsPair.user2;
    players.S = table.nsPair.user1;
  }

  if (table.eastPlayerId === table.ewPair.user1.id) {
    players.E = table.ewPair.user1;
    players.W = table.ewPair.user2;
  } else {
    players.E = table.ewPair.user2;
    players.W = table.ewPair.user1;
  }

  return players;
};

export const getPlayerBySeat = (seat, table) => {
  const players = getPlayersBySeat(table);

  return players[seat];
};

// in order to provide a consistent chat thread for multiple boards of the same table,
// we avoid basing the thread ID on the table ID
export const getTableThreadId = (session, table) => {
  return `${session.id}-${table.tableNumber}-${table.roundNumber}`;
};

// a pair should never be able to play another pair more than once
export const getMaxNumberOfRounds = (numPairs, winnerType) => {
  // in one winner movement, a pair can play every other pair regardless of orientation
  if (winnerType === 'OneWinner') {
    // if there's an odd number of pairs, a pair can spend a round sitting out
    if (numPairs % 2 === 1) {
      return numPairs;
    } else {
      return numPairs - 1;
    }
  } else if (winnerType === 'TwoWinner') {
    // in two winner movement, orientations are fixed, so we should never have
    // more rounds than there are tables
    return Math.ceil(numPairs / 2);
  }

  throw new Error('Incorrect winnerType provided. Got: ' + winnerType);
};

// checks if it's current player's turn to play,
// taking a declarer playing as a dummy into account
export const isOurTurn = ({ viewpoint, activeSeat, declarer }) => {
  const dummy = declarer ? whoIsDummy(declarer) : null;

  if (activeSeat === dummy) {
    return viewpoint === declarer;
  } else {
    return viewpoint === activeSeat;
  }
};

export const getPlayerSeatForTable = (userId, table) => {
  if (table.nsPair.user1.id === userId || table.nsPair.user2.id === userId) {
    return table.northPlayerId === userId ? 'N' : 'S';
  } else if (table.ewPair.user1.id === userId || table.ewPair.user2.id === userId) {
    return table.eastPlayerId === userId ? 'E' : 'W';
  }

  return null;
};

export const areOpponents = (seat1, seat2) => {
  if (seat1 === 'N' || seat1 === 'S') {
    return seat2 === 'E' || seat2 === 'W';
  } else if (seat1 === 'E' || seat1 === 'W') {
    return seat2 === 'N' || seat2 === 'S';
  }
};

export const updateCardPlay = (nextTrick, prevCardPlay) => {
  const nextCardPlay = prevCardPlay.concat();
  const idx = nextCardPlay.findIndex(trick => trick.index === nextTrick.index);

  // if this is an existing trick, swap it over with the one we just received
  if (idx > -1) {
    nextCardPlay.splice(idx, 1, nextTrick);
  } else {
    // otherwise add it at the end
    nextCardPlay.push(nextTrick);
  }

  return nextCardPlay;
};

export const wasClaimRejected = (claim) => {
  const atLeastOneRejection = claim.responses.some(response => !response.value);

  return atLeastOneRejection;
};

// whenever any of these claim values changes, we scroll the chat down
// otherwise the chat div scrolls up as it reduces its size whenever the claim div enlarges
export const getClaimScrollKey = (table) => {
  if (!table.claim) { return ''; }

  let key = table.claim.seat;

  key += table.claim.responses.length;
  key += table.nsTricks
  key += table.ewTricks;

  return key;
};
