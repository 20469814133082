import React, { useCallback, useContext } from 'react';
import ActionButton from './ActionButton.js';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import PageReloadContext from '../../PageReloadContext.js';
import { useTranslation } from 'react-i18next';

const RefreshButton = () => {
  const { reload } = useContext(PageReloadContext);
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    reload();

    return false;
  }, [reload]);

  return (
    <ActionButton
      label={t('reload')}
      icon={<RefreshIcon style={{ fontSize: 30 }} />}
      onClick={handleClick}
    />
  );
};

export default RefreshButton;
