import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { resultSubscriptions } from '../../graphql/subscriptions/';
import { tableQueries, boardQueries } from '../../graphql/queries/';

class Hands extends React.Component {
  handleResultSubscriptionData = (refetch) => {
    refetch();
  }

  render() {
    const { boardId } = this.props;
    return (
      <Query
        query={tableQueries.tablesForTravellers(boardId)}
        fetchPolicy='no-cache'
      >
        {({ refetch: refetchTables, data: tableData }) => (
          <Query
            query={boardQueries.boards}
            fetchPolicy='no-cache'
          >
            {({ data: boardData }) => (
              <PPSubscription
                subscription={resultSubscriptions.resultsUpdate}
                onSubscriptionData={() => { this.handleResultSubscriptionData(refetchTables); }}
              >
                {() => {
                  if (!boardData || !tableData) { return null; }

                  return this.props.children({
                    tables: tableData.tablesForResults,
                    boards: boardData.boards
                  });
                }}
              </PPSubscription>
            )}
          </Query>
        )}
      </Query>
    );
  }
};

Hands.propTypes = {
  boardId: PropTypes.string
};

export default Hands;
