import React, { useContext } from 'react';
import { CurrentUser, CurrentUserSettings } from './Components/Queries/';
import { CurrentSession } from './Components/SubscriptionQueries/';
import GenericSpinner from './Components/GenericSpinner.js';
import LanguageLoader from './LanguageLoader.js';
import ReconnectionMonitorContext from './Components/ReconnectionMonitorContext.js';

/**
 * We get user and session information from the Pianola API on-demand
 * e.g. the first time a Pianola Play session is opened, we make a request for the session metadata
 * and the first time a user joins a session we make a request for the user metadata
 *
 * This wrapper allows us to get those two vars at the highest level possible,
 * showing a reasonable message for what could turn out to be a long-ish request
 */

const InitialLoad = (props) => {
  const { children } = props;
  const spinner = (<GenericSpinner/>);
  const reconnectionKey = useContext(ReconnectionMonitorContext);

  return (
    <CurrentSession
      key={reconnectionKey}
      loaderComponent={spinner}
    >
      {(currentSession) => (
        <CurrentUser
          loaderComponent={spinner}
        >
          {(currentUser) => (
            <CurrentUserSettings
              loaderComponent={spinner}
            >
              {(currentUserSettings) => (
                <LanguageLoader
                  currentUser={currentUser}
                >
                  {children(
                    currentSession,
                    currentUser,
                    currentUserSettings
                  )}
                </LanguageLoader>
              )}
            </CurrentUserSettings>
          )}
        </CurrentUser>
      )}
    </CurrentSession>
  );
};

export default InitialLoad;
