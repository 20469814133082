import React from 'react';
import PropTypes from 'prop-types';
import { messageQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class Messages extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <PPQuery query={messageQueries.messages}>
        {data => {
          return children(data.messages);
        }}
      </PPQuery>
    );
  }
}

Messages.propTypes = {
  children: PropTypes.func.isRequired
};

export default Messages;
