import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  card: {
    imageRendering: '-webkit-optimize-contrast'
  },
  enabled: {
    cursor: 'pointer'
  },
  disabled: {
    cursor: 'not-allowed'
  }
}));

const Card = (props) => {
  const { faceDown, simplifiedCards, enabled, onClick } = props;
  let { value } = props;

  const theme = useTheme();

  const classes = useStyles();

  let dir = '/img/cards/';

  if (faceDown) {
    value = theme.game.cardImage;
  } else {
    dir += (simplifiedCards ? 'simplified' : 'normal') + '/'
  }

  return (
    <img
      className={clsx(classes.card, enabled && !faceDown ? classes.enabled : classes.disabled)}
      style={{width: '100%'}}
      src={`${dir}${value}.png`}
      alt={value}
      onClick={(e) => {
        if (enabled && onClick) {
          onClick({
            cardValue: value,
            event: e
          });
        }
      }}
    />
  );
};

Card.propTypes = {
  value: PropTypes.string.isRequired,
  faceDown: PropTypes.bool,
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  cardRefCallback: PropTypes.func
};

export default Card;
