import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.block.content.background,
    borderRadius: '1rem',
    overflow: 'hidden',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#333',
    width: '17rem',
    margin: '0 auto'
  },
  day: {
    background: '#e02020',
    fontSize: '2rem',
    lineHeight: '4.4rem',
    color: '#fff'
  },
  date: {
    background: '#fff',
    fontSize: '8rem',
    lineHeight: '9rem'
  },
  month: {
    fontSize: '2rem',
    lineHeight: '4.4rem',
    '& span': {
      padding: '0 1rem'
    }
  },
  time: {
    background: '#333',
    color: '#fff',
    borderRadius: '0.3rem'
  }
}));

const Calendar = (props) => {
  const { datetime } = props;
  const classes = useStyles();

  const luxonStartingTime = DateTime.fromISO(datetime);

  const day = luxonStartingTime.toFormat('EEEE').toUpperCase();
  const date = luxonStartingTime.toFormat('d');
  const month = luxonStartingTime.toFormat('LLL').toUpperCase();
  const time = luxonStartingTime.toLocaleString(DateTime.TIME_24_SIMPLE);

  return (
    <div className={classes.container}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item className={classes.day}>
          {day}
        </Grid>
        <Grid item className={classes.date}>
          {date}
        </Grid>
        <Grid item className={classes.month}>
          <span>{month}</span>
          <span className={classes.time}>{time}</span>
        </Grid>
      </Grid>
    </div>
  );
};

export default Calendar;
