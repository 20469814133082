import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  badge: {
    display: 'inline-block',
    marginLeft: '0.4em',
    background: '#333',
    color: '#fff',
    borderRadius: '1em',
    minWidth: '1.6em',
    padding: '0 0.5em',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  green: {
    background: '#4B8806'
  },
  red: {
    background: '#C83030'
  },
  yellow: {
    background: '#BE8600'
  },
  blue: {
    background: '#335CA3'
  }
}));

const Badge = (props) => {
  const {
    colour,
    text
  } = props;
  const classes = useStyles();

  return (
    <span className={clsx(classes.badge, classes[colour])}>
      {text}
    </span>
  );
};

Badge.propTypes = {
  colour: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Badge;
