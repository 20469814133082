import React from 'react';
import LobbyPair from '../../../Components/Lobby/LobbyPair.js';

const Pairs = (props) => {
  const { pairs } = props;

  return (
    <>
      {pairs.map(pair => {
        return (
          <LobbyPair
            key={pair.id}
            pair={pair}
            showOnlineIndicator={true}
          />
        );
      })}
    </>
  );
};

export default Pairs;
