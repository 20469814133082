import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '1em 0 0 3.5em',
    fontSize: '1.6em',
    fontWeight: 'bold'
  },
  right: {
    textAlign: 'right',
    margin: '1em 3.5em 0 0'
  },
  role: {
    marginLeft: '0.8em',
    fontSize: '0.8em',
    color: '#999'
  }
}));

const MessageGroupHeader = (props) => {
  const { from } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={clsx(classes.container, from.isCurrentUser && classes.right)}>
      {from.fullName}
      {
        from.type && (
          <span className={classes.role}>{t(from.type).toUpperCase()}</span>
        )
      }
    </div>
  );
};

MessageGroupHeader.propTypes = {
  from: PropTypes.object.isRequired
};

export default MessageGroupHeader;
