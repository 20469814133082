import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import {
  Close as CloseIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from '@material-ui/icons';
import { amber, green, red, blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'none',
    marginBottom: '12px',
    '&:last-child': {
      marginBottom: 0
    }
  },
  success: {
    backgroundColor: green[600],
    color: '#fff'
  },
  error: {
    backgroundColor: red[900],
    color: '#fff'
  },
  info: {
    backgroundColor: blue[700],
    color: '#fff'
  },
  warning: {
    backgroundColor: amber[700],
    color: '#000000de'
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  }
}));

const SnackBarIcon = (props) => {
  switch (props.messageType) {
    case 'success':
      return (
        <CheckCircleIcon className={props.className} />
      );
    case 'error':
      return (
        <ErrorIcon className={props.className} />
      );
    case 'warning':
      return (
        <WarningIcon className={props.className} />
      );
    case 'info':
      return (
        <InfoIcon className={props.className} />
      );
    default:
      return null;
  }
};


const PPSnackbar = (props) => {
  const { snackbarKey, isOpen, message, type, closeSnackbar, optionalProps = {} } = props;
  const classes = useStyles();

  const localCloseSnackbar = useCallback((e, reason) => {
    closeSnackbar(snackbarKey, reason);
  }, [closeSnackbar, snackbarKey]);

  const autoHideDuration = typeof optionalProps.autoHideDuration === 'undefined' ?
    10000 :
    optionalProps.autoHideDuration;

  return (
    <Snackbar
      className={classes.root}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={localCloseSnackbar}
    >
      <SnackbarContent
        className={classes[type]}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <SnackBarIcon
              messageType={type}
              className={classes.icon}
            />
            {message}
          </span>
        }
        action={
          <>
            {optionalProps.action}
            <IconButton
              key='close'
              aria-label='close'
              color='inherit'
              onClick={localCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    </Snackbar>
  );
};

PPSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  optionalProps: PropTypes.object,
  isOpen: PropTypes.bool
};

export default PPSnackbar;
