import gql from 'graphql-tag';

const userMutations = {
  loginUser: gql`
    mutation ($email: String!, $password: String!) {
      loginUser(email: $email, password: $password) {
        id
        token
      }
    }
  `,
  sendOnlinePing: gql`
    mutation {
      sendOnlinePing
    }
  `
}

export default userMutations;
