import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Paper,
  MenuList,
  MenuItem
} from '@material-ui/core';
import { getPairNames } from '../../../helpers/results.js';

const PairSelect = (props) => {
  const { pairs } = props;
  const history = useHistory();

  const handlePairChange = (pairId) => {
    history.push('/director/results/scorecards/' + pairId);
  };

  return (
    <Paper>
      <MenuList>
        {
          pairs.map(pair => (
            <MenuItem
              key={pair.id}
              onClick={() => { handlePairChange(pair.id); }}
            >
              {getPairNames(pair)}
            </MenuItem>
          ))
        }
      </MenuList>
    </Paper>
  );
};

export default PairSelect;
