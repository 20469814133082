import React from 'react';
import PropTypes from 'prop-types';
import { userQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class Users extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <PPQuery query={userQueries.users}>
        {(data, refetch) => {
          return children(data.users, refetch);
        }}
      </PPQuery>
    );
  }
}

Users.propTypes = {
  children: PropTypes.func.isRequired
};

export default Users;
