import React from 'react';
import PropTypes from 'prop-types';
import { pairQueries } from '../../graphql/queries';
import PPQuery from './PPQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class Pairs extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <PPQuery
        query={pairQueries.pairs}
      >
        {(data, refetch) => {
          return children(data.pairs, refetch);
        }}
      </PPQuery>
    );
  }
}

Pairs.propTypes = {
  children: PropTypes.func.isRequired
};

export default Pairs;
