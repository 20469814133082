import React from 'react';
import PropTypes from 'prop-types';
import StatusItem from './StatusItem.js';
import { useTranslation } from 'react-i18next';

const BoardNumber = (props) => {
  const { boardNumber } = props;
  const { t } = useTranslation();

  return (
    <StatusItem
      label={t('board')}
      text={boardNumber + 1}
    />
  );
};

BoardNumber.propTypes = {
  boardNumber: PropTypes.number.isRequired
};

export default BoardNumber;
