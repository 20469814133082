import gql from 'graphql-tag';
import { userFragments, messageFragments } from '../fragments';

const messageSubscriptions = {
  messageFeed: gql`
    subscription ($threadId: ID!) {
      messageFeed(threadId: $threadId) {
        type
        message {
          ...FullMessage
        }
        typingNotification {
          user {
            ...DisplayUser
          }
          timestamp
        }
      }
    }
    ${messageFragments.fullMessage}
    ${userFragments.displayUser}
  `
};

export default messageSubscriptions;
