import gql from 'graphql-tag';
import { systemDescriptionFragments } from '../fragments';

const systemDescriptionQueries = {
  systemDescription: gql`
    query {
      systemDescription {
        ...FullSystemDescription
      }
    }
    ${systemDescriptionFragments.fullSystemDescription}
  `
};

export default systemDescriptionQueries;
