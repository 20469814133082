import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { BoardNumbers as BoardNumbersQ } from '../../../Components/Queries/';
import { Travellers as TravellersSQ } from '../../../Components/SubscriptionQueries/';
import BoardSelect from '../../../Components/Results/Travellers/BoardSelect.js';
import TravellersTable from '../../../Components/Results/Travellers/TravellersTable.js';
import DealWithMakeableContracts from '../../../Components/Results/Travellers/DealWithMakeableContracts.js';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  }
}));

const Travellers = (props) => {
  const { scoringMethod } = props;
  const classes = useStyles();

  const [boardNumber, setBoardNumber] = useState(1);

  const handleBoardNumberClick = useCallback((boardNumber) => {
    setBoardNumber(boardNumber);
  }, [setBoardNumber]);

  return (
    <BoardNumbersQ>
      {(boardNumbers) => (
        <TravellersSQ
          boardNumber={boardNumber - 1}
          withBoard
        >
          {({ tables, boardNotPlayedYet }) => (
            <Grid
              container
              direction='column'
              alignItems='stretch'
              spacing={1}
            >
              <Grid item>
                <BoardSelect
                  boardNumbers={boardNumbers}
                  currentActiveNumber={boardNumber - 1}
                  onBoardNumberClick={handleBoardNumberClick}
                />
              </Grid>
              <Grid
                container
                item
                spacing={1}
                wrap='nowrap'
              >
                <Grid className={classes.grow} item>
                  <TravellersTable
                    boardNumber={boardNumber - 1}
                    tables={tables}
                    boardNotPlayedYet={boardNotPlayedYet}
                    scoringMethod={scoringMethod}
                  />
                </Grid>
                <Grid item>
                  {/* boards are all the same for each traveller so just get one */}
                  {(tables.length && tables[0].board)
                    ? (
                      <DealWithMakeableContracts
                        board={tables[0].board}
                      />
                    )
                    : null}
                </Grid>
              </Grid>
            </Grid>
          )}
        </TravellersSQ>
      )}
    </BoardNumbersQ>
  );
};

export default Travellers;
