import gql from 'graphql-tag';

const boardScoreMutations = {
  setAssignedScore: gql`
    mutation (
      $tableNumber: Int!,
      $boardNumber: Int!,
      $nsAssignedScore: Int!
    ) {
      setAssignedScore(
        tableNumber: $tableNumber,
        boardNumber: $boardNumber,
        nsAssignedScore: $nsAssignedScore
      )
    }
  `,
  setArtificialScore: gql`
    mutation (
      $tableNumber: Int!,
      $boardNumber: Int!,
      $nsArtificialScore: Int!,
      $ewArtificialScore: Int!
    ) {
      setArtificialScore(
        tableNumber: $tableNumber,
        boardNumber: $boardNumber,
        nsArtificialScore: $nsArtificialScore,
        ewArtificialScore: $ewArtificialScore
      )
    }
  `,
  setBoardNotPlayed: gql`
    mutation (
      $tableNumber: Int!,
      $boardNumber: Int!
    ) {
      setBoardNotPlayed(
        tableNumber: $tableNumber,
        boardNumber: $boardNumber
      )
    }
  `

};

export default boardScoreMutations;
