import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../Components/Routing/';
import { UserSettingsProvider } from '../Components/UserSettings/UserSettingsContext.js';
import OnlinePing from '../Components/OnlinePing.js';
import { SoundEffectsProvider } from '../Components/SoundEffects/SoundEffectsContext.js';
import { PageReloadProvider } from '../Components/PageReloadContext.js';
import ImagePreloader from '../Components/ImagePreloader.js';
import Nav from '../Components/Nav/Nav.js';
import Director from './Director/Director.js';
import Player from './Player/Player.js';
import HotJarUser from '../Components/HotJarUser.js';
import ErrorPage from '../Components/Errors/ErrorPage.js';
import { getLoginUrl } from '../helpers/url.js';
import { hasUserTypeMismatch } from '../helpers/users.js';

const Session = (props) => {
  const { currentSession, currentUser, currentUserSettings } = props;

  // it's possible that the director ID gets changed before the game starts
  // if that's the case, the old / new directors will need to refresh in order to see
  // the correct version of the lobby
  if (currentUser &&
    currentSession &&
    hasUserTypeMismatch(currentUser, currentSession)) {
    window.location = '/session/' + currentSession.id;
  }

  if (currentSession && currentSession.status === 'Deleted') {
    return (
      <ErrorPage
        heading='Session was deleted'
        message='This session was deleted by a club administrator. For more information please contact your club.'
      />
    );
  }

  if (!currentUser) {
    window.location = getLoginUrl();
    return null;
  }

  return (
    <UserSettingsProvider
      currentUserSettings={currentUserSettings}
    >
      <HotJarUser currentUser={currentUser} />
      <ImagePreloader />
      <SoundEffectsProvider
        currentUserSettings={currentUserSettings}
      >
        <PageReloadProvider>
          <OnlinePing currentUser={currentUser} />
          <Nav
            currentUser={currentUser}
            session={currentSession}
          >
            <Switch>
              <PrivateRoute
                path='/director'
                component={Director}
                authorisedUserTypes={['Admin', 'Director']}
                currentUser={currentUser}
                currentSession={currentSession}
              />
              <PrivateRoute
                path='/player'
                component={Player}
                authorisedUserTypes={['Player']}
                currentUser={currentUser}
                currentSession={currentSession}
              />
              <Route
                exact path='/' render={() => {
                  if (currentUser.type === 'Player') {
                    return (<Redirect to='/player' />);
                  } else if (currentUser.type === 'Director') {
                    return (<Redirect to='/director' />);
                  }
                }}
              />
              <Route render={() => {
                // we need the 404 redirect iin here (rather than app.js)
                // as all / traffic heads here first so a ctach all in app.js
                // won't work
                return (<Redirect to='/error404' />);
              }}
              />
            </Switch>
          </Nav>
        </PageReloadProvider>
      </SoundEffectsProvider>
    </UserSettingsProvider>
  );
};

Session.propTypes = {
  currentSession: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentUserSettings: PropTypes.object.isRequired
};

export default Session;
