import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  subheading: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    margin: '2rem 0 1rem 0'
  }
}));

const BlockSubheading = (props) => {
  const { children, cssClasses } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.subheading, cssClasses)}>
      {children}
    </div>
  );
};

export default BlockSubheading;
