import _ from 'lodash';

export const checkForSingleton = (cards, enabledSuit) => {
  const enabledCards = cards.filter(card => {
    // card hasn't been played
    return card.visible &&
    // card is in a suit that's legal to play
    (card.suit === enabledSuit || enabledSuit === 'all')
  });

  return enabledCards.length === 1;
};

const spreadArray = (min, max, length) => {
  const steps = (max - min) / (length-1);
  const arr = _.range(min, max, steps);
  arr.push(max);
  return arr;
};

// offset X and offset Y are in `em`s
// rotation is in degrees
const OFFSET_X_MIN = -10;
const OFFSET_X_MAX = -9;

const OFFSET_Y_RANGE_MULTIPLIER = 0.1;
const ROTATION_RANGE_MULTIPLIER = 1.75;
const HIGHLIGHT_OFFSET_Y_INCREASE = 2;

export const getCardsForSpread = (cards, enabledSuit, straightLine, index) => {
  const visibleCards = cards.filter(card => card.visible);
  const numCards = visibleCards.length;
  const numCardsHalf = Math.ceil(numCards / 2);

  if (straightLine) {
    return cards.map((oldCard, i) => {
      const card = Object.assign({}, oldCard);
      card.offsetY = 0;
      card.offsetX = (i > 0) ? -9 : 0;
      card.rotate = 0;

      // lift active cards up a bit (your cards only, looks odd when we do it for dummy)
      if (index === 2 && (card.suit === enabledSuit || enabledSuit === 'all')) {
        card.offsetY -= HIGHLIGHT_OFFSET_Y_INCREASE;
      }

      return card;
    });
  }

  // the fewer cards there are left in the hand,
  // the smaller the range of possible values for offset Y and rotation is
  const offsetYMax = numCards * OFFSET_Y_RANGE_MULTIPLIER;
  const offsetYMin = offsetYMax * -1;
  const rotationMax = numCards * ROTATION_RANGE_MULTIPLIER;
  const rotationMin = rotationMax * -1;

  // to make the effect more pronounced, we reduce offset Y even further
  // when there's fewer cards
  const offsetYMagnitudeMultiplier = numCards / 13;

  // offset X and Y are lower at the edges of the spread and highest at the middle
  let offsetXValues = spreadArray(OFFSET_X_MIN, OFFSET_X_MAX, numCardsHalf);
  offsetXValues = offsetXValues.concat(offsetXValues.slice().reverse());
  // first card in spread shouldn't have an overlap because there's no card beneath it
  offsetXValues[0] = 0;

  let offsetYValues = spreadArray(offsetYMax, offsetYMin, numCardsHalf);
  offsetYValues = offsetYValues.concat(offsetYValues.slice().reverse());

  // whereas rotation increases from -X to X for each card
  const rotationValues = spreadArray(rotationMin, rotationMax, numCards);

  // transformOrigin
  const transformOriginsX = spreadArray(100, 0, numCards);
  let transformOriginsY = spreadArray(100, 75, numCardsHalf);
  transformOriginsY = transformOriginsY.concat(transformOriginsY.slice().reverse());

  let i = 0;

  return cards.map((oldCard) => {
    if (!oldCard.visible) { return oldCard; }

    const card = Object.assign({}, oldCard);
    card.offsetY = offsetYValues[i] * offsetYMagnitudeMultiplier;
    card.offsetX = offsetXValues[i];
    // 0 rotation for 1 card left
    card.rotate = numCards > 1 ?  rotationValues[i] : 0;

    card.transformOrigin = transformOriginsX[i] + '% ' + transformOriginsY[i] + '% 0';

    // spread active cards out further and lift them up a bit
    if (card.suit === enabledSuit || enabledSuit === 'all') {
      card.offsetY -= HIGHLIGHT_OFFSET_Y_INCREASE;
    }

    i++;

    return card;
  });
};

// sets predetermined maximum card stack sizes based on screen size
// top stack sizes are picked based on screen height
const MAX_STACK_SIZE_TOP = [
  // { stackSize: 2, maxDimension: 550 },
  // { stackSize: 3, maxDimension: 700 },
  // { stackSize: 4, maxDimension: 800 },
  // { stackSize: 5, maxDimension: 900 },
  { stackSize: 6, maxDimension: 1080 },
  { stackSize: 7, maxDimension: 1200 },
  { stackSize: 8 }
];
// side stack sizes are picked based on screen width
const MAX_STACK_SIZE_SIDE = [
  // { stackSize: 5, maxDimension: 1380 },
  // { stackSize: 6, maxDimension: 1450 },
  // { stackSize: 7, maxDimension: 1520 },
  { stackSize: 8 }
];

const getLargestStackSize = (stackSizeArray, dimension) => {
  let maxCards;

  for (let i = 0; i < stackSizeArray.length; i++) {
    const breakpoint = stackSizeArray[i];

    if (breakpoint.maxDimension > dimension || !breakpoint.maxDimension) {
      maxCards = breakpoint.stackSize;
      break;
    }
  }

  return maxCards;
};

export const splitOversizeStacks = (cards, seatIndex, { width, height }) => {
  const splitStacks = [[]];

  if (!cards.length) {
    return [];
  }

  // find the largest stack size that satisfies the maxWidth / maxHeight constraint
  let maxCards;
  if (seatIndex === 0 || seatIndex === 2) {
    maxCards = getLargestStackSize(MAX_STACK_SIZE_TOP, height);
  } else {
    maxCards = getLargestStackSize(MAX_STACK_SIZE_SIDE, width);
  }

  // every maxCards, create a new stack to put cards in
  cards.forEach((card) => {
    let lastStack = splitStacks[splitStacks.length - 1];
    const visibleCardsInStack = lastStack.filter(card => card.visible);

    if (visibleCardsInStack.length === maxCards) {
      lastStack = [];
      splitStacks.push(lastStack);
    }

    lastStack.push(card);
  });

  return splitStacks;
};

// we want to fit as many cards in the top stack as we can without overlapping the middle trick
// therefore, we modify the overlap based on how many cards we need to show in the stack..
// the more cards, the more the overlap - but not too much to obscure the card value
const TOP_STACK_MARGIN_BASE = -13;

export const getStackBottomMargin = (cards, simplifiedCards) => {
  const visibleCards = cards.filter(card => card.visible);
  let multiplier = simplifiedCards ? 0.90 : 1;
  switch (visibleCards.length) {
    case 4:
      multiplier = 1.05;
      break;
    case 5:
      multiplier = 1.08;
      break;
    case 6:
      multiplier = 1.11;
      break;
    case 7:
      multiplier = 1.14;
      break;
    case 8:
      multiplier = 1.18;
      break;
    default:
      multiplier = 1;
      break;
  }

  return TOP_STACK_MARGIN_BASE * multiplier;
};
