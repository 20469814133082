import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableCell,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Cancel as CancelIcon
} from '@material-ui/icons';
import ScoreEditor from './ScoreEditor.js';
import { boardScoreMutations } from '../../../../graphql/mutations';
import { usePPMutation } from '../../../../hooks/';
import { useTranslation } from 'react-i18next';
import SpinnerButton from '../../../SpinnerButton.js';

const useStyles = makeStyles(theme => ({
  saveCancelContaner: {
    display: 'flex',
    justifyContent: 'center'
  },
  editingScoresContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  setNotPlayedBtn: {
    marginTop: 10
  },
  scoreEditor: {
    minWidth: 130
  }
}));

const TravellerScores = (props) => {
  const { row, editable, showMPs } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [editingScores, setEditingScores] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [parsedScore, setParsedScore] = useState(null);
  const [setAssignedScore, { loading: assignedScoreLoading }] = usePPMutation(boardScoreMutations.setAssignedScore);
  const [setArtificialScore, { loading: artificialScoreLoading }] = usePPMutation(boardScoreMutations.setArtificialScore);
  const [setBoardNotPlayed, { loading: notPlayedLoading }] = usePPMutation(boardScoreMutations.setBoardNotPlayed);

  const handleSave = useCallback(async () => {
    if (!parsedScore) {
      setShowErrors(true);
      return;
    }

    // with artificial scores (percentages)
    // the players can receive e.g. 60%/40% or 60%/60%
    // so both NS and EW values are necessary
    if (parsedScore.isArtificialScore) {
      await setArtificialScore({
        variables: {
          tableNumber: row.tableNumber,
          boardNumber: row.boardNumber,
          nsArtificialScore: parsedScore.ns,
          ewArtificialScore: parsedScore.ew
        }
      });
    } else {
      // with assigned scores (integers)
      // the EW scores are always minus whatever NS got
      // (e.g. 440 / -440)
      // so we pass NS only for simplicity's sake
      await setAssignedScore({
        variables: {
          tableNumber: row.tableNumber,
          boardNumber: row.boardNumber,
          nsAssignedScore: parsedScore.ns
        }
      });
    }

    setEditingScores(false);
  }, [parsedScore, row, setArtificialScore, setAssignedScore]);

  const setNotPlayed = useCallback(async () => {
    await setBoardNotPlayed({
      variables: {
        tableNumber: row.tableNumber,
        boardNumber: row.boardNumber
      }
    });
    setEditingScores(false);
  }, [setBoardNotPlayed, row]);

  const handleCancel = () => {
    setEditingScores(false);
  };

  const handleEditClick = () => {
    setEditingScores(true);
  };

  let lastCell = null;

  const anyMutationIsLoading = assignedScoreLoading || artificialScoreLoading || notPlayedLoading;

  if (editable) {
    if (editingScores) {
      lastCell = (
        <div className={classes.editingScoresContainer}>
          <div className={classes.saveCancelContaner}>
            <Tooltip arrow placement='top' title={<Typography>{t('save-changes')}</Typography>}>
              <SpinnerButton
                onClick={handleSave}
                loading={assignedScoreLoading || artificialScoreLoading}
                disabled={anyMutationIsLoading}
                isIconButton={true}
              >
                <CheckIcon />
              </SpinnerButton>
            </Tooltip>
            <Tooltip arrow placement='top' title={<Typography>{t('cancel')}</Typography>}>
              <IconButton
                onClick={handleCancel}
                disabled={anyMutationIsLoading}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <SpinnerButton
              className={classes.setNotPlayedBtn}
              onClick={setNotPlayed}
              loading={notPlayedLoading}
              disabled={anyMutationIsLoading}
            >
              {t('mark-as-not-played')}
            </SpinnerButton>
          </div>
        </div>
      );
    } else {
      lastCell = (
        <Tooltip arrow placement='top' title={<Typography>{t('edit-scores')}</Typography>}>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  }

  return (
    <>
      {
        editingScores
          ? (
            <TableCell colSpan={2} className={classes.scoreEditor}>
              <ScoreEditor
                key={row.tableNumber + row.boardNumber}
                nsScore={row.nsScore}
                ewScore={row.ewScore}
                showErrors={showErrors}
                setShowErrors={setShowErrors}
                setParsedScore={setParsedScore}
                setNotPlayed={setNotPlayed}
              />
            </TableCell>
          )
          : (row.notPlayed
            ? (
              <TableCell colSpan={2}>
                {t('not-played')}
              </TableCell>
            )
            : (
              <>
                <TableCell>
                  {row.nsScore}
                </TableCell>
                <TableCell>
                  {row.ewScore}
                </TableCell>
              </>
            )
          )
      }
      {showMPs && (
        <>
          <TableCell>
            {row.nsMPs}
          </TableCell>
          <TableCell>
            {row.ewMPs}
          </TableCell>
        </>
      )}
      <TableCell align='right'>
        {lastCell}
      </TableCell>
    </>
  );
};

export default TravellerScores;
