import React, { useEffect } from 'react';
import { usePPMutation } from '../../hooks/';
import Player from '../Players/Player.js';
import { pairMutations } from '../../graphql/mutations/';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core';
import SpinnerButton from '../SpinnerButton.js';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  item: {
    background: '#fff',
    borderRadius: '0.5rem',
    padding: '1rem 1.5rem 1rem 1rem',
    marginBottom: '1rem',
    fontSize: '1rem'
  }
}));

const PossiblePartner = (props) => {
  const { user, currentUser, canMarkAsPartner } = props;
  const { t } = useTranslation();
  const [markAsPartner, { called, reset, error }] = usePPMutation(pairMutations.markAsPartner, { t });
  const classes = useStyles();

  const handleClick = () => {
    if (called) { return; }
    markAsPartner({
      variables: {
        userId: user.id
      }
    });
  };

  const showMarkAsPartnerButton = user.gameStatus === 'NotInPair' && currentUser.gameStatus === 'NotInPair';

  // reset `called` every time the user's game status changes
  useEffect(() => {
    reset();
  }, [currentUser.gameStatus, reset]);

  return (
    <div className={classes.item}>
      <Grid
        container
        alignItems='center'
        spacing={2}
      >
        <Grid item xs zeroMinWidth>
          <Player
            {...user}
          />
        </Grid>
        <Grid item>
          <Tooltip
            arrow placement='top' title={
              <Typography>
                {
                  showMarkAsPartnerButton
                    ? <>{t('click-to-choose-as-your-partner', { name: user.firstName })}</>
                    : <>{t('you-already-have-a-partner', { name: user.firstName })}</>
                }
              </Typography>
            }
          >
            <span>
              <SpinnerButton
                variant='outlined'
                size='small'
                onClick={handleClick}
                loading={called && !error}
                disabled={!canMarkAsPartner || !showMarkAsPartnerButton}
                startIcon={<PersonAddIcon />}
              >
                {t('my-partner')}
              </SpinnerButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

export default PossiblePartner;
