import React from 'react';
import Grid from '@material-ui/core/Grid';
import Player from '../Players/Player.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    padding: '1rem',
    color: '#333',
    fontSize: '1rem'
  }
}));

const Pair = (props) => {
  const { pair } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='center'
        spacing={1}
      >
        <Grid item xs={6}>
          <Player
            {...pair.user1}
          />
        </Grid>
        <Grid item xs={6}>
          <Player
            {...pair.user2}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Pair;
