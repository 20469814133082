import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LobbyPair from '../../../Components/Lobby/LobbyPair.js';
import DeletePairButton from '../../../Components/Lobby/DeletePairButton.js';
import BlockSubheading from '../../../Components/BlockSubheading.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  noPairReadyMsg: {
    background: '#fff',
    borderRadius: '0.5rem',
    textAlign: 'center',
    fontSize: '1.4rem',
    height: '6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem'
  }
}));

const Pairs = (props) => {
  const { pairs, currentUser, session } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  let currentUserPair;
  const offlinePlayerPairs = [];
  const readyPairs = [];

  // show pairs where one or more players are offline at the top
  pairs.forEach(pair => {
    if (pair.user1.id === currentUser.id ||
      pair.user2.id === currentUser.id) {
      currentUserPair = pair;
    } else if (pair.user1.onlineStatus === 'Offline' ||
      pair.user2.onlineStatus === 'Offline') {
      offlinePlayerPairs.push(pair);
    } else {
      readyPairs.push(pair);
    }
  });

  return (
    <>
      {
        currentUserPair && (
          <>
            <BlockSubheading>
              {t('your-pair')}
            </BlockSubheading>
            <LobbyPair
              pair={currentUserPair}
              actionButton={
                <DeletePairButton
                  pair={currentUserPair}
                  disabled={session.status !== 'NotStarted'}
                />
              }
            />
          </>
        )
      }
      {offlinePlayerPairs.length > 0 && (
        <>
          <BlockSubheading>
            {t('pairs-where-one-or-more-partners-are-offline')} ({offlinePlayerPairs.length})
          </BlockSubheading>
          {offlinePlayerPairs.map(pair => {
            return (
              <LobbyPair
                key={pair.id}
                pair={pair}
                showOnlineIndicator
                actionButton={(
                  <DeletePairButton
                    pair={pair}
                    tooltip='unpair'
                  />
                )}
              />
            );
          })}
        </>
      )}
      <BlockSubheading>
        {t('pairs-ready-to-play')} ({readyPairs.length})
      </BlockSubheading>
      {
        readyPairs.length === 0
          ? <div className={classes.noPairReadyMsg}>
            {t('there-are-currently-no-pairs-ready')}
            </div>
          : readyPairs.map(pair => {
            return (
              <LobbyPair
                key={pair.id}
                pair={pair}
                showOnlineIndicator
                actionButton={(
                  <DeletePairButton
                    pair={pair}
                    tooltip='unpair'
                  />
                )}
              />
            );
          })
      }
    </>
  );
};

export default Pairs;
