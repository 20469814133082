import React from 'react';
import PPQuery from '../Queries/PPQuery.js';
import PPSubscription from './PPSubscription.js';
import { sessionQueries } from '../../graphql/queries/';
import { sessionSubscriptions } from '../../graphql/subscriptions/';
import { setRegion } from '../../helpers/session.js';

class CurrentSession extends React.Component {
  render() {
    const { loaderComponent } = this.props;
    return (
      <PPQuery
        query={sessionQueries.currentSession}
        fetchPolicy='no-cache'
        loaderComponent={loaderComponent}
      >
        {({currentSession}) => (
          <PPSubscription
            subscription={sessionSubscriptions.sessionUpdate}
            onSubscriptionData={this.handleSubscriptionResult}
          >
            {(subscriptionResult) => {
              // set the session.region so it can be used to build the  mutations uri
              if (currentSession) {
                setRegion(currentSession.region);
              }

              if (subscriptionResult && subscriptionResult.data) {
                return this.props.children(subscriptionResult.data.sessionUpdate);
              } else {
                return this.props.children(currentSession);
              }
            }}
          </PPSubscription>
        )}
      </PPQuery>
    );
  }
}

export default CurrentSession;
