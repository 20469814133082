import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import TableNumber from './TableNumber.js';
import RoundsCount from './RoundsCount.js';
import BoardNumber from './BoardNumber.js';
import TimeLeft from './TimeLeft.js';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.dark,
    padding: '0 1.4rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    height: '6rem',
    textAlign: 'center'
  }
}));

const Status = (props) => {
  const { session, table } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        wrap='nowrap'
      >
        <Grid item>
          <TableNumber table={table} />
        </Grid>
        <Grid item>
          <RoundsCount session={session} />
        </Grid>
        <Grid item>
          <BoardNumber boardNumber={table.boardNumber} />
        </Grid>
        <Grid item>
          <TimeLeft session={session} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Status;
