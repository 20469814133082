import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardWrapper from './CardWrapper.js';
import { useScreenDimensions } from '../../../hooks';
import { splitOversizeStacks, getStackBottomMargin } from '../../../helpers/card.js';

const useStyles = makeStyles(() => ({
  group: {
    display: 'inline-block',
    minWidth: '8.5em',
    minHeight: '18.2em',
    marginBottom: '0.25em'
  },
  verticalStack: {
    minHeight: '1em',
    margin: '0 1em 0 0',
    display: 'inline-block',
    '&:last-child': {
      margin: '0 1em 0 0'
    }
  },
  index0Stack: {
    verticalAlign: 'top'
  },
  index2Stack: {
    verticalAlign: 'bottom'
  },
  subStack: {
    margin: '0 8.5em -13em 0',
    fontSize: 'inherit',
  },
  verticalSubStack: {
    display: 'inline-block',
    margin: '0 .25em',
    '&:last-child': {
      margin: '0 0.25em'
    }
  }
}));

const StackCardSuitGroup = (props) => {
  const { cards, seatIndex, scale, simplifiedCards, ...other } = props;
  const classes = useStyles();
  const screenDimensions = useScreenDimensions();

  const visibleCards = cards.filter(card => card.visible);
  if (visibleCards.length === 0) {
    return null;
  }

  const isVerticalStack = seatIndex === 0 || seatIndex === 2;

  const stacks = splitOversizeStacks(cards, seatIndex, screenDimensions);

  return (
    <div className={
      clsx(
        classes.group,
        isVerticalStack && classes.verticalStack,
        seatIndex === 0 && classes.index0Stack,
        seatIndex === 2 && classes.index2Stack
      )}
      style={{fontSize:`${scale}rem`}}
    >
      {
        stacks.map((cardsInStack, index) => {
          const visibleCards = cardsInStack.filter(card => card.visible);

          if (visibleCards.length === 0) {
            return null;
          }

          let bottomMargin;

          if (isVerticalStack) {
            bottomMargin = getStackBottomMargin(cardsInStack, simplifiedCards);
          }

          let visibleCardIndex = 0;

          return (
            <div
              key={index}
              className={clsx(
                classes.subStack,
                isVerticalStack && classes.verticalSubStack,
                seatIndex === 0 && classes.index0Stack,
                seatIndex === 2 && classes.index2Stack
              )}
            >
              {cardsInStack.map((card, index) => {
                let cardBottomMargin = 0;

                if (card.visible) {
                  // if this is the last visible card of the stack, no negative margin
                  // so the entire stack's height can be calculated properly
                  if (visibleCardIndex < visibleCards.length - 1) {
                    cardBottomMargin = bottomMargin;
                  }

                  visibleCardIndex++;
                }

                return (
                  <CardWrapper
                    key={card.rank + card.suit}
                    card={card}
                    isStack={true}
                    isTop={isVerticalStack}
                    cardsInStack={cardsInStack.length}
                    bottomMargin={cardBottomMargin}
                    simplifiedCards={simplifiedCards}
                    {...other}
                  />
                );
              })}
            </div>
          );
        })
      }
    </div>
  );
};

StackCardSuitGroup.propTypes = {
  seatIndex: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  faceDown: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  onCardClick: PropTypes.func,
  cardRefCallback: PropTypes.func,
  scale: PropTypes.number
};

export default StackCardSuitGroup;
