import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link, CircularProgress } from '@material-ui/core';
import TranslationWithJSX from '../../../Components/TranslationWithJSX.js';
import { useTranslation } from 'react-i18next';

const REFRESH_INTERVAL_MS = 30000;

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 5,
    position: 'relative'
  },
  seconds: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    width: '2rem'
  },
  progress: {
    marginLeft: '0.5rem',
    display: 'inline-block',
    verticalAlign: 'middle',
    color: '#fff'
  },
  refreshLink: {
    fontSize: 'inherit',
    marginLeft: 5
  }
}));

const RefreshWidget = (props) => {
  const { refetchRankings, refetchTables } = props;
  const [timeToRefreshMs, setTimeToRefreshMs] = useState(REFRESH_INTERVAL_MS);
  const classes = useStyles();
  const { t } = useTranslation();

  const refresh = useCallback(() => {
    setTimeToRefreshMs(REFRESH_INTERVAL_MS);
    refetchRankings();
    refetchTables();
  }, [setTimeToRefreshMs, refetchRankings, refetchTables]);

  // set countdown
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeToRefreshMs(oldVal => oldVal - 1000);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeToRefreshMs, setTimeToRefreshMs]);

  // if the timer runs out, refresh
  useEffect(() => {
    if (timeToRefreshMs < 1000) {
      refresh();
    }
  }, [timeToRefreshMs, refresh]);

  const secondsUntilRefresh = Math.round((timeToRefreshMs) / 1000);

  const progressValue = Math.round(100 * (REFRESH_INTERVAL_MS - timeToRefreshMs) / REFRESH_INTERVAL_MS);

  const timerElement = <span className={classes.seconds}>{secondsUntilRefresh}</span>;

  return (
    <div className={classes.container}>
      <TranslationWithJSX
        id='results-updating-in-n-seconds'
        translationProps={{ n: timerElement }}
      />
      <CircularProgress
        className={classes.progress}
        variant='static'
        color='secondary'
        disableShrink
        value={progressValue}
        size={20}
      />
      <br />
      <Link
        component='button'
        variant='body2'
        color='secondary'
        className={classes.refreshLink}
        onClick={refresh}
      >
        {t('refresh-now')}
      </Link>
    </div>
  );
};

RefreshWidget.propTypes = {
  refetchTables: PropTypes.func.isRequired,
  refetchRankings: PropTypes.func.isRequired
};

export default RefreshWidget;
