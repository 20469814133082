import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import SettingsModal from './SettingsModal.js';

const useStyles = makeStyles(theme => ({
  settingsButton: {
    color: '#999',
    '&:hover': {
      color: '#fff'
    }
  }
}));

const SettingsButton = (props) => {
  const { currentUser } = props;
  const classes = useStyles();

  const [showingSettings, setShowingSettings] = useState(false);

  const handleShowSettingsModalClick = useCallback(() => {
    setShowingSettings(true);
  }, [setShowingSettings]);

  const handleSettingsModalClose = useCallback(() => {
    setShowingSettings(false);
  }, [setShowingSettings]);

  return (
    <>
      <IconButton className={classes.settingsButton} onClick={handleShowSettingsModalClick}>
        <SettingsIcon fontSize='large' />
      </IconButton>
      <SettingsModal
        open={showingSettings}
        onClose={handleSettingsModalClose}
        currentUser={currentUser}
      />
    </>
  );
};

export default SettingsButton;
