import React, { useCallback, useState } from 'react';
import ResultsModal from '../../../Views/Player/Game/ResultsModal.js';
import ActionButton from './ActionButton.js';
import ResultsIcon from '@material-ui/icons/Equalizer';
import { useTranslation } from 'react-i18next';

const ResultsButton = (props) => {
  const { session } = props;
  const { t } = useTranslation();

  const [showingResults, setShowingResults] = useState(false);

  const handleViewResultsClick = useCallback(() => {
    setShowingResults(true);
  }, [setShowingResults]);

  const handleModalClose = useCallback(() => {
    setShowingResults(false);
  }, [setShowingResults]);

  return (
    <>
      <ActionButton
        label={t('results')}
        icon={<ResultsIcon style={{ fontSize: 30 }} />}
        onClick={handleViewResultsClick}
        tooltip={t('view-live-results')}
      />
      <ResultsModal
        open={showingResults}
        onClose={handleModalClose}
        winnerType={session.winnerType}
        scoringMethod={session.scoringMethod}
      />
    </>
  );
};

export default ResultsButton;
