import React from 'react';
import PPSubscription from './PPSubscription.js';
import { tableSubscriptions } from '../../graphql/subscriptions/';
import { updateCardPlay } from '../../helpers/game.js';

class Play extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      activeSeat: null,
      cardPlay: props.table.cardPlay,
      nsTricks: props.table.nsTricks,
      ewTricks: props.table.ewTricks,
      claim: props.table.claim
    };
  }

  updateTrick = (trick) => {
    const {
      activeSeat,
      trick: newTrick,
      nsTricks,
      ewTricks
    } = trick;

    this.setState({
      cardPlay: updateCardPlay(newTrick, this.state.cardPlay),
      activeSeat,
      nsTricks,
      ewTricks
    });
  };

  handleTrickUpdate = async ({ data }) => {
    const { delay } = data.trickUpdate;

    // delay this player from being shown the play for 0.5-2s
    // e.g. if the card was autoplayed but we want to obfuscate that
    if (delay) {
      const delayMs = Math.random() * 1500 + 500;
      setTimeout(() => {
        this.updateTrick(data.trickUpdate);
      }, delayMs);
    } else {
      this.updateTrick(data.trickUpdate);
    }
  };

  handleCardPlayed = (result) => {
    if (!result) { return; }

    const data = result.data;

    this.updateTrick(data.playCard);
  };

  handleClaimUpdate = async ({ data }) => {
    this.setState({ claim: data.claimUpdate.claim });
  };

  render() {
    return (
      <PPSubscription
        subscription={tableSubscriptions.trickUpdate}
        variables={{ tableId: this.props.table.id }}
        onSubscriptionData={this.handleTrickUpdate}
      >
        {() => (
          <PPSubscription
            subscription={tableSubscriptions.claimUpdate}
            variables={{ tableId: this.props.table.id }}
            onSubscriptionData={this.handleClaimUpdate}
          >
            {() => {
              const table = {
                ...this.props.table,
                cardPlay: this.state.cardPlay,
                activeSeat: this.state.activeSeat || this.props.table.activeSeat,
                nsTricks: this.state.nsTricks,
                ewTricks: this.state.ewTricks,
                claim: this.state.claim
              };
              return this.props.children(table, this.handleCardPlayed);
            }}
          </PPSubscription>
        )}
      </PPSubscription>
    );
  }
}

export default Play;
