import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import Avatar from '../Players/PlayerAvatar.js';
import TypingDots from './TypingDots.js';
import MessageMiniCard from './MessageMiniCard.js';
import MessageIcon from './MessageIcon.js';
import { useTranslation } from 'react-i18next';
import TranslationWithJSX from '../TranslationWithJSX.js';

const styles = {
  content: {
    display: 'inline-block',
    background: '#fff',
    color: '#333',
    fontSize: '1.6em',
    padding: '0.5em 0.8em',
    maxWidth: '75%',
    margin: '0.1em 0 0.1em 3.5em',
    overflowWrap: 'anywhere',
    borderRadius: '0.2em 0.6em 0.6em 0.2em',
    '& p': {
      marginTop: 0,
      marginBottom: 0
    },
    '@media all and (max-width:1194px)': {
      maxWidth: '100%',
      marginLeft: '3em'
    }
  },
  contentRight: {
    float: 'right',
    borderRadius: '0.6em 0.2em 0.2em 0.6em !important',
    margin: '0.1em 3.5em 0.1em 0',
    '@media all and (max-width:1194px)': {
      marginRight: '3em'
    }
  },
  systemMessage: {
    background: '#fffdd3'
  },
  globalMessage: {
    background: 'gold',
    color: '#000',
    fontWeight: 'bold',
    boxShadow: '0 0 0.5em gold'
  },
  privateMessage: {
    background: '#ccc'
  },
  messageLabel: {
    display: 'block',
    fontSize: '0.7em',
    lineHeight: '1em',
    textTransform: 'uppercase',
    marginBottom: '0.3em'
  },
  globalMessageLabel: {
    opacity: 0.5
  },
  privateMessageLabel: {
    opacity: 0.9
  },
  avatar: {
    display: 'none',
    position: 'absolute',
    left: 0,
    bottom: 0,
    fontSize: '1.6em',
    width: '2.5em',
    height: '2.5em'
  },
  avatarRight: {
    left: 'auto',
    right: 0
  },
  li: {
    position: 'relative',
    clear: 'both',
    '&:first-child': {
      '& > div:last-child': {
        borderTopLeftRadius: '0.6em !important',
        borderTopRightRadius: '0.6em !important'
      }
    },
    '&:last-child': {
      '& > div:first-child': {
        display: 'block !important'
      },
      '& > div:last-child': {
      }
    },
    '&:after': {
      content: '""',
      display: 'table',
      clear: 'both'
    }
  }
};

const Message = (props) => {
  const {
    classes,
    text,
    from,
    icon,
    type,
    isTypingNotification,
    recipient,
    translate,
    attachments
  } = props;
  const { t } = useTranslation();

  let localAttachments;

  if (attachments) {
    localAttachments = { ...attachments };

    if (localAttachments.bid) {
      localAttachments.bid = (<MessageMiniCard {...attachments.bid} />);
    }
  }

  const isGlobalMessage = type === 'Global';
  const isSystemMessage = type === 'System';
  const isPrivateMessage = type === 'Private';

  return (
    <li className={classes.li}>
      <div
        className={
          clsx(
            classes.avatar,
            from.isCurrentUser && classes.avatarRight
          )
        }
      >
        {
          icon
            ? (<MessageIcon name={icon}/>)
            : (
              <Avatar
                avatarUrl={from.avatarUrl}
                fullName={from.fullName}
                firstName={from.firstName}
                lastName={from.lastName}
              />
            )
        }
      </div>
      <div
        className={
          clsx(
            classes.content,
            from.isCurrentUser && classes.contentRight,
            isSystemMessage && classes.systemMessage,
            isGlobalMessage && classes.globalMessage,
            isPrivateMessage && classes.privateMessage
          )
        }
      >
        {
          isTypingNotification
            ? <TypingDots />
            : <>
              {
                isGlobalMessage && (
                  <span className={clsx(classes.messageLabel, classes.globalMessageLabel)}>{t('to-all-tables')}</span>
                )
              }
              {
                isPrivateMessage && (
                  <span className={clsx(classes.messageLabel, classes.privateMessageLabel)}>
                    {
                      from.isCurrentUser
                        ? t('private-to-name', { name: recipient.fullName })
                        : t('private-to-you')
                    }
                  </span>
                )
              }
              {
                translate
                  ? (
                      <TranslationWithJSX
                        id={text}
                        translationProps={localAttachments}
                      />
                    )
                  : text
              }
              </>
        }
      </div>
    </li>
  );
};

Message.propTypes = {
  from: PropTypes.object.isRequired,
  text: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  translate: PropTypes.bool,
  isTypingNotification: PropTypes.bool,
  bid: PropTypes.object
};

export default withStyles(styles)(Message);
