import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, Typography } from '@material-ui/core';
import AuctionTableBody from './AuctionTableBody.js';
import PlayerAvatar from '../../Players/PlayerAvatar.js';
import { getPlayersBySeat } from '../../../helpers/game.js';

const useStyles = makeStyles(theme => ({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  tableContainer: {
    marginTop: '1rem'
  }
}));

const AuctionTable = (props) => {
  const { table } = props;
  const classes = useStyles();

  const playersBySeat = getPlayersBySeat(table);
  const directions = ['W', 'N', 'E', 'S'];

  return (
    <>
      <div className={classes.avatarContainer}>
        {
          directions.map(direction => (
            <Tooltip
              arrow
              placement='top'
              key={direction}
              title={<Typography>{playersBySeat[direction].fullName}</Typography>}
            >
              <span>
                <PlayerAvatar
                  {...playersBySeat[direction]}
                />
              </span>
            </Tooltip>
          ))
        }
      </div>
      <div className={classes.tableContainer}>
        <AuctionTableBody
          dealer={table.board.dealer}
          auction={table.auction}
        />
      </div>
    </>
  );
};

export default AuctionTable;
