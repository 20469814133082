import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import TimeNow from './TimeNow.js';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 0.6rem 0 2rem'
  }
}));

const PlayerHeader = (props) => {
  const { session } = props;
  const classes = useStyles();

  const sessionName = (session && session.name) || '';

  return (
    <Grid
      container
      justify='space-between'
      alignItems='center'
      className={classes.container}
    >
      <Grid item>
        <Typography variant='h6'>{sessionName}</Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          justify='flex-end'
          alignItems='center'
          spacing={2}
          wrap='nowrap'
        >
          {
            session &&
              <Grid item>
                <TimeNow />
              </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlayerHeader;
