import React from 'react';
import PropTypes from 'prop-types';
import AnimatedCard from './AnimatedCard.js';

class CardPlayAnimation extends React.Component {
  state = {
    left: this.props.startPos.left,
    top: this.props.startPos.top,
    width: this.props.startPos.width,
    rotation: this.props.startPos.rotation,
    speed: this.props.speed,
    visible: true
  };

  animate = () => {
    const { animate, card, endPos, delay, speed, onAnimationComplete } = this.props;
    if (animate) {
      // after the given delay, action the animation by changing the card pos
      setTimeout(() => {
        this.setState({
          left: endPos.left,
          top: endPos.top,
          width: endPos.width,
          rotation: endPos.rotation,
          speed: speed
        });
      }, delay);
      // fire the animation complete event after the delay and after the animation
      // has completed (delay + speed)
      setTimeout(() => {
        this.setState({
          visible: false
        });
        onAnimationComplete(card);
      }, delay + speed);
    }
  }

  componentDidMount () {
    // animate on component mount
    this.animate();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    // check if props have changed
    if(oldProps.startPos !== newProps.startPos) {
      // if they have reset the state to the new startPos but move there
      // instantly (speed: 0), ready for the next animation
      this.setState({
        left: newProps.startPos.left,
        top: newProps.startPos.top,
        width: newProps.startPos.width,
        rotation: newProps.startPos.rotation,
        speed: 0,
        visible: true
      });
      // do the animation
      this.animate();
    }
  }

  render () {
    const { animate, card, simplifiedCards } = this.props;
    if (animate) {
      const { left, top, width, speed, visible, rotation } = this.state;
      return (
        <React.Fragment>
          <AnimatedCard
            animateSpeed={speed}
            card={card}
            left={left}
            top={top}
            width={width}
            rotation={rotation}
            visible={visible}
            simplifiedCards={simplifiedCards}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
};

CardPlayAnimation.propTypes = {
  startPos: PropTypes.object.isRequired,
  endPos: PropTypes.object.isRequired,
  speed: PropTypes.number.isRequired,
  delay: PropTypes.number.isRequired,
  card: PropTypes.object.isRequired,
  animate: PropTypes.bool.isRequired,
  onAnimationComplete: PropTypes.func.isRequired
}

export default CardPlayAnimation;
