import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  Typography,
  IconButton,
  useMediaQuery,
  InputBase
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ClaimSpeechBubble from './ClaimSpeechBubble.js';
import { tableMutations } from '../../../graphql/mutations';
import { usePPMutation } from '../../../hooks/';
import TranslationWithJSX from '../../TranslationWithJSX.js';
import { useTranslation } from 'react-i18next';
import SpinnerButton from '../../SpinnerButton.js';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: '-2rem',
    top: '-2rem',
    color: theme.palette.grey[500]
  },
  tricksToClaim: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  reason: {
    marginTop: '1rem'
  }
}));

const MakeClaim = (props) => {
  const { table, onMakingClaimClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const maxTricksPossible = 13 - (table.nsTricks + table.ewTricks);
  const [tricksClaimed, setTricksClaimed] = useState(maxTricksPossible);
  const [reason, setReason] = useState('');
  const [makeClaim, { called, error }] = usePPMutation(tableMutations.makeClaim, { memoize: true });

  const handleDropdownChange = useCallback((e) => {
    setTricksClaimed(e.target.value);
  }, [setTricksClaimed]);

  const handleReasonChange = useCallback((e) => {
    setReason(e.target.value);
  }, [setReason]);

  const handleSubmitClaim = useCallback(() => {
    if (!reason || called) { return; }

    makeClaim({
      variables: {
        reason,
        tricksClaimed,
        tableId: table.id
      }
    });
  }, [makeClaim, reason, tricksClaimed, table.id, called]);

  const trickOptions = [];
  for (let i = maxTricksPossible; i >= 0; i--) {
    trickOptions.push(i);
  }

  const isNarrow = useMediaQuery('(max-width:1280px)');

  return (
    <div className={classes.root}>
      <IconButton className={classes.closeButton} onClick={onMakingClaimClose}>
        <CloseIcon />
      </IconButton>
      <ClaimSpeechBubble table={table}>
        <div className={classes.tricksToClaim}>
          <TranslationWithJSX
            id={`${isNarrow ? 'claim' : 'i-want-to-claim'}-tricks-because`}
            translationProps={{
              n: (
                <>
                  &nbsp;&nbsp;
                  <FormControl>
                    <Select
                      value={tricksClaimed}
                      onChange={handleDropdownChange}
                    >
                      {trickOptions.map(i => (
                        <MenuItem key={i} value={i}>{i}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  &nbsp;&nbsp;
                </>
              )
            }}
          />
        </div>
        <div className={classes.reason}>
          <InputBase
            multiline
            variant='outlined'
            maxRows={isNarrow ? 3 : 5}
            onChange={handleReasonChange}
            placeholder={t('type-your-reason-here')}
            value={reason}
            fullWidth
          />
        </div>
      </ClaimSpeechBubble>
      <Grid
        justify='space-between'
        container
      >
        <Grid item>
          <Button
            onClick={onMakingClaimClose}
          >
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Tooltip arrow title={<Typography>{t('you-must-provide-a-reason')}</Typography>} disableHoverListener={reason}>
            <div>
              <SpinnerButton
                onClick={handleSubmitClaim}
                disabled={!reason}
                variant='contained'
                color='primary'
                loading={called && !error}
              >
                {t('claim-n-tricks', { n: tricksClaimed })}
              </SpinnerButton>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

export default MakeClaim;
