import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Tab } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  header: {
    background: theme.palette.block.header.background,
    color: theme.palette.block.header.color,
    height: '6rem',
    padding: '0 2rem',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '2rem'
  },
  headerDark: {
    background: theme.palette.background.dark
  },
  tab: {
    color: theme.palette.block.header.color
  }
}));

const ChatHeader = (props) => {
  const { headerText, tabs, theme, currentThreadId, onTabChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleTabChange = useCallback((e, val) => {
    onTabChange(val);
  }, [onTabChange]);

  if (!tabs && !headerText) {
    return null;
  }

  return (
    <Grid item>
      <div className={clsx(classes.header, theme === 'dark' && classes.headerDark)}>
        {
          tabs
            ? (
              <div>
                <Tabs
                  onChange={handleTabChange}
                  value={currentThreadId}
                >
                  {tabs.map(tab => (
                    <Tab
                      key={tab.threadId}
                      label={tab.headerText}
                      value={tab.threadId}
                    />
                  ))}
                </Tabs>
              </div>
            )
            : (
              <Grid
                container
                direction='row'
                justify='space-between'
                alignItems='center'
              >
                <Grid item>
                  {headerText || t('chat')}
                </Grid>
              </Grid>
            )
        }
      </div>
    </Grid>
  );
};

ChatHeader.propTypes = {
  tabs: PropTypes.array,
  currentThreadId: PropTypes.string,
  onTabChange: PropTypes.func,
  headerText: PropTypes.string,
  theme: PropTypes.string
};

export default ChatHeader;
