import React from 'react';
import ActionBanner from '../../../Components/ActionBanner.js';
import { useTranslation } from 'react-i18next';

const GameFinishedBanner = props => {
  const { session, history } = props;
  const { t } = useTranslation();

  if (session.resultsPublished) {
    return (
      <ActionBanner headingText='game-finished'>
        {t('the-game-has-now-finished-and-results')}
      </ActionBanner>
    );
  } else {
    return (
      <ActionBanner
        headingText={t('game-finished')}
        buttonText={t('review-and-download-the-results')}
        onButtonClick={() => {
          history.push('/director/results');
        }}
      >
        {t('please-review-and-amend-the-results')}
      </ActionBanner>
    );
  }
};

export default GameFinishedBanner;
