import gql from 'graphql-tag';
import { pairFragments } from '../fragments/';

const pairSubscriptions = {
  pairUpdate: gql`
    subscription {
      pairUpdate {
        type
        pair {
          ...FullPair
        }
      }
    }
    ${pairFragments.fullPair}
  `
};

export default pairSubscriptions;
