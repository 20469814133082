import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '../Card.js';

const useStyles = makeStyles(() => ({
  card: {
    position: 'relative',
    display: 'inline-block',
    top: 0,
    margin: 0,
    padding: 0,
    width: '13em',
    height: '18.2em',
    transition: 'all 0.2s ease-in-out',
    transitionProperty: 'transform, margin, width',
    pointerEvents: 'auto',
    borderRadius: '0.6em',
    transform: 'translate3d(0, 0, 0)',
    '&:after': { // card shadow for faded cards
      display: 'block',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: 'url("/img/cards/bg_overlay.png") repeat left top',
      transition: 'opacity 0.2s ease-in-out',
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  focusedSuit: {
    marginRight: 0
  },
  innerCard: {
    overflow: 'hidden',
    transition: 'transform 0.2s ease-in-out',
  },
  layoutCard: {
    width: '100%',
    height: '100%',
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    top: 0
  },
  stack: {
    // TODO fix
    marginRight: '-8.5em !important'
  },
  cardInTopStack: {
    display: 'block',
    marginLeft: '0 !important',
    marginTop: '0 !important',
    marginRight: '0 !important'
  },
  // cards are only faded when a different suit is enabled for playing
  faded: {
    '&:after': { // card shadow for disabled cards
      opacity: 1
    }
  },
  enabled: {
    '&:hover $innerCard': {
      transform: 'translate3d(0, -2em, 0)'
    }
  },
  invisible: {
    visibility: 'hidden',
    width: '8.5em',
    height: 0,
    opacity: 0,
    marginRight: '-8.5em',
    '& img, &:after': {
      display: 'none'
    },
    '&$cardInTopStack': {
      margin: '0 !important'
    }
  }
}));

const CardWrapper = (props) => {
  const {
    card,
    isStack,
    isTop,
    enabled,
    onCardClick,
    cardRefCallback,
    focusedSuit,
    autoPlay,
    faded,
    bottomMargin,
    ...other
  } = props;
  const cardValue = `${card.rank}${card.suit}`;
  const classes = useStyles();
  const style = {};

  const isTopStack = isTop && isStack;

  let {offsetX, offsetY, transformOrigin, rotate} = card;
  let layoutCardTransform;
  if (isStack || focusedSuit || !card.visible) {
    offsetX = offsetY = rotate = layoutCardTransform = transformOrigin = 0;
    layoutCardTransform = `rotate(0)`;
  } else {
    style.transformOrigin = transformOrigin;
    style.transform = `rotate(${rotate}deg) translateY(${offsetY}em)`;
    style.marginLeft = offsetX + 'em';
    // the layout card is a div that has the same dimensions as the card, but with rotation undone
    //
    // it's necessary so CardPlay can get the correct boundingClientRect
    // when figuring out the coordinates for the start of the animation
    //
    // if we were to use the root div of this component, the bounding rect will be wider because of the rotated edges
    // which would throw off the starting width of the animated card
    //
    // rotation is passed separately for the same reason
    layoutCardTransform = `rotate(${-rotate}deg)`;
  }

  if (bottomMargin) {
    style.marginBottom = bottomMargin + 'em';
  }

  // autoplay singletons
  useEffect(() => {
    if (!autoPlay || !card.visible) { return; }

    const timeout = setTimeout(() => {
      onCardClick({
        cardValue: cardValue,
        autoplayed: true
      });
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, [autoPlay, onCardClick, cardValue, card.visible]);

  return (
    <div
      className={
        clsx(
          classes.card,
          enabled && classes.enabled,
          faded && classes.faded,
          isStack && classes.stack,
          isTopStack && classes.cardInTopStack,
          card.visible === false && classes.invisible,
          focusedSuit && classes.focusedSuit
        )
      }
      style={style}
    >
      {/* extra div so we can animate the hover separately */}
      <div className={classes.innerCard}>
        <Card
          value={cardValue}
          onClick={onCardClick}
          enabled={enabled}
          {...other}
        />
      </div>
      {/*
        passing cardValue this way looks hacky, and we could instead pass it as a data-* property to CardPlay
        however, that would show the card value in the DOM, which would make it easier
        for a tech-savvy player to easily look up opponents' card values
      */}
      <div
        className={classes.layoutCard}
        style={{transform: layoutCardTransform}}
        data-rotation={rotate}
        ref={
          cardRefCallback && (
            (el) => cardRefCallback(el, cardValue)
          )
        }
      >
      </div>
    </div>
  );
};

CardWrapper.propTypes = {
  card: PropTypes.object.isRequired,
  cardRefCallback: PropTypes.func,
  faceDown: PropTypes.bool,
  enabled: PropTypes.bool,
  onCardClick: PropTypes.func,
  isStack: PropTypes.bool,
  isTop: PropTypes.bool,
  focusedSuit: PropTypes.string,
  cardsInStack: PropTypes.number
};

export default CardWrapper;
