import React, { useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { PPSubscription } from '../../Components/SubscriptionQueries/';
import PPSnackbarContext from '../../Components/PPSnackbar/PPSnackbarContext.js';
import { tableSubscriptions } from '../../graphql/subscriptions';
import { tableMutations } from '../../graphql/mutations';
import { usePPMutation } from '../../hooks/';
import { useTranslation } from 'react-i18next';

// Shows a message if the director gets called by a table
// that the director isn't looking at

const useStyles = makeStyles(theme => ({
  button: {
    color: '#000000de'
  }
}));

const CallNotifier = (props) => {
  const { currentTable, children } = props;
  const classes = useStyles();
  const history = useHistory();
  const [setDirectorAtTable] = usePPMutation(tableMutations.setDirectorAtTable);
  const { openSnackbar, closeSnackbar } = useContext(PPSnackbarContext);
  const { t } = useTranslation();

  const handleButtonClick = useCallback(async (notificationTableId) => {
    // if the director is at a table, have them leave it...
    if (currentTable) {
      await setDirectorAtTable({
        variables: {
          tableId: currentTable.id,
          directorAtTable: false
        }
      });
    }

    // ... then go to the next one
    await setDirectorAtTable({
      variables: {
        tableId: notificationTableId,
        directorAtTable: true
      }
    });

    history.push(`/director/table/${notificationTableId}`);
    closeSnackbar(notificationTableId);
  }, [closeSnackbar, history, currentTable, setDirectorAtTable]);

  const showMessage = useCallback((message, tableId) => {
    openSnackbar(
      tableId,
      message,
      'warning',
      {
        // disables auto hide
        autoHideDuration: null,
        noClickAway: true,
        action: (
          <Button
            size='small'
            className={classes.button}
            onClick={() => { handleButtonClick(tableId); }}
          >
            {t('go-to-table')}
          </Button>
        )
      }
    );
  }, [handleButtonClick, openSnackbar, classes.button, t]);

  const handleDirectorCallSubscriptionData = useCallback(({ data }) => {
    const { directorCallStatus, tableNumber, tableId } = data.directorCallUpdate;
    const directorCalledMessage = t('director-called-by-table-n', { n: tableNumber + 1 });

    if (directorCallStatus !== 'DirectorCalled') { return; }

    showMessage(directorCalledMessage, tableId);
  }, [showMessage, t]);

  const handleDirectorTurnSubscriptionData = useCallback(({ data }) => {
    const { tableNumber, tableId } = data.directorTurn;
    const yourTurnMessage = t('your-turn-at-table-n', { n: tableNumber + 1 });

    if (!currentTable || currentTable.id !== tableId) {
      showMessage(yourTurnMessage, tableId);
    }
  }, [showMessage, currentTable, t]);

  return (
    <PPSubscription
      subscription={tableSubscriptions.directorCallUpdate}
      onSubscriptionData={handleDirectorCallSubscriptionData}
    >
      {() => (
        <PPSubscription
          subscription={tableSubscriptions.directorTurn}
          onSubscriptionData={handleDirectorTurnSubscriptionData}
        >
          {() => {
            return children;
          }}
        </PPSubscription>
      )}
    </PPSubscription>
  );
};

export default CallNotifier;
