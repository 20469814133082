import React from 'react';
import ClaimSpeechBubble from './ClaimSpeechBubble.js';
import { Trans } from 'react-i18next';

const ClaimDescription = (props) => {
  const { table } = props;

  const { tricksClaimed, reason } = table.claim;

  // in the translation string:
  // n = tricks claimed
  // nn = tricks remaining
  // these names need to match what's in the i18n key
  const n = tricksClaimed;
  const nn = 13 - (table.nsTricks + table.ewTricks);

  return (
    <ClaimSpeechBubble
      table={table}
    >
      {/* Trans component used to allow use of html tags */}
      <Trans
        i18nKey={`i-would-like-to-claim-n-tricks`}
      >
        I would like to <strong>claim {{n}} tricks (of {{nn}})</strong> because
      </Trans>
      <strong>: {reason}</strong>
    </ClaimSpeechBubble>
  );
};

export default ClaimDescription;
