import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import client, { wsClient } from './graphql/client.js';
import { Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { getTheme } from './mui/theme.js';
import { getAppDisplayName, getSourceAppName } from './helpers/url.js';
import ErrorBoundary from './Components/Errors/ErrorBoundary.js';
import { PPRouter } from './Components/Routing/';
import { ReconnectionMonitorProvider } from './Components/ReconnectionMonitorContext.js';
import ResolutionNotSupported from './Components/ResolutionNotSupported.js';
import DeviceNotSupported from './Components/DeviceNotSupported.js';
import Session from './Views/Session.js';
import { Login, Logout } from './Views/Account';
import { Error403, Error404 } from './Views/Error';
import { PPSnackbarProvider } from './Components/PPSnackbar/PPSnackbarContext.js';
import { stopReportingRuntimeErrors } from 'react-error-overlay';
import { Settings } from 'luxon';
import InitialLoad from './InitialLoad.js';

// disable error overlay - it's way too slow,
// esp. when developing with 4 tabs in containers and having an expected error like restarting the local server
// see: https://stackoverflow.com/a/62857214/484538
if (process.env.NODE_ENV === 'local') {
  stopReportingRuntimeErrors();
}

const sourceAppName = getSourceAppName(window.location.hostname);
const theme = getTheme(sourceAppName);

const useStyles = makeStyles({
  '@global': {
    html: {
      fontSize: '10px',
      height: '100%',
      overflow: 'hidden',
      userSelect: 'text',
      '@media all and (max-height:800px)': {
        fontSize: '9px'
      },
      '@media all and (max-height:600px)': {
        fontSize: '8px'
      }
    },
    body: {
      fontSize: '1.6rem',
      height: '100%',
      overflow: 'hidden',
      background: theme.palette.background.main
    },
    '#root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  root: {
    background: theme.palette.background.main,
    color: '#fff',
    position: 'fixed',
    width: '100%',
    height: '100%'
  },
  contentContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    overflowY: 'auto'
  }
});

const App = () => {
  const classes = useStyles();

  useEffect(() => {
    // set document title to 'Pianola Play' or 'Bridgemate Play'
    document.title = getAppDisplayName();

    // set favicon
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = `/${sourceAppName}-favicon.ico`;
    document.getElementsByTagName('head')[0].appendChild(link);

    // Set Luxon defaults (changed to user preference in session.js on login)
    Settings.defaultLocale = 'en-GB';
  }, []);

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          <PPSnackbarProvider>
            <PPRouter>
              <CssBaseline />
              <DeviceNotSupported />
              <ReconnectionMonitorProvider wsClient={wsClient}>
                <div className={classes.root}>
                  <div className={classes.contentContainer}>
                    <Switch>
                      <Route exact path='/login' component={Login} />
                      <Route exact path='/logout' component={Logout} />
                      <Route exact path='/error403' component={Error403} />
                      <Route exact path='/error404' component={Error404} />
                      <Route
                        path='/' render={() => (
                          <InitialLoad>
                            {(currentSession, currentUser, currentUserSettings) =>
                              <>
                                <ResolutionNotSupported />
                                <Session
                                  currentSession={currentSession}
                                  currentUser={currentUser}
                                  currentUserSettings={currentUserSettings}
                                />
                              </>
                            }
                          </InitialLoad>
                        )}
                      />
                    </Switch>
                  </div>
                </div>
              </ReconnectionMonitorProvider>
            </PPRouter>
          </PPSnackbarProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
