import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import BackIcon from '@material-ui/icons/ArrowBack';
import SharedGame from '../../../Components/Game/Game.js';
import { tableMutations } from '../../../graphql/mutations';
import { usePPMutation } from '../../../hooks';
import { getPlayerSeatForTable } from '../../../helpers/game.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  logoContainer: {
    position: 'fixed',
    left: 0,
    top: '1rem',
    zIndex: 1,
    color: '#fff'
  },
  backButton: {
    color: 'rgba(255,255,255,0.5)'
  },
  logo: {
    width: theme.logo.gameWidth,
    height: theme.logo.gameHeight,
    background: `transparent url("/img/${theme.logo.image}.svg") no-repeat center center`,
    backgroundSize: 'contain',
    cursor: 'pointer'
  }
}));

const Game = (props) => {
  const { table, refetch, session, currentUser, onCardPlayed, onBidMade, nextTableId } = props;
  const classes = useStyles();
  const history = useHistory();
  const [setDirectorAtTable, { called, reset }] = usePPMutation(tableMutations.setDirectorAtTable);
  const { t } = useTranslation();

  const tableId = table && table.id;
  const tableStatus = table && table.status;

  const handleLeaveClick = useCallback(async () => {
    if (called) { return; }

    await setDirectorAtTable({
      variables: {
        tableId: tableId,
        directorAtTable: false
      }
    });

    reset();
    history.push('/director');
  }, [history, tableId, setDirectorAtTable, called, reset]);

  // players have an easy mechanism to get them to the next table (just refetch currentTableForPlayer),
  // but the director doesn't have that luxury, so we expect a nextTableId in the final notification
  useEffect(() => {
    let timeout;

    // TODO we don't have a way for the director to join the end game lobby at the moment
    // until then, boot the director to the dashboard when a table plays their last board of the game
    if (tableStatus === 'SessionFinished') {
      timeout = setTimeout(handleLeaveClick, 500);
    }

    if (tableStatus === 'InactiveFinished' && nextTableId) {
      history.push('/director/table/' + nextTableId);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [tableStatus, handleLeaveClick, nextTableId, history]);

  if (!table) {
    return null;
  }

  let readOnly = true;
  let localTable = table;

  // playing director:
  // make the table not readOnly and place the director at the correct viewpoint
  const directorSeat = getPlayerSeatForTable(currentUser.id, table);
  if (directorSeat) {
    readOnly = false;
    localTable = {
      ...table,
      viewpoint: directorSeat
    };
  }

  return (
    <>
      <div className={classes.logoContainer}>
        <Grid
          container
          alignItems='center'
          justify='center'
        >
          <Grid item>
            <Tooltip arrow title={<Typography>{t('back-to-dashboard')}</Typography>}>
              <IconButton className={classes.backButton} onClick={handleLeaveClick}>
                <BackIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <div className={classes.logo} onClick={handleLeaveClick}></div>
          </Grid>
        </Grid>
      </div>
      <SharedGame
        table={localTable}
        session={session}
        refetch={refetch}
        currentUser={currentUser}
        readOnly={readOnly}
        onCardPlayed={onCardPlayed}
        onBidMade={onBidMade}
      />
    </>
  );
};

export default Game;
