import gql from 'graphql-tag';
import { tableFragments, pairFragments, boardFragments } from '../fragments';

const tableQueries = {
  tablesInProgress: gql`
    query {
      tablesInProgress {
        id
        nsPair {
          ...FullPair
        }
        ewPair {
          ...FullPair
        }
        northPlayerId
        eastPlayerId
        boardNumber
        boardsPerRound
        tableNumber
        roundNumber
        orderInRound
        directorCallStatus
        directorCallerId
        nsTricks
        ewTricks
        isSitOut
        cardPlay {
          index
          cards {
            step
          }
        }
        status
        activeSeat
        declarer
      }
    }
    ${pairFragments.fullPair}
  `,
  tablesInProgressShort: gql`
    query {
      tablesInProgress {
        id
        tableNumber
        status
        isSitOut
        nsTricks
        ewTricks
      }
    }
  `,
  currentTableForPlayer: gql`
    query {
      currentTableForPlayer {
        ...FullTable
      }
    }
    ${tableFragments.fullTable}
  `,
  tableById: gql`
    query ($id: ID!) {
      tableById(id: $id) {
        ...FullTable
      }
    }
    ${tableFragments.fullTable}
  `,
  tableDeal: gql`
    query ($id: ID!) {
      tableById(id: $id) {
        id
        board {
          id
          deal {
            rank
            suit
            seat
          }
        }
      }
    }
  `,
  tablesForTravellers: gql`
    query (
      $boardNumber: Int
    ) {
      tablesForTravellers (
        boardNumber: $boardNumber
      ) {
        ...ResultTable
      }
    }
    ${tableFragments.resultTable}
  `,
  tablesForTravellersWithBoard: gql`
    query (
      $boardNumber: Int
    ) {
      tablesForTravellers (
        boardNumber: $boardNumber
      ) {
        ...ResultTable
        board {
          ...FullBoard
        }
      }
    }
    ${tableFragments.resultTable}
    ${boardFragments.fullBoard}
  `,
  tablesForScorecards: gql`
    query (
      $pairId: ID
    ) {
      tablesForScorecards (
        pairId: $pairId
      ) {
        ...ResultTable
      }
    }
    ${tableFragments.resultTable}
  `,
  finishedTablesForRound: gql`
    query (
      $tableNumber: Int!,
      $roundNumber: Int!
    ) {
      finishedTablesForRound (
        tableNumber: $tableNumber,
        roundNumber: $roundNumber
      ) {
        ...TableForInterstitial
      }
    }
    ${tableFragments.tableForInterstitial}
  `
};

export default tableQueries;
