import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import Deal from '../../../Components/Deal/Deal.js';
import AuctionTable from '../../../Components/Game/Auction/AuctionTable.js';
import PPDialog from '../../../Components/PPDialog.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  content: {
    background: theme.palette.block.content.background,
    minWidth: '26rem',
    textAlign: 'center'
  }
}));

const DealModal = (props) => {
  const { open, onClose, table, isPlayingDirector } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [showingWarning, setShowingWarning] = useState(isPlayingDirector);

  const handleContinueClick = () => {
    setShowingWarning(false);
  };

  return (
    <PPDialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      title={t('hand-and-auction-for-board', { n: table.board.number + 1 })}
      contentClassName={classes.content}
    >
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        spacing={2}
      >
        {
          showingWarning
            ? (
              <Grid item>
                <p>{t('this-will-reveal-the-hands-of-your-opponents')}</p>
                <Button onClick={handleContinueClick}>{t('continue')}</Button>
              </Grid>
            )
            : (
              <>
                <Grid item>
                  <Deal
                    dealer={table.board.dealer}
                    viewpoint='S'
                    vulnerable={table.board.vulnerable}
                    cards={table.board.deal}
                  />
                </Grid>
                <Grid item>
                  <AuctionTable table={table} />
                </Grid>
              </>
            )
        }
      </Grid>
    </PPDialog>
  );
};

export default DealModal;
