import gql from 'graphql-tag';

const userSettingsQueries = {
  currentUserSettings: gql`
    query {
      currentUserSettings {
        userId
        settings
      }
    }
  `
};

export default userSettingsQueries;
