import React from 'react';
import { Query } from 'react-apollo';
import PPSubscription from './PPSubscription.js';
import { pairQueries } from '../../graphql/queries/';
import { pairSubscriptions } from '../../graphql/subscriptions/';
import { populatePairsWithUserData } from '../../helpers/users.js';
import Users from './Users.js';

class Lobby extends React.Component {
  state = {
    pairs: []
  };

  handlePairQueryResult = ({ pairs }) => {
    this.setState({ pairs });
  };

  handlePairSubscriptionData = ({ data }) => {
    const { type, pair: updatedPair } = data.pairUpdate;

    const newPairs = this.state.pairs.concat();
    const idx = newPairs.findIndex(pair => pair.id === updatedPair.id);

    if (type === 'Deleted') {
      newPairs.splice(idx, 1);
    } else if (type === 'Created' && idx === -1) {
      newPairs.push(updatedPair);
    }

    this.setState({ pairs: newPairs });
  };

  render() {
    return (
      <Users>
        {(users) => (
          <Query
            query={pairQueries.pairs}
            fetchPolicy='no-cache'
            onCompleted={this.handlePairQueryResult}
          >
            {() => (
              <PPSubscription
                subscription={pairSubscriptions.pairUpdate}
                onSubscriptionData={this.handlePairSubscriptionData}
              >
                {() => {
                  const pairs = populatePairsWithUserData({
                    pairs: this.state.pairs,
                    users
                  });
                  return this.props.children({
                    pairs,
                    users
                  });
                }}
              </PPSubscription>
            )}
          </Query>
        )}
      </Users>
    );
  }
}

export default Lobby;
