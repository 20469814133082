import React from 'react';
import PossiblePartner from '../../../Components/Lobby/PossiblePartner.js';

const ClubMembers = (props) => {
  const { users, currentUser, canMarkAsPartner } = props;

  const filteredUsers = users.filter(user => {
    // no need to show the user currently logged in
    // or users who are not players
    if (user.id === currentUser.id || user.type !== 'Player') {
      return false;
    }

    // only show users that aren't in a pair
    return user.gameStatus === 'NotInPair';
  }).sort((a, b) => {
    // show online users first
    if (a.onlineStatus === 'Offline') { return 1; }
    if (b.onlineStatus === 'Offline') { return -1; }
    return 0;
  });

  return (
    <>
      {filteredUsers.map(user => (
        <PossiblePartner
          key={user.id}
          user={user}
          currentUser={currentUser}
          canMarkAsPartner={canMarkAsPartner}
        />
      ))}
    </>
  );
};

export default ClubMembers;
