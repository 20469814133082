import gql from 'graphql-tag';
import { pairFragments } from '../fragments';

const rankingQueries = {
  rankings: gql`
    query {
      rankings {
        rankings {
          place
          pair {
            ...FullPair
          }
          score
          position
        }
        sessionFinished
      }
    }
    ${pairFragments.fullPair}
  `
};

export default rankingQueries;
