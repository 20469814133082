import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { cardHelpers } from 'shared-helpers';
import StackCardSuitGroup from './StackCardSuitGroup.js';
import { checkForSingleton } from '../../../helpers/card.js';
import { useComponentScale } from '../../../hooks';

const {
  getCardsForSuit,
  getOrderOfSuitsForCardList
} = cardHelpers;

const useStyles = makeStyles(() => ({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: '0 auto',
    textAlign: 'center',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    fontSize: 0,
    width: '100%',
    pointerEvents: 'none'
  },
  index0: {
    paddingTop: '7rem',
    '@media all and (max-width:1194px)': {
      paddingTop: '5.5rem'
    }
  },
  index1: {
    paddingRight: '7rem',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '45vh',
    '@media all and (max-width:1194px)': {
      paddingRight: '5.5rem'
    }
  },
  index2: {
    paddingBottom: '1rem'
  },
  index3: {
    paddingLeft: '7rem',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '45vh',
    '@media all and (max-width:1194px)': {
      paddingLeft: '5.5rem'
    }
  }
}));

const StackCardList = (props) => {
  const { index, cards, faceDown, enabledSuit, contract, trickNumber, autoPlaySingletons, ...other } = props;

  const suits = getOrderOfSuitsForCardList(contract, index, true);
  const classes = useStyles();
  const containerRef = useRef(null);
  const seatIndex = index;

  const autoPlay = autoPlaySingletons && checkForSingleton(cards, enabledSuit);

  const componentScale = useComponentScale();
  const scale = componentScale / 1.3;
  const isVerticalStack = index === 0 || index === 2;

  // when the element gets rendered, fix its height so removing cards
  // doesn't cause the trick to jitter around unexpectedly
  useEffect(() => {
    const el = containerRef.current;

    if (!el || !isVerticalStack) { return; }

    el.style.height = 'auto';
    // if we're recalculating these because component scale changed,
    // cards will be affected too, and if we fix the stack's height
    // before their height settles, the stack will appear off
    const timeout = setTimeout(() => {
      el.style.height = window.getComputedStyle(el).height;
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [containerRef, trickNumber, isVerticalStack, scale]);

  return (
    <div className={
      clsx(
        classes.list,
        classes[`index${index}`]
      )}
      ref={containerRef}
    >
    {
      suits.map((suit, index) => {
        const suitCards = getCardsForSuit(cards, suit);
        // enable cards that the player can click
        const enabled = suit === enabledSuit || enabledSuit === 'all';
        // only fade out the other cards if there is an enabled suit
        const faded = enabledSuit && !enabled;

        return (
          <StackCardSuitGroup
            key={index}
            seatIndex={seatIndex}
            cards={suitCards}
            faceDown={faceDown}
            enabled={enabled}
            faded={faded}
            scale={scale}
            autoPlay={enabled && autoPlay}
            {...other}
          />
        );
      })
    }
    </div>
  );
};

StackCardList.propTypes = {
  index: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  faceDown: PropTypes.bool.isRequired,
  enabledSuit: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  cardRefCallback: PropTypes.func
};

export default StackCardList;
