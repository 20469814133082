import React, { useCallback, useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import SpreadCardList from '../Play/SpreadCardList.js';
import BiddingBox from '../../BiddingBox/BiddingBox.js'
import BidsBySeat from './BidsBySeat.js';
import { tableMutations } from '../../../graphql/mutations';
import { usePPMutation, useComponentScale, useLocalAuction } from '../../../hooks';
import { getPlayersBySeat } from '../../../helpers/game.js';
import { cardHelpers } from 'shared-helpers';
import ConfirmBidModal from './ConfirmBidModal.js';
import SoundEffectsContext from '../../SoundEffects/SoundEffectsContext.js';
import UserSettingsContext from '../../UserSettings/UserSettingsContext.js';
import clsx from 'clsx';
import PlayerSeats from '../PlayerSeats/PlayerSeats.js';

const { arrangeSeatsBasedOnViewPoint } = cardHelpers;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0, top: 0, right: 0, bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  flexGrow: {
    flex: 1
  },
  flexAuto: {
    flex: 'auto'
  },
  rowFirst: {
    paddingBottom: '1rem'
  },
  rowMiddle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rowLast: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingTop: '1rem'
  },
  columnFirst: {
    paddingRight: '1rem'
  },
  columnMiddle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0'
  },
  columnLast: {
    paddingLeft: '1rem'
  },
  biddingBox: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '7em',
    top: '7em',
    right: '7em',
    bottom: '7em'
  },
  cardSpreads: {
    '& div': {
      pointerEvents: 'none',
      '&:after': {
        display: 'none'
      }
    },
  }
}));

const Auction = (props) => {
  const { table, onBidMade, forceCardsFaceUp } = props;
  const [bidToConfirm, setBidToConfirm] = useState(null);
  const [makeBid] = usePPMutation(tableMutations.makeBid);
  const componentScale = useComponentScale();
  const classes = useStyles();
  const playSound = useContext(SoundEffectsContext);
  const { simplifiedCards, simplifiedLayout } = useContext(UserSettingsContext);

  const { localTable, onBidMade: onLocalTableBidMade } = useLocalAuction(table);

  const cardsBySeat = { W: [], N: [], E: [], S: [] };

  localTable.board.deal.forEach(card => {
    cardsBySeat[card.seat].push({
      ...card,
      visible: true
    });
  });

  const isOurTurn = localTable.viewpoint === localTable.activeSeat && localTable.directorCallStatus !== 'DirectorCalled';

  useEffect(() => {
    if (isOurTurn) {
      playSound('yourTurn');
    }
  }, [isOurTurn, playSound]);

  const handleBidMade = useCallback((bid) => {
    setBidToConfirm(bid);
  }, [setBidToConfirm]);

  const handleConfirmBidClose = useCallback(() => {
    setBidToConfirm(null);
  }, [setBidToConfirm]);

  const handleBidConfirm = useCallback(async (bid) => {
    onLocalTableBidMade(bid);

    const result = await makeBid({
      variables: {
        tableId: table.id,
        ...bid
      }
    });
    onBidMade(result);
  }, [table.id, makeBid, onBidMade, onLocalTableBidMade]);

  // pop the bids in arrays based on direction
  const viewpoint = localTable.viewpoint || 'S';
  const seats = arrangeSeatsBasedOnViewPoint(viewpoint);

  const getCardsForDirection = (i) => {
    const seat = seats[i];
    return (
      <div
        className={clsx(classes.cardSpreads)}
        key={i}
      >
        <SpreadCardList
          cards={cardsBySeat[seat.value]}
          index={i}
          faceDown={i !== 2 && !forceCardsFaceUp}
          enabledSuit='none'
          simplifiedCards={simplifiedCards.value}
          straightLine={simplifiedLayout.value}
          contract={{ denomination: 'NT' }}
        />
      </div>
    );
  };

  return (
    <>
      <div className={clsx(classes.root)}>
        <div className={clsx(classes.rowFirst, classes.flexAuto)}>
          {getCardsForDirection(0)}
        </div>
        <div className={clsx(classes.rowMiddle, classes.flexAuto)}>
          <div className={clsx(classes.columnFirst, classes.flexGrow)}>
            {getCardsForDirection(3)}
          </div>
          <div className={clsx(classes.columnMiddle, classes.flexGrow)}>
            <BidsBySeat
              auction={localTable.auction}
              dealer={localTable.board.dealer}
              viewpoint={viewpoint}
              scale={componentScale / 1.1}
              shrink={forceCardsFaceUp}
            >
              <div className={classes.biddingBox}>
                <BiddingBox
                  onClick={handleBidMade}
                  auction={localTable.auction}
                  dealer={localTable.board.dealer}
                  clickable={isOurTurn}
                />
              </div>
            </BidsBySeat>
          </div>
          <div className={clsx(classes.columnLast, classes.flexGrow)}>
            {getCardsForDirection(1)}
          </div>
        </div>
        <div className={clsx(classes.rowLast, classes.flexAuto)}>
          {getCardsForDirection(2)}
        </div>
      </div>
      <PlayerSeats
        playersBySeat={getPlayersBySeat(localTable)}
        viewpoint={localTable.viewpoint}
        activeSeat={localTable.activeSeat}
        dealer={localTable.board.dealer}
        declarer={localTable.declarer}
        vulnerable={localTable.board.vulnerable}
      />
      <ConfirmBidModal
        open={!!bidToConfirm}
        bid={bidToConfirm}
        onBidConfirm={handleBidConfirm}
        onClose={handleConfirmBidClose}
      />
    </>
  );
};

export default Auction;
