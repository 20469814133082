import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from './Icon.js';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: theme.palette.block.content.background,
    borderRadius: '0.5rem',
    overflow: 'hidden',
    color: '#222',
    height: '100%'
  },
  header: {
    background: '#fff',
    height: '6rem',
    padding: '0 2rem',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '2rem'
  },
  icon: {
    paddingTop: '1rem'
  },
  content: {
    position: 'absolute',
    top: '6.1rem',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '0 2rem 2rem 2rem',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  noPadding: {
    padding: 0
  }
}));

const Block = (props) => {
  const { children, heading, icon, iconTooltip, iconColor } = props;
  const classes = useStyles();

  // set bools to false if it's not passed in as prop
  let { noPadding } = props;
  if (typeof noPadding === 'undefined') {
    noPadding = false;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid
          container
          alignItems='center'
        >
          <Grid item xs zeroMinWidth>
            {heading}
          </Grid>
          <Grid item className={classes.icon}>
            {
              icon && (
                <Icon type={icon} color={iconColor} tooltip={iconTooltip} />
              )
            }
          </Grid>
        </Grid>
      </div>
      <div className={clsx(classes.content, noPadding && classes.noPadding)}>
        {children}
      </div>
    </div>
  );
};

export default Block;
