import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import clsx from 'clsx';

const useStyles = makeStyles({
  indicator: {
    boxShadow: '0 0 0 0.1rem #fff'
  },
  online: {
    backgroundColor: '#73D801'
  },
  away: {
    backgroundColor: '#FF9800'
  },
  offline: {
    backgroundColor: '#D40305'
  }
});

const OnlineStatusIndicator = (props) => {
  const { onlineStatus, children } = props;
  const classes = useStyles();

  if (!onlineStatus) {
    return children;
  }

  return (
    <Badge
      overlap='circle'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      variant='dot'
      classes={{
        badge: clsx(classes.indicator, classes[onlineStatus.toLowerCase()])
      }}
    >
      {children}
    </Badge>
  );
};

OnlineStatusIndicator.propTypes = {
  onlineStatus: PropTypes.string
};

export default OnlineStatusIndicator;
