import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StatusTimer from '../../StatusTimer.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    textAlign: 'center'
  },
  timer: {
    fontSize: '18rem',
    fontWeight: 'bold',
    margin: 0
  }
}));

const SitOut = (props) => {
  const { session } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className={classes.container}
    >
      <Grid item>
        <p className={classes.timer}>
          <StatusTimer
            timeLastRoundStarted={session.timeLastRoundStarted}
            roundTimeLimit={session.roundTimeLimit}
          />
        </p>
        <p>
          {t('you-have-a-sit-out-for-this-round')}
        </p>
      </Grid>
    </Grid>
  );
};

export default SitOut;
