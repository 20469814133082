import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

/**
 * A bare-bones component that updates once a minute and shows the last time
 * a player has been seen
 *
 * It's on its own so it can be rerendered once a minute without touching
 * any components that would otherwise take more resources to rerender
 */
const LastSeenTime = (props) => {
  const { t } = useTranslation();
  const { lastSeen } = props;
  const lastSeenTime = lastSeen
    ? t('seen-time-ago', { time: DateTime.fromISO(lastSeen).toRelative() })
    : null;
  // pick an unimportant state so we can force React to rerender
  // eslint-disable-next-line
  const [_, setTime] = useState(Date.now());

  useEffect(() => {
    const updateInterval = setInterval(() => {
      setTime(Date.now());
    }, 60000);

    return function cleanup () {
      clearInterval(updateInterval);
    };
  });

  return lastSeenTime;
};

LastSeenTime.propTypes = {
  lastSeen: PropTypes.string
};

export default LastSeenTime;
