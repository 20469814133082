import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import DealModal from '../../../Views/Director/Game/DealModal.js';
import { tableMutations } from '../../../graphql/mutations';
import { usePPMutation } from '../../../hooks';
import { getPlayerSeatForTable } from '../../../helpers/game.js';
import ActionButton from './ActionButton.js';
import ClaimButton from './ClaimButton.js';
import SystemDescriptionButton from './SystemDescriptionButton.js';
import ViewHandIcon from '@material-ui/icons/ViewModuleRounded';
import LeaveTableIcon from '@material-ui/icons/ExitToAppRounded';
import { useTranslation } from 'react-i18next';

const DirectorActions = (props) => {
  const { table, currentUser, setMakingClaim } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [setDirectorAtTable, { called, reset }] = usePPMutation(tableMutations.setDirectorAtTable);
  const [showingDeal, setShowingDeal] = useState(false);

  const handleLeaveClick = useCallback(async () => {
    if (called) { return; }

    await setDirectorAtTable({
      variables: {
        tableId: table.id,
        directorAtTable: false
      }
    });

    reset();
    history.push('/director');
  }, [history, table.id, setDirectorAtTable, called, reset]);

  const handleShowDealClick = useCallback(() => {
    setShowingDeal(true);
  }, [setShowingDeal]);

  const handleModalClose = useCallback(() => {
    setShowingDeal(false);
  }, [setShowingDeal]);

  const isPlayingDirector = !!getPlayerSeatForTable(currentUser.id, table);

  return (
    <>
      <Grid
        container
        alignItems='center'
        wrap='nowrap'
      >
        {
          isPlayingDirector && (
            <Grid item>
              <ClaimButton
                table={table}
                setMakingClaim={setMakingClaim}
              />
            </Grid>
          )
        }
        {
          !table.isSitOut && (
            <Grid item>
              <ActionButton
                label={t('view-hand')}
                icon={<ViewHandIcon style={{ fontSize: 30 }} />}
                onClick={handleShowDealClick}
              />
            </Grid>
          )
        }
        <Grid item>
          <ActionButton
            label={t('leave')}
            icon={<LeaveTableIcon style={{ fontSize: 30 }} />}
            onClick={handleLeaveClick}
            tooltip={t('back-to-dashboard')}
          />
        </Grid>
        {
          isPlayingDirector && (
            <Grid item>
              <SystemDescriptionButton
                currentUser={currentUser}
              />
            </Grid>
          )
        }
      </Grid>
      {
        !table.isSitOut && (
          <DealModal
            open={showingDeal}
            onClose={handleModalClose}
            isPlayingDirector={isPlayingDirector}
            table={table}
          />
        )
      }
    </>
  );
};

export default DirectorActions;
