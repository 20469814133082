import React from 'react';
import PossiblePartner from '../../../Components/Lobby/PossiblePartner.js';
import BlockSubheading from '../../../Components/BlockSubheading.js';
import { useTranslation } from 'react-i18next';

const ClubMembers = (props) => {
  const { users, currentUser } = props;
  const { t } = useTranslation();

  const onlinePlayers = [];
  const offlinePlayers = [];

  users.forEach(user => {
    // only show player users
    if (user.type !== 'Player' ||
      // users that aren't in a pair
      user.gameStatus !== 'NotInPair') {
      return;
    }

    if (user.onlineStatus === 'Online' || user.onlineStatus === 'Away') {
      onlinePlayers.push(user);
    } else {
      offlinePlayers.push(user);
    }
  });

  return (
    <>
      <BlockSubheading>{t('online-now')} ({onlinePlayers.length})</BlockSubheading>
      {onlinePlayers.map(user => (
        <PossiblePartner
          user={user}
          key={user.id}
          currentUser={currentUser}
          canMarkAsPartner
        />
      ))}

      <BlockSubheading>{t('offline')} ({offlinePlayers.length})</BlockSubheading>
      {offlinePlayers.map(user => (
        <PossiblePartner
          key={user.id}
          user={user}
          currentUser={currentUser}
          canMarkAsPartner
        />
      ))}
    </>
  );
};

export default ClubMembers;
